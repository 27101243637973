@import "./variables.scss";

.react-responsive-modal-modal{
    overflow-y: visible;
}

.btn{
    text-align: center;
    border:1px solid #d1dbe2;
    cursor: pointer;
    &.btn-circle{
        width: 40px;
        height: 40px;
        border-radius: 50%;
        &:not(.btn-default){
            border:0px!important;
        }
        
        padding: 0px;
        *{
            font-size: 15px;
        }
        &.btn-sm{
            width: 30px;
            height: 30px;
            line-height: 30px;
        }
        &.btn-xs{
            width: 20px;
            height: 20px;
            line-height: 20px;
            *{
                font-size: 13px!important;
            }
        }
        
    }
    &.btn-rounded{
        border-radius: 20px;
        padding: 0px 10px;
        font-size: 14px;
        font-weight: 600;
        height: 30px;
        min-width: 100px;
        color: #3eb6ad;
        background:#fff;
        border:1px solid #d1dbe2;
        &.btn-lg{
            height: 40px
        }
        &:hover,&.active{
            color: #3eb6ad;
            border:1px solid #d1dbe2;
            background:#fff;
        }
    }
    &.btn-yellow{
        box-shadow: 0 18px 36px 0 rgba(239,194,91,0.5);
        background-image: linear-gradient(225deg,#fdd271,#d5a433);
        border: none;
        color: #fff;
        &:hover,&.active{
            box-shadow: 0 18px 36px 0 rgba(239,194,91,0.9);
            color: #fff;
            border: none;
            background-image: linear-gradient(225deg,#fdd271,#d5a433);
        }
    }
    &.btn-default{
        color: #3eb6ad;
        background:#fff;
        border:1px solid #3eb6ad;
        &:hover,&.active{
            color: #3eb6ad;
            border:1px solid #3eb6ad;
            background:#fff;
        }
    }
    &.btn-danger{
        box-shadow: 0 10px 20px 0 rgba(220, 53, 69, 0.5);
        background-image: linear-gradient(225deg, #dc3545, #dc3545);
        color: #fff;
        border: none;
        &:hover,&.active{
            box-shadow: 0 10px 20px 0 rgba(220, 53, 69, 0.5);
            background-image: linear-gradient(225deg, #dc3545, #dc3545);
            color: #fff;
            border: none;
        }
    }
    &.btn-green{
        box-shadow: 0 10px 20px 0 rgba(90, 202, 195, 0.5);
        background-image: linear-gradient(225deg, #72dcd6, #3eb6ad);
        color: #fff;
        border: none;
        &:hover,&.active{
            box-shadow: 0 10px 20px 0 rgba(90, 202, 195, 0.9);
            background-image: linear-gradient(225deg, #72dcd6, #3eb6ad);
            color: #fff;
            border: none;
        }
    }
    &.btn-gray{
        box-shadow: 0 10px 20px 0 rgba(210, 211, 211, 0.5);
        background-image: linear-gradient(225deg, #e4e8ec, #b1bfcd);
        color: #fff;
        border: 1px solid transparent;
        &:hover,&.active{
            box-shadow: 0 10px 20px 0 rgba(210, 211, 211, 0.9);
            color: #fff;
            border: 1px solid transparent;
            background-image: linear-gradient(225deg, #e4e8ec, #b1bfcd);
        }
    }
    &.btn-red{
        box-shadow: 0 10px 20px 0 rgba(251, 208, 185, 0.5);
        background-image: linear-gradient(140deg, #ffdac8, #ff6818);
        color: #fff;
        border: none;
        &:hover{
            box-shadow: 0 10px 20px 0 rgba(251, 208, 185, 0.9);
            color: #fff;
            border: none;
            background-image: linear-gradient(140deg, #ffdac8, #ff6818);
        }
    }
    &.btn-close-modal{
        position: absolute;
        top: 0;
        right: 0;
        z-index: 5;
    }
    .close_ico{
        font-family: montserratRegular;
        font-size: 38px;
        font-weight: normal;
        line-height: 1;
        text-shadow: 0 1px 0 #fff;
    }
    
}


.text-green{
    color: #5acac3;
}

.nav-link.active{
    @extend .btn-green
}

.dropdown .btn-rounded{
    color: inherit;
    position: relative;
    line-height: 15px;
    padding: 5px 10px;
    width:100%;
    text-align: left;
    &:hover{
        color: inherit;
    }
    &:after{
        content:none;
    }
    i.fa{
        position: absolute;
        right:5px;
        background: #cdd6dc;
        color:#fff;
        border-radius: 50%;
        padding: 2px;
        margin-top: -2px;
    }
}

.search_container{
    width: 100%;
    margin-top: 15px;
    display: flex;
    background-color: #f8f8f8;
    border: solid 0.5px #b1bfcd;
    border-radius: 10em;
    padding: 0.15em 0.8em 0.15em 0.1em;
    input{
        width: 100%;
        border: 0;
        background: transparent;
        padding: 0;
        margin: 0;
        vertical-align: top;
        padding-left: 12px;
        font-size: 13px;
    }
}

.no-border{
    border:none!important;
}


.form-control{
    height: 30px;
    &.is-invalid{
        background:none;
    }
}

form {
    .form-group{
        position:relative;
        .error_form{
            bottom: 0px;
            transform: translateY(-6px);
        }
    }
    .error_form{
        position: absolute;
        left: 100%;
        bottom: 1rem;
        z-index: 1000;
        object-fit: contain;
        box-shadow: 0 5px 45px 0 rgba(153,153,153,0.5);
        background-color: #ffffff;
        border-radius: 5px;
        padding: 5px 10px;
        width: 12vw;
        &.left{
            left: 0px;
            transform: translateX(-100%);
            &:after{
                transform: scaleX(-1);
                right: -0.8vw;  
            }
        }
        &.bottom{
            bottom:unset;
            left:50%;
            transform: translateX(-50%);
            top:100%;
            &:after{
                right: 50%;
                transform:translateX(50%);
                bottom:100%;
                border-color: transparent transparent #ffffff transparent;
            }
        }
        &.top{
            bottom:100%;
            left:50%;
            transform: translateX(-50%);
            &:after{
                right: 50%;
                transform:translateX(50%);
                bottom: -11px;
                border-color: #ffffff transparent transparent transparent;
            }
        }
        &:after{
            content: "";
            position: absolute;
            right: 100%;
            margin-top: -6px;
            border-width: 0.4vw;
            border-style: solid;
            border-color: transparent #ffffff transparent transparent;
            bottom: 7px;
        }
        span{
            opacity: 0.8;
            font-size: 13px;
            color: #f45656;
        }
    }
}

label.btn{
    line-height: 30px;
    input[type=file]{
        position:absolute;
    }
}

.bootstrap-select .btn.dropdown-toggle:after{
    vertical-align: .255em;
}

.text_overflow{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.statistics_exam .modal-dialog{
    max-width: 90%
    
}

.container-cms{
    /*height: calc(100vh - 200px);*/
    @media (min-width: 767px){
        margin: 20px 20px!important;
    }
    @media (min-width: 1200px){
        margin: 20px 30px!important;
    }

    @media (max-width: 767px){
        height: calc( 100vh - 144px);
    }
    .optiscroll-vtrack, .optiscroll-htrack{
        background: rgba(25, 86, 197, 0.3);
    }
    .item-list{
        margin:0px;
        *{
            font-size: 0.625vw;
        }
        h4{
            font-size: 1vw;
        }
        &.items-8{
            .item-block{    
                @media (min-width: $desktop) {
                    max-width: 11.8%;
                    flex: 11.8%;
                    margin: 0px 0.4% 12px 0.4%; 
                    &:nth-child(-n+1), &:nth-child(8n+1){
                        margin-left:0px;
                    }
                    &:nth-child(7n+1):not(:first-child){
                        margin-right:0px;
                    }
                }
                @media (min-width: $tablet) and (max-width: $desktop) {
                    max-width: 19.2%;
                    flex: 19.2%;
                    margin:0px 0.5% 12px 0.5%; 
                    &:nth-child(-n+1), &:nth-child(6n+1){
                        margin-left:0px;
                    }
                    &:nth-child(5n+1):not(:first-child){
                        margin-right:0px;
                    }
                }
                @media (max-width: $tablet) {
                    max-width: 49%;
                    flex: 49%;
                    margin-right: 1%; 
                }
            }
        }
        &.items-6{
            .item-block{    
                @media (min-width: $desktop) {
                    max-width: 16%;
                    flex: 16%;
                    max-height: 239px;
                    margin: 0px 0.4% 12px 0.4%; 
                    &:nth-child(6n+1){
                        margin-left:0px;
                    }
                    &:nth-child(6n){
                        margin-right:0px;
                    }
                }
                @media (min-width: $tablet) and (max-width: $desktop) {
                    max-width: 23.5%;
                    flex: 23.5%;
                    margin: 0px 1% 12px 1%; 
                    &:nth-child(4n+1){
                        margin-left:0px;
                    }
                    &:nth-child(4n){
                        margin-right:0px;
                    }
                }
                @media (max-width: $tablet) {
                    max-width: 49%;
                    flex: 49%;
                    margin: 0px 1% 12px 1%; 
                    &:nth-child(2n+1){
                        margin-left:0px;
                    }
                    &:nth-child(2n){
                        margin-right:0px;
                    }
                }
            }
        }
        
        .item-block{
            min-height: 230px;
            object-fit: contain;
            border-radius: 4px;
            background-color: #ffffff;
            box-shadow: 0 5px 10px 0 rgba(46,104,208,0.1);
            text-align: center;
            padding-top: 70px;
            margin-bottom: 25px;
            position: relative;

            .item-img{
                margin:auto;
                img{
                    width:80px;
                    height:80px;
                    border-radius: 50%;
                    &.noradius{
                        box-shadow: 0 5px 10px 0 rgba(46,104,208,0.1) !important;
                    }
                }
            }
            .item-img-journal{
                margin:auto;
                img{
                    width:80px;
                    height:80px;
                    // border-radius: 50%;
                    &.noradius{
                        box-shadow: 0 5px 10px 0 rgba(46,104,208,0.1) !important;
                    }
                }
            }
            &.exam_item{
                padding-top: 40px; 
                .stat_icon{
                    position: absolute;
                    top: 15px;
                    right: 15px;
                    &.mr{
                        right: 40px;
                    }
                    i{
                        color: rgb(182, 195, 208);
                        font-size: 16px;
                        cursor: pointer;
                    }
                }
                .item-img{
                    img{
                        width: 120px;
                    }
                }
                .corrected{
                    color: #5acac3;
                    margin-top: 20px;
                    display: block;
                    font-weight: bold;
                }
                .not_passed{
                    color: #e92727;
                    margin-top: 20px;
                    display: block;
                    font-weight: bold;
                }
            }

            .btn-share-accept,.btn-share-refuse{
                position:absolute;
                bottom:5px;
                /*display:none;*/
            }
            .btn-share-accept{
                left:25%    
            }
            .btn-share-refuse{
                right:25%    
            }

            &.challenge_item{
                height: auto;
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                background-color: #fff;
                padding: 0.5rem 0.7rem;
                border-radius: 5px;
                box-shadow: 0 5px 48px 0 rgba(46, 104, 208, 0.1);
                height: 255px;
                img.medal {
                    width: 40%;
                    height: 80px;
                }
    
                .c_title {
                    display: block;
                    font-size: 0.85rem;
                    font-weight: 600;
                    margin-bottom: 0.2rem;
                }
    
                .c_level {
                    font-size: 0.8rem;
                    display: block;
                    width: 100%;
                    text-align: center;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
    
                    .level {
                        color: $primary_gray;
                    }
                }
    
                .grades {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 100%;
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    font-size: 0.75rem;
                    text-align: center;
                }

                .all_btn {
                    margin: 0.8rem 0;

                    .btn {
                        text-transform: uppercase;
                    }
                }
    
                .c_footer {
                    width: 100%;
                    text-align: right;
                    position: absolute;
                    bottom: 10px;
                    padding: 0 0.5rem;
    
                    .c_btn {
                        button {
                            font-size: 0.75rem;
                        }
                    }
                }
    
                &.all,
                &.group {
                    .all_btn {
                        margin: 0.8rem 0;
    
                        .btn {
                            text-transform: uppercase;
                            font-size: 0.75rem;
                        }
                    }
                }
                &.school{
    
                    .c_title {
                        margin-top: 15px;
                    }
        
                    .c_level {
                        margin-top: 10px;
                    }
        
                    .grades {
                        margin-top: 15px;
                    }
                }
            }

            &.exercise_item {
                &.exercise_kls_item{
                    padding-top: 20px;    
                    .item_name{
                        height: 115px;
                        margin-top:10px;
                    }
                }
                .badge{
                    position: absolute;
                    top: 10px;
                    left: 10px;
                    border-radius: 50%;
                    width: 20px;
                    height: 20px;
                    line-height: 20px;
                    padding: 0px;
                }
                padding-top: 50px;
                .item_name{
                    white-space: pre-wrap;
                    height: 40px;
                    font-size: 11px;
                }
                .exercise-img{
                    width: 90px;
                    height: 90px;
                    border:1px solid transparent;
                    border-radius: 50%;
                    margin:auto;
                    img{
                        width: 80px;
                        height: 80px;
                        margin-top: 4px;
                    }
                    &.act{
                        width: 100px;
                        height: 100px;
                        border-radius: 50%;
                        box-shadow: 1px 1px 9px 1px #adabab;
                        margin-bottom: 10px;
                        img{
                            width: 90px;
                            height: 90px;
                            padding: 10px;
                        }
                    }
                }
                border-bottom:5px solid transparent;
                &.activity_theme_item{
                    .item_name{
                        height: inherit !important;
                    }
                }
            }
            &.asset_item{
                border-bottom:5px solid transparent;
                padding-top: 60px;
                .item_name{
                    margin-top: 10px;    
                }
                .item_namee{
                    margin-top: 20px;
                    &:hover{
                        display:block;  
                      }
                }
                &.selected{
                    opacity: 0.5;
                }
            }
            &.report_item{
                border-bottom:5px solid transparent;
                padding-top: 60px;
                .report_icon{
                    font-size: 30px;
                    margin-bottom: 5px;
                    color: #5acac3;
                    i{
                        font-size: 30px;
                    }
                }
                .report_date{
                    margin-top: 10px;  
                    font-size: 13px;  
                }
                .report_object{
                    margin-top: 5px;  
                    font-size: 13px;  
                }
                .report_creator{
                    margin-top: 5px; 
                    font-size: 13px;   
                }
            }
            &.behavior_item{
                border-bottom:5px solid transparent;
                padding-top: 60px;
                .behavior_icon{
                    font-size: 30px;
                    margin-bottom: 5px;
                    color: #5acac3;
                    i{
                        font-size: 30px;
                    }
                }
                .behavior_date{
                    margin-top: 10px;  
                    font-size: 13px;  
                }
                .behavior_object{
                    margin-top: 5px;  
                    font-size: 13px;  
                }
                .behavior_creator{
                    margin-top: 5px; 
                    font-size: 13px;   
                }
                .assurance_status{
                    border-radius: 10px;
                    color: white;
                    font-weight: bold;
                    margin: 10px;
                }
            }
            &.folder_item{
                .item_name{
                    margin-top: 10px;    
                }
            }
            &.course_item,
            &.formation_item{
                // padding-top: 50px;
                padding-top: 40px;
                &.notifred{
                    position: absolute;
                    right: -7px;
                    top: -7px;
                    background: #d33f29;
                    width: 20px;
                    height: 20px;
                    border-radius: 12px;
                    font-size: 10px;
                    font-weight: 500;
                    line-height: 20px;
                    color: black;
                }
                .item_name{
                    white-space: pre-wrap;
                    margin-top: 20px;
                    // height: 40px;
                    // /*height: 27px;*/
                }
            }
            &.book_item{
                // padding-top: 50px;
                padding-top: 40px;
                &.notifred{
                    position: absolute;
                    right: -7px;
                    top: -7px;
                    background: #d33f29;
                    width: 20px;
                    height: 20px;
                    border-radius: 12px;
                    font-size: 10px;
                    font-weight: 500;
                    line-height: 20px;
                    color: black;
                }
                .item_name{
                    white-space: pre-wrap;
                    // height: 40px;
                    // /*height: 27px;*/
                }
            }
            
            .item_name{
                width: 90%;
                margin: auto;
                font-size: 0.875vw;
                font-weight: 600;
                @extend .text_overflow;
            }
            .item_n{
                width: 90%;
                margin: auto;
                font-size: 10px;
                font-weight: 600;
                @extend .text_overflow;
            }
            .item_info{
                position: absolute;
                bottom: 3px;
                left: 0px;
                width: 100%;
                padding: 0% 5%;
                @extend .text_overflow;
                &.top{
                    bottom: 17px;
                }
            }
            .add_item{
                width: 70px;
                height: 70px;
                line-height: 70px;
                font-size: 60px;
                margin-bottom: 20px;
                @extend .btn-yellow;
            }
        }
    }
    /*&.parent{
        margin-top: -30px !important;
        height: 100vh;
    }*/
}


.item-list.list_books{
    margin:0px;
    *{
        font-size: 0.625vw;
    }
    h4{
        font-size: 1vw;
    }
    .items-8{
        width: 100%;
        display: flex;
        .item-block{    
            @media (min-width: $desktop) {
                max-width: 11.8%;
                flex: 11.8%;
                margin: 0px 0.4% 12px 0.4%; 
                &:nth-child(-n+1), &:nth-child(8n+1){
                    margin-left:0px;
                }
                &:nth-child(7n+1):not(:first-child){
                    margin-right:0px;
                }
            }
            @media (min-width: $tablet) and (max-width: $desktop) {
                max-width: 19.2%;
                flex: 19.2%;
                margin:0px 0.5% 12px 0.5%; 
                &:nth-child(-n+1), &:nth-child(6n+1){
                    margin-left:0px;
                }
                &:nth-child(5n+1):not(:first-child){
                    margin-right:0px;
                }
            }
            @media (max-width: $tablet) {
                max-width: 49%;
                flex: 49%;
                margin-right: 1%; 
            }
        }
    }
    .items-6{
        width: 100%;
        display: flex;
        .item-block{    
            @media (min-width: $desktop) {
                max-width: 16%;
                flex: 16%;
                max-height: 239px;
                margin: 0px 0.4% 12px 0.4%; 
                &:nth-child(6n+1){
                    margin-left:0px;
                }
                &:nth-child(6n){
                    margin-right:0px;
                }
            }
            @media (min-width: $tablet) and (max-width: $desktop) {
                max-width: 23.5%;
                flex: 23.5%;
                margin: 0px 1% 12px 1%; 
                &:nth-child(4n+1){
                    margin-left:0px;
                }
                &:nth-child(4n){
                    margin-right:0px;
                }
            }
            @media (max-width: $tablet) {
                max-width: 49%;
                flex: 49%;
                margin: 0px 1% 12px 1%; 
                &:nth-child(2n+1){
                    margin-left:0px;
                }
                &:nth-child(2n){
                    margin-right:0px;
                }
            }
        }
    }
    
    .item-block{
        min-height: 230px;
        object-fit: contain;
        border-radius: 4px;
        background-color: #ffffff;
        box-shadow: 0 5px 10px 0 rgba(46,104,208,0.1);
        text-align: center;
        padding-top: 70px;
        margin-bottom: 25px;
        position: relative;

        .item-img{
            margin:auto;
            img{
                width:80px;
                height:80px;
                border-radius: 50%;
                &.noradius{
                    box-shadow: 0 5px 10px 0 rgba(46,104,208,0.1) !important;
                }
            }
        }
        .item-img-journal{
            margin:auto;
            img{
                width:80px;
                height:80px;
                // border-radius: 50%;
                &.noradius{
                    box-shadow: 0 5px 10px 0 rgba(46,104,208,0.1) !important;
                }
            }
        }
        &.exam_item{
            padding-top: 40px; 
            .stat_icon{
                position: absolute;
                top: 15px;
                right: 15px;
                &.mr{
                    right: 40px;
                }
                i{
                    color: rgb(182, 195, 208);
                    font-size: 16px;
                    cursor: pointer;
                }
            }
            .item-img{
                img{
                    width: 120px;
                }
            }
            .corrected{
                color: #5acac3;
                margin-top: 20px;
                display: block;
                font-weight: bold;
            }
            .not_passed{
                color: #e92727;
                margin-top: 20px;
                display: block;
                font-weight: bold;
            }
        }

        .btn-share-accept,.btn-share-refuse{
            position:absolute;
            bottom:5px;
            /*display:none;*/
        }
        .btn-share-accept{
            left:25%    
        }
        .btn-share-refuse{
            right:25%    
        }

        &.challenge_item{
            height: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            background-color: #fff;
            padding: 0.5rem 0.7rem;
            border-radius: 5px;
            box-shadow: 0 5px 48px 0 rgba(46, 104, 208, 0.1);
            height: 255px;
            img.medal {
                width: 40%;
                height: 80px;
            }

            .c_title {
                display: block;
                font-size: 0.85rem;
                font-weight: 600;
                margin-bottom: 0.2rem;
            }

            .c_level {
                font-size: 0.8rem;
                display: block;
                width: 100%;
                text-align: center;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                .level {
                    color: $primary_gray;
                }
            }

            .grades {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 100%;
                text-align: center;
                display: flex;
                flex-direction: column;
                font-size: 0.75rem;
                text-align: center;
            }

            .all_btn {
                margin: 0.8rem 0;

                .btn {
                    text-transform: uppercase;
                }
            }

            .c_footer {
                width: 100%;
                text-align: right;
                position: absolute;
                bottom: 10px;
                padding: 0 0.5rem;

                .c_btn {
                    button {
                        font-size: 0.75rem;
                    }
                }
            }

            &.all,
            &.group {
                .all_btn {
                    margin: 0.8rem 0;

                    .btn {
                        text-transform: uppercase;
                        font-size: 0.75rem;
                    }
                }
            }
            &.school{

                .c_title {
                    margin-top: 15px;
                }
    
                .c_level {
                    margin-top: 10px;
                }
    
                .grades {
                    margin-top: 15px;
                }
            }
        }

        &.exercise_item {
            &.exercise_kls_item{
                padding-top: 20px;    
                .item_name{
                    height: 115px;
                    margin-top:10px;
                }
            }
            .badge{
                position: absolute;
                top: 10px;
                left: 10px;
                border-radius: 50%;
                width: 20px;
                height: 20px;
                line-height: 20px;
                padding: 0px;
            }
            padding-top: 50px;
            .item_name{
                white-space: pre-wrap;
                height: 40px;
                font-size: 11px;
            }
            .exercise-img{
                width: 90px;
                height: 90px;
                border:1px solid transparent;
                border-radius: 50%;
                margin:auto;
                img{
                    width: 80px;
                    height: 80px;
                    margin-top: 4px;
                }
                &.act{
                    width: 100px;
                    height: 100px;
                    border-radius: 50%;
                    box-shadow: 1px 1px 9px 1px #adabab;
                    margin-bottom: 10px;
                    img{
                        width: 90px;
                        height: 90px;
                        padding: 10px;
                    }
                }
            }
            border-bottom:5px solid transparent;
            &.activity_theme_item{
                .item_name{
                    height: inherit !important;
                }
            }
        }
        &.asset_item{
            border-bottom:5px solid transparent;
            padding-top: 60px;
            .item_name{
                margin-top: 10px;    
            }
            .item_namee{
                margin-top: 20px;
                &:hover{
                    display:block;  
                    }
            }
            &.selected{
                opacity: 0.5;
            }
        }
        &.report_item{
            border-bottom:5px solid transparent;
            padding-top: 60px;
            .report_icon{
                font-size: 30px;
                margin-bottom: 5px;
                color: #5acac3;
                i{
                    font-size: 30px;
                }
            }
            .report_date{
                margin-top: 10px;  
                font-size: 13px;  
            }
            .report_object{
                margin-top: 5px;  
                font-size: 13px;  
            }
            .report_creator{
                margin-top: 5px; 
                font-size: 13px;   
            }
        }
        &.behavior_item{
            border-bottom:5px solid transparent;
            padding-top: 60px;
            .behavior_icon{
                font-size: 30px;
                margin-bottom: 5px;
                color: #5acac3;
                i{
                    font-size: 30px;
                }
            }
            .behavior_date{
                margin-top: 10px;  
                font-size: 13px;  
            }
            .behavior_object{
                margin-top: 5px;  
                font-size: 13px;  
            }
            .behavior_creator{
                margin-top: 5px; 
                font-size: 13px;   
            }
            .assurance_status{
                border-radius: 10px;
                color: white;
                font-weight: bold;
                margin: 10px;
            }
        }
        &.folder_item{
            .item_name{
                margin-top: 10px;    
            }
        }
        &.course_item,
        &.formation_item{
            // padding-top: 50px;
            padding-top: 40px;
            &.notifred{
                position: absolute;
                right: -7px;
                top: -7px;
                background: #d33f29;
                width: 20px;
                height: 20px;
                border-radius: 12px;
                font-size: 10px;
                font-weight: 500;
                line-height: 20px;
                color: black;
            }
            .item_name{
                white-space: pre-wrap;
                margin-top: 20px;
                // height: 40px;
                // /*height: 27px;*/
            }
        }
        &.book_item{
            // padding-top: 50px;
            padding-top: 40px;
            &.notifred{
                position: absolute;
                right: -7px;
                top: -7px;
                background: #d33f29;
                width: 20px;
                height: 20px;
                border-radius: 12px;
                font-size: 10px;
                font-weight: 500;
                line-height: 20px;
                color: black;
            }
            .item_name{
                white-space: pre-wrap;
                // height: 40px;
                // /*height: 27px;*/
            }
        }
        
        .item_name{
            width: 90%;
            margin: auto;
            font-size: 0.875vw;
            font-weight: 600;
            @extend .text_overflow;
        }
        .item_n{
            width: 90%;
            margin: auto;
            font-size: 10px;
            font-weight: 600;
            @extend .text_overflow;
        }
        .item_info{
            position: absolute;
            bottom: 3px;
            left: 0px;
            width: 100%;
            padding: 0% 5%;
            @extend .text_overflow;
            &.top{
                bottom: 17px;
            }
        }
        .add_item{
            width: 70px;
            height: 70px;
            line-height: 70px;
            font-size: 60px;
            margin-bottom: 20px;
            @extend .btn-yellow;
        }
    }
}

@media (max-width: 767.98px){
    .custom_modal {
        .modal-dialog{
            height:calc(100% - 1rem);
            .modal-content{
                height: 100%;
            }
        }
    }
}

.responsive_modal{
    .modal-dialog{
        height:80%;
        .modal-content{
            height:100%;
        }
    }
}

.custom_modal {
    &.top_modal{
        z-index:100000
    }
    &.math_keyboard{
        z-index: 100000000000;
    }
    .modal-content{
        border: 0px;
        border-radius: 20px;
        box-shadow: 0 5px 45px 0 rgba(46,104,208,0.1);
    }
    h2{
        font-size: 18px;
        font-weight: 500;
        margin: 0 0 28px;
    }
    .modal-dialog-centered{
        height:80%;
    }

    &.full-width{
        .modal-dialog-centered{
            max-width: 90%;
            .modal-content{
                height:100%;
            }
        }
    }
    &.add_exam, &.statistics_exam{
        .modal-content{
            height: 100%;
            .modal-body{
                height: calc(100% - 75px);
                &::-webkit-scrollbar-track{
                    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
                    background-color: #F5F5F5;
                }
                &::-webkit-scrollbar{
                    width: 5px;
                    height: 3px;
                    background-color: #F5F5F5;
                }
                &::-webkit-scrollbar-thumb{
                    background-color: #3eb6ad;
                }
                form{
                    .school-ligne-classes{
                        .error_form{
                            display: inline-block;
                        }
                        .RadioLegend {
                            width: 30%;
                            margin: 0 0 15px;
                            float: left;
                            display: flex;
                            >span{
                                padding: 0;
                                margin-right: 5px;
                            }
                            legend{
                                margin-bottom: 0;
                                line-height: 24px;
                            }
                        }
                    }
                }
            }
        }
        @media screen and (max-width: 768px) {
            max-width: calc(var(--vw, 1vw) * 100);
            margin: 0;
            >div{
                max-width: calc(var(--vw, 1vw) * 100);
                height: calc(var(--vh, 1vh) * 100);
                margin: 0;
                .modal-content{
                    border-radius: 0;
                }
            }
        }
    }
    &.comment_content{
        z-index: 100000000000;
        > div{
            margin: auto;
            height: 50%;
            top: 25%;
            .modal-content{
                height: 100%;
            }
        }
    }
    &.edit_course_modal{
        .modal-dialog {
            max-width: 700px;
            .modal-body{
                overflow-x: visible;
            }
        }
    }
    &.edit_book_modal{
        .modal-dialog{
            max-width: 800px;
            .modal-content{
                height: max-content;
                max-height: 100%;
                padding: 10px 2px;
                .modal-body{
                    max-height: calc(100% - 63px);
                    height: max-content;
                    &::-webkit-scrollbar-track{
                        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
                        background-color: #F5F5F5;
                    }
                    &::-webkit-scrollbar{
                        width: 5px;
                        height: 3px;
                        background-color: #F5F5F5;
                    }
                    &::-webkit-scrollbar-thumb{
                        background-color: #3eb6ad;
                    }
                }
            }
        }
    }
    &.books_modal_list{
        z-index: 100000;
    }
}
.custom_modal.replacement_professors{
    .modal-dialog{
        max-width: 1000px !important;
        width: 100% !important;
        overflow: hidden;
        .modal-content{
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
        }
        .modal-header{
            display: flex;
            flex-wrap: wrap;
            .title{
                width: 100%;
                text-align: center;
                margin-bottom: 15px;
            }
            .header{
                width: 100%;
                .order_type{
                    .btn-default{
                        color: inherit;
                        background: #fff;
                        border: 0px solid #d1dbe2;
                        i{
                            margin-left: 5px;
                        }
                    }
                }
                .dropdown-menu{
                    padding: 8px;
                    li{
                        font-size: 12px;
                        border-bottom: 1px solid #d8d8d8;
                        margin-bottom: 2px;
                        cursor: pointer;
                    }
                }
            }
        }
        .modal-body{
            height: calc(100% - 120px);
            background: white;
            border-radius: 15px;
            margin-bottom: 15px;
        }
        @media (max-width: 1000px){
            margin: 0;
            border-radius: 0;
            .modal-content{
                border-radius: 0;
                .btn-close-modal{
                    right: 10px;
                }
            }
        }
        .list_professors_replacement{
            min-height: 200px;
            display: flex;
            flex-wrap: wrap;
            height: calc(100% - 55px);
            overflow-y: auto;

            &::-webkit-scrollbar-track{
                -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
                background-color: #F5F5F5;
            }
            &::-webkit-scrollbar{
                width: 5px;
                height: 3px;
                background-color: #F5F5F5;
            }
            &::-webkit-scrollbar-thumb{
                background-color: #3eb6ad;
            }
        }
        .actions_modal{
            margin-top: 15px;
        }
    }
    @media (max-width: 1000px){
        width: 93%;
    }
}
.custom_modal.replacement_classrooms{
    .modal-dialog{
        max-width: 1000px !important;
        width: 100% !important;
        overflow: hidden;
        .modal-content{
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
        }
        .modal-header{
            display: flex;
            flex-wrap: wrap;
            .title{
                width: 100%;
                text-align: center;
                margin-bottom: 15px;
            }
            .header{
                width: 100%;
                .order_type{
                    .btn-default{
                        color: inherit;
                        background: #fff;
                        border: 0px solid #d1dbe2;
                        i{
                            margin-left: 5px;
                        }
                    }
                }
                .dropdown-menu{
                    padding: 8px;
                    li{
                        font-size: 12px;
                        border-bottom: 1px solid #d8d8d8;
                        margin-bottom: 2px;
                        cursor: pointer;
                    }
                }
            }
        }
        .modal-body{
            height: calc(100% - 120px);
            background: white;
            border-radius: 15px;
            margin-bottom: 15px;
        }
        @media (max-width: 1000px){
            margin: 0;
            border-radius: 0;
            .modal-content{
                border-radius: 0;
                .btn-close-modal{
                    right: 10px;
                }
            }
        }
        .list_classrooms_replacement{
            min-height: 200px;
            display: flex;
            flex-wrap: wrap;
            height: calc(100% - 55px);
            overflow-y: auto;

            &::-webkit-scrollbar-track{
                -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
                background-color: #F5F5F5;
            }
            &::-webkit-scrollbar{
                width: 5px;
                height: 3px;
                background-color: #F5F5F5;
            }
            &::-webkit-scrollbar-thumb{
                background-color: #3eb6ad;
            }
        }
        .actions_modal{
            margin-top: 15px;
        }
    }
    @media (max-width: 1000px){
        width: 93%;
    }
}

.fixed_modal{
    .modal-dialog{
        height: 90%;
        .modal-content{
            height: 100%;
            .modal-body{
                overflow: auto;
                display:flex;
                flex-wrap:wrap
            }
        }
    }
}

.modal-body{
    padding: 2rem;
}

.gray_div{
    border-radius: 8px;
    background-color: #fcfcfd;
    border: solid 0.6px #d1dbe2;
    padding: 1.5em;
}

@mixin fullscreen-modal-mixin() {
    padding: 0px!important;
    overflow-y: hidden!important;
    .modal-dialog{
        max-width:100vw;
        height:100%;
        margin:0px;
        .modal-content{
            width:100vw;
            height:100%;
            border-radius:0px;
        }
    }
}
.arabic_keyboard_modal{
    width:fit-content!important;
    height: fit-content!important;
    .modal-dialog{
        margin: 0px;
        .modal-content{
            background:transparent;
            border:none;
        }
        .modal-body{
            padding:0px
        }
    }
}

.fullscreen_modal{
    @include fullscreen-modal-mixin();
    &.zzz_index{
        z-index: 99999;
    }
}

@media (max-width: $tablet) {
    .fullscreen_modal_xs{
        @include fullscreen-modal-mixin()
    }
}
.providers_payments{
    .modal-content{
        height: 100%;
    }
}
.test_invitation{
    .modal-content{
        height: 100%;
    }
}

.bootstrap-select{
    &.dropup .dropdown-toggle:after, .dropdown-toggle:after{
        font: normal normal normal 14px/1 FontAwesome;
        border-radius: 50%;
        padding: 2px;
        background-color: #ccc;
        color: #fff;
        width: 18px;
        height: 18px;
        line-height: 15px;
        text-align: center;
        font-size: 18px;
        border: none !important;
        position: initial !important;
        margin: 0px !important;
        vertical-align: middle;
        content: '\f107';
    }
} 

.nav-exercises{
    flex-wrap: nowrap;
    display: flex;
    overflow: auto;
    &::-webkit-scrollbar-track{
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        background-color: #F5F5F5;
    }
    &::-webkit-scrollbar{
        width: 5px;
        height: 3px;
        background-color: #F5F5F5;
    }
    &::-webkit-scrollbar-thumb{
        background-color: #3eb6ad;
    }
}

.nav-exercises .btn{
    position:relative;
    top: 100%;
    transform: translateY(-100%);
    margin:0px 2px;
    height: 30px;
    width: 30px;
    min-width: 30px;
    padding: 0px;
    &.active-exercise, &:hover{
        @extend .btn-green;
    }
}

.dragandrophandler{
    background-image: none;
    border: 2px dotted #b1c1cf;
    width: 100%;
    color: #92AAB0;
    text-align: left;
    vertical-align: middle;
    text-align: center;
    padding: 15px 0;
    position: relative;
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    position: relative;
    strong{
        color:#303c54
    }
    input{
        position: absolute;
        opacity: 0;
        height: 100%;
        width: 100%;
        top:0px;
        left:0px;
    }
}

.modal_create_groupe .modal-dialog{
    max-width: 620px;
    .modal-header{
        padding-bottom: 20px;
    }
    .date_start_end{
        display: flex;
        .date__challenge{
          width: 49%;
          &:first-of-type{
            margin-right: 2%;
          }
        }
    }
    @media (max-width: 1199px) {
        margin: 0 auto;
        .modal-content{
            border-radius: 0;
        }
    }
    @media (max-width: 767px) {
        margin: 0 auto;
        height: calc(100%);
    }
}

@keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
}


body > .chapter_sortable{
    display: block;
    >div{
        background: transparent;
        border: 0;
        box-shadow: none;
    }
    min-height: 190px;
    margin-top:10px;
    /* height: 156px; */
    object-fit: contain;
    border-radius: 10px;
    border: solid 1px #d1dbe2;
    background-color: #FFF;
    border-bottom: 6px solid #f2985a;
    padding: 6px 20px 0;
    position: relative;
    .img{
        width: 96px;
        height: 96px;
        display: block;
        text-align: center;
        line-height: 96px;
        margin: auto;
        img{
        width: 96px;
        height: 96px;
        }
    }
    h4{
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: normal;
        text-align: center;
        color: #1b2437;
        margin: 16px 0 0;
    }
    z-index: 11111111;
}


body > li{
    display: block;
    >div{
        width: 100%;
        visibility: visible !important;
        opacity: 1 !important;
        display: flex;
        justify-content: space-between;
        border: 1px solid rgb(176,190,204);
        padding: 5px;
        margin-bottom: 15px;
        border-radius: 10px;
        box-shadow: 5px 5px 15px -2px;
        line-height: 27px;
        .exercice_name{
            display: flex;
            .exercice_logo{
                margin-right: 10px;
                img{
                    width: 30px;
                    height: 30px;
                }
            }
        }
        .actions{
            display: none;
            padding: 0;
            width: 100px;
            display: flex;
            justify-content: space-between;
            i{
                padding: 8px;
                cursor: pointer;
            }
        }
    }
    &.item_tree{
        z-index: 99999999;
        position: relative;
        .item_header{
            background-color: #f4f9ff;
            padding: 0.5vw 0.8vw !important;
            position: relative;
            cursor: pointer;
            text-align: left;
            font-size: 0.75vw;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            img{
                margin-right: 3%;
                width: 2.19vw;
                height: 2.19vw;
            }
            .name-container{
                margin-right: 80px;
                display: inline-flex;
                flex: 1;
                span{
                    white-space: unset !important;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden !important;
                    text-overflow: ellipsis !important;
                    display: -webkit-box;
                    width: 100%;
                    top: 22%;
                    margin: auto;
                }
            }
            i{
                top: 50%;
                right: 10px;
                position: absolute;
                transform: translateY(-50%);
                cursor: pointer;
                color: #fff;
                background-color: #d1dbe2;
                padding: 4px;
                border-radius: 50%;
                width: 22px;
                height: 22px;
                text-align: center;
                font-size: 0.875vw;
                left: unset;
            }
        }
        &.chapter_item{
            &:not(.open){
                .child_items, .actions{
                    display: none;
                }
            }
            .actions{
                border-top: 1px solid #dee4ec;
                margin: -4px;
                .add_chapter_btn{
                    border: none;
                    -webkit-box-pack: center;
                    -webkit-justify-content: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                    padding: 6px;
                    align-items: center;
                    cursor: pointer;
                    font-size: 2.19vw;
                    display: flex;
                    border-radius: 10px;
                    flex-basis: 100%;
                    .btn-circle.btn-yellow{
                        font-size: 2.19vw;
                        margin: 2px 5px;
                        min-width: 2.19vw;
                        min-height: 2.19vw;
                        display: -webkit-box;
                        display: -webkit-flex;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-align-items: center;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;
                        -webkit-box-pack: center;
                        -webkit-justify-content: center;
                        -ms-flex-pack: center;
                        justify-content: center;
                        box-shadow: 0 18px 36px 0 rgba(239, 194, 91, 0.5);
                        background-image: linear-gradient(225deg, #fdd271, #d5a433);
                        color: #fff;
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        border: 0px !important;
                        padding: 0px;
                        font-weight: 400;
                        text-align: center;
                        vertical-align: middle;
                        user-select: none;
                        background-color: transparent;
                    }
                    .title{
                        font-size: 0.875vw;
                        font-weight: bold;
                    }
                }
            }
            .item_header{
               i{
                    &.remove_xblock{
                        right: 70px;
                    }
                    &.edit_xblock{
                        right: 40px;
                    }
                    &.clone_xblock{
                        right: 100px;
                    }
                    &.sort_item{
                        right: 130px;
                    }
                }
            }
        }
        &.slide_item{
            .item_header{
               i{
                    &.remove_xblock{
                        right: 72px;
                    }
                    &.edit_xblock{
                        right: 42px;
                    }
                    &.clone_xblock{
                        right: 102px;
                    }
                    &.sort_item{
                        right: 132px;
                    }
                }
            }
        }
    }
    &.subject_details_sort{
        >div{
            background: transparent;
            border: 0;
            box-shadow: none;
        }
    }
    .stepTbl{
        width: 100%;
        position: relative;
        border-bottom: solid 0.6px #d1dbe2;
        padding-top: 5px;
        opacity: 1 !important;
        height: 37px;
        background-color: #ffffff;
        .name{
            width: calc(100% - 100px);
            text-align: left;
            margin-top: 5px;
            font-family: Montserrat;
            font-size: 12px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            -webkit-letter-spacing: normal;
            -moz-letter-spacing: normal;
            -ms-letter-spacing: normal;
            letter-spacing: normal;
            color: #1b2437;
            display: inline-block;
            vertical-align: middle;
            padding: 0 3px;
            height: 100%;
        }
        .actions{
            display: inline-block;
            width: 100px;
            span{
                margin-left: 0px;
                i{
                    color: #a1a9b3;
                    font-size: 18px;
                    cursor: pointer;
                }
            }
        }
    }
}
.w_600{
  width: 600px !important;
}
.w_500{
  width: 500px !important;
}
.m_t_80_im{
    margin-top: 80px !important;
}
.h_100{
    height: 100%;
    p.p_render{
        height: 100%;
        overflow-y: auto;
    }
    .tox-toolbar__primary{
        width: 100%;
        padding-right: 20px;
    }
}

.modal_activities{
    >div{
        max-width: 700px;
        border-radius: 15px;
        height: 90%;
        max-height: 90%;
        .modal-content{
            border-radius: 15px;
            height: max-content;
            max-height: 100%;
            padding-bottom: 15px;
            >div{

                overflow: auto;

                &::-webkit-scrollbar-track{
                    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
                    background-color: #F5F5F5;
                }
                &::-webkit-scrollbar{
                    width: 5px;
                    height: 3px;
                    background-color: #F5F5F5;
                }
                &::-webkit-scrollbar-thumb{
                    background-color: #3eb6ad;
                }
            }
            .modal-header{
                > div{
                    display: flex;
                    width: 100%;
                    justify-content: center;
                    button{
                        margin-right: 0 !important;
                        background: #00b3a9;
                        background-image: none;
                        position: absolute;
                        top: -23px;
                        right: -23px;
                        border: 5px solid #fff !important;
                        width: 40px;
                        height: 40px;
                        text-align: center;
                        color: #fff;
                        border-radius: 50%;
                        font-size: 31px;
                        line-height: 28px;
                        box-shadow: none;
                        background-image: none;
                        font-weight: bold;
                        >span{
                            font-size: 35px !important;
                            font-weight: normal !important;
                            text-shadow: 0 1px 0 #fff !important;
                            line-height: 30px;
                            font-family: inherit;
                        }
                    }
                }
            }
            .modal-body{    
                height: max-content;
                max-height: calc(100% - 56px);
                overflow: auto;
                &::-webkit-scrollbar-track{
                    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
                    background-color: #F5F5F5;
                }
                &::-webkit-scrollbar{
                    width: 5px;
                    height: 3px;
                    background-color: #F5F5F5;
                }
                &::-webkit-scrollbar-thumb{
                    background-color: #3eb6ad;
                }
                border-bottom-left-radius: 15px;
                border-bottom-right-radius: 15px;
                .params{
                    .line{
                        display: flex;
                        >div{
                            width: 100%;
                        }
                        .textErrors{
                            color: red;
                        }
                        .radioLevels{
                            display: flex;
                            flex-wrap: wrap;
                            .RadioLegend{
                                width: 33%;
                                display: flex;
                                >span{
                                    padding: 0;
                                    margin-right: 5px;
                                    height: max-content;
                                }
                                legend{
                                    margin-bottom: 0;
                                    padding-top: 3px;
                                }
                            }
                        }
                        &.image{
                            >div{
                                text-align: center;
                                img{
                                    max-width: 100px;
                                    max-height: 100px;
                                    margin: auto;
                                    border: 1px solid #b7b7b7;
                                    padding: 5px;
                                    border-radius: 5px;
                                }
                                span{
                                    width: max-content;
                                    margin: auto;
                                    background: #00b3a9;
                                    color: white;
                                    cursor: pointer;
                                    padding: 5px 10px;
                                    border-radius: 15px;

                                }
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                            }
                        }
                        .react-datepicker-wrapper{
                            margin-left: 0;
                        }
                    }
                    .templates{
                        .drag_div{
                            position: relative;
                            width: 100%;
                            margin: 20px 0px;
                            .template{
                                display: flex;
                                -webkit-box-pack: justify;
                                justify-content: space-between;
                                padding: 0px 5px;
                                .name{
                                    width: calc(100% - 15px);
                                    text-align: left;
                                    display: block;
                                }
                                .remove_template{
                                    color: red;
                                }
                            }
                        }
                    }
                    .video_url,.link_url{
                        display: flex;
                        flex-wrap: wrap;
                        label{
                            margin-right: 10px;
                            width: 80px;
                        }
                        input{
                            width: calc(100% - 90px);
                            margin-bottom: 10px;
                        }
                        button{
                            margin: auto;
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }
    }
    &.modal_bibliotheque{
        .modal-content{
            padding-bottom: 0px;
            border: 0;
        }
    }
}

.export_params.global{
    .modal-content{
        height: 100%;
    }
}

.text-decoration-line-through{
    text-decoration: line-through;
}

.col-25{
    flex: 0 0 20%;
    max-width: 20%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

.occasion_modal{
    .modal-dialog{
        height: 80%;
        min-height: inherit;
        max-height: 80%;
        max-width: 80%;
        .modal-content{
            height: 100%;
            margin-top: 3%;
            .title{
                height: 30px;
                font-size: 18px;
                padding-top: 5px;
                font-weight: bold;
            
            }
            .modal-body{
                height: calc(100% - 30px);
                overflow: inherit;
                .rows{
                    height: calc(100% - 35px);
                    overflow: hidden;
                    overflow-y: auto;
                    &::-webkit-scrollbar-track{
                        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
                        background-color: #F5F5F5;
                    }
                    &::-webkit-scrollbar{
                        width: 5px;
                        height: 3px;
                        background-color: #F5F5F5;
                    }
                    &::-webkit-scrollbar-thumb{
                        background-color: #3eb6ad;
                    }
                    .row{
                        >div{
                            margin-bottom: 15px;
                            label{
                                display: block;
                                font-family: Montserrat;
                                font-size: 15px;
                                font-weight: 500;
                                font-style: normal;
                                font-stretch: normal;
                                line-height: normal;
                                letter-spacing: normal;
                                color: #1b2437;
                                margin-bottom: 5px;
                            }
                            input{
                                float: left;
                                width: 100%;
                                height: 28px;
                                border-radius: 2.6px;
                                border: .6px solid #d1dbe2;
                                background-color: rgba(245,248,249,.3);
                                font-size: 14px;
                                font-weight: 400;
                                color: #1b2437!important;
                                padding-left: 10px;
                            }
                            textarea{
                                float: left;
                                width: 100%;
                                height: 28px;
                                border-radius: 2.6px;
                                border: .6px solid #d1dbe2;
                                background-color: rgba(245,248,249,.3);
                                font-size: 14px;
                                font-weight: 400;
                                color: #1b2437!important;
                                padding-left: 10px;
                                height: 50px;
                            }
                            .textErrors{
                                color: red;
                                width: 100%;
                                float: left;
                            }
                            .intl-tel-input{
                                width: 100%;
                                .flag-container {
                                    right: auto;
                                    left: 0;
                                    width: 100%;
                                }
                                input{
                                    padding-right: 6px;
                                    padding-left: 52px;
                                    margin-left: 0;
                                    position: relative;
                                    z-index: 0;
                                    margin-top: 0!important;
                                    margin-bottom: 0!important;
                                    margin-right: 0;
                                }
                            }
                        }
                    }
                    .images{
                        min-height: 120px;
                        .dragandrophandler{
                            min-height: 120px;
                            .images_container{
                                display: flex;
                                flex-wrap: wrap;
                                justify-content: flex-start;
                                .image{
                                    width: 100px;
                                    height: 100px;
                                    position: relative;
                                    padding: 5px;
                                    .img{
                                        width: 100%;
                                        height: 100%;
                                        border: 1px solid #93aab0;
                                        border-radius: 5px;
                                        img{
                                            max-width: 100%;
                                            max-height: 100%;
                                            cursor: default;
                                        }
                                    }
                                    .remove_image{
                                        color: red;
                                        position: absolute;
                                        top: -4px;
                                        right: 0;
                                        cursor: pointer;
                                    }
                                }
                            }
                        }
                    }
                }
                .actions{
                    button{
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

.pack_modal{
    .modal-dialog{
        height: 80%;
        min-height: inherit;
        max-height: 80%;
        max-width: 80%;
        .modal-content{
            height: 100%;
            margin-top: 3%;
            .title{
                height: 30px;
                font-size: 18px;
                padding-top: 5px;
                font-weight: bold;
            
            }
            .modal-body{
                height: calc(100% - 30px);
                overflow: inherit;
                .rows{
                    height: calc(100% - 35px);
                    overflow: hidden;
                    overflow-y: auto;
                    &::-webkit-scrollbar-track{
                        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
                        background-color: #F5F5F5;
                    }
                    &::-webkit-scrollbar{
                        width: 5px;
                        height: 3px;
                        background-color: #F5F5F5;
                    }
                    &::-webkit-scrollbar-thumb{
                        background-color: #3eb6ad;
                    }
                    .row{
                        >div{
                            margin-bottom: 15px;
                            label{
                                display: block;
                                font-family: Montserrat;
                                font-size: 15px;
                                font-weight: 500;
                                font-style: normal;
                                font-stretch: normal;
                                line-height: normal;
                                letter-spacing: normal;
                                color: #1b2437;
                                margin-bottom: 5px;
                            }
                            input{
                                float: left;
                                width: 100%;
                                height: 28px;
                                border-radius: 2.6px;
                                border: .6px solid #d1dbe2;
                                background-color: rgba(245,248,249,.3);
                                font-size: 14px;
                                font-weight: 400;
                                color: #1b2437!important;
                                padding-left: 10px;
                            }
                            textarea{
                                float: left;
                                width: 100%;
                                height: 28px;
                                border-radius: 2.6px;
                                border: .6px solid #d1dbe2;
                                background-color: rgba(245,248,249,.3);
                                font-size: 14px;
                                font-weight: 400;
                                color: #1b2437!important;
                                padding-left: 10px;
                                height: 50px;
                            }
                            .textErrors{
                                color: red;
                                width: 100%;
                                float: left;
                            }
                            .intl-tel-input{
                                width: 100%;
                                .flag-container {
                                    right: auto;
                                    left: 0;
                                    width: 100%;
                                }
                                input{
                                    padding-right: 6px;
                                    padding-left: 52px;
                                    margin-left: 0;
                                    position: relative;
                                    z-index: 0;
                                    margin-top: 0!important;
                                    margin-bottom: 0!important;
                                    margin-right: 0;
                                }
                            }
                        }
                    }
                    .images{
                        min-height: 120px;
                        .dragandrophandler{
                            min-height: 120px;
                            .images_container{
                                display: flex;
                                flex-wrap: wrap;
                                justify-content: flex-start;
                                .image{
                                    width: 100px;
                                    height: 100px;
                                    position: relative;
                                    padding: 5px;
                                    .img{
                                        width: 100%;
                                        height: 100%;
                                        border: 1px solid #93aab0;
                                        border-radius: 5px;
                                        img{
                                            max-width: 100%;
                                            max-height: 100%;
                                            cursor: default;
                                        }
                                    }
                                    .remove_image{
                                        color: red;
                                        position: absolute;
                                        top: -4px;
                                        right: 0;
                                        cursor: pointer;
                                    }
                                }
                            }
                        }
                    }
                }
                .actions{
                    button{
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

.devis_modal{
    .modal-dialog{
        height: 70%;
        min-height: inherit;
        max-height: 70%;
        width: 20%;
        min-width: 400px;
        margin: auto;
        top: 50%;
        transform: translateY(-50%) !important;
        box-shadow: 0px 0px 30px 5px grey;
        border-radius: 25px;
        .modal-content{
            height: 100%;
            margin-top: 0%;
            .modal-body{
                height: calc(100%);
                overflow: inherit;
                padding-top: 10px;
                .rows{
                    height: calc(100% - 35px);
                    overflow: hidden;
                    overflow-y: auto;
                    padding-right: 5px;
                    .title{
                        font-size: 18px;
                        padding-top: 5px;
                        font-weight: bold;
                        margin-bottom: 40px;
                    }
                    &::-webkit-scrollbar-track{
                        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
                        background-color: #F5F5F5;
                    }
                    &::-webkit-scrollbar{
                        width: 5px;
                        height: 3px;
                        background-color: #F5F5F5;
                    }
                    &::-webkit-scrollbar-thumb{
                        background-color: #3eb6ad;
                    }
                    padding-top: 20px; 
                    .row{
                        >div{
                            margin-bottom: 15px;
                            label{
                                display: block;
                                font-family: Montserrat;
                                font-size: 15px;
                                font-weight: 500;
                                font-style: normal;
                                font-stretch: normal;
                                line-height: normal;
                                letter-spacing: normal;
                                color: #1b2437;
                                margin-bottom: 5px;
                            }
                            input{
                                float: left;
                                width: 100%;
                                height: 28px;
                                border-radius: 2.6px;
                                border: .6px solid #d1dbe2;
                                background-color: rgba(245,248,249,.3);
                                font-size: 14px;
                                font-weight: 400;
                                color: #1b2437!important;
                                padding-left: 10px;
                            }
                            input[type=number]::-webkit-inner-spin-button,
                            input[type=number]::-webkit-outer-spin-button {
                                -webkit-appearance: none;
                                margin: 0;
                            }             
                            input[type=number] {-moz-appearance: textfield;}
                            textarea{
                                float: left;
                                width: 100%;
                                height: 28px;
                                border-radius: 2.6px;
                                border: .6px solid #d1dbe2;
                                background-color: rgba(245,248,249,.3);
                                font-size: 14px;
                                font-weight: 400;
                                color: #1b2437!important;
                                padding-left: 10px;
                                height: 50px;
                            }
                            .textErrors{
                                color: red;
                                width: 100%;
                                float: left;
                            }
                            .intl-tel-input{
                                width: 100%;
                                .flag-container {
                                    right: auto;
                                    left: 0;
                                    width: 100%;
                                }
                                input{
                                    padding-right: 6px;
                                    padding-left: 52px;
                                    margin-left: 0;
                                    position: relative;
                                    z-index: 0;
                                    margin-top: 0!important;
                                    margin-bottom: 0!important;
                                    margin-right: 0;
                                }
                            }
                        }
                        .article_det{
                            >div{
                                width: 100%;
                                border-radius: 15px;
                                border: 2px solid gray;
                                margin-bottom: 15px;
                                padding: 10px; 
                                .label{
                                    width: 100%;
                                    font-weight: bold;
                                }
                                .item{
                                    display: flex;
                                    justify-content: flex-start;
                                    .key{
                                        width: 120px;
                                        font-weight: bold;
                                        text-align: left;
                                    }
                                }
                            }
                        }
                    }
                    .images{
                        min-height: 120px;
                        .dragandrophandler{
                            min-height: 120px;
                            .images_container{
                                display: flex;
                                flex-wrap: wrap;
                                justify-content: flex-start;
                                .image{
                                    width: 100px;
                                    height: 100px;
                                    position: relative;
                                    padding: 5px;
                                    .img{
                                        width: 100%;
                                        height: 100%;
                                        border: 1px solid #93aab0;
                                        border-radius: 5px;
                                        img{
                                            max-width: 100%;
                                            max-height: 100%;
                                            cursor: default;
                                        }
                                    }
                                    .remove_image{
                                        color: red;
                                        position: absolute;
                                        top: -4px;
                                        right: 0;
                                        cursor: pointer;
                                    }
                                }
                            }
                        }
                    }
                }
                .actions{
                    button{
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

.simulation_modal{
    .modal-dialog{
        height: 90%;
        min-height: inherit;
        max-height: 90%;
        width: 80%;
        min-width: 400px;
        max-width: inherit;
        margin: auto;
        top: 50%;
        transform: translateY(-50%) !important;
        .modal-content{
            height: 100%;
            margin-top: 0%;
            .modal-body{
                height: calc(100%);
                overflow: inherit;
                padding-top: 10px;
                .subjects{
                    height: calc(100%);
                    overflow: hidden;
                    overflow-y: auto;
                    padding-right: 5px;
                    padding-bottom: 10px;
                    .title{
                        font-size: 18px;
                        padding-top: 5px;
                        font-weight: bold;
                    }
                    &::-webkit-scrollbar-track{
                        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
                        background-color: #F5F5F5;
                    }
                    &::-webkit-scrollbar{
                        width: 5px;
                        height: 3px;
                        background-color: #F5F5F5;
                    }
                    &::-webkit-scrollbar-thumb{
                        background-color: #3eb6ad;
                    }
                    padding-top: 20px; 
                    .demande_response{
                        width: 100%;
                        margin-top: 15px;
                        text-align: center;
                        .send{
                          width: max-content;
                          box-shadow: 0 10px 20px 0 rgba(90, 202, 195, 0.5);
                          background-image: linear-gradient(225deg, #72dcd6, #3eb6ad);
                          color: #fff;
                          border: none;
                          cursor: pointer;
                          border-radius: 20px;
                          padding: 0px 10px;
                          font-size: 14px;
                          font-weight: 600;
                          height: 30px;
                          min-width: 100px;
                          line-height: 30px;
                          margin: auto;
                        }
                    }
                    .type_manuals_articles, .type_fournitures_articles{
                        .ttle{
                            text-align: left;
                        }
                    }
                }
                .actions{
                    button{
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

.occasion_viewer_modal{
    .modal-dialog{
        height: 80%;
        min-height: inherit;
        max-height: 80%;
        max-width: 80%;
        .modal-content{
            height: 100%;
            margin-top: 3%;
            .title{
                height: 30px;
                font-size: 18px;
                padding-top: 5px;
                font-weight: bold;
            
            }
            .modal-body{
                height: calc(100% - 30px);
                overflow: inherit;
                .rows{
                    height: calc(100% - 35px);
                    overflow: hidden;
                    overflow-y: auto;
                    margin-bottom: 15px;
                    &::-webkit-scrollbar-track{
                        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
                        background-color: #F5F5F5;
                    }
                    &::-webkit-scrollbar{
                        width: 5px;
                        height: 3px;
                        background-color: #F5F5F5;
                    }
                    &::-webkit-scrollbar-thumb{
                        background-color: #3eb6ad;
                    }
                    .row{
                        >div{
                            margin-bottom: 15px;
                            label{
                                display: block;
                                font-family: Montserrat;
                                font-size: 15px;
                                font-weight: 500;
                                font-style: normal;
                                font-stretch: normal;
                                line-height: normal;
                                letter-spacing: normal;
                                color: #1b2437;
                                margin-bottom: 5px;
                            }
                            input{
                                float: left;
                                width: 100%;
                                height: 28px;
                                border-radius: 2.6px;
                                border: .6px solid #d1dbe2;
                                background-color: rgba(245,248,249,.3);
                                font-size: 14px;
                                font-weight: 400;
                                color: #1b2437!important;
                                padding-left: 10px;
                            }
                            textarea{
                                float: left;
                                width: 100%;
                                height: 28px;
                                border-radius: 2.6px;
                                border: .6px solid #d1dbe2;
                                background-color: rgba(245,248,249,.3);
                                font-size: 14px;
                                font-weight: 400;
                                color: #1b2437!important;
                                padding-left: 10px;
                                height: 50px;
                            }
                            .textErrors{
                                color: red;
                                width: 100%;
                                float: left;
                            }
                            .intl-tel-input{
                                width: 100%;
                                .flag-container {
                                    right: auto;
                                    left: 0;
                                    width: 100%;
                                }
                                input{
                                    padding-right: 6px;
                                    padding-left: 52px;
                                    margin-left: 0;
                                    position: relative;
                                    z-index: 0;
                                    margin-top: 0!important;
                                    margin-bottom: 0!important;
                                    margin-right: 0;
                                }
                            }
                        }
                    }
                    .images{
                        min-height: 120px;
                        .dragandrophandler{
                            min-height: 120px;
                            .images_container{
                                display: flex;
                                flex-wrap: wrap;
                                justify-content: flex-start;
                                .image{
                                    width: 100px;
                                    height: 100px;
                                    position: relative;
                                    padding: 5px;
                                    .img{
                                        width: 100%;
                                        height: 100%;
                                        border: 1px solid #93aab0;
                                        border-radius: 5px;
                                        img{
                                            max-width: 100%;
                                            max-height: 100%;
                                            cursor: default;
                                        }
                                    }
                                    .remove_image{
                                        color: red;
                                        position: absolute;
                                        top: -4px;
                                        right: 0;
                                        cursor: pointer;
                                    }
                                }
                            }
                        }
                    }
                }
                .actions{
                    button{
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

.pack_viewer_modal{
    .modal-dialog{
        height: 80%;
        min-height: inherit;
        max-height: 80%;
        max-width: 80%;
        .modal-content{
            height: 100%;
            margin-top: 3%;
            .title{
                height: 30px;
                font-size: 18px;
                padding-top: 5px;
                font-weight: bold;
            
            }
            .modal-body{
                height: calc(100% - 30px);
                overflow: inherit;
                .rows{
                    height: calc(100% - 35px);
                    overflow: hidden;
                    overflow-y: auto;
                    margin-bottom: 15px;
                    &::-webkit-scrollbar-track{
                        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
                        background-color: #F5F5F5;
                    }
                    &::-webkit-scrollbar{
                        width: 5px;
                        height: 3px;
                        background-color: #F5F5F5;
                    }
                    &::-webkit-scrollbar-thumb{
                        background-color: #3eb6ad;
                    }
                    .row{
                        >div{
                            margin-bottom: 15px;
                            label{
                                display: block;
                                font-family: Montserrat;
                                font-size: 15px;
                                font-weight: 500;
                                font-style: normal;
                                font-stretch: normal;
                                line-height: normal;
                                letter-spacing: normal;
                                color: #1b2437;
                                margin-bottom: 5px;
                            }
                            input{
                                float: left;
                                width: 100%;
                                height: 28px;
                                border-radius: 2.6px;
                                border: .6px solid #d1dbe2;
                                background-color: rgba(245,248,249,.3);
                                font-size: 14px;
                                font-weight: 400;
                                color: #1b2437!important;
                                padding-left: 10px;
                            }
                            textarea{
                                float: left;
                                width: 100%;
                                height: 28px;
                                border-radius: 2.6px;
                                border: .6px solid #d1dbe2;
                                background-color: rgba(245,248,249,.3);
                                font-size: 14px;
                                font-weight: 400;
                                color: #1b2437!important;
                                padding-left: 10px;
                                height: 50px;
                            }
                            .textErrors{
                                color: red;
                                width: 100%;
                                float: left;
                            }
                            .intl-tel-input{
                                width: 100%;
                                .flag-container {
                                    right: auto;
                                    left: 0;
                                    width: 100%;
                                }
                                input{
                                    padding-right: 6px;
                                    padding-left: 52px;
                                    margin-left: 0;
                                    position: relative;
                                    z-index: 0;
                                    margin-top: 0!important;
                                    margin-bottom: 0!important;
                                    margin-right: 0;
                                }
                            }
                        }
                    }
                    .images{
                        min-height: 120px;
                        .dragandrophandler{
                            min-height: 120px;
                            .images_container{
                                display: flex;
                                flex-wrap: wrap;
                                justify-content: flex-start;
                                .image{
                                    width: 100px;
                                    height: 100px;
                                    position: relative;
                                    padding: 5px;
                                    .img{
                                        width: 100%;
                                        height: 100%;
                                        border: 1px solid #93aab0;
                                        border-radius: 5px;
                                        img{
                                            max-width: 100%;
                                            max-height: 100%;
                                            cursor: default;
                                        }
                                    }
                                    .remove_image{
                                        color: red;
                                        position: absolute;
                                        top: -4px;
                                        right: 0;
                                        cursor: pointer;
                                    }
                                }
                            }
                        }
                    }
                }
                .actions{
                    button{
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

.react-responsive-modal-root{
    z-index: 1100;
}