@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700");
@import url("https://fonts.googleapis.com/css?family=Arvo:400,700");
@import url("https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css");
@import "./evaluation/evaluation_app.scss";
@import "./cms.scss";
@import "./variables.scss";
@import "../css/animate.css";
@import "./bootstrap/bootstrap.scss";
@import '~pretty-checkbox/src/pretty-checkbox.scss';
$font-size: 15px;
$line-height: 1.4;
$lines-to-show: 2;
@font-face {
  font-family: "revicons";
  font-style: normal;
  font-weight: 400;
  src: local("revicons"), local("revicons"),
    url("../fonts/revicons.woff") format("woff2");
}

@font-face {
  font-family: "DejaVu Sans";
  src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans.ttf") format("truetype");
}

@font-face {
  font-family: "DejaVu Sans";
  font-weight: bold;
  src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "DejaVu Sans";
  font-style: italic;
  src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Oblique.ttf") format("truetype");
}

@font-face {
  font-family: "DejaVu Sans";
  font-weight: bold;
  font-style: italic;
  src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Oblique.ttf") format("truetype");
}

#menu-level{
  z-index: 100000!important;
}

#menu-country, #menu-city{
  z-index: 100000 !important;
}
*,
a,
body,
div,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: Montserrat, sans-serif;
  outline: none;
}
input, textarea{
  -webkit-appearance: none;
  appearance: none;
  -ms-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
}

body{
    font-size: 14px;
    line-height: 1.42857143;
    color: #333;
    background-color: #fff;
}
.h4, .h5, .h6, h4, h5, h6 {
  margin-top: 10px;
  margin-bottom: 10px;
}

button{
  outline-style:none;
  box-shadow:none;
  border-color:transparent;
  &:focus, &:hover{
    outline-style:none;
    box-shadow:none;
    border-color:transparent;
  }
}
textarea{
  resize: none;
}
.w12 {
  width: 100%;
  float: left;
}
.Exercies {
  width: 69px;
  height: 15px;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--dark);
}
a {
  cursor: pointer;
}
.hidden{display: none !important;}
.visibility_hidden{visibility: hidden !important;}
.react-datepicker-popper {
  z-index: 99;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range {
  background-color: #4f5df5;
  border-radius: 50%;
  box-shadow: rgb(148, 156, 249) 0px 0px 7px 1px;
  font-weight: 500;
}
.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover {
  background-color: #4f5df5;
  border-radius: 50%;
  box-shadow: rgb(148, 156, 249) 0px 0px 7px 1px;
  font-weight: 500;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 17px;
  line-height: 14px;
  font-size: 10px;
}
.react-datepicker__header__dropdown.react-datepicker__header__dropdown--scroll {
  margin-bottom: 5px;
  .react-datepicker__month-read-view--selected-month,
  .react-datepicker__year-read-view--selected-year {
    font-size: 10px;
  }
}
.react-datepicker__current-month.react-datepicker__current-month--hasYearDropdown.react-datepicker__current-month--hasMonthDropdown {
  font-size: 10px;
}
.react-datepicker__week {
  div {
    color: #cacaca;
  }
}
.react-datepicker__navigation--next,
.react-datepicker__navigation--prev {
  color: #d8dfe5;
}

.react-datepicker__navigation.react-datepicker__navigation--next{
  border-left-color: #ccc;
}

.react-datepicker__navigation.react-datepicker__navigation--previous{
  border-right-color: #ccc;
}

.react-datepicker__navigation{
  // border: 5px solid transparent;
  width:7px;
  height: 7px;
}
.react-datepicker__header {
  background-color: #fff;
  border-bottom: none;
}
.react-datepicker__triangle {
  display: none;
}
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-weight: 400;
  text-transform: uppercase;
}
.react-datepicker__current-month {
  margin-bottom: 6px;
  font-size: 10px;
}
.react-datepicker__day-names {
  border-bottom: 1px solid #cbe5f6;
  div {
    font-weight: bold;
  }
}

.react-datepicker-wrapper {
  width: 100%;
  margin-left: 5px;
  .react-datepicker__input-container {
    width: 100%;
    position: relative;
    &:before {
      content: "\F073";
      font-family: FontAwesome;
      font-size: 18px;
      color: #b7c4d2;
      position: absolute;
      left: 7px!important;
      top: 50%!important;
      transform: translateY(-50%)
    }
    input{
      padding-left: 30px!important;
      /*color: #bbc7d3;*/
      &:not(.form-control){
        font-size: 11px;
        position: relative;
        z-index: 99;

        float: left;
        width: 100%;
        height: 28px;
        border-radius: 2.6px;
        border: .6px solid #d1dbe2;
        background-color: rgba(245,248,249,.3);
        font-weight: 400;
        color: #1b2437!important;
      }
      &.zoom_datetime{
        caret-color: transparent;
      }



      &.debut{
        padding: 5px;
        font-size: 13px;
        border: 1px solid #ced4da;
        border-radius: 6px;

      }
    }
  }
}
.react-datepicker-popper {
  margin-top: 0;
  z-index: 999;
  .react-datepicker--time-only {
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    background-color: #fff;
    color: #000;
    border: none;
    border-radius: 5px;
    display: inline-block;
    position: relative;
    box-shadow: 0 0 9px 0px rgba(0, 0, 0, 0.2);
    text-align: left;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      top: -5px;
      left: 50%;
      margin-left: -5px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 5px 5px 5px;
      border-color: transparent transparent #fff transparent;
    }
    .react-datepicker__time-container {
      width: 130px;
      padding: 20px 10px 0;
      .react-datepicker__header {
        display: none;
      }
      .react-datepicker__time {
        .react-datepicker__time-box {
          width: 100%;
          ul {
            &.react-datepicker__time-list {
              padding: 5px 0;
              &::-webkit-scrollbar-track {
              }

              &::-webkit-scrollbar {
                width: 8px;
                
              }

              &::-webkit-scrollbar-thumb {
                background-color: #3eb6ad;
                height: 50px;
              }
              li {
                &.react-datepicker__time-list-item {
                  height: 40px;
                  padding: 0 10px;
                  text-align: left;
                  font-family: "Montserrat", sans-serif;
                  font-weight: 500;
                  font-size: 14px;
                  color: #c9d3dc;
                  &.react-datepicker__time-list-item--selected {
                    background: transparent;
                    color: #4c5362;
                    font-weight: 600;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

a {
  cursor: pointer;
}
.container-koolskools {
  max-width: 940px;
  text-align: center;
}
body {
  overflow-x: hidden;
}
.selectInput{
  height: 28px;
  border-radius: 2.6px;
  border: solid 0.6px #d1dbe2;
  background-color: rgba(245, 248, 249, 0.3);
  width: 100%;
  padding-left: 10px;
  &:before, &:after{
    display: none;
  }
  div{
    background-color: transparent!important;
  }
}
a:hover,
a:focus {
  text-decoration: none;
}
ul li {
  list-style-type: none;
}
// header {
//   width: 100%;
//   float: left;
//   background: $primary;
//   padding: 10px 0 13px;
  
// }

.navigation {
  position: relative;
  text-align: center;
}

nav {
  opacity: 0.9;
  border-radius: 17.5px;
  background-color: $secondary;
  display: inline-block;
  padding: 2px 3px;
  margin-top: 20px;
  ul {
    padding: 0;
    margin: 0;
    li {
      display: inline-flex;
      margin-left: 30px;
      &:first-child {
        margin-left: 0;
      }
      a {
        font-family: "Montserrat", sans-serif;
        font-size: 12px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        text-transform: uppercase;
        letter-spacing: 1.4px;
        color: rgba(255, 255, 255, 0.54);
        border-radius: 30px;
        padding: 5px 10px;
        border: solid 2px transparent;
        cursor: pointer;
        &:hover,
        &:focus,
        &.active {
          background: $primary;
          border: 2px solid $white;
          font-weight: 600;
          color: $white;
        }
      }
    }
  }
}
.lang {
  position: absolute;
  right: -50px;
  top: -10px;
  .btn-group {
    > button {
      border-radius: 0;
      border: none;
      color: $white;
      font-family: "Montserrat", sans-serif;
      &.button-first {
        opacity: 0.9;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        color: $white;
        background: $secondary;
        border: none;
        padding: 16px 12px 14px;
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px;
        font-family: "Montserrat", sans-serif;
        color: hsla(0, 0%, 100%, 0.9);
        font-size: 13px;
        font-weight: 700;
        letter-spacing: 1.3px;
        text-transform: uppercase;
      }
      &.dropdown-toggle {
        background: transparent;
        padding: 0 3px;
      }
    }
    .caret {
      margin-left: 0;
    }
  }
}
.signin {
  text-align: right;
  margin: 15px 0 0;
  li {
    cursor: pointer;
    display: inline-block;
    text-align: center;
    a {
      opacity: 0.9;
      font-family: "Montserrat", sans-serif;
      font-size: 11px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 2.5px;
      text-align: right;
      color: $white;
      cursor: pointer;
    }
  }
}

section {
  width: 100%;
  float: left;
  overflow-x: hidden;
  &.one {
    position: relative;
    padding-bottom: 0;
    min-height: 500px;
    .before-one {
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      top: 0;
    }
    .slider {
      position: relative;
      margin-top: 8px;
      border-radius: 10px;
      margin-right: 20px;
      box-shadow: -4px 40px 53px 0 rgba(0, 0, 0, 0.15);
      img {
        border-radius: 10px;
        width: 100%;
      }
      .carousel {
        border-radius: 10px;
        overflow: hidden;
        position: relative;
        &:before {
          content: "";
          background-image: linear-gradient(180deg, transparent, #000);
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          z-index: 999;
        }
        h3 {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 20px;
          -webkit-text-stroke: 0 $orange;
          font-size: 27px;
          font-weight: 700;
          font-style: normal;
          font-stretch: normal;
          line-height: 3;
          letter-spacing: normal;
          text-align: center;
          color: $white;
          margin: 0;
          z-index: 999;
        }
        .banner-anim {
          position: relative;
          min-height: 435px;
          &:before {
            content: "";
            background-image: linear-gradient(180deg, transparent, #000);
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            z-index: 999;
          }
        }
        .banner-anim-elem {
          min-height: 430px;
          .bg {
            min-height: 430px;
          }
        }
      }

      .slider-bottom {
        position: absolute;
        bottom: -20px;
        left: 0;
        right: 0;
        text-align: center;
        z-index: 999;
        a {
          cursor: pointer;
          font-family: Montserrat, sans-serif;
          font-size: 12px;
          text-transform: uppercase;
          font-weight: 700;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 2.4px;
          color: hsla(0, 0%, 100%, 0.9);
          background: #ffa827;
          padding: 13px 28px;
          border: 5px solid $white;
          border-radius: 40px;
          margin: 0 23px;
          display: inline-block;
          width: 250px;
          img {
            max-width: 22px;
            max-height: 22px;
            vertical-align: bottom;
            margin-right: 8px;
          }
        }
      }
    }

    .scrolling {
      position: absolute;
      bottom: -60px;
      left: 50%;
      margin-left: -26px;
      z-index: 999;
      text-align: center;
      opacity: 0.9;
      font-family: Montserrat, sans-serif;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: 1.2px;
      line-height: 16px;
      color: #6c6c6c;
      font-size: 12px;
      font-weight: 700;
      a {
        opacity: 0.9;
        font-family: Montserrat, sans-serif;
        font-size: 9px;
        font-weight: 400;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: 1.2px;
        text-align: center;
        line-height: 16px;
        color: #6c6c6c;
        i {
          font-size: 12px;
          font-weight: 700;
        }
      }
    }
    .news-feed {
      h3 {
        opacity: 1;
        font-family: "Montserrat", sans-serif;
        font-size: 12px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 1.8px;
        text-align: left;
        color: $white;
        text-transform: uppercase;
        margin: 0;
      }
      p {
        font-size: 11px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 14px;
        letter-spacing: normal;
        text-align: left;
        color: rgba(84, 84, 84, 0.71);
        &.link {
          text-transform: uppercase;
          font-weight: bold;
          margin-bottom: 0;
          &.link-planete {
            a {
              color: #0dafc4;
              &:before {
                border-color: transparent transparent transparent #0dafc4;
              }
            }
          }
          &.link-monde {
            a {
              color: #d43c2f;
              &:before {
                border-color: transparent transparent transparent #d43c2f;
              }
            }
          }
          &.link-sport {
            a {
              color: #8b92ff;
              &:before {
                border-color: transparent transparent transparent #8b92ff;
              }
            }
          }
          a {
            position: relative;
            padding-right: 15px;
            &:before {
              content: "";
              right: 0;
              top: 4px;
              position: absolute;
              border-style: solid;
              border-width: 4px 0 4px 6px;
              border-color: transparent transparent transparent #0dafc4;
            }
          }
        }
      }
    }
  }
  &.two {
    padding: 0 0 35px;
    text-align: center;
    .container-subjects {
      max-width: 780px;
      margin: auto;
      > div {
        margin-top: 20px;
        h2 {
          margin-bottom: 30px;
        }
      }
    }
    h2 {
      font-family: "Arvo", serif;
      font-size: 23px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 1.3px;
      text-align: center;
      text-transform: uppercase;
      color: $white;
      background-color: $primary;
      padding: 12px 60px 10px;
      border-radius: 25px;
      display: inline-block;
      &.home {
        padding-left: 15px;
        padding-right: 15px;
        width: 250px;
      }
    }
    .subjects {
      .subjects-bg {
        position: relative;
        max-width: 810px;
        background: url("../img/subjects-bg.svg") no-repeat scroll;
        background-size: cover;
        -webkit-filter: drop-shadow(0 -1px 4px rgba(0, 0, 0, 0.15));
        -moz-filter: drop-shadow(0 -1px 4px rgba(0, 0, 0, 0.15));
        -ms-filter: drop-shadow(0 -1px 4px rgba(0, 0, 0, 0.15));
        -o-filter: drop-shadow(0 -1px 4px rgba(0, 0, 0, 0.15));
        filter: drop-shadow(0 -1px 4px rgba(0, 0, 0, 0.15));
        margin: 20px auto;
        .subjects-bbg {
          min-height: 260px;
          padding: 50px 0 50px 10px;
          max-width: 640px;
          border-radius: 150px;
          margin: auto;
          overflow: hidden;
          position: relative;

          img {
            max-width: 158px;
            display: inline-block;
          }
        }
      }

      .arrow-next,
      .arrow-prev {
        background-color: #46bfb6;
        width: 36px;
        height: 36px;
        position: absolute;
        border-radius: 50%;
        top: 50%;
        margin-top: -18px;
        text-align: center;
        cursor: pointer;
        i {
          color: $white;
          line-height: 36px;
          font-size: 25px;
        }
      }
      .arrow-prev {
        left: 10px;
      }
      .arrow-next {
        right: 10px;
      }
    }
  }
  &.section-news-feeds {
    background-color: $primary;
    padding: 100px 0 145px;
    position: relative;
    overflow: hidden;
    &:before {
      content: "";
      background: $secondary;
      border-radius: 100%;
      position: absolute;
      top: -50px;
      bottom: -50px;
      width: 70%;
      right: -400px;
    }
    h3 {
      font-family: "Arvo", sans-serif;
      font-size: 18px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      text-transform: uppercase;
      letter-spacing: 1.3px;
      text-align: right;
      color: rgba(255, 255, 255, 1);
      padding: 21px 45px;
      border-top-left-radius: 50px;
      border-bottom-left-radius: 50px;
      background-color: rgba(255, 255, 255, 0.31);
      position: absolute;
      right: 0;
      top: 74px;
      z-index: 999;
      margin: 0;
    }
    .section-feeds {
      position: relative;
      z-index: 999;
      padding: 0 110px;
      .section-feed {
        max-width: 45%;
        box-shadow: 1px 3px 10px 0px rgba(0, 0, 0, 0.2);
        margin-top: -20px;
        border-radius: 7px;
        .news-img {
          width: 53%;
          height: 160px;
          overflow: hidden;
          img {
            height: 100%;
          }
        }
        &.feed-left {
          float: left;
          margin-right: 60px;
          .news-img {
            float: right;
            border-top-right-radius: 7px;
            border-bottom-right-radius: 7px;
          }
          .news-article {
            float: left;
            border-top-left-radius: 7px;
            border-bottom-left-radius: 7px;
            p {
              text-align: left;
              &.link {
                a {
                  float: left;
                }
                i {
                  margin-left: 0;
                  float: right;
                }
              }
            }
          }
        }
        &.feed-right {
          float: right;
          margin-left: 60px;
          .news-img {
            float: left;
            border-top-left-radius: 7px;
            border-bottom-left-radius: 7px;
          }
          .news-article {
            float: right;
            border-top-right-radius: 7px;
            border-bottom-right-radius: 7px;
            p {
              text-align: right;
              &.link {
                a {
                  float: right;
                }
                i {
                  float: left;
                  margin-right: 0;
                  transform: rotate(180deg);
                }
              }
            }
          }
        }
        .news-article {
          height: 160px;
          background-color: $white;
          position: relative;
          width: 47%;
          padding: 9px 12px;
          &:before {
            content: "";
            position: absolute;
            bottom: -87px;
            left: 0;
            background: url("../../assets/img/shape-news-feed.svg");
            background-size: cover;
            width: 100%;
            min-height: 120px;
            z-index: 0;
            -webkit-filter: drop-shadow(0px 3px 1px rgba(0, 0, 0, 0.15));
            -moz-filter: drop-shadow(0px 3px 1px rgba(0, 0, 0, 0.15));
            -ms-filter: drop-shadow(0px -3px 1px rgba(0, 0, 0, 0.15));
            -o-filter: drop-shadow(0px 3px 1px rgba(0, 0, 0, 0.15));
            filter: drop-shadow(0px 3px 1px rgba(0, 0, 0, 0.15));
          }
          &:after {
            content: "";
            position: absolute;
            bottom: 0;
            background: $white;
            left: 0;
            right: 0;
            height: 14px;
            border-radius: 10px;
            z-index: 999;
          }
          p {
            opacity: 0.9;
            font-family: "Montserrat", sans-serif;
            font-size: 12px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 15px;
            letter-spacing: normal;
            color: #000000;
            margin: 0;
            &.news-date {
              font-size: 10px;
              margin-top: 5px;
              font-weight: 500;
              letter-spacing: 0.6px;
              color: #b9b9b9;
            }
            &.link {
              opacity: 0.9;
              font-family: "Montserrat", sans-serif;
              font-size: 12px;
              font-weight: 500;
              font-style: normal;
              font-stretch: normal;
              line-height: 2.09;
              letter-spacing: 0.2px;
              color: $white;
              position: absolute;
              bottom: 9px;
              left: 12px;
              right: 12px;
              z-index: 99999;
              a {
                color: $white;
                padding: 0 12px;
                border-radius: 25px;
                line-height: 24px;
                font-size: 11.5px;
                min-width: 127px;
                text-align: center;
                text-transform: uppercase;
              }
              i {
                margin-top: 9px;
                font-size: 22px;
                content: "";
                width: 39px;
                height: 8px;
                display: inline-block;
                -webkit-mask: url("../../assets/img/news_arrow.svg") no-repeat
                  50% 50%;
                mask: url("../../assets/img/news_arrow.svg") no-repeat 50% 50%;
                -webkit-mask-size: cover;
                mask-size: cover;
                position: relative;
              }
              &.planete {
                a,
                i {
                  background-color: #00b0c4;
                }
              }
              &.monde {
                a,
                i {
                  background-color: #e62721;
                }
              }
              &.science {
                a,
                i {
                  background-color: #ff6735;
                }
              }
              &.sport {
                a,
                i {
                  background-color: #9172ff;
                }
              }
              &.cinema {
                a,
                i {
                  background-color: #ffa400;
                }
              }
            }
          }
        }
      }
    }
  }
  &.koolskools-notes {
    background: #f7f7f7;
    padding: 70px 200px 150px;
    background: url("../../assets/img/graphics-copy.svg") no-repeat scroll
      center;
    h3 {
      opacity: 0.9;
      font-family: "Montserrat", sans-serif;
      font-size: 14px;
      line-height: 20px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: 0.9px;
      text-align: center;
      color: $secondary;
      margin-bottom: 70px;
      span {
        position: relative;
        &:before {
          content: "";
          position: absolute;
          right: -200px;
          top: -8px;
          width: 130px;
          height: 157px;

          background: url("../../assets/img/home_rocket1.svg") no-repeat scroll
            center;
        }
      }
    }
    .alice-carousel {
      .alice-carousel__stage-item {
        img {
          border: 20px solid $white;
          box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.5);
        }
      }
    }
    .current {
      transform: scale(1.5);
      transition: 0.3s;
      z-index: 999999;

      position: relative;
    }
    main {
      position: relative;
      .pager-controls {
        .pager-control--prev,
        .pager-control--next {
          display: none;
        }
      }
      &:before {
        content: "";
        background: url("../../assets/img/home_rocket2.svg") no-repeat scroll;
        width: 133px;
        height: 130px;
        position: absolute;
        left: -70px;
        top: 50%;
      }
    }
    .frame {
      margin: 10px;
      position: relative;

      .track {
        padding: 75px 3px 75px;
        img {
          -webkit-filter: drop-shadow(0 -1px 4px rgba(0, 0, 0, 0.15));
          -moz-filter: drop-shadow(0 -1px 4px rgba(0, 0, 0, 0.15));
          -ms-filter: drop-shadow(0 -1px 4px rgba(0, 0, 0, 0.15));
          -o-filter: drop-shadow(0 -1px 4px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0 -1px 4px rgba(0, 0, 0, 0.15));
          border: 20px solid #fff;
        }
      }
    }
    .pager-controls {
      span {
        width: 9px;
        height: 9px;
        background: rgba(216, 216, 216, 0.4);
        border-radius: 50%;
        display: inline-block;
        margin: 0 3.5px;
        &.current-nav {
          background: rgba(216, 216, 216, 0.9);
        }
      }
    }
  }
}

/*footer {
  width: 100%;
  float: left;
  background: #f7f7f7;
  border-top: 10px solid $white;
  box-shadow: 0 -2px 10px 0 rgba(0, 0, 0, 0.15);
  padding-bottom: 30px;
  position: relative;
  z-index: 9;
  .footer-logo {
    text-align: center;
    margin-bottom: 30px;
    a {
      background: url("../../assets/img/footer-logo-cloud.svg") no-repeat scroll;
      width: 294px;
      height: 143px;
      display: block;
      margin: -115px auto 0;
      padding-top: 27px;
      -webkit-filter: drop-shadow(0 -1px 8px rgba(0, 0, 0, 0.15));
      -moz-filter: drop-shadow(0 -1px 8px rgba(0, 0, 0, 0.15));
      -ms-filter: drop-shadow(0 -1px 8px rgba(0, 0, 0, 0.15));
      -o-filter: drop-shadow(0 -1px 8px rgba(0, 0, 0, 0.15));
      filter: drop-shadow(0 -1px 8px rgba(0, 0, 0, 0.15));
      img {
        width: 167px;
      }
    }
  }
  .bloc-footer {
    float: left;
    width: 20%;
    text-align: left;
    h3 {
      font-family: "Montserrat", sans-serif;
      font-size: 14px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 2.4px;
      margin-bottom: 20px;
      color: #292929;
      opacity: 0.63;
    }
    ul {
      margin: 0;
      padding: 0;
      li {
        list-style-type: none;
        a {
          opacity: 0.63;
          font-family: "Montserrat", sans-serif;
          font-size: 15px;
          font-weight: 400;
          font-style: normal;
          font-stretch: normal;
          line-height: 2.57;
          letter-spacing: normal;
          color: #6c6c6c;
        }
      }
    }
  }
}*/

.copyright {
  width: 100%;
  float: left;
  background: #46bfb6;
  text-align: center;
  padding: 10px;
  p {
    font-family: "Montserrat", sans-serif;
    font-size: 10.5px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: center;
    color: $white;
    margin: 0;
  }
}
body {
  .MuiBackdrop-root-17 {
    background-color: rgba(110, 125, 146, 0.72);
  }

  .MuiPaper-root-19 {
    border-radius: 10px;
  }
  .MuiDialog-paper-5 {
    overflow-y: inherit;
    border-radius: 10px;
  }
}
.dialog {
  border: solid 1px #979797;
  border-left: 10px solid #00b3a9;
  padding: 35px 0 30px 50px;
  border-radius: 10px;
  /*max-width: 700px;*/
  &.dialogAssetList{
    max-width: 900px;
  }
  &.dialogAssurance{
    max-width: none;
    h3{
      opacity: 0.9;
      -webkit-text-stroke: 0 #f27046;
      font-family: "Montserrat", sans-serif;
      font-size: 16.5px;
      letter-spacing: 0.3px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.6;
      color:black;
      width: 180px;
    }
    h2{
      opacity: 0.9;
      -webkit-text-stroke: 0 #f27046;
      font-family: "Montserrat", sans-serif;
      font-size: 11.5px !important ;
      letter-spacing: 0.3px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.6;
      color:black;
      margin: 10px;
      width: 29%;
    }
    .table-costum{
      border:1px solid #6bd6d0;
      padding: 1%;
      border-radius: 10px;
      margin-bottom: 10px;
    }
    .top-assurance{
      display: flex;
      justify-content: space-between;
      color: black;
    }
    &.top{
      display: flex;
      justify-content: space-between
    }
  }
  
  &.dialogBuyPack{
    width: 1087px;
    height: 480px;
    max-width: 1087px;
    border-left: none;
    object-fit: contain;
    border-radius: 20px;
    box-shadow: 0 5px 45px 0 rgba(46, 104, 208, 0.1);
    background-color: #FFF;
    padding: 23px 42px 32px;
    overflow: hidden;
    position: relative;
    &.dialogBuyPackage{
      width: 1250px;
      height: auto;
      max-width: 1250px;
      padding: 55px 50px 40px;
      .packageTop{
        position: relative;
        z-index: 99;
        float: left;
        width: 100%;
        h2{
          -webkit-text-stroke: 0 #f27046;
          font-size: 50px;
          line-height: 90px;
          font-weight: bold;
          color: #b1bfcd;
          position: relative;
          float: left;
          img{
            display: none;
          }
          &::before{
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 73px;
            height: 2px;
            background: #b1bfcd;
          }
        }
        a{
          float: right;
          width: 229px;
          height: 54px;
          object-fit: contain;
          border-radius: 40px;
          box-shadow: -1px 3px 17px 0 rgba(0, 0, 0, 0.16);
          border: solid 4px #fff;
          background-color: #ffa727;
          font-size: 14px;
          font-weight: 500;
          line-height: 1.03;
          text-align: center;
          color: #fff;
          line-height: 46px;
          margin-top: 20px;
        }
        margin-bottom: 60px;
      }
      .packages{
        position: relative;
        z-index: 99;
        margin: 0 -20px;
      }
    }
    &.dialogPayments{
      width: 365px;
      max-width: 365px;
      height: 372px;
      padding: 26px 32px 30px;
      h3{
        font-size: 18px;
        font-weight: 500;
        line-height: 22px;
        color: #1b2437;
        text-align: left;
        opacity: 1;
      }
      .payments{
        .blocPayment{
          width: 100%;
          float: left;
          height: 75px;
          border-radius: 4px;
          border: solid 0.9px #d1dbe2;
          background-color: rgba(245, 248, 249, 0.3);
          padding: 10px;
          margin-bottom: 15px;
          display: flex;
          align-items: center;
          img{
            float: left;
            width: 85px;
            height: 55px;
          }
          h4{
            float: left;
            opacity: 0.8;
            font-size: 18px;
            font-weight: 500;
            color: #1b2437;
            margin: 0 0 0 23px;
          }
        }
      }
    }
    .buyClose{
      position: absolute;
      cursor: pointer;
      top: 0;
      right: 0;
      width: 40px;
      height: 40px;
      border-radius: 100px;
      box-shadow: 0 10px 20px 0 rgba(90, 202, 195, 0.5);
      background-image: linear-gradient(225deg, #72dcd6, #3eb6ad);
      color: #fff;
      font-size: 40px;
      line-height: 40px;
      text-align: center;
      z-index: 999;
      i {
        color: #fff;
        font-size: 21px;
        line-height: 40px;
      }
    }
    .buyBack{
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      width: 40px;
      height: 40px;
      border-radius: 100px;
      box-shadow: 0 10px 20px 0 rgba(90, 202, 195, 0.5);
      background-image: linear-gradient(225deg, #72dcd6, #3eb6ad);
      color: #fff;
      text-align: center;
      z-index: 999;
      i {
        color: #fff;
        line-height: 39px;
        font-size: 32px;
      }
    }
    h3{
      opacity: 0.8;
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      color: #1b2437;
      margin: 0 0 40px;
      letter-spacing: normal;
    }
    .buyLeft{
      position: absolute;
      left:0;
      bottom: 0;
      width: 382px;
      height: 355px;
      object-fit: contain;
      background: url('../img/parents/fill1.svg') no-repeat scroll transparent;
      background-size: contain;
    }
    .buyRight{
      position: absolute;
      right:0;
      top: 0;
      width: 438px;
      height: 402px;
      object-fit: contain;
      background: url('../img/parents/fill2.svg') no-repeat scroll transparent;
      background-size: contain;
    }
    .buyPack{
      position: relative;
    }
  }
  &.dialogAddLevel{
    border: none;

  }
  &.dialogMessagerie {
    max-width: 1100px;
    width: 1100px;
    border-radius: 0;
    padding: 0;
    border: none;
    border-bottom-right-radius: 10px !important;
    .closeModal {
      position: absolute;
      top: -15px;
      right: -17px;
      border: 6px solid #fff;
      width: 40px;
      height: 40px;
      text-align: center;
      color: #fff;
      border-radius: 50%;
      font-size: 37px;
      line-height: 28px;
      background-color: #47bfb6;
      z-index: 99;
      box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.3);
    }
    &.dialogAddGroup {
      max-width: 385px;
      padding: 25px 25px 20px;
      object-fit: contain;
      opacity: 0.9;
      border-radius: 10px;
      box-shadow: 0 18px 36px 0 rgba(0, 0, 0, 0.2);
      background-color: #ffffff;
      &.dialogAddPhoto {
        max-width: 442px;
        padding: 25px 25px 35px;
        .switch {
          width: 340px;
          margin: 0 auto 30px;
          height: 45px;
          opacity: 0.9;
          border-radius: 27px;
          border: solid 1px #4caeae;
          a {
            float: left;
            width: 50%;
            height: 43px;
            font-family: Montserrat;
            font-size: 11px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: 0.5px;
            color: #4caeae;
            border-radius: 27px;
            text-align: center;
            border: 3px solid #fff;
            padding: 12px 0;
            &.active {
              color: #fff;
              background: #4caeae;
            }
          }
        }
        .fileDrop {
          height: 49px;
          width: 100%;
          border-radius: 4px;
          border: dashed 1px #b1bfcd;
          background-color: #ffffff;
          text-align: center;
          font-family: Montserrat;
          font-size: 14px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: 49px;
          letter-spacing: normal;
          color: #9b9ea7;
          margin: 40px 0;
          b {
            color: #1b2437;
            font-weight: 500;
          }
        }
        .listPhoto {
          min-height: 140px;
          float: left;
          width: 100%;
          /*display:flex;
          flex-direction: column;
          justify-content: center*/
          .dragandrophandler{
            margin-top:20px;
          }
          img {
            width: 51px;
            height: 51px;
            border-radius: 5px;
            margin: 0 6px 15px;
            border: 1px solid transparent;
            &.active {
              border: 1px solid #47bdb4;
            }
          }
        }
        .button {
          float: left;
          width: 100%;
          margin-top: 15px;
          text-align: center;
          button {
            width: 106px;
            height: 30px;
            object-fit: contain;
            opacity: 0.9;
            border-radius: 49.5px;
            background-color: #4caeae;
            font-size: 12px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            letter-spacing: normal;
            color: #ffffff;
            text-align: center;
            border: solid 1px #fff;
            margin: 0 5px;
            &.cancel {
              color: #4caeae;
              border: solid 1px #4caeae;
              background: #fff;
            }
          }
        }
      }
      h3 {
        font-family: Montserrat;
        font-size: 18px;
        font-weight: 400;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #1b2437;
        margin-bottom: 24px;
      }
      .form {
        .addGroupHeader {
          float: left;
          width: 100%;
          margin-bottom: 15px;
          img {
            float: left;
            width: 50px;
            margin-right: 15px;
          }
          input {
            border: none;
            font-family: Montserrat;
            font-size: 10px;
            font-weight: 400;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #848586;
            border-bottom: solid 1px #5cbfce;
            float: left;
            width: calc(100% - 65px);
            height: 30px;
            margin-top: 7px;
          }
        }
        h4 {
          font-size: 18px;
          font-weight: 400;
          font-style: normal;
          font-stretch: normal;
          line-height: 35px;
          letter-spacing: normal;
          color: #1b2437;
          img {
            float: right;
          }
        }
        label {
          padding: 10px 0;
          float: left;
          width: 100%;
          border-bottom: solid 1px rgba(214, 217, 224, 0.2);
          font-size: 14px;
          font-weight: 400;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #1b2437;
          position: relative;
          margin: 0;
          img {
            width: 30px;
            height: 30px;
            border-radius: 30px;
            margin-right: 19px;
          }
          input {
            visibility: hidden;
            position: absolute;
            right: 0;
            top: 13px;
            width: 16px;
            height: 16px;
            &:checked {
              + span {
                &:before {
                  content: "";
                  content: "";
                  position: absolute;
                  top: 1px;
                  left: 1px;
                  right: 1px;
                  bottom: 1px;
                  background: #5cbfce;
                  border-radius: 16px;
                }
              }
            }
          }
          span {
            width: 16px;
            height: 16px;
            object-fit: contain;
            opacity: 0.9;
            border-radius: 16px;
            border: solid 1px #b1bfcd;
            float: right;
            margin-top: 8px;
            position: relative;
          }
        }
        .button {
          float: left;
          width: 100%;
          margin-top: 15px;
          text-align: center;
          button {
            width: 106px;
            height: 30px;
            object-fit: contain;
            opacity: 0.9;
            border-radius: 49.5px;
            background-color: #4caeae;
            font-size: 12px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            letter-spacing: normal;
            color: #ffffff;
            text-align: center;
            border: solid 1px #fff;
            margin: 0 5px;
            &.cancel {
              color: #4caeae;
              border: solid 1px #4caeae;
              background: #fff;
            }
          }
        }
      }
    }
  }
  &.dialogCalendar {
    max-width: 1150px;
    width: 1150px;
    border-left: none;
    border-radius: 20px 20px 5px 5px;
    padding: 20px 0 0px;
    .calendarHeader {
      padding: 5px 25px 25px;
      position: relative;
      width: 100%;
      float: left;
      .react-datepicker-wrapper {
        width: auto;
        float: left;
      }
      input {
        width: 223px;
        float: left;
        height: 30px;
        border-radius: 2px;
        border: solid 0.6px #d1dbe2;
        background-color: rgba(245, 248, 249, 0.3);
        color: #434b5b;
        font-size: 13px;
        font-weight: 500;
      }
      .calendar-switch {
        float: left;
        width: 650px;
        height: 30px;
        margin-left: 30px;
        border-radius: 15px;
        border: solid 1px #d1dbe2;
        background-color: #f5f8f9;
        a {
          width: 50%;
          height: 28px;
          float: left;
          font-family: "Montserrat";
          font-size: 12px;
          font-weight: 600;
          font-style: normal;
          font-stretch: normal;
          line-height: 28px;
          letter-spacing: normal;
          text-align: center;
          color: #474e5e;
          border-radius: 15px;
          &.active {
            color: #fff;
            object-fit: contain;
            border-radius: 15px;
            background-image: linear-gradient(263deg, #fdd271, #d5a433);
          }
        }
      }
      .choose_view{
        width: max-content;
        margin-top: 3px;
        float: right;
        margin-right: 15px;
        >div{
          width: 100%;
          display: flex;
          flex-direction: inherit;
          justify-content: center;
          height: 22px;
          .choices{
            margin-left: 15px;
            flex-direction: row;
            display: flex;
            flex-wrap: wrap;
            border: 0px solid #d7d5d5;
            border-radius: 9px;
            width: max-content;
            height: 22px;
            margin: auto;
            >span{
              padding: 5px 10px;
              display: block;
              min-width: 90px;
              font-size: 10px;
              border: 1px solid #d1dbe2;
              color: #51c4bc;
              background-color: white;
              text-align: center;
              cursor: pointer;
              border-radius: 15px;
              margin: 0px 4px;
              height: 100%;
              line-height: 14px;
              font-weight: bold;
              &.active{
                color: white;
                box-shadow: 0 10px 20px 0 rgba(90, 202, 195, 0.5);
                background-image: linear-gradient(225deg, #72dcd6, #3eb6ad);
                border: 0px solid #51c4bc;
              }
            }
          }
        }
      }
      .onClose {
        position: absolute;
        cursor: pointer;
        top: -20px;
        right: 0;
        width: 40px;
        height: 40px;
        border-radius: 100px;
        box-shadow: 0 10px 20px 0 rgba(90, 202, 195, 0.5);
        background-image: linear-gradient(225deg, #72dcd6, #3eb6ad);
        text-align: center;
        color: #fff;
        font-size: 40px;
        line-height: 40px;
      }
    }
    .calendar {
      border: solid 1px rgba(140, 151, 178, 0.2);
      float: left;
      width: 100%;
      header {
        width: 100%;
        float: left;
        padding: 0;
        height: 50px;
        object-fit: contain;
        border-bottom: solid 1px rgba(140, 151, 178, 0.2);
        border-top: solid 1px rgba(140, 151, 178, 0.2);
        background-color: rgba(177, 191, 205, 0.31);
        &.header-week {
          height: 60px;
        }
        .calendarHeader {
        }
        .month-display {
          display: none;
        }
        .day-names {
          .day {
            float: left;
            width: 14.28%;
            opacity: 0.8;
            font-family: Montserrat;
            font-size: 12px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: 50px;
            -webkit-letter-spacing: normal;
            -moz-letter-spacing: normal;
            -ms-letter-spacing: normal;
            letter-spacing: normal;
            text-align: center;
            color: #1b2437;
            text-transform: uppercase;
            border-left: solid 1px rgba(140, 151, 178, 0.2);
            &:first-child {
              border-left: none;
            }
          }
          &.day-names-week {
            .day {
              width: 16.66%;
              line-height: 20px;
              padding-top: 11px;
              font-size: 13px;
              b {
                display: block;
                font-weight: 600;
                color: #858b97;
                font-size: 11px;
              }
            }
          }
        }
      }
      .weekDetails {
        float: left;
        width: 100%;
        .weekDetail {
          width: 16.66%;
          float: left;
          border-left: solid 1px rgba(140, 151, 178, 0.2);
          padding: 14px 6px;
          height: 500px;
          &:first-child {
            border-left: none;
          }
        }
      }
      .week {
        float: left;
        width: 100%;
        margin: 0;
        .day {
          float: left;
          width: 14.28%;
          height: 100px;
          border-bottom: solid 1px rgba(140, 151, 178, 0.2);
          border-left: solid 1px rgba(140, 151, 178, 0.2);
          position: relative;
          padding: 40px 4px 4px;
          span {
            font-family: "Montserrat";
            font-size: 17px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            letter-spacing: 0.52px;
            color: #9b9b9b;
            position: absolute;
            top: 2px;
            right: 2px;
            width: 32px;
            height: 32px;
            border-radius: 36px;
            line-height: 32px;
            text-align: center;
          }
          b {
            font-family: "Montserrat";
            font-size: 17px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: 0.52px;
            color: #1b2437;
            position: absolute;
            left: 8px;
            top: 8px;
          }
          .event {
            object-fit: contain;
            border-radius: 4px;
            border: solid 1px rgba(132, 177, 228, 0.2);
            background-color: rgba(132, 177, 228, 0.2);
            padding: 4px;
            width: 100%;
            margin-bottom: 2px;
          }
          &.selected {
            span {
              color: #fff;
              box-shadow: 0 3px 17px 0 rgba(70, 85, 246, 0.45);
              background-image: linear-gradient(225deg, #6975f4, #4151f5);
            }
          }
          &.different-month {
            span {
              opacity: 0.5;
            }
          }
          &.today {
            span {
              color: #fff;
              box-shadow: 0 3px 17px 0 rgba(70, 85, 246, 0.45);
              background-image: linear-gradient(225deg, #6975f4, #4151f5);
            }
          }
        }
      }
    }
    .to_print{
      .schedular_header{
        .school_info{
          display: flex;
          justify-content: space-between;
          .school{
            img{
              width: 100px;
              height: 100px;
              margin-right: 15px;
            }
            span{
              margin-left: 10px;
              font-weight: bold;
            }
          }
          .scholar_year{
            line-height: 100px;
            font-weight: bold;
          }
        }
        .classe{
          text-align: center;
          padding-bottom: 10px;
          margin-top: -12px;
          span{
            margin-left: 10px;
            font-weight: bold;
          }
        }
      }
      .day {
        line-height: 20px !important;
      }
    }
  }
  &.dialogAnnualReviews {
    height: 100%;
    overflow: hidden;
    max-width: 80%;
    width: 80%;
    .reviewsHeader {
      padding: 5px 25px 5px 5px;
      position: relative;
      width: 100%;
      float: left;
      .choose_view{
        width: max-content;
        margin-top: 3px;
        float: right;
        margin-right: 15px;
        >div{
          width: 100%;
          display: flex;
          flex-direction: inherit;
          justify-content: center;
          height: 22px;
          .choices{
            margin-left: 15px;
            flex-direction: row;
            display: flex;
            flex-wrap: wrap;
            border: 0px solid #d7d5d5;
            border-radius: 9px;
            width: max-content;
            height: 22px;
            margin: auto;
            >span{
              padding: 5px 10px;
              display: block;
              min-width: 90px;
              font-size: 10px;
              border: 1px solid #d1dbe2;
              color: #51c4bc;
              background-color: white;
              text-align: center;
              cursor: pointer;
              border-radius: 15px;
              margin: 0px 4px;
              height: 100%;
              line-height: 14px;
              font-weight: bold;
              &.active{
                color: white;
                box-shadow: 0 10px 20px 0 rgba(90, 202, 195, 0.5);
                background-image: linear-gradient(225deg, #72dcd6, #3eb6ad);
                border: 0px solid #51c4bc;
              }
            }
            
            >div{
              .react-switch-bg{
                  height: 22px !important;
                  width: 51px !important;
                  >div{
                      height: 22px !important;
                  }
              }
              .react-switch-handle{
                  height: 20px !important;
                  width: 20px !important;
              }
            }
          }
        }
      }
      .onClose {
        position: absolute;
        cursor: pointer;
        top: -20px;
        right: 0;
        width: 40px;
        height: 40px;
        border-radius: 100px;
        box-shadow: 0 10px 20px 0 rgba(90, 202, 195, 0.5);
        background-image: linear-gradient(225deg, #72dcd6, #3eb6ad);
        text-align: center;
        color: #fff;
        font-size: 40px;
        line-height: 40px;
      }
    }
    .reviews {
      border: solid 1px rgba(140, 151, 178, 0.2);
      float: left;
      width: 100%;
      min-height: calc(100% - 35px);
      &.not_print{
        overflow: auto;
        height: calc(100% - 35px);
      }
      header {
        width: 100%;
        float: left;
        padding: 0;
        height: 50px;
        object-fit: contain;
        border-bottom: solid 1px rgba(140, 151, 178, 0.2);
        border-top: solid 1px rgba(140, 151, 178, 0.2);
        background-color: rgba(177, 191, 205, 0.31);
      }
      >div{
        height: 100%;
        .annual_reviews_page {
          width: 100%;
          height: 100%;
          margin: 0;
          .annual_reviews_container{
            margin-top: 0;
          }
        }
      }
    }
    .to_print{
      background: white;
      width: 8.1in;
      .annual_reviews_container{
        padding: 1rem 10px 20px;
      }
      .print_reviews_header{
        .school_info{
          display: flex;
          justify-content: space-between;
          .school{
            img{
              width: 100px;
              height: 100px;
              margin-right: 15px;
            }
            span{
              margin-left: 10px;
              font-weight: bold;
            }
          }
          .scholar_year{
            line-height: 100px;
            font-weight: bold;
          }
        }
        .classe{
          text-align: right;
          padding-bottom: 10px;
          margin-top: -60px;
          padding-right: 10px;
          span{
            margin-left: 10px;
            font-weight: bold;
          }
        }
      }
      .reviews{
        margin-top: 40px;
        height: calc(100% - 164px);
        position: relative;
        min-height: unset;
        border: 0;
        .date_div{
          position: absolute;
          bottom: 0;
          left: 4px;
          width: calc(100% - 8px);
          justify-content: flex-end;
          .key{
            display: none;
          }
          .val{
            text-align: right;
            font-size: 9px;
          }
        }
      }
      .annual_reviews_page{
        .a_r_single_table{
          border: 0;
          margin-top: 0;
        }
      }
      .rev_footer{
        padding: 0 40px;
        height: unset;;
        >div{
          width: 100%;
          display: flex;
          justify-content: flex-end;
          .key{
            font-weight: bold;
            width: 150px;
          }
          .val{
            font-weight: bold;
            width: 150px;
          }
          &.signature{
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            >div{
              width: 30%;
              margin-left: 70%;
              text-align: center;
              font-weight: bold;
              &.sign{
                border: 1px solid black;
                border-radius: 5px;
                height: 100px;
              }
            }
          }
        }
      }
      .rev_head{
        padding: 0 40px;
        padding-right: 0;
        height: unset;;
        >div{
          width: 100%;
          display: flex;
          justify-content: flex-end;
          .key{
            font-weight: bold;
            width: 150px;
          }
          .val{
            font-weight: bold;
            width: 90px;;
          }
          &.signature{
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            >div{
              width: 30%;
              margin-left: 70%;
              text-align: center;
              font-weight: bold;
              &.sign{
                border: 1px solid black;
                border-radius: 5px;
                height: 100px;
              }
            }
          }
        }
      }
      .rev_footer{
        >div{
          justify-content: flex-start;
        }
      }
      .footer-revs_print{
        position: relative;
        height: calc(100% - 70px);
        .ctt{
          font-weight: bold;
          color: black;
          width: 100%;
          border-top: 1px solid;
          left: 0;
          right: 0;
          text-align: center;
          top: unset;
          bottom: 0;
          position: absolute;
          &>div{
              width: 100%;
              line-height: 26px;
              display: block;
          }
        }
      }
    }
  }
  &.dialogStudentsDocuments {
    max-width: 100%;
    width: 100%;
    border-left: none;
    border-radius: 20px;
    padding: 15px;
    height: 100%;
    min-height: 440px;
    @media(max-width: 1199px) {
      padding: 0px;
    }
  }
  &.dialogStudentSchedule {
    .student_schedule{
      height: 100%;
      .schedule{
        height: 100%;
        overflow-y: auto;
      }
    }
  }
  &.CourbeDetails {
    max-width: 90%;
    width: 90%;
    border-left: none;
    border-radius: 20px;
    padding: 15px;
    height: 90%;
    min-height: 440px;
    @media(max-width: 1199px) {
      padding: 0px;
    }
  }
  &.dialogPermissions {
    max-width: 800px;
    width: 800px;
    border-left: none;
    border-radius: 20px;
    padding: 15px;
    height: 100%;
    min-height: 440px;
    @media(max-width: 1199px) {
      padding: 0px;
    }
  }
  &.dialogPaymentDetails{
    width: 400px;
    border: none;
    border-radius: 20px;
    padding: 40px;
    @media(max-width: 1199px) {
      padding: 0px;
    }
  }
  &.dialogPaymentDetailsDuplicate{
    width: 80%;
    border: none;
    border-radius: 20px;
    padding: 40px;
    @media(max-width: 1199px) {
      padding: 0px;
    }
  }
  &.dialogDeleteSchool {
    &.dialogPrintConvoc{
      width:auto;
      section{
        width:auto;
      }
    }  
    width: 440px;
    object-fit: contain;
    border-radius: 20px;
    box-shadow: 0 5px 45px 0 rgba(46, 104, 208, 0.1);
    background-color: #fff;
    padding: 25px;
    border-left: none;
    position: relative;
    h2 {
      margin: 0 0 25px;
      font-family: "Montserrat", sans-serif;
      font-size: 18px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #1b2437;
      margin-bottom: 28px;
    }
    p {
      font-family: Montserrat;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #1b2437;
      margin: 0;
    }
    .button-delete {
      margin-top: 30px;
      text-align: center;
      button {
        width: 106px;
        height: 30px;
        object-fit: contain;
        border-radius: 49.5px;
        border: solid 0.6px #d1dbe2;
        background-color: rgba(245, 248, 249, 0.3);
        text-align: center;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #3eb6ad;
        margin: 0 5px;
        &.yes {
          box-shadow: 0 6px 20px 0 rgba(90, 202, 195, 0.5);
          background-image: -webkit-linear-gradient(54deg, #72dcd6, #3eb6ad);
          background-image: linear-gradient(54deg, #72dcd6, #3eb6ad);
          color: #fff;
          border: none;
        }
      }
    }
  }
  &.dialogTypeUpdate {
    width: 540px;
    height: 300px;
    object-fit: contain;
    border-radius: 20px;
    box-shadow: 0 5px 45px 0 rgba(46, 104, 208, 0.1);
    background-color: #fff;
    padding: 25px;
    border-left: none;
    position: relative;
    h2 {
      margin: 0 0 25px;
      font-family: "Montserrat", sans-serif;
      font-size: 18px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #1b2437;
      margin-bottom: 28px;
    }
    .content_types{
      height: 100%;
      .list_types{
        width: 100%;
        height: calc(100% - 110px);
        display: flex;
        flex-direction: column;
      }
    }
    .button-delete {
      margin-top: 30px;
      text-align: center;
      button {
        width: 106px;
        height: 30px;
        object-fit: contain;
        border-radius: 49.5px;
        border: solid 0.6px #d1dbe2;
        background-color: rgba(245, 248, 249, 0.3);
        text-align: center;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #3eb6ad;
        margin: 0 5px;
        &.yes {
          box-shadow: 0 6px 20px 0 rgba(90, 202, 195, 0.5);
          background-image: -webkit-linear-gradient(54deg, #72dcd6, #3eb6ad);
          background-image: linear-gradient(54deg, #72dcd6, #3eb6ad);
          color: #fff;
          border: none;
        }
      }
    }
  }
  &.dialogConnexion {
    width: 580px;
    object-fit: contain;
    border-radius: 20px;
    box-shadow: 0 5px 45px 0 rgba(46, 104, 208, 0.1);
    background-color: #fff;
    padding: 50px 80px 55px;
    border-left: none;
    position: relative;
    .resend_activation_data{
      padding-bottom: 15px;
      padding-top: 15px;
      .resend_activation_data_btn {
        width: 106px;
        height: 25px;
        float: right;
        object-fit: contain;
        border-radius: 49.5px;
        box-shadow: 0 6px 20px 0 rgba(90, 202, 195, 0.5);
        background-image: -webkit-linear-gradient(54deg, #96d5f2, #0ea2cc);
        background-image: linear-gradient(54deg, #96d5f2, #0ea2cc);
        color: #fff;
        border: none;
        text-align: center;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 25px;
        letter-spacing: normal;
        margin: 0;
      }
    }
    .resend_activation_data_message{
      color: green;
      padding: 10px 0;
    }
    .modalConnexion{
      .modalConnexionTop {
        .textErrors {
          color: #f13434;
          font-size: 10px;
          font-family: "Montserrat", sans-serif;
        }
        h2 {
          color: #3eb6ad;
        }
        p {
          float: right;
          font-family: Montserrat;
          font-size: 10.6px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #a7b7bc;
          margin: 5px 21px 0 0;
        }
      }
    }
    .modalConnexion.modalform {
      overflow-y: scroll;
      height: 100%;
      overflow-x: hidden;
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #f5f5f5;
      }

      &::-webkit-scrollbar {
        width: 5px;
        background-color: #f5f5f5;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #3eb6ad;
      
      }
  }

    &.dialogRegister {
      /*width: 480px;*/
      min-width: 480px;
      padding: 25px 30px;
      .resend_activation_data{
        padding-bottom: 15px;
        .resend_activation_data_btn {
          width: 106px;
          height: 25px;
          float: right;
          object-fit: contain;
          border-radius: 49.5px;
          box-shadow: 0 6px 20px 0 rgba(90, 202, 195, 0.5);
          background-image: -webkit-linear-gradient(54deg, #96d5f2, #0ea2cc);
          background-image: linear-gradient(54deg, #96d5f2, #0ea2cc);
          color: #fff;
          border: none;
          text-align: center;
          font-family: Montserrat;
          font-size: 12px;
          font-weight: 600;
          font-style: normal;
          font-stretch: normal;
          line-height: 25px;
          letter-spacing: normal;
          margin: 0;
        }
      }
      .resend_activation_data_message{
        color: green;
        padding: 10px 0;
      }
      &.dialogAddStudents {
        .modalConnexionForm {
          .connexionLigne {
            &.connexionLigneCenter {
              text-align: center;
            }
            button {
              &.add {
                float: none;
                display: inline-block;
                margin: 0 4.5px;
              }
            }
          }
        }
      }
      &:before {
        display: none;
      }
      .modalConnexionTop {
        h2 {
          color: #3eb6ad;
          float: left;
        }
        p {
          float: right;
          font-family: Montserrat;
          font-size: 10.6px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #a7b7bc;
          margin: 5px 21px 0 0;
        }
      }
      .modalConnexionForm {
        .connexionLigne {
          &.w12 {
            margin-bottom: 25px;
          }
          &.connexionGender {
            label {
              display: inline-block;
              margin-right: 8px;
            }
          }
          a.terms_link{
            float:none
          }
          .connexionW33 {
            padding: 0 10px;
            width: 33.33%;
            float: left;
          }
          .gender {
            display: inline-block;
            > div {
              display: inline-block;
              position:relative;
              input {
                position: absolute;
                opacity: 0;
                width: 100%;
                height: 100%;
                left: 0px;
                cursor:pointer;
              }
              span {
                float: left;
                width: 27px;
                height: 27px;
              }
              b {
                font-family: Montserrat;
                font-size: 14px;
                font-weight: 500;
                font-style: normal;
                font-stretch: normal;
                line-height: 27px;
                margin-left: 7px;
                letter-spacing: normal;
                color: rgba(27, 36, 55, 0.8);
              }
            }
            .choiseSituation {
              display: inline-block;
              span {
                background: url("../img/iconMale.svg") no-repeat scroll
                  transparent;
                background-size: contain;
              }
              input {
                &:checked {
                  + span {
                    background: url("../img/iconMaleActive.svg") no-repeat
                      scroll transparent;
                  }
                }
              }
            }
            .genderMale {
              display: inline-block;
              span {
                background: url("../img/iconMale.svg") no-repeat scroll
                  transparent;
                background-size: contain;
              }
              input {
                &:checked {
                  + span {
                    background: url("../img/iconMaleActive.svg") no-repeat
                      scroll transparent;
                  }
                }
              }
            }
            .genderFemale {
              display: inline-block;
              margin-left: 18px;
              span {
                background: url("../img/iconFemale.svg") no-repeat scroll
                  transparent;
                background-size: contain;
              }
              input {
                &:checked {
                  + span {
                    background: url("../img/iconFemaleActive.svg") no-repeat
                      scroll transparent;
                  }
                }
              }
            }
          }
          .RadioLegend {
            float: left;
            margin-right: 25px;
            &:last-child {
              margin-right: 0;
            }
            > span {
              padding: 0;
              float: left;
              flex: none;
              display: initial;
            }
            > legend {
              float: left;
              margin: 0;
              font-family: Montserrat;
              font-size: 13px;
              font-weight: 500;
              font-style: normal;
              font-stretch: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #1b2437;
              margin-left: 5px;
              margin-top: 3px;
            }
          }
          textarea {
            float: left;
            width: 100%;
            height: 50px;
            border-radius: 2.6px;
            border: solid 0.6px #d1dbe2;
            background-color: rgba(245, 248, 249, 0.3);
            resize: none;
          }
          a {
            color: #3eb6ac;
            font-size: 11px;
            font-weight: 600;
            float: left;
            margin-top: 7px;
          }
          .select {
            float: left;
            width: 100%;
            height: 26.5px;
            border-radius: 2.6px;
            border: solid 0.6px #d1dbe2;
            background-color: rgba(245, 248, 249, 0.3);
            position: relative;
            padding-left: 3px;
            select {
              float: left;
              width: 100%;
              font-size: 12px;
              height: 25.5px;
              border: none;
              outline: none;
              -webkit-appearance: none;
              -moz-appearance: none;
              appearance: none;
              text-indent: 1px;
              text-overflow: "";
              background: transparent;
              outline: 0;
              position: relative;
              z-index: 9;
              border-radius: 4px!important;
              border: none!important;
              font-weight: 500;
              font-family: Montserrat;
              font-size: 14px;
              color: #1b2437;
              padding: 0 10px;
              &::-ms-expand {
                display: none;
              }
            }
            i {
              color: #d2dbe2;
              font-size: 16px;
              position: absolute;
              right: 5px;
              top: 5px;
            }
          }
          button {
            &.add {
              float: right;
              min-width: 106px;
              height: 30px;
              object-fit: contain;
              border-radius: 49.5px;
              text-align: center;
              font-family: Montserrat;
              font-size: 12px;
              font-weight: 600;
              font-style: normal;
              font-stretch: normal;
              line-height: 30px;
              letter-spacing: normal;
              box-shadow: 0 6px 20px 0 rgba(90, 202, 195, 0.5);
              background-image: -webkit-linear-gradient(
                54deg,
                #72dcd6,
                #3eb6ad
              );
              background-image: linear-gradient(54deg, #72dcd6, #3eb6ad);
              color: #fff;
              border: none;
              &.cancel {
                background: #fff;
                color: #3eb6ad;
                box-shadow: none;
                border: solid 0.6px #d1dbe2;
              }
              &.newStudents {
                width: 155px;
              }
            }
          }
        }
      }
    }
    &.dialogCandid {
      /*width: 480px;*/
      min-width: 480px;
      // height: 760px;
      height: 695px;
       padding: 25px 15px 25px 30px;

      // overflow-y: scroll;
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #f5f5f5;
      }

      &::-webkit-scrollbar {
        width: 5px;
        background-color: #f5f5f5;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #3eb6ad;
      
      }
      // padding: 25px 30px;
      .resend_activation_data{
        padding-bottom: 15px;
        .resend_activation_data_btn {
          width: 106px;
          height: 25px;
          float: right;
          object-fit: contain;
          border-radius: 49.5px;
          box-shadow: 0 6px 20px 0 rgba(90, 202, 195, 0.5);
          background-image: -webkit-linear-gradient(54deg, #96d5f2, #0ea2cc);
          background-image: linear-gradient(54deg, #96d5f2, #0ea2cc);
          color: #fff;
          border: none;
          text-align: center;
          font-family: Montserrat;
          font-size: 12px;
          font-weight: 600;
          font-style: normal;
          font-stretch: normal;
          line-height: 25px;
          letter-spacing: normal;
          margin: 0;
        }
      }
      .resend_activation_data_message{
        color: green;
        padding: 10px 0;
      }
      &.dialogAddStudents {
        .modalConnexionForm {
          .connexionLigne {
            &.connexionLigneCenter {
              text-align: center;
            }
            button {
              &.add {
                float: none;
                display: inline-block;
                margin: 0 4.5px;
              }
            }
          }
        }
      }
      &:before {
        display: none;
      }
      .modalConnexionTop {
        h2 {
          color: #3eb6ad;
          float: left;
        }
        p {
          float: right;
          font-family: Montserrat;
          font-size: 10.6px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #a7b7bc;
          margin: 5px 21px 0 0;
        }
      }
      .modalConnexionForm {
        .connexionLigne {
          &.w12 {
            margin-bottom: 25px;
          }
          &.connexionGender {
            label {
              display: inline-block;
              margin-right: 8px;
            }
          }
          a.terms_link{
            float:none
          }
          .connexionW33 {
            padding: 0 10px;
            width: 33.33%;
            float: left;
          }
          .gender {
            display: inline-block;
            > div {
              display: inline-block;
              position:relative;
              input {
                position: absolute;
                opacity: 0;
                width: 100%;
                height: 100%;
                left: 0px;
                cursor:pointer;
              }
              span {
                float: left;
                width: 27px;
                height: 27px;
              }
              b {
                font-family: Montserrat;
                font-size: 14px;
                font-weight: 500;
                font-style: normal;
                font-stretch: normal;
                line-height: 27px;
                margin-left: 7px;
                letter-spacing: normal;
                color: rgba(27, 36, 55, 0.8);
              }
            }
            .choiseSituation {
              display: inline-block;
              span {
                background: url("../img/iconMale.svg") no-repeat scroll
                  transparent;
                background-size: contain;
              }
              input {
                &:checked {
                  + span {
                    background: url("../img/iconMaleActive.svg") no-repeat
                      scroll transparent;
                  }
                }
              }
            }
            .genderMale {
              display: inline-block;
              span {
                background: url("../img/iconMale.svg") no-repeat scroll
                  transparent;
                background-size: contain;
              }
              input {
                &:checked {
                  + span {
                    background: url("../img/iconMaleActive.svg") no-repeat
                      scroll transparent;
                  }
                }
              }
            }
            .genderFemale {
              display: inline-block;
              margin-left: 18px;
              span {
                background: url("../img/iconFemale.svg") no-repeat scroll
                  transparent;
                background-size: contain;
              }
              input {
                &:checked {
                  + span {
                    background: url("../img/iconFemaleActive.svg") no-repeat
                      scroll transparent;
                  }
                }
              }
            }
          }
          .RadioLegend {
            float: left;
            margin-right: 25px;
            &:last-child {
              margin-right: 0;
            }
            > span {
              padding: 0;
              float: left;
              flex: none;
              display: initial;
            }
            > legend {
              float: left;
              margin: 0;
              font-family: Montserrat;
              font-size: 13px;
              font-weight: 500;
              font-style: normal;
              font-stretch: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #1b2437;
              margin-left: 5px;
              margin-top: 3px;
            }
          }
          textarea {
            float: left;
            width: 100%;
            height: 50px;
            border-radius: 2.6px;
            border: solid 0.6px #d1dbe2;
            background-color: rgba(245, 248, 249, 0.3);
            resize: none;
          }
          a {
            color: #3eb6ac;
            font-size: 11px;
            font-weight: 600;
            float: left;
            margin-top: 7px;
          }
          .select {
            float: left;
            width: 100%;
            height: 26.5px;
            border-radius: 2.6px;
            border: solid 0.6px #d1dbe2;
            background-color: rgba(245, 248, 249, 0.3);
            position: relative;
            padding-left: 3px;
            select {
              float: left;
              width: 100%;
              font-size: 12px;
              height: 25.5px;
              border: none;
              outline: none;
              -webkit-appearance: none;
              -moz-appearance: none;
              appearance: none;
              text-indent: 1px;
              text-overflow: "";
              background: transparent;
              outline: 0;
              position: relative;
              z-index: 9;
              border-radius: 4px!important;
              border: none!important;
              font-weight: 500;
              font-family: Montserrat;
              font-size: 14px;
              color: #1b2437;
              padding: 0 10px;
              &::-ms-expand {
                display: none;
              }
            }
            i {
              color: #d2dbe2;
              font-size: 16px;
              position: absolute;
              right: 5px;
              top: 5px;
            }
          }
          button {
            &.add {
              float: right;
              min-width: 106px;
              height: 30px;
              object-fit: contain;
              border-radius: 49.5px;
              text-align: center;
              font-family: Montserrat;
              font-size: 12px;
              font-weight: 600;
              font-style: normal;
              font-stretch: normal;
              line-height: 30px;
              letter-spacing: normal;
              box-shadow: 0 6px 20px 0 rgba(90, 202, 195, 0.5);
              background-image: -webkit-linear-gradient(
                54deg,
                #72dcd6,
                #3eb6ad
              );
              background-image: linear-gradient(54deg, #72dcd6, #3eb6ad);
              color: #fff;
              border: none;
              &.cancel {
                background: #fff;
                color: #3eb6ad;
                box-shadow: none;
                border: solid 0.6px #d1dbe2;
              }
              &.newStudents {
                width: 155px;
              }
            }
          }
        }
      }
    }
    &.dialogInscription{
      min-width: 80%;
      height: 100%;
      padding: 0;
      padding-bottom: 30px;
      .modalConnexion{
        overflow: auto;
        overflow-x: hidden;
        >div>.row{
          margin: auto;
          margin-top: 10px;
        }
      }
      .modalConnexionTop{
        .schoolClose{
          background: #1c4381;
          position: absolute;
          top: -23px;
          right: -23px;
          border: 5px solid #fff;
          width: 40px;
          height: 40px;
          text-align: center;
          color: #fff;
          border-radius: 50%;
          font-size: 31px;
          line-height: 28px;
          box-shadow: none;
          background-image: none;
          font-weight: bold;
        }
        .menu{
          width: 100%;
          background: #1c4381;
          padding: 0px 25px;
          padding-top: 10px;
          height: 54px;
          border-radius: 10px 10px 0 0;
          .slick-slider{
            .slick-list{
              .slick-slide{
                height: 44px;
                >div{
                  height: 100%;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  position: relative;
                }
                a{
                  color: white;
                  text-align: center;
                  padding-bottom: 10px;
                  opacity: 0.54;
                  &.active{
                    font-weight: bold;
                    opacity: 1;
                    &:before{
                      content: "";
                      position: absolute;
                      left: 50%;
                      margin-left: -10px;
                      bottom: 0;
                      width: 0;
                      height: 0;
                      border-left: 10px solid transparent;
                      border-right: 10px solid transparent;
                      border-bottom: 8px solid #fff;
                    }
                  }
                  span{
                    display: block;
                    font-family: Montserrat,sans-serif;
                    text-transform: uppercase;
                  }
                  &.disabled{
                    cursor: not-allowed;
                  }
                }
              }
            }
            .slick-arrow{
              &:before {
                font: normal normal normal 23px/1 FontAwesome;
                line-height: normal;
                color: #fff;
              }
      
              &.slick-prev {
                &:before {
                  content: "\f104";
                }
              }
      
              &.slick-next {
                &:before {
                  content: "\f105";
                }
              }
            }
          }
          ul{
            padding: 0;
            border: 2px solid gray;
            border-radius: 15px;
            display: flex;
            margin: 0;
            li{
              cursor: pointer;
              padding: 5px;
              background: #bdbcbc;
              border-right: 1px solid gray;
              &.active{
                font-weight: bold;
                color: white;
                background: #3eb6ad;
              }
              &.disabled{
                cursor: not-allowed;
              }
              &:first-of-type{
                border-radius: 12px 0 0 12px;
              }
              &:last-of-type{
                border-radius: 0 12px 12px 0;
                border-right: 0;
              }
            }
          }
        }
      }
      .modalInscriptionContent{
        display: flex;
        width: 100%;
        height: calc(100% - 94px);
        flex-wrap: wrap;
        padding: 10px 25px;
        .form{
          width: 100%;
          padding-right: 10px;
          height: calc(100% - 0px);
          overflow-y: auto;
          overflow-x: hidden;
          .title{
            font-weight: bold;
            border: 1px solid #56729f;
            background-color: #56729f;
            border-radius: 27px;
            padding: 9px !important;
            color: #fff !important;
            font-size: 12px;
            font-weight: 700;
            align-items: center;
            text-align: center;
          }
          .all_children{
            width: 100%;
            background-color: rgba(255, 255, 255, 0.3);
            border-radius: 8px;
            border: 1px solid #d1dbe2;
            overflow: hidden;
            table{
              width: 100%;
              tr{
                th{
                  text-align: center;
                  padding: 10px;
                  background-color: white;
                  border: 1px solid #e8e8e8;
                  border-width: 0 1px 0 0;
                  &:last-of-type{
                    border-right: 0;
                  }
                }
                td{
                  background-color: white;
                  border: 1px solid #e8e8e8;
                  border-width: 1px 1px 1px 1px;
                  height: 50px;
                  padding: 10px;
                  &:first-of-type{
                    border-left: 0;
                  }
                  &:last-of-type{
                    border-right: 0;
                  }
                }
                &.current{
                  font-weight: bold;
                }
                &:last-of-type{
                  td{
                    border-bottom: 0;
                  }
                }
              }
            }
          }
          .child_name{
            display: flex;
            padding: 9px !important;
            justify-content: center;
            padding-top: 0 !important;
            font-size: 20px;
            > div{
              width: max-content;
              display: flex;
              justify-content: flex-start;
              margin: 0 1%;
              &:first-of-type{
                justify-content: flex-end;
              }
              span{
                font-weight: bold;
                &.label{
                  margin-right: 10px;
                }
              }
            }
          }
          .radios{
            width: 25px;
            display: inline-block;
            margin-left: 5px;
            height: 25px;
            &.empty_legend{
              .RadioLegend{
                margin: 0;
                margin-bottom: 26px;
              }
            }
            .RadioLegend{
              >span{
                padding: 0;
              }
            }
          }
          .line{
            margin-top: 10px;
            padding: 0 10px;
            label{
              margin-bottom: 0;
            }
            >div{
              input{
                float: left;
                width: 100%;
                height: 28px;
                border-radius: 2.6px;
                border: .6px solid #d1dbe2;
                background-color: rgba(245,248,249,.3);
                font-size: 14px;
                font-weight: 400;
                color: #1b2437!important;
                padding-left: 10px;
                &.disabled{
                  opacity: 0.5;
                }
              }
              textarea{
                width: 100%;
                height: 100px;
                border-radius: 2.6px;
                border: .6px solid #d1dbe2;
                background-color: rgba(245, 248, 249, 0.3);
                font-size: 14px;
                font-weight: 400;
                color: #1b2437 !important;
                padding: 10px;
                &:disabled{
                  opacity: 0.5;
                }
              }
              .relation{
                display: flex;
                padding-top: 20px;
                >div{
                  padding-top: 5px;
                }
                label{
                  &::before{
                    top: 5px;
                  }
                  &::after{
                    top: 5px;
                  }
                }
                .selectInput{
                  padding-left: 0;
                }
                .radios{
                  width: max-content;
                  min-width: 200px;
                  padding-top: 0;
                  margin-left: 0;
                  legend{
                    margin-top: 8px;
                    margin-right: 10px;
                  }
                }
              }
              .label.radios{
                margin: 0;
                width: max-content;
                .RadioLegend{
                  display: flex;
                  legend{
                    margin: 7px 0px 0 10px;
                  }
                }
              }
              &.phone{
                label{
                  width: 100%;
                }
                .intl-tel-input{
                  width: calc(100% - 30px);
                  input{
                    padding-left: 45px;
                  }
                }
                .checkbox-phone{
                  margin: 0;
                  margin-left: 14px;
                  line-height: inherit;
                }
              }
              .react-datepicker-wrapper{
                margin: 0;
              }
            }
            &.title_row{
              border-bottom: 1px solid #ccc;
              box-shadow: 0 5px 45px 0 rgba(46,104,208,0.1);
              width: calc(50% - 30px);
              margin: 0 15px;
              margin-top: 15px;
              border: 1px solid #ccc;
              border-radius: 5px 5px 0 0;
              padding: 0;
              .col-4, .col-6{
                border-right: 1px solid #ccc;
              }
            }
            &.doc_row{
              box-shadow: 0 5px 45px 0 rgba(46,104,208,0.1);
              margin: 0 15px;
              width: calc(50% - 30px);
              border: 1px solid #ccc;
              border-top: 0;
              padding: 0;
              .col-4, .col-6{
                border-right: 1px solid #ccc;
              }
              .col-2{
                button{
                  position: relative;
                  padding: 0 10px;
                  min-width: max-content;
                  font-size: 11px;
                  input{
                    cursor: pointer;
                    opacity: 0;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0px;
                    left: 0px;
                  }
                }
                .actions{
                  button{
                    margin: 0 5px;
                    padding: 0 5px;
                    width: 20px !important;
                    height: 20px !important;
                    font-size: 12px;
                    line-height: 20px;
                    i{
                      font-size: 13px;
                    }
                  }
                  .remove_document{
                    color: red;
                    margin: 0 5px;
                    cursor: pointer;
                    border-radius: 50%;
                    border: 1px solid red;
                    display: block;
                    width: 20px;
                    height: 20px;
                    line-height: 19px;
                  }
                }
              }
              &>div{
                padding: 10px 0px;
                height: 50px;
                .doc{
                  margin: 0;
                }
              }
              &:last-of-type{
                border-radius: 0 0 5px 5px;
              }
            }
            &.bulletin_row{
              border-top: 0;
              >div{
                text-align: left;
                label{
                  min-width: max-content;
                  padding-right: 10px;
                  line-height: 28px;
                }
              }
              >div{
                button{
                  position: relative;
                  padding: 0 10px;
                  min-width: max-content;
                  font-size: 11px;
                  input{
                    cursor: pointer;
                    opacity: 0;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0px;
                    left: 0px;
                  }
                }
                .actions{
                  button{
                    margin: 0 5px;
                    padding: 0 5px;
                    width: 20px !important;
                    height: 20px !important;
                    font-size: 12px;
                    line-height: 20px;
                    margin-top: 5px;
                    i{
                      font-size: 13px;
                    }
                  }
                  .remove_document{
                    color: red;
                    margin: 0 5px;
                    cursor: pointer;
                    border-radius: 50%;
                    border: 1px solid red;
                    display: block;
                    width: 20px;
                    height: 20px;
                    line-height: 19px;
                    text-align: center;
                    margin-top: 4px;
                  }
                }
              }
              &>div{
                display: flex;
                .doc{
                  margin: 0;
                  width: calc(100% - 130px);
                  .name{
                    line-height: 28px;
                  }
                }
              }
              &:last-of-type{
                border-radius: 0 0 5px 5px;
              }
            }
            .bulletin_radio{
              margin-bottom: -7px;
            }
            &.subjects_test{
              .table_container{
                background-color: rgba(255,255,255,0.3);
                border-radius: 8px;
                padding: 10px 5px;
                border: 1px solid rgb(209,219,226);
                table{
                  width: 100%;
                  tr{
                    th{
                      text-align: center;
                      padding-bottom: 20px;
                    }
                    td{
                      background-color: rgb(255,255,255);
                      border: 1px solid rgb(232,232,232);
                      border-width: 1px 1px 1px 1px;
                      height: 50px;
                      padding: 10px;
                      .radios{
                        .RadioLegend{
                          display: flex;
                          legend{
                            margin-top: 6px;
                            margin-left: 5px;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            button{
              &.download_file{
                margin-left: 40px;
                height: 35px !important;
                width: 35px !important;
                i{
                  font-size: 20px;
                  cursor: pointer;
                }
              }
            }
            .textErrors{
              color: red;
            }
            .actions{
              display: flex;
              justify-content: center;
              .addChild{
                display: inline-block;
                width: 30px;
                height: 30px;
                border-radius: 15px;
                box-shadow: rgba(239, 194, 91, 0.5) 0px 18px 36px 0px;
                background-image: linear-gradient(225deg, rgb(253, 210, 113), rgb(213, 164, 51));
                font-size: 16px;
                text-align: center;
                line-height: 30px;
                font-weight: bold;
                color: rgb(255, 255, 255);
                margin-top: -10px;
                margin-left: 10px;
                margin-right: 10px;
                cursor: pointer;
              }
            }
            .admission{
              .RadioLegend{
                display: flex;
                legend{
                  margin-top: 6px;
                  margin-left: 6px;
                  min-width: max-content;
                }
              }
            }
            .add_file{
              text-align: right;
              span{
                float: right;
                width: 105px;
                height: 27px;
                border-radius: 49.5px;
                box-shadow: #3eb6ad82 0px 6px 24px 0px;
                background-image: linear-gradient(254deg, #6df3e9, #3eb6ad);
                font-family: Montserrat;
                font-size: 12px;
                font-weight: 600;
                font-style: normal;
                font-stretch: normal;
                line-height: 27px;
                letter-spacing: normal;
                text-align: center;
                color: rgb(255, 255, 255);
                cursor: pointer;
              }
            }

            .doc{
              display: flex;
              -webkit-box-pack: justify;
              justify-content: space-between;
              padding: 0px 5px;
              margin-bottom: 5px;
              .name{
                  width: calc(100%);
                  text-align: left;
                  display: block;
              }
              >div{
                display: flex;
                justify-content: space-between;
                width: 35px;
                .download_file{
                  margin: 0;
                  width: 20px !important;
                  height: 20px !important;
                  font-size: 12px;
                  line-height: 20px;
                  i{
                    font-size: 13px;
                  }
                }
              }
              .remove_document{
                  color: red;
              }
            }
            .image-profile{
              .fa-user{
                width: 100px;
                height: 100px;
                font-size: 100px;
              }
              img{
                width: 100px;
                height: 100px;
              }
              button{
                input{
                  cursor: pointer;
                }
                input[type=file]{
                  cursor: pointer;
                }
              }
            }
            // &:last-of-type{
            //   >div:last-of-type{
            //     padding-bottom: 20px;
            //   }
            // }
            .file_uploading{
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
            }
            .connexion-custom-select{
              >div:last-of-type{
              }
            }
          }
          .child{
            padding-bottom: 15px;
            margin-bottom: 10px;
            position: relative;
            &:last-of-type{
              border-bottom: 0px;
              margin-bottom: 0px;
            }
            &.styled{
              border: 2px solid #56729f;
              border-radius: 10px;
              margin-top: 20px;
              padding-bottom: 10px;
              padding-top: 0;
              .child_title{
                position: absolute;
                bottom: 100%;
                transform: translateY(50%);
                background: white;
                padding: 5px;
                font-weight: bold;
                left: 8px;
              }
              .removeChild{
                display: inline-block;
                width: 22px;
                height: 22px;
                border-radius: 15px;
                box-shadow: #f7090945 0px 18px 36px 0px;
                background-image: linear-gradient(225deg, #f52b2b, #f36464);
                font-size: 16px;
                font-weight: bold;
                text-align: center;
                line-height: 22px;
                color: rgb(255, 255, 255);
                cursor: pointer;
                position: absolute;
                left: 100%;
                bottom: 100%;
                transform: translate(-50%, 50%);
                margin: 0;
              }
            }
            &>.row{
              margin: auto;
              margin-top: 10px;
            }
          }
          .files{
            .drag_div{
              position: relative;
              width: 100%;
              margin: 0px 0px 20px 0;
              .template{
                  display: flex;
                  -webkit-box-pack: justify;
                  justify-content: space-between;
                  padding: 0px 5px;
                  margin-bottom: 5px;
                  .name{
                      width: calc(100% - 35px);
                      text-align: left;
                      display: block;
                  }
                  >div{
                    display: flex;
                    justify-content: space-between;
                    width: 35px;
                    .download_file{
                      margin: 0;
                      width: 20px !important;
                      height: 20px !important;
                      font-size: 12px;
                      line-height: 20px;
                      i{
                        font-size: 13px;
                      }
                    }
                  }
                  .remove_document{
                      color: red;
                  }
              }
              .dragandrophandler{
                min-height: 150px;
                height: max-content;
              }
            }
          }

          .react-datepicker__time-list-item{
            color: black !important;
            &.react-datepicker__time-list-item--disabled{
              color: #ccc !important;
            }
          }
          >.row{
            margin: auto;
            margin-top: 10px;
          }

          &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: #f5f5f5;
          }
  
          &::-webkit-scrollbar {
            width: 5px;
            background-color: #f5f5f5;
          }
  
          &::-webkit-scrollbar-thumb {
            background-color: #3eb6ad;
          }
        }
      }
      .modalInscriptionBottom{
        .actions{
          justify-content: flex-end;
          padding: 0 45px;
          >button{
            margin: 0 5px;
            i{
              margin: 0 5px;
            }
            &.disabled{
              opacity: 0.5;
            }
          }
        }
      }
      &.dialogExport{
        min-width: 94%;
        padding: 2em 2em 0px;
        padding-bottom: 2rem;
        .schoolClose{
          position: absolute;
          cursor: pointer;
          top: 0;
          right: 0;
          width: 40px;
          height: 40px;
          border-radius: 100px;
          box-shadow: 0 10px 20px 0 rgba(90,202,195,.5);
          background-image: -webkit-linear-gradient(225deg,#72dcd6,#3eb6ad);
          background-image: linear-gradient(225deg,#72dcd6,#3eb6ad);
          color: #fff;
          font-size: 40px;
          line-height: 40px;
          text-align: center;
        }
        .pageClasses{
          margin: 0 !important;
          .row{
            label{
              display: block;
              font-family: Montserrat;
              font-size: 15px;
              font-weight: 500;
              font-style: normal;
              font-stretch: normal;
              line-height: normal;
              letter-spacing: normal;
              color: rgb(27, 36, 55);
              margin-bottom: 5px;
            }
          }
          .select_{
            .select{
              float: left;
              width: 100%;
              height: 29px;
              border-radius: 2.6px;
              border: 0.6px solid rgb(209, 219, 226);
              background-color: rgba(245, 248, 249, 0.3);
              position: relative;
              padding-left: 3px;
              select{
                float: left;
                width: 100%;
                font-size: 12px;
                height: 28px;
                appearance: none;
                text-indent: 1px;
                background: transparent;
                outline: 0px;
                position: relative;
                z-index: 9;
                font-weight: 500;
                font-family: Montserrat;
                color: rgb(27, 36, 55);
                padding: 0px 5px;
                border-radius: 4px !important;
                border: none !important;
              }
              i{
                color: rgb(210, 219, 226);
                font-size: 14px;
                position: absolute;
                right: 5px;
                top: 6px;
              }
            }
          }
          .react-datepicker-wrapper{
            .input-group{
              height: 29px;
              input{
                height: 29px;
              }
            }
          }
          .name_search{
            input{
              height: 29px;
            }
          }
          .item-list{
            height: calc(100% - 200px);
            margin-right: -15px;
            margin-left: -15px;
            .cycle_data{
              padding: 12px;
            }
            
          }
        }
      }
    }
    &:before:not(.dialogChildren) {
      content: "";
      width: 101px;
      height: 116px;
      position: absolute;
      left: 10px;
      bottom: 30px;
      background: url("../../assets/img/iconFuseeCnx.png") no-repeat scroll
        transparent;
    }
    h2 {
      margin: 0 0 25px;
      font-family: "Montserrat", sans-serif;
      font-size: 18px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #1b2437;
    }
    .schoolClose {
      position: absolute;
      cursor: pointer;
      top: 0;
      right: 0;
      width: 40px;
      height: 40px;
      border-radius: 100px;
      box-shadow: 0 10px 20px 0 rgba(90, 202, 195, 0.5);
      background-image: linear-gradient(225deg, #72dcd6, #3eb6ad);
      color: #fff;
      font-size: 40px;
      line-height: 40px;
      text-align: center;
      i {
        color: #fff;
        font-size: 21px;
        line-height: 40px;
      }
    }
    .modalConnexionForm {
      .row{
        .col-12{
          margin: 0 0 24px 0;
        }
        label{
          font-family: Montserrat;
          font-size: 14px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #1b2437;
          margin-bottom: 2px;
        }
        .form-control{
          height: 26.5px
        }
        .RadioLegend>legend{
          font-family: Montserrat;
          font-size: 13px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #1b2437;
          margin: 16px 0 0 5px;
        }
        .intl-tel-input{
          width:100%
        }
      }
      .connexionLigne {
        .textErrors {
          color: #f13434;
          font-size: 10px;
          font-family: "Montserrat", sans-serif;
          display: flex;
          width: 100%;
        }
        float: left;
        width: calc(100% + 20px);
        margin: 0 -10px 25px;
        &.row{
          margin: 0px;
          >div{
            margin: 0 -10px 25px;
            .intl-tel-input{
              width:100%
            }
          }
        }
        &.w12 {
          margin: 0;
          width: 100%;
        }
        .RadioLegend {
          float: left;
          margin-right: 25px;
          &:last-child {
            margin-right: 0;
          }
          > span {
            padding: 0;
            float: left;
            flex: none;
            display: initial;
            position: relative;
            &:before {
              content: "";
              position: absolute;
              left: 3px;
              right: 3px;
              top: 3px;
              bottom: 3px;
              border-radius: 50%;
            }


          }
          > legend {
            float: left;
            margin: 0;
            font-family: Montserrat;
            font-size: 13px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #1b2437;
            margin-left: 5px;
            margin-top: 3px;
          }
        }

        .connexionW50 {
          float: left;
          width: 50%;
          padding: 0 10px;
        }
        .connexionW100 {
          float: left;
          width: 100%;
          padding: 0 10px;
          .textErrors {
            color: #f13434;
            font-size: 10px;
            font-family: "Montserrat", sans-serif;
            }
        }
        .captcha {
          display: flex;
          width: 100%;
          padding: 0 10px;
          .pCaptcha {
            min-width: 40px;
            line-height: 25px;
            font-family: Montserrat;
            font-size: 11px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            letter-spacing: normal;
            color: #3e3e3e;
            margin : 0 6px 0 0;
          }
          .captchaInput {
            object-fit: contain;
            opacity: 0.9;
            font-family: "Montserrat", sans-serif;
            font-size: 12px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: 1;
            letter-spacing: normal;
            text-align: center;
            /*color: transparent;*/
            text-shadow: 0 0 0 #0c1312;
            &:focus {
              outline: none;
            }
          }
        }
        .flagSelect {
          float: left;
          width: 100%;
          height: 26.5px;
          border-radius: 2.6px;
          border: solid 0.6px #d1dbe2;
          background-color: rgba(245, 248, 249, 0.3);
          .select {
            float: left;
            width: 70px;
            height: 25.5px;
            position: relative;
            padding-left: 3px;
            border: none;
            &:before {
              content: "";
              position: absolute;
              right: 0;
              top: 4px;
              bottom: 4px;
              width: 1px;
              background: #b1bfcd;
            }
            select {
              float: left;
              width: 50px;
              font-size: 12px;
              height: 25.5px;
              border: none;
              outline: none;
              -webkit-appearance: none;
              -moz-appearance: none;
              appearance: none;
              text-indent: 1px;
              text-overflow: "";
              background: transparent;
              outline: 0;
              position: relative;
              z-index: 9;
              border-radius: 4px!important;
              border: none!important;
              font-weight: 500;
              font-family: Montserrat;
              color: #1b2437;
              padding: 0 5px;
              &::-ms-expand {
                display: none;
              }
            }
            i {
              color: #d2dbe2;
              font-size: 16px;
              position: absolute;
              right: 5px;
              top: 5px;
            }
          }

          input {
            border: none;
            width: calc(100% - 70px);
            padding-left: 6px;
          }
        }
        label {
          display: block;
          font-family: Montserrat;
          font-size: 14px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #1b2437;
          margin-bottom: 2px;
        }
        .select {
          float: left;
          width: 100%;
          height: 26.5px;
          border-radius: 2.6px;
          border: solid 0.6px #d1dbe2;
          background-color: rgba(245, 248, 249, 0.3);
          position: relative;
          padding-left: 3px;
          select {
            float: left;
            width: 100%;
            font-size: 12px;
            height: 25.5px;
            border: none;
            outline: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            text-indent: 1px;
            text-overflow: "";
            background: transparent;
            outline: 0;
            position: relative;
            z-index: 9;
            border-radius: 4px!important;
            border: none!important;
            font-weight: 500;
            font-family: Montserrat;
            font-size: 14px;
            color: #1b2437;
            padding: 0 5px;
            &::-ms-expand {
              display: none;
            }
          }
          i {
            color: #d2dbe2;
            font-size: 16px;
            position: absolute;
            right: 5px;
            top: 5px;
          }
        }
        input{
          float: left;
          width: 100%;
          height: 26.5px;
          border-radius: 2.6px;
          border: solid 0.6px #d1dbe2;
          background-color: rgba(245, 248, 249, 0.3);
          font-family: Montserrat;
          font-size: 14px;
          font-weight: 500;
          &:not([type='tel']){
            padding-left: 10px;
          }
          
        }
        .forgot {
          font-family: Montserrat;
          font-size: 12px;
          font-weight: normal;
          color: #4e4e4e;
        }
        .back_btn {
          font-family: Montserrat;
          font-size: 10.6px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #a7b7bc;
          float: left;
          margin-left: 40px; 
        }
        .signin {
          width: 106px;
          height: 25px;
          float: right;
          object-fit: contain;
          border-radius: 49.5px;
          box-shadow: 0 6px 20px 0 rgba(90, 202, 195, 0.5);
          background-image: -webkit-linear-gradient(54deg, #72dcd6, #3eb6ad);
          background-image: linear-gradient(54deg, #72dcd6, #3eb6ad);
          color: #fff;
          border: none;
          text-align: center;
          font-family: Montserrat;
          font-size: 12px;
          font-weight: 600;
          font-style: normal;
          font-stretch: normal;
          line-height: 25px;
          letter-spacing: normal;
          margin: 0;
        }
      }
    }
  }
  &.dialogSubscribedStudents {
    width: 84%;
    height:100%;
    object-fit: contain;
    border-radius: 20px;
    box-shadow: 0 5px 45px 0 rgba(46, 104, 208, 0.1);
    background-color: #fff;
    padding: 2em;
    border-left: none;
    position: relative;
    max-width: unset;
    padding-bottom: 0;
    @media(max-width: 1199px){
      width: 100%;
      padding: 2em 0;
      border: 0;
    }
    @media(max-width: 768px){
      height: 100%;
      .pdf-container{    
        height: 160%; 
        display: flex; 
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
        >div{
            flex: 1;
              height: 100%;
            .pdf-body{
                 height: 100%;
                .students-account{
                  height: inherit !important;
                  // display: flex;
                }
                .students-trombine{
                  height: inherit !important;
                  // display: flex;
                }
            }
        }
      }
    }
  }
  &.dialogSubscribedStudentsForInscriptions{
    padding: 0;
    .schoolClose{
      background: #1c4381;
      position: absolute;
      top: -23px;
      right: -23px;
      border: 5px solid #fff;
      width: 40px;
      height: 40px;
      text-align: center;
      color: #fff;
      border-radius: 50%;
      font-size: 31px;
      line-height: 28px;
      box-shadow: none;
      background-image: none;
      font-weight: bold;
    }
  }
  &.dialogClosedPayments {
    object-fit: contain;
    border-radius: 20px;
    box-shadow: 0 5px 45px 0 rgba(46, 104, 208, 0.1);
    background-color: #fff;
    padding: 2em;
    border-left: none;
  }
  &.dialogChooseExport {
    width: max-content;
    min-width: 50%;
    height:max-content;
    min-height:50%;
    object-fit: contain;
    border-radius: 20px;
    box-shadow: 0 5px 45px 0 rgba(46, 104, 208, 0.1);
    background-color: #fff;
    padding: 2em;
    border-left: none;
    position: relative;
    max-width: unset;
    padding-bottom: 0;
    &.dialogListStudentsPayments{
      // width: 84%;
      width: 94%;
      height: 100%;
      padding-bottom: 2em;
      .export_content{
        height: calc(100% - 40px);
        .payments_students{
          height: calc(100% - 166px);
          .students_list{
            height: 100%;
            width: 100%;
            overflow-y: auto;
            padding: 10px 15px;
            padding-bottom: 0;
            background-color: rgb(245, 246, 250);
            margin: 18px 15px;
            border-radius: 8px;
            padding: 10px 5px;
            border-width: 1px;
            border-style: solid;
            border-color: rgb(209, 219, 226);
            border-image: initial;
            table{
              margin: auto;
              width: 100%;
              border-collapse: separate;
              border-spacing: 0px;
              tr{
                text-align: center;
                td:not(.spacer_td){
                  // border-left: 1px solid black;
                  // border-top: 1px solid black;
                  width: 6%;
                  &.large{
                    width: 6.46%
                  }
                  &.name{
                    width: calc(16% - 20px);
                  }
                  padding: 3px;
                  font-size: 10px;
                }
                &.header{
                  font-weight: bold;
                  td:not(.spacer_td){
                    padding-bottom: 20px;
                  }
                }
                &.spacer{
                  height: 10px;
                }
                &.student:not(.spacer), &.header{
                  td:not(.spacer_td){
                    background-color: rgb(255, 255, 255);
                    height: 50px;
                    border-style: solid;
                    border-color: rgb(232, 232, 232);
                    border-image: initial;
                    border-width: 1px 0px 1px 1px;
                    padding: 10px;
                    &:first-child {
                      border-top-left-radius: 4px;
                      border-bottom-left-radius: 4px;
                    }
                    &:last-child {
                      border-right-width: 1px;
                      border-top-right-radius: 4px;
                      border-bottom-right-radius: 4px;
                    }
                    &.gray{
                      background: #F5F5F5;
                    }
                    &.red{
                      background: #FF0000;
                    }
                    &.greeen{
                      background: #00FF00;
                    }
                    &.gray2{
                      background: gray;
                    }
                  }
                }
                td.spacer_td{
                  width: 10px;
                  border-left: 1px;
                  border-left-style: solid;
                  border-left-color: #e8e8e8;
                }
                td.name_ {
                  background-color: #f5f6fa !important;
              }
                td.name.header_level {
                  background-color: #e8e8e8 !important;
                }
              }
              &.mobile{

                margin: auto;
                width: 100%;
                border-collapse: separate;
                border-spacing: 0px;
                tr{
                  text-align: center;
                  td:not(.spacer_td){
                    // border-left: 1px solid black;
                    // border-top: 1px solid black;
                    width: 25%;
                    &.large{
                      width: 25%
                    }
                    &.name{
                      width: calc(50% - 10px);
                    }
                  }
                }
              }
            }
            &.no_scroll{
              overflow: unset;
            }
          }
          .loading{
            position: relative;
            width: 100%;
            height: 100%;
            padding: 15px;
            .loading_img{
              position: relative;
              width: 100%;
              height: 100%;
              img{
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                max-width: 50%;
                max-height: 50%;
              }
            }
          }
        }
        .act_export{
          margin: 0 !important;
        }
      }
      @media(max-width: 768px){
        height: 100%;
        width: 100%;
        margin: 0;
        padding: 2em;
      }
    }
    &.dialogProfessorsTasksStats{
      width: 94%;
      height: 100%;
      padding-bottom: 2em;
      .export_content{
        height: calc(100% - 40px);
        .payments_students{
          height: calc(100% - 166px);
          .professors_tasks_list{
            height: 100%;
            width: 100%;
            overflow-y: auto;
            padding: 10px 15px;
            padding-bottom: 0;
            background-color: rgb(245, 246, 250);
            margin: 18px 15px;
            border-radius: 8px;
            padding: 10px 5px;
            border-width: 1px;
            border-style: solid;
            border-color: rgb(209, 219, 226);
            border-image: initial;
            table{
              margin: auto;
              width: 100%;
              border-collapse: separate;
              border-spacing: 0px;
              tr{
                text-align: center;
                td:not(.spacer_td){
                  // border-left: 1px solid black;
                  // border-top: 1px solid black;
                  padding: 3px;
                  font-size: 13px;
                  &.large{
                    width: 6.46%
                  }
                  &.gray{
                    background: gray;
                  }
                  &.spans{
                    span{
                      display: block;
                      &:last-of-type{
                        font-size: 10px;
                      }
                    }
                  }
                }
                &.header{
                  font-weight: bold;
                  td:not(.spacer_td){
                    padding-bottom: 20px;
                  }
                }
                &.spacer{
                  height: 10px;
                }
                &.student:not(.spacer), &.header{
                  td:not(.spacer_td){
                    background-color: rgb(255, 255, 255);
                    height: 50px;
                    border-style: solid;
                    border-color: rgb(232, 232, 232);
                    border-image: initial;
                    border-width: 1px 0px 1px 1px;
                    padding: 10px;
                    &:first-child {
                      border-top-left-radius: 4px;
                      border-bottom-left-radius: 4px;
                    }
                    &:last-child {
                      border-right-width: 1px;
                      border-top-right-radius: 4px;
                      border-bottom-right-radius: 4px;
                    }
                    &.gray{
                      background: #F5F5F5;
                    }
                    &.red{
                      background: #FF0000;
                    }
                    &.greeen{
                      background: #00FF00;
                    }
                    &.gray2{
                      background: gray;
                    }
                  }
                }
                td.spacer_td{
                  width: 10px;
                  border-left: 1px;
                  border-left-style: solid;
                  border-left-color: #e8e8e8;
                }
                td.name_ {
                  background-color: #f5f6fa !important;
              }
                td.name.header_level {
                  background-color: #e8e8e8 !important;
                }
              }
              &.mobile{

                margin: auto;
                width: 100%;
                border-collapse: separate;
                border-spacing: 0px;
                tr{
                  text-align: center;
                  td:not(.spacer_td){
                    // border-left: 1px solid black;
                    // border-top: 1px solid black;
                    width: 25%;
                    &.large{
                      width: 25%
                    }
                    &.name{
                      width: calc(50% - 10px);
                    }
                  }
                }
              }
            }
            &.no_scroll{
              overflow: unset;
            }
          }
          .loading{
            position: relative;
            width: 100%;
            height: 100%;
            padding: 15px;
            .loading_img{
              position: relative;
              width: 100%;
              height: 100%;
              img{
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                max-width: 50%;
                max-height: 50%;
              }
            }
          }
        }
        .act_export{
          margin: 0 !important;
        }
      }
      @media(max-width: 768px){
        height: 100%;
        width: 100%;
        margin: 0;
        padding: 2em;
      }
    }
    &.dialogListProfessorsAbsences{
      width: 94%;
      height: 100%;
      padding-bottom: 2em;
      .export_content{
        height: calc(100% - 40px);
        .payments_students{
          height: calc(100% - 166px);
          .professors_list{
            height: 100%;
            width: 100%;
            overflow-y: auto;
            padding: 10px 15px;
            padding-bottom: 0;
            background-color: rgb(245, 246, 250);
            margin: 18px 15px;
            border-radius: 8px;
            padding: 10px 5px;
            border-width: 1px;
            border-style: solid;
            border-color: rgb(209, 219, 226);
            border-image: initial;
            table{
              margin: auto;
              width: 100%;
              border-collapse: separate;
              border-spacing: 0px;
              tr{
                text-align: center;
                td:not(.spacer_td){
                  // border-left: 1px solid black;
                  // border-top: 1px solid black;
                  width: 6%;
                  &.large{
                    width: 6.46%
                  }
                  &.name{
                    width: 100%;
                  }
                  padding: 3px;
                  font-size: 12px;
                }
                &.header{
                  font-weight: bold;
                  td:not(.spacer_td){
                    padding-bottom: 20px;
                  }
                }
                &.spacer{
                  height: 10px;
                }
                &.student:not(.spacer), &.header{
                  td:not(.spacer_td){
                    background-color: rgb(255, 255, 255);
                    height: 50px;
                    border-style: solid;
                    border-color: rgb(232, 232, 232);
                    border-image: initial;
                    border-width: 1px 0px 1px 1px;
                    padding: 10px;
                    &:first-child {
                      border-top-left-radius: 4px;
                      border-bottom-left-radius: 4px;
                    }
                    &:last-child {
                      border-right-width: 1px;
                      border-top-right-radius: 4px;
                      border-bottom-right-radius: 4px;
                    }
                    &.gray{
                      background: #F5F5F5;
                    }
                    &.red{
                      background: #FF0000;
                    }
                    &.greeen{
                      background: #00FF00;
                    }
                    &.gray2{
                      background: gray;
                    }
                  }
                }
                td.spacer_td{
                  width: 10px;
                  max-width: 10px;
                  border-left: 1px;
                  border-left-style: solid;
                  border-left-color: #e8e8e8;
                }
              }
              &.mobile{

                margin: auto;
                width: 100%;
                border-collapse: separate;
                border-spacing: 0px;
                tr{
                  text-align: center;
                  td:not(.spacer_td){
                    // border-left: 1px solid black;
                    // border-top: 1px solid black;
                    width: 25%;
                    &.large{
                      width: 25%
                    }
                    &.name{
                      width: calc(50% - 10px);
                    }
                  }
                }
              }
              td{
                width: inherit !important;
              }
              .professor_absences{
                td{
                  width: inherit !important;
                }
              }
            }
            &.no_scroll{
              overflow: unset;
            }
          }
          .loading{
            position: relative;
            width: 100%;
            height: 100%;
            padding: 15px;
            .loading_img{
              position: relative;
              width: 100%;
              height: 100%;
              img{
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                max-width: 50%;
                max-height: 50%;
              }
            }
          }
        }
        .act_export{
          margin: 0 !important;
        }
      }
      @media(max-width: 768px){
        height: 100%;
        width: 100%;
        margin: 0;
        padding: 2em;
      }
    }
    &.dialogListSMS{
      width: 94%;
      height: 100%;
      padding-bottom: 2em;
      .export_content{
        height: calc(100% - 40px);
        .sms{
          height: calc(100% - 166px);
          .sms_list{
            height: 100%;
            width: 100%;
            overflow-y: auto;
            padding: 10px 15px;
            padding-bottom: 0;
            background-color: rgb(245, 246, 250);
            margin: 18px 15px;
            border-radius: 8px;
            padding: 10px 5px;
            border-width: 1px;
            border-style: solid;
            border-color: rgb(209, 219, 226);
            border-image: initial;
            table{
              margin: auto;
              width: 100%;
              border-collapse: separate;
              border-spacing: 0px;
              tr{
                text-align: center;
                td:not(.spacer_td){
                  // border-left: 1px solid black;
                  // border-top: 1px solid black;
                  &.large{
                  }
                  &.name{
                    min-width: 150px;
                    &.name_2{
                      padding-left: 40px !important;
                    }
                    i{
                      color: orange;
                      cursor: pointer;
                      margin-right: 5px;
                      font-size: 14px;
                    }
                  }
                  padding: 3px;
                  font-size: 10px;
                }
                &.header{
                  font-weight: bold;
                  td:not(.spacer_td){
                    padding-bottom: 20px;
                  }
                }
                &.spacer{
                  height: 10px;
                }
                &.student:not(.spacer), &.header{
                  td:not(.spacer_td){
                    background-color: rgb(255, 255, 255);
                    height: 50px;
                    border-style: solid;
                    border-color: rgb(232, 232, 232);
                    border-image: initial;
                    border-width: 1px 0px 1px 1px;
                    padding: 10px;
                    &:first-child {
                      border-top-left-radius: 4px;
                      border-bottom-left-radius: 4px;
                    }
                    &:last-child {
                      border-right-width: 1px;
                      border-top-right-radius: 4px;
                      border-bottom-right-radius: 4px;
                    }
                    &.gray{
                      background: #F5F5F5;
                    }
                    &.red{
                      background: #FF0000;
                    }
                    &.greeen{
                      background: #00FF00;
                    }
                    &.gray2{
                      background: gray;
                    }
                  }
                }
                td.spacer_td{
                  width: 10px;
                  max-width: 10px;
                  border-left: 1px;
                  border-left-style: solid;
                  border-left-color: #e8e8e8;
                }
              }
              &.mobile{

                margin: auto;
                width: 100%;
                border-collapse: separate;
                border-spacing: 0px;
                tr{
                  text-align: center;
                  td:not(.spacer_td){
                    // border-left: 1px solid black;
                    // border-top: 1px solid black;
                    width: 25%;
                    &.large{
                      width: 25%
                    }
                    &.name{
                      width: calc(50% - 10px);
                    }
                  }
                }
              }
              td{
                width: inherit !important;
              }
              .professor_absences{
                td{
                  width: inherit !important;
                }
              }
            }
            &.no_scroll{
              overflow: unset;
            }
          }
          .loading{
            position: relative;
            width: 100%;
            height: 100%;
            padding: 15px;
            .loading_img{
              position: relative;
              width: 100%;
              height: 100%;
              img{
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                max-width: 50%;
                max-height: 50%;
              }
            }
          }
        }
        .d_f_v{
          display: flex;
          justify-content: center;
          flex-direction: column;
          padding-top: 16px;
          >div{
            display: flex;
            label{
              margin-right: 5px;
            }
          }
        }
        .act_export{
          margin: 0 !important;
        }
      }
      @media(max-width: 768px){
        height: 100%;
        width: 100%;
        margin: 0;
        padding: 2em;
      }
      &.dialogInfos{
        width: 50%;
        height: 250px;
        max-height: 250px;
        .row.act_export{
          padding: 10px;
          text-align: center;
          &.error{
            color: red;
          }
          &.warning{
            color: orange;
          }
          .message{
            padding: 20px 10px;
          }
          .actions_export{
            .button{
              min-width: 100px;
              text-align: center;
            }
          }
        }
      }
    }
    &.dialogListControlsGraded{
      width: 94%;
      height: 100%;
      padding-bottom: 2em;
      .export_content{
        height: calc(100% - 40px);
        .controls_graded{
          height: calc(100% - 166px);
          .controls_graded_list{
            height: 100%;
            width: 100%;
            overflow-y: auto;
            padding: 10px 15px;
            padding-bottom: 0;
            background-color: rgb(245, 246, 250);
            margin: 18px 15px;
            border-radius: 8px;
            padding: 10px 5px;
            border-width: 1px;
            border-style: solid;
            border-color: rgb(209, 219, 226);
            border-image: initial;
            table{
              margin: auto;
              width: 100%;
              border-collapse: separate;
              border-spacing: 0px;
              tr{
                text-align: center;
                td:not(.spacer_td){
                  // border-left: 1px solid black;
                  // border-top: 1px solid black;
                  &.large{
                  }
                  &.name{
                    min-width: 150px;
                    &.name_2{
                      padding-left: 40px !important;
                    }
                    i{
                      color: orange;
                      cursor: pointer;
                      margin-right: 5px;
                      font-size: 14px;
                    }
                  }
                  padding: 3px;
                  font-size: 10px;
                  &.spans{
                    span{
                      display: block;
                    }
                  }
                  .percent{
                    width: 45px;
                    color: white;
                    font-weight: bold;
                    margin: auto;
                    border-radius: 10px;
                    display: block;
                    font-size: 10px;
                    height: 20px;
                    padding: 0.25em 0.6em;
                    line-height: 16px;
                    &.red{
                      background: red;
                    }
                    &.orange{
                      background: orange;
                    }
                    &.green{
                      background: green;
                    }
                  }
                }
                &.header{
                  font-weight: bold;
                  td:not(.spacer_td){
                    padding-bottom: 20px;
                  }
                }
                &.spacer{
                  height: 10px;
                }
                &.student:not(.spacer), &.header{
                  td:not(.spacer_td){
                    background-color: rgb(255, 255, 255);
                    height: 50px;
                    border-style: solid;
                    border-color: rgb(232, 232, 232);
                    border-image: initial;
                    border-width: 1px 0px 1px 1px;
                    padding: 10px;
                    &:first-child {
                      border-top-left-radius: 4px;
                      border-bottom-left-radius: 4px;
                    }
                    &:last-child {
                      border-right-width: 1px;
                      border-top-right-radius: 4px;
                      border-bottom-right-radius: 4px;
                    }
                    &.gray{
                      background: #F5F5F5;
                    }
                    &.red{
                      background: #FF0000;
                    }
                    &.greeen{
                      background: #00FF00;
                    }
                    &.gray2{
                      background: gray;
                    }
                  }
                }
                td.spacer_td{
                  width: 10px;
                  max-width: 10px;
                  border-left: 1px;
                  border-left-style: solid;
                  border-left-color: #e8e8e8;
                }
              }
              &.mobile{

                margin: auto;
                width: 100%;
                border-collapse: separate;
                border-spacing: 0px;
                tr{
                  text-align: center;
                  td:not(.spacer_td){
                    // border-left: 1px solid black;
                    // border-top: 1px solid black;
                    width: 25%;
                    &.large{
                      width: 25%
                    }
                    &.name{
                      width: calc(50% - 10px);
                    }
                  }
                }
              }
              td{
                width: inherit !important;
              }
              .professor_absences{
                td{
                  width: inherit !important;
                }
              }
            }
            &.no_scroll{
              overflow: unset;
            }
          }
          .loading{
            position: relative;
            width: 100%;
            height: 100%;
            padding: 15px;
            .loading_img{
              position: relative;
              width: 100%;
              height: 100%;
              img{
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                max-width: 50%;
                max-height: 50%;
              }
            }
          }
        }
        .d_f_v{
          display: flex;
          justify-content: center;
          flex-direction: column;
          padding-top: 16px;
          >div{
            display: flex;
            label{
              margin-right: 5px;
            }
          }
        }
        .act_export{
          margin: 0 !important;
        }
      }
      @media(max-width: 768px){
        height: 100%;
        width: 100%;
        margin: 0;
        padding: 2em;
      }
      &.dialogInfos{
        width: 50%;
        height: 250px;
        max-height: 250px;
        .row.act_export{
          padding: 10px;
          text-align: center;
          &.error{
            color: red;
          }
          &.warning{
            color: orange;
          }
          .message{
            padding: 20px 10px;
          }
          .actions_export{
            .button{
              min-width: 100px;
              text-align: center;
            }
          }
        }
      }
    }
    &.dialogGeneralDirection{
      width: 94%;
      height: 100%;
      padding-bottom: 2em;
      .export_content{
        height: calc(100% - 40px);
        .general_direction{
          height: calc(100% - 166px);
          width: 100%;
          overflow-y: auto;
          padding: 10px 15px;
          padding-bottom: 0;
          // background-color: rgb(245, 246, 250);
          margin: 18px 15px;
          border-radius: 8px;
          padding: 10px 5px;
          border-width: 1px;
          border-style: solid;
          border-color: rgb(209, 219, 226);
          border-image: initial;
          margin-left: 0;
            
          .loading{
            position: relative;
            width: 100%;
            height: 100%;
            padding: 15px;
            .loading_img{
              position: relative;
              width: 100%;
              height: 100%;
              img{
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                max-width: 50%;
                max-height: 50%;
              }
            }
          }
          .general_direction_card{
            padding-top: 5px;
            padding-bottom: 5px;
            height: 100px;
            >div{
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              width: 100%;
              height: 100%;
              border-radius: 3px;
              background: white;
              border-radius: 10px;
              box-shadow: 1px 1px 6px 2px #b5adad;
              &.green{
                // border: 1px solid #1ae820;
                border-left: 6px solid #1ae820;
              }
              &.green2{
                // border: 1px solid #53842a;
                border-left: 6px solid #53842a;
              }
              &.green3{
                // border: 1px solid #009688;
                border-left: 6px solid #009688;
              }
              &.yellow2{
                // border: 1px solid #cddc39;
                border-left: 6px solid #cddc39;
              }
              &.pink{
                // border: 1px solid #e66bef;
                border-left: 6px solid #e66bef;
              }
              &.orange{
                // border: 1px solid #f36422;
                border-left: 6px solid #f36422;
              }
              &.yellow{
                // border: 1px solid #ffee02;
                border-left: 6px solid #ffee02;
              }
              &.yellow2{
                // border: 1px solid #cddc39;
                border-left: 6px solid #cddc39;
              }
              &.blue{
                // border: 1px solid #00adef;
                border-left: 6px solid #00adef;
              }
              &.blue2{
                // border: 1px solid #607d8b;
                border-left: 6px solid #607d8b;
              }
              &.blue3{
                // border: 1px solid #001294;
                border-left: 6px solid #001294;
              }
              &.blue4{
                // border: 1px solid #18d1e8;
                border-left: 6px solid #18d1e8;
              }
              &.red{
                // border: 1px solid #f91004;
                border-left: 6px solid #f91004;
              }
              &.red2{
                // border: 1px solid #b50008;
                border-left: 6px solid #b50008;
              }
              &.gray{
                // border: 1px solid #9e9e9e;
                border-left: 6px solid #9e9e9e;
              }
              &.brown{
                // border: 1px solid #795548;
                border-left: 6px solid #795548;
              }
              .title{
                margin: 0;
                padding: 5px 10px;
                display: flex;
                justify-content: space-between;
                .main_title{
                  color: black;
                  font-weight: normal;
                  font-size: 14px;
                  text-align: left;
                }
                .second_title{
                  text-align: center;
                  color: black;
                  font-weight: normal;
                  font-size: 13px;
                  .select{
                    width: max-content;
                    height: 24px;
                    border-radius: 10px;
                    border: solid 0.6px #d1dbe2;
                    background-color: rgba(224, 227, 228, 0.3);
                    position: relative;
                    padding-left: 3px;
                    margin: auto;
                    select{
                      width: 100%;
                      font-size: 12px;
                      height: 22px;
                      border: none;
                      outline: none;
                      -webkit-appearance: none;
                      -moz-appearance: none;
                      appearance: none;
                      text-indent: 1px;
                      text-overflow: "";
                      background: transparent;
                      outline: 0;
                      position: relative;
                      z-index: 9;
                      border-radius: 4px !important;
                      border: none !important;
                      font-weight: 500;
                      font-family: Montserrat;
                      color: #1b2437;
                      padding: 0 5px;
                      cursor: pointer;
                      padding-right: 20px;
                    }
                    i{
                      color: #d2dbe2;
                      font-size: 14px;
                      position: absolute;
                      right: 5px;
                      top: 4px;
                    }
                  }
                }
                
              }
              .value{
                padding-bottom: 10px;
                text-align: center;
                font-size: 18px;
              }
            }
          }
        }
        .d_f_v{
          display: flex;
          justify-content: center;
          flex-direction: column;
          padding-top: 16px;
          >div{
            display: flex;
            label{
              margin-right: 5px;
            }
          }
        }
        .act_export{
          margin: 0 !important;
        }
      }
      @media(max-width: 768px){
        height: 100%;
        width: 100%;
        margin: 0;
        padding: 2em;
      }
      &.dialogInfos{
        width: 50%;
        height: 250px;
        max-height: 250px;
        .row.act_export{
          padding: 10px;
          text-align: center;
          &.error{
            color: red;
          }
          &.warning{
            color: orange;
          }
          .message{
            padding: 20px 10px;
          }
          .actions_export{
            .button{
              min-width: 100px;
              text-align: center;
            }
          }
        }
      }
    }
    @media(max-width: 1199px){
      width: 100%;
      padding: 2em 0;
      border: 0;
    }
    @media(max-width: 768px){
      height: 100%;
    }
  }

  &.dialogInfoCandidate {
    width: 400px;
    object-fit: contain;
    border-radius: 20px;
    box-shadow: 0 5px 45px 0 rgba(46, 104, 208, 0.1);
    background-color: #fff;
    padding: 25px;
    border-left: none;
    position: relative;
  }

  &.dialogPrinView {
    width: 1000px;
    object-fit: contain;
    border-radius: 20px;
    box-shadow: 0 5px 45px 0 rgba(46, 104, 208, 0.1);
    background-color: #fff;
    padding: 25px;
    border-left: none;
    position: relative;
  }

  &.dialogPopupSuccess {
    object-fit: contain;
    border-radius: 20px;
    box-shadow: 0 5px 45px 0 rgba(46, 104, 208, 0.1);
    background-color: #fff;
    padding: 0px;
    border-left: none;
    position: relative;
    z-index: 999999;
  }

  &.dialogAddSchool {
    object-fit: contain;
    border-radius: 20px;
    box-shadow: 0 5px 45px 0 rgba(46, 104, 208, 0.1);
    background-color: #fff;
    padding: 25px;
    border-left: none;
    position: relative;
    &.dialogAddSubject{
      max-width: 100%;
    }
    
    .image-profile{
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #919191;
      i{
        width: 150px;
        height: 150px;
        font-size: 150px;
        border-radius: 50%;
        border: 1px solid #919191;
        text-align: center;
        overflow: hidden;
      }
      img{
        width: 150px;
        height: 150px;
        border-radius: 50%;
      }
      .btn{
        position:relative;
        padding: 0px 20px;
        margin-top: 10px;
        input{
          opacity:0;
          position: absolute;
          top:0px;
          left:0px;
          width:100%;
          height:100%
        }
      }
    }
    &.dialogAddCircuitStep{
      width: 30%;
    }
    &.dialogLate{
      width: 346px;
      height: 289px;
      object-fit: contain;
      border-radius: 20px;
      .add-school{
        .add-school-form{
          .school-ligne{
            .school-ligneLate{
              input{
                width:190px;
                display: inline-block;
              }
              span{
                font-family: Montserrat;
                font-size: 14px;
                font-weight: 400;
                color: rgba(27, 36, 55, 0.8);
                display: inline-block;
                line-height: 28px;
                margin-left: 10px;
              }
            }
          }
        }
      }
      .bloc-next{
        text-align: center;
        margin-top: 25px;
        a{
          width: 124px;
          height: 30px;
          display:inline-block;
          object-fit: contain;
          line-height: 28px;
          text-align:center;
          border-radius: 49.5px;
          box-shadow: 0 3px 17px 0 rgba(90, 202, 195, 0.5);
          background-image: linear-gradient(157deg, #72dcd6, #3eb6ad);
          color:rgba(255,255,255,.9);
          font-size: 12px;
          font-weight: 500;
        }
      }
    }
    &.dialogChapter{
      width: 334px;
      max-width: 334px;
      &.dialogChapterrefentiel{
        width: auto;
        max-width: unset;
      }
      .school-ligne{
        .upload{
          img{
            width: 50px;
            height: 50px;
            
          }
          span{
            width: 115px;
            display: inline-block;
            margin-left: 8px;
            height: 30px;
            object-fit: contain;
            border-radius: 49.5px;
            background-color: #3eb6ad;
            line-height: 30px;
            text-align: center;
            font-size: 12px;
            font-weight: 400;
            color: rgba(255,255,255,.8);



        
          }
        }
      }
    }
    &.dialogAddChapter{
      width: 742px;
      max-width: 742px;
      /*height: 438px;*/
      padding: 25px 12.5px;
      height: 100%;
      &.dialogAddDiscount{
        width: 80%;
        max-width: 80%;
      }
      .add-school{
        .add-school-top{
          padding-left: 12.5px;
        }
        .top-assurance{
          display: flex;
          justify-content: space-between
        }
        .chapters{
          margin-top: 25px;
          .chapterAdd{
            /*float:left;
            width: calc(50% - 25px);
            margin: 0 12.5px 25px;*/
            margin-top:10px;
            /*height: 156px;*/
            min-height: 190px;
            object-fit: contain;
            border-radius: 10px;
            box-shadow: 0 5px 48px 0 rgba(46, 104, 208, 0.1), 0 8px 30px 0 rgba(0, 0, 0, 0.07);
            background-color: #FFF;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            span{
              width: 60px;
              height: 60px;
              border-radius: 150px;
              box-shadow: 13px 0px 30px 3px rgba(239, 194, 91, 0.5);
              background-image: linear-gradient(225deg, #fdd271, #d5a433);
              transform: rotate(45deg);
              color: #FFF;
              font-size: 47px;
              line-height: 60px;
              text-align: center;
            }
            b{
              font-family: Montserrat;
              font-size: 16px;
              font-weight: 600;
              line-height: 18px;
              text-align: center;
              color: #1b2437;
              margin-top: 11px;
            }
          }
          .cardChapter{
            /*float:left;
            width: calc(50% - 25px);
            margin: 0 12.5px 25px;*/
            min-height: 190px;
            margin-top:10px;
            /* height: 156px; */
            object-fit: contain;
            border-radius: 10px;
            border: solid 1px #d1dbe2;
            background-color: #FFF;
            border-bottom: 6px solid #f2985a;
            padding: 6px 20px 0;
            position: relative;
            .img{
              width: 96px;
              height: 96px;
              display: block;
              text-align: center;
              line-height: 96px;
              margin: auto;
              img{
                width: 96px;
              height: 96px;
              }
            }
            h4{
              font-size: 14px;
              font-weight: 600;
              line-height: 18px;
              letter-spacing: normal;
              text-align: center;
              color: #1b2437;
              margin: 16px 0 0;
            }
          }
        }
      }
      .sort_container_element{
        overflow-y: auto;
        width: 100%;
        height: 100%;
        .chapters{
          margin-right: 0 !important;
          margin-left: 0 !important;
        }
        &::-webkit-scrollbar-track{
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
            background-color: #F5F5F5;
        }
        &::-webkit-scrollbar{
            width: 5px;
            height: 3px;
            background-color: #F5F5F5;
        }
        &::-webkit-scrollbar-thumb{
            background-color: #3eb6ad;
        }
      }
    }
    &.dialogParents{
      width: 650px;
      max-width: 650px;
      padding: 35px 80px 70px;
      position: relative;
      &.dialogParentsInformations{
        max-width: 500px;
        padding: 25px;
        &:before{
          display: none;
        }
        .add-school-form{
          margin: 0!important;
          .upload{
            padding: 0 10px 30px;
            display: block;
            position: relative;
            input{
              visibility: hidden;
              position: absolute;
              left:0;
              right:0;
              top:0;
              bottom:0;
            }
            img{
              width: 50px;
              height: 50px;
            }
            span{
              width: 120px;
              height: 30px;
              object-fit: contain;
              border-radius: 49.5px;
              border: solid 2px #FFF;
              background-color: #3eb6ad;
              font-family: Montserrat;
              font-size: 12px;
              font-weight: 400;
              text-transform: uppercase;
              text-align: center;
              line-height: 28px;
              color: rgba(255, 255, 255, .8);
              display: inline-block;
              margin-left: 6px;
            }
          }
          .gender {
            display: inline-block;
            > div {
              display: inline-block;
              position:relative;
              input {
                position: absolute;
                opacity: 0;
                width: 100%;
                height: 100%;
                left: 0px;
                cursor:pointer;
              }
              span {
                float: left;
                width: 27px;
                height: 27px;
              }
              b {
                font-family: Montserrat;
                font-size: 14px;
                font-weight: 500;
                font-style: normal;
                font-stretch: normal;
                line-height: 27px;
                margin-left: 7px;
                letter-spacing: normal;
                color: rgba(27, 36, 55, 0.8);
              }
            }
            .genderMale {
              display: inline-block;
              span {
                background: url("../img/iconMale.svg") no-repeat scroll
                  transparent;
                background-size: contain;
              }
              input {
                &:checked {
                  + span {
                    background: url("../img/iconMaleActive.svg") no-repeat
                      scroll transparent;
                  }
                }
              }
            }

            .choiseSituation {
              display: inline-block;
              span {
                background: url("../img/iconMale.svg") no-repeat scroll
                  transparent;
                background-size: contain;
              }
              input {
                &:checked {
                  + span {
                    background: url("../img/iconMaleActive.svg") no-repeat
                      scroll transparent;
                  }
                }
              }
            }
            .genderFemale {
              display: inline-block;
              margin-left: 18px;
              span {
                background: url("../img/iconFemale.svg") no-repeat scroll
                  transparent;
                background-size: contain;
              }
              input {
                &:checked {
                  + span {
                    background: url("../img/iconFemaleActive.svg") no-repeat
                      scroll transparent;
                  }
                }
              }
            }
          }
        }
      }
      &:before {
        content: "";
        width: 101px;
        height: 116px;
        position: absolute;
        right: 10px;
        bottom: 35px;
        background: url("../../assets/img/iconFuseeCnx.png") no-repeat scroll
          transparent;
        -moz-transform: scaleX(-1);
        -webkit-transform: scaleX(-1);
        -o-transform: scaleX(-1);
        transform: scaleX(-1);
        -ms-filter: fliph; /*IE*/
        filter: fliph; /*IE*/
        opacity: .7;
      }
      .add-school{
        .add-school-form{
          margin: 0 -10px;
          .school-ligne{
            float: left;
            width: 100%;
            margin-bottom: 25px;
            .school-ligne-w50{
              .intl-tel-input{
                width:100%
              }
              width: 50%;
              float: left;
              padding: 0 10px;
            }
            .school-ligne-w100{
              width: 100%;
              float: left;
              padding: 0 10px;
            }
            .field_error{
              color: red;
            }
            &.pack_article_image{

              .image{
                width: 100%;
                padding-top: 30px;
                position: relative;
                .img{
                  max-width: 150px;
                  max-height: 150px;
                  margin: auto;
                  position: relative;
                  padding: 5px;
                  img{
                    width: max-content;
                    max-width: 100%;
                    max-height: 100% !important;
                  }
                  .remove_image{
                    position: absolute;
                    top: 0;
                    right: 0;
                    color: red;
                    cursor: pointer;
                    i{
                      position: absolute;
                      top: 0;
                      right: 0;
                      color: red;
                    }
                  }
                }
              }
              .browse{
                margin: 15px auto;
                box-shadow: 0 10px 20px 0 rgba(6, 150, 140, 0.507);
                background-image: linear-gradient(225deg, #d2d8d8, #ababab);
                color: #ffffff;
                border: none;
                border-radius: 20px;
                padding: 5px 10px;
                font-size: 14px;
                font-weight: 600;
                height: 30px;
                min-width: 100px;
                width: max-content;
                cursor: pointer;
              }
            }
      
          }
         
          .bloc-next{
            text-align: right;
            float: left;
            width: 100%;
            margin-top: 5px;
            a{
              width: 120px;
              height: 35px;
              object-fit: contain;
              border-radius: 49.5px;
              box-shadow: 0 7px 20px 0 rgba(90, 202, 195, 0.5);
              background-image: linear-gradient(254deg, #72dcd6, #3eb6ad);
              font-size: 12px;
              font-weight: 600;
              color: #fff;
              text-align: center;
              line-height: 35px;
              display: inline-block;
            }
          }
        }
        &.persons_to_contact{
          .person_to_conatct{
            position: relative;
            border: 2px solid #c8c8c8;
            border-radius: 7px;
            padding-top: 10px;
            padding-bottom: 10px;
            margin-bottom: 15px !important;
            .title{
              position: absolute;
              bottom: calc(100% - 5px);
              background-color: white;
              padding: 0 5px;
              color: gray;
              font-weight: bold;
            }
            .display_flex{
              label{
                width: 100%;
              }
            }
            .input_choice{
              width: 31%;
              margin: 1%;
              input{
                width: 100%;
              }
            }
            @media (max-width: 767px){
              .input_choice{
                width: 48%;
              }
            }

          }
          .school-ligne-w100{
            .intl-tel-input{
              width: 100%;
            }
          }
        }
        &.additional_information_form{
          .main_label{
            width: 100%;
          }
          .input_choice{
            width: 31%;
            margin: 1%;
            input{
              width: 100%;
            }
          }
          @media (max-width: 767px){
            .input_choice{
              width: 48%;
            }
          }
        }
      }
    }
    &.dialogAddProfessor {
      padding: 25px 20px 20px;
      @media(min-width: 1200px){
        min-width: 640px;
      }
      .add-school {
        .add-school-form {
          .school-ligne {
            margin-bottom: 0;
            &.school-ligne-diploma {
              margin-bottom: 25px;
              .newClasseTop{
                img{
                  &.file{
                    width: 50px;
                    margin-right: 10px;
                  }
                }
                span{
                  font-size: 15px;
                  font-weight: 500;
                }
              }
            }
            &.school-ligne-margin {
              width: calc(100% + 50px);
              margin-left: -25px;
              margin-right: -25px;
              margin-bottom: 15px;
              .school-ligne-w50 {
                padding: 0 25px;
              }
              .react-datepicker-wrapper{
                margin:0px
              }
            }
            
            .RadioLegend {
              /* width: 25%;*/
              width: 30%;
              margin: 0 0 15px;
            }
            .radioLevels {
              .RadioLegend {
                > legend {
                  text-transform: uppercase;
                }
              }
            }
            &.school-ligne-classes {
              border: 2px dotted #d8dfe6;
              margin: 0 -20px 15px;
              width: calc(100% + 40px);
              padding: 10px 20px 0;
            }
          }
        }
      }
      .error{
        color: red;
      }
    }
    &.dialogProfessorAbscences {
      height: 100%;
      max-width: 80%;
      width: 80%;
      .add-school{
        height: calc(100% - 57px);
      }
    }
    &.dialogAssistantAbscences {
      height: 100%;
      max-width: 80%;
      width: 80%;
      .add-school{
        height: calc(100% - 57px);
      }
    }
    &.dialogEstablishments {
      height: 100%;
      max-width: 80%;
      width: 80%;
      .add-school{
        height: calc(100% - 57px);
      }
      .add_establishment{
        position: absolute;
        bottom: 5px;
        right: 10px;
        cursor: pointer;
        width: 105px;
        height: 30px;
        border-radius: 49.5px;
        box-shadow: 0 6px 24px 0 rgba(239,194,91,0.5);
        background-image: linear-gradient(254deg,#fdd271,#d5a433);
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 30px;
        -webkit-letter-spacing: normal;
        -moz-letter-spacing: normal;
        -ms-letter-spacing: normal;
        -webkit-letter-spacing: normal;
        -moz-letter-spacing: normal;
        -ms-letter-spacing: normal;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
      }
    }

    &.dialogAddStudent {
      .friends-switch-block{
        text-align: center;
        @media(max-width: 767.98px){
          margin-top: 25px;
        }
        .friends-switch{
          border-radius: 49.5px;
          border: .6px solid #d1dbe2;
          background-color: rgba(245,248,249,.3);
          height: 32px;
          display: inline-block;
          margin: 0 auto 20px;
          overflow: hidden;
          a{
            font-family: Montserrat,sans-serif;
            font-size: 12px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #3eb6ad;
            padding: 8px 20px;
            float: left;
            border-radius: 49.5px;
            &.active{
              box-shadow: 0 -10px 20px 0 rgba(90,202,195,.5);
              background-image: -webkit-linear-gradient(191deg,#72dcd6,#3eb6ad);
              background-image: linear-gradient(259deg,#72dcd6,#3eb6ad);
              color: #fff;
              padding: 8px 30px;
            }
          }
        }
      }
      .title-block{
        text-align: center;
        @media(max-width: 767.98px){
          margin-top: 25px;
        }
        .title{
          border-radius: 49.5px;
          border: .6px solid #d1dbe2;
          background-color: rgba(245,248,249,.3);
          height: 32px;
          display: inline-block;
          margin: 0 auto 20px;
          overflow: hidden;
          padding: 5px 10px;
          font-weight: bold;
        }
      }
      .exist_students, .exist_professors{
        .friends-top{
          /*margin-top: 30px;*/
          float: left;
          width: 100%;
          .friend-search {
            float: left;
            width: 228px;
            position: relative;
            @media(max-width: 767.98px){
              float: none;
              margin:auto;
            }
            input {
              height: 28px;
              border-radius: 19px;
              border: solid 1px #b1bfcd;
              background-color: #f8f8f8;
              width: 100%;
              padding-left: 16px;
            }
            span {
              position: absolute;
              right: 11px;
              top: 3px;
            }
          }
          .friend-right{
            float: right;
            display: flex;
            @media(max-width: 767.98px){
              float: none;
              justify-content: center;
            }
            .filter-objects{
              .filter_dropdown{
                ul{
                  padding-left: 10px;
                }
                button{
                  background: transparent;
                  border: none;
                  &:after{
                    border-top: .35em solid;
                    border-right: .35em solid transparent;
                    border-bottom: 0;
                    border-left: .35em solid transparent;
                    color: #1b2437;
                    vertical-align: 0.2em;
                  }
                  .subject-caret{
                    display: none;
                  }
                  .icon_filter{
                      background-image: url(../img/icon_classe.svg);
                      width: 16px;
                      height: 16px;
                      display: inline-block;
                      vertical-align: middle;
                      background-size: contain;
                      background-repeat: no-repeat;
                  }
                  .selected_text{
                      font-size: 14px;
                      font-weight: 400;
                      line-height: 1.42857143;
                      text-align: center;
                      white-space: nowrap;
                      cursor: pointer;
                      color: #333;
                  }
                }
                &.levels_dropdown{
                    button{
                        .icon_filter{
                            background-image: url(../img/icon_level.svg);
                        }
                    }
                }
              }
            }
          }
        }
        .friend-content{
          float: left;
          width: 100%;
          margin-top: 20px;
          overflow-y: auto;
          .content-scroll {
            height: 280px;
            &::-webkit-scrollbar-track {
              -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
              background-color: #f5f5f5;
            }
    
            &::-webkit-scrollbar {
              width: 5px;
              background-color: #f5f5f5;
            }
    
            &::-webkit-scrollbar-thumb {
              background-color: #3eb6ad;
            }
          }
          .friend-content-bloc{
            height: 100%;
            margin-bottom: 15px;
            float: left;
            width: 100%;
            margin: 10px 0 0;
            .friend-content-bloc-scroll.exist-students, .friend-content-bloc-scroll.exist-professors{
                height: 260px;
                overflow-y: auto;
                padding-right: 10px;
                padding-top: 10px;
                padding-left: 5px;
                &::-webkit-scrollbar-track {
                  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                  background-color: #f5f5f5;
                }
      
                &::-webkit-scrollbar {
                  width: 5px;
                  background-color: #f5f5f5;
                }
      
                &::-webkit-scrollbar-thumb {
                  background-color: #3eb6ad;
                }
                .optiscroll-content{
                  height: 100%;
                  padding-top: 15px;
                  overflow-x: hidden;
                }
                .friend-bloc.exist-student, .friend-bloc.exist-professor {
                  width: 12.5%;
                  float: left;
                  padding: 0 10px;
                  text-align: center;
                  margin-bottom: 25px;
                  position: relative;
                  @media(max-width: 1199px){
                    width: 33.33%;
                  }
                  .friend-img{
                    margin-bottom: 10px!important;
                  }
                  img{
                    width: 72px;
                    height: 72px;
                    box-shadow: 0 7px 24px -4px #a9adb1;
                    border-radius: 72px;
                    margin: auto;
                    margin-bottom: 10px;
                  }
                  h5{
                    font-size: 11.5px;
                    font-weight: 400;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.27;
                    letter-spacing: normal;
                    text-align: center;
                    color: #1b2437;
                    margin-top: 10px;
                    overflow:hidden;
                    white-space:nowrap;
                    text-overflow: ellipsis;
                  }
                  &.selected{
                    img{
                      visibility: hidden;
                    }
                    &:before{
                      content: "";
                      width: 80px;
                      height: 80px;
                      border-radius: 76px;
                      position: absolute;
                      left: 50%;
                      transform: translateX(-50%);
                      top: -2px;
                      background: url(../img/item_selected.svg) no-repeat scroll transparent;
                      background-position: 0 0;
                      background-size: contain;
                      box-shadow: 0 7px 24px -4px #a9adb1;
                    }
                  }
                }
            }
          }
          .bloc-next{
            margin-top: 16px;
            text-align: center;
            width: 100%;
            float: left;
            a{
              box-shadow: 0 1px 20px -1px rgba(90,202,195,.5);
              background-image: -webkit-linear-gradient(54deg,#72dcd6,#3eb6ad);
              background-image: -webkit-linear-gradient(36deg,#72dcd6,#3eb6ad);
              background-image: linear-gradient(54deg,#72dcd6,#3eb6ad);
              color: #fff;
              padding: 5px 40px;
              display: inline-block;
              border-radius: 20px;
              margin-bottom: 15px;
              font-weight: 600;
            }
          }
        }
      }
      .add-school{
        .select{
          float: left;
          width: 100%;
          height: 26.5px;
          border-radius: 2.6px;
          border: .6px solid #d1dbe2;
          background-color: rgba(245,248,249,.3);
          position: relative;
          select{
            float: left;
            width: 100%;
            font-size: 12px;
            height: 25.5px;
            border: none;
            outline: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            text-indent: 1px;
            text-overflow: "";
            background: transparent;
            outline: 0;
            position: relative;
            z-index: 9;
            border-radius: 4px!important;
            border: none!important;
            font-weight: 500;
            font-family: Montserrat;
            color: #1b2437;
            padding: 0 5px;
          }
          i{
            color: #d2dbe2;
            font-size: 16px;
            position: absolute;
            right: 5px;
            top: 5px;
          }
        }
        .intl-tel-input{
          width:100%
        }
      }
    }
    &.dialogAddBehavior{
      .d_f{
        display: flex;
        .react-datepicker-wrapper{
          margin-left: 0;
        }
      }
      .input_file{
        cursor: pointer;
        opacity: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
      .author_report{
        display: flex;
        justify-content: space-between;
        .author_name{

        }
        .edit_author{
          cursor: pointer;
          width: 25px;
          height: 25px;
          border-radius: 50%;
          border: 0px !important;
          padding: 0px;
          box-shadow: 0 10px 20px 0 rgba(90,202,195,0.5);
          background-image: -webkit-linear-gradient(225deg,#72dcd6,#3eb6ad);
          background-image: linear-gradient(225deg,#72dcd6,#3eb6ad);
          color: #fff;
          text-align: center;
        }
      }
      .input_choice{
        height: 30px;
        line-height: 13px;
      }
      .document{
        display: flex;
        .doc{
          width: calc(100% - 60px);
          .name{
            text-align: left;
            display: block;
          }
        }
        .actions{
          display: flex;
          justify-content: center;
          width: 60px;
          button{
            margin: 0 5px;
            padding: 0 5px;
            width: 20px!important;
            height: 20px!important;
            font-size: 12px;
            line-height: 20px;
            i{
              font-size: 13px;
            }
          }
          .remove_document{
            color: red;
            margin: 0 5px;
            cursor: pointer;
            border-radius: 50%;
            border: 1px solid red;
            display: block;
            width: 20px;
            height: 20px;
            line-height: 19px;
            i{
              margin-left: 4px;
            }
          }
        }
      }
    }
    h2 {
      margin: 0 0 25px;
      font-family: "Montserrat", sans-serif;
      font-size: 18px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #1b2437;
    }
    .schoolClose {
      position: absolute;
      cursor: pointer;
      top: 0;
      right: 0;
      width: 40px;
      height: 40px;
      border-radius: 100px;
      box-shadow: 0 10px 20px 0 rgba(90, 202, 195, 0.5);
      background-image: linear-gradient(225deg, #72dcd6, #3eb6ad);
      text-align: center;
      color: #fff;
      font-size: 40px;
      line-height: 40px;
      i {
        color: #fff;
        font-size: 21px;
        line-height: 40px;
      }
    }
    .add-school-form {
      float: left;
      width: 100%;
      @media (max-width: 1199px){
          margin-top: 13%;
      }
      .textErrors {
        color: #f13434;
        font-size: 10px;
        font-family: "Montserrat", sans-serif;
      }
      .school-ligne {
        width: 100%;
        float: left;
        margin-bottom: 15px;
        &.row{
          width: auto;
          float: none;
        }
        &.checkbox_style{
          .state{
            label{
              &:before{
                top:0;
              }
              &:after{
                top:0;
              }
            }
          }
        }
        .schoolBrowse{
          position: relative;
          width: 100px;
          height: 22px;
          object-fit: contain;
          border-radius: 11px;
          border: solid 1px #d1dbe2;
          background-color: rgba(245, 248, 249, 0.3);
          text-align: center;
          span{
            font-family: Montserrat;
            font-size: 12px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: 22px;
            letter-spacing: normal;
            color: #3eb6ad;
          }
          input{
            position: absolute;
            visibility: hidden;
          }
        }
        .schoolLibrary{
          margin: 7px -3px;
          img{
            width: 35px;
            height: 35px;
            border-radius: 4px;
            margin: 0 3px;
          }
        }
        .w12 {
          margin-top: 4px;
        }
        .w50 {
          width: 50% !important;
          padding: 0 12px !important;
        }
        .colors{
          margin: 0 -6px;
          float: left;
          .color{
            width: 30px;
            height: 30px;
            border-radius: 4px;
            float: left;
            margin: 0 6px 15px;
            border: 1px solid transparent;
            padding: 2px;
            position: relative;
            cursor: pointer;
            &.selected{
              border: 2px solid #92bc92!important;
              opacity:1;
            }
            &:before{
              content: "";
              position: absolute;
              left: 0;
              right: 0;
              top: 0;
              bottom: 0;
              height: 2px;
              background: #fff;
            }
            &.active{
              border: 1px solid #9f9f9f;
            }
            &.color1{
              background: #d33f29;
            }
            &.color2{
              background: #fa5656;
            }
            &.color3{
              background: #ff774f;
            }
            &.color4{
              background: #ffa726;
            }
            &.color5{
              background: #e4ba5b;
            }
            &.color6{
              background: #f7cc68;
            }
            &.color7{
              background: #f9c499;
            }
            &.color8{
              background: #fbc3c4;
            }
            &.color9{
              background: #e6effa;
            }
            &.color10{
              background: #b1bfcd;
            }
            &.color11{
              background: #3e4ca0;
            }
            &.color12{
              background: #4d58a8;
            }
            &.color13{
              background: #5765f5;
            }
            &.color14{
              background: #56a0fa;
            }
            &.color15{
              background: #53c5be;
            }
            &.color16{
              background: #5eaad7;
            }
            &.color17{
              background: #a1c1e6;
            }
            &.color18{
              background: #d9dcfd;
            }
            &.color19{
              background: #fff;
              border: solid 1px #d9dce2;
            }
            &.color20{
              background: #000;
            }
          }
        }

        .school-ligne-w50 {
          margin-bottom: 0 !important;
        }
        &.smargin {
          margin: 0 -12px 25px !important;
          width: calc(100% + 24px) !important;
        }
        &.scenter {
          text-align: center;
        }
        button {
          &.add {
            display: inline-block;
            min-width: 106px;
            height: 40px;
            object-fit: contain;
            border-radius: 49.5px;
            text-align: center;
            font-family: Montserrat;
            font-size: 12px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            letter-spacing: normal;
            box-shadow: 0 6px 20px 0 rgba(90, 202, 195, 0.5);
            background-image: -webkit-linear-gradient(54deg, #72dcd6, #3eb6ad);
            background-image: linear-gradient(54deg, #72dcd6, #3eb6ad);
            color: #fff;
            border: none;
            margin: 0 7px;
            cursor: pointer;
            &.cancel {
              background: #fff;
              color: #3eb6ad;
              box-shadow: none;
              border: solid 0.6px #d1dbe2;
            }

            &.new {
              width: 100%;
              margin-bottom: 20px;
            }
          }
          &.save{
            min-width: 130px;
          }
        }
        label {
          display: block;
          font-family: Montserrat;
          font-size: 15px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #1b2437;
          margin-bottom: 5px;
        }
        &.pack_article_image{

          .image{
            width: 100%;
            padding-top: 30px;
            position: relative;
            .img{
              max-width: 150px;
              max-height: 150px;
              width: 150px;
              height: 150px;
              margin: auto;
              position: relative;
              padding: 5px;
              img{
                width: max-content;
                max-width: 100%;
                max-height: 100% !important;
              }
              .remove_image{
                position: absolute;
                top: 0;
                right: 0;
                color: red;
                cursor: pointer;
                i{
                  position: absolute;
                  top: 0;
                  right: 0;
                  color: red;
                }
              }
            }
          }
          .browse{
            margin: 15px auto;
            box-shadow: 0 10px 20px 0 rgba(6, 150, 140, 0.507);
            background-image: linear-gradient(225deg, #d2d8d8, #ababab);
            color: #ffffff;
            border: none;
            border-radius: 20px;
            padding: 5px 10px;
            font-size: 14px;
            font-weight: 600;
            height: 30px;
            min-width: 100px;
            width: max-content;
            cursor: pointer;
          }
        }
        div.group-add-input{
          display:flex;
          button{
            height: 28px;
            line-height: 14px;
          }
        }
        input, textarea {
          float: left;
          width: 100%;
          height: 28px;
          border-radius: 2.6px;
          border: solid 0.6px #d1dbe2;
          background-color: rgba(245, 248, 249, 0.3);
          font-size: 14px;
          font-weight: 400;
          color: #1b2437!important;
          &:not([type=tel]){
            padding-left: 10px;
          }
          &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            opacity: 1; /* Firefox */
            color: #1b2437;
            font-weight: 400;
          }
          
          &:-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: #1b2437;
            font-weight: 400;
          }
          
          &::-ms-input-placeholder { /* Microsoft Edge */
            color: #1b2437;
            font-weight: 400;
          }
        }
        textarea{
          height: 70px;
          resize: none;
        }
        .select {
          float: left;
          width: 100%;
          height: 29px;
          border-radius: 2.6px;
          border: solid 0.6px #d1dbe2;
          background-color: rgba(245, 248, 249, 0.3);
          position: relative;
          padding-left: 3px;
          select {
            float: left;
            width: 100%;
            font-size: 12px;
            height: 28px;
            border: none;
            outline: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            text-indent: 1px;
            text-overflow: "";
            background: transparent;
            outline: 0;
            position: relative;
            z-index: 9;
            border-radius: 4px!important;
            border: none!important;
            font-weight: 500;
            font-family: Montserrat;
            color: #1b2437;
            padding: 0 5px;
            &::-ms-expand {
              display: none;
            }
          }
          i {
            color: #d2dbe2;
            font-size: 14px;
            position: absolute;
            right: 5px;
            top: 6px;
          }
        }
        &.school-ligne-margin {
          margin: 0 -12px 15px;
          width: calc(100% + 24px);
          .school-ligne-w50 {
            float: left;
            width: 50%;
            padding: 0 12px;
            .flagSelect {
              float: left;
              width: 100%;
              height: 26.5px;
              border-radius: 2.6px;
              border: solid 0.6px #d1dbe2;
              background-color: rgba(245, 248, 249, 0.3);
              .select {
                float: left;
                width: 70px;
                height: 25.5px;
                position: relative;
                padding-left: 3px;
                border: none;
                &:before {
                  content: "";
                  position: absolute;
                  right: 0;
                  top: 4px;
                  bottom: 4px;
                  width: 1px;
                  background: #b1bfcd;
                }
                select {
                  float: left;
                  width: 50px;
                  font-size: 12px;
                  height: 25.5px;
                  border: none;
                  outline: none;
                  -webkit-appearance: none;
                  -moz-appearance: none;
                  appearance: none;
                  text-indent: 1px;
                  text-overflow: "";
                  background: transparent;
                  outline: 0;
                  position: relative;
                  z-index: 9;
                  border-radius: 4px!important;
                  border: none!important;
                  font-weight: 500;
                  font-family: Montserrat;
                  color: #1b2437;
                  padding: 0 5px;
                  &::-ms-expand {
                    display: none;
                  }
                }
                i {
                  color: #d2dbe2;
                  font-size: 16px;
                  position: absolute;
                  right: 5px;
                  top: 5px;
                }
              }

              input {
                border: none;
                width: calc(100% - 70px);
                padding-left: 6px;
              }
            }
          }
          .school-ligne-w100 {
            float: left;
            width: 100%;
            padding: 0 12px;
            .flagSelect {
              float: left;
              width: 100%;
              height: 26.5px;
              border-radius: 2.6px;
              border: solid 0.6px #d1dbe2;
              background-color: rgba(245, 248, 249, 0.3);
              .select {
                float: left;
                width: 70px;
                height: 25.5px;
                position: relative;
                border: none;
                &:before {
                  content: "";
                  position: absolute;
                  right: 0;
                  top: 4px;
                  bottom: 4px;
                  width: 1px;
                  background: #b1bfcd;
                }
                select {
                  float: left;
                  width: 50px;
                  font-size: 12px;
                  height: 25.5px;
                  border: none;
                  outline: none;
                  -webkit-appearance: none;
                  -moz-appearance: none;
                  appearance: none;
                  text-indent: 1px;
                  text-overflow: "";
                  background: transparent;
                  outline: 0;
                  position: relative;
                  z-index: 9;
                  border-radius: 4px!important;
                  border: none!important;
                  font-weight: 500;
                  font-family: Montserrat;
                  font-size: 14px;
                  color: #1b2437;
                  padding: 0 10px;
                  &::-ms-expand {
                    display: none;
                  }
                }
                i {
                  color: #d2dbe2;
                  font-size: 16px;
                  position: absolute;
                  right: 5px;
                  top: 5px;
                }
              }

              input {
                border: none;
                width: calc(100% - 70px);
                padding-left: 6px;
              }
            }
          }
        }
        .RadioLegend {
          float: left;
          margin-right: 25px;
          &:last-child {
            margin-right: 0;
          }
          > span {
            padding: 0;
            float: left;
            flex: none;
            display: initial;
            position: relative;
            &:before {
              content: "";
              position: absolute;
              left: 3px;
              right: 3px;
              top: 3px;
              bottom: 3px;
              border-radius: 50%;
            }

            &[class*="checked"] {
              &:before {
                display: none;
              }
              svg {
                color: #00b3a9;
              }
            }
          }
          > legend {
            float: left;
            margin: 0;
            font-family: Montserrat;
            font-size: 13px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #1b2437;
            margin-left: 5px;
            margin-top: 3px;
          }
        }

        &.school-ligne-center {
          text-align: center;
          margin-top: 12px;
          button {
           /* width: 106px;*/
            width: 130px;
            height: 30px;
            object-fit: contain;
            border-radius: 49.5px;
            border: solid 0.6px #d1dbe2;
            background-color: rgba(245, 248, 249, 0.3);
            text-align: center;
            font-family: Montserrat;
            font-size: 12px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #3eb6ad;
            margin: 0 5px;
            &.add {
              box-shadow: 0 6px 20px 0 rgba(90, 202, 195, 0.5);
              background-image: -webkit-linear-gradient(
                54deg,
                #72dcd6,
                #3eb6ad
              );
              background-image: linear-gradient(54deg, #72dcd6, #3eb6ad);
              color: #fff;
              border: none;
            }
          }
        }
      }
    }
    &.dialogProviders{
      .d_flex{
        display: flex;
      }
      .jc_c{
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 1;
      }
      a.closeModal{
        position: absolute;
        cursor: pointer;
        top: 0px;
        right: 0px;
        width: 40px;
        height: 40px;
        box-shadow: rgba(90, 202, 195, 0.5) 0px 10px 20px 0px;
        background-image: linear-gradient(225deg, rgb(114, 220, 214), rgb(62, 182, 173));
        text-align: center;
        color: rgb(255, 255, 255);
        font-size: 40px;
        line-height: 40px;
        border-radius: 100px;
      }
      > h2{
        text-align: center;
      }
    }
    &.dialogSchoolBadges{
      width: 90%;
      height: 90%;
      >div{
        height: 100%;
      }
      .badges_content{
        overflow-y: auto;
        overflow-x: hidden;
        position: relative;
        width: 100%;
        justify-content: center;
        .badges{
          width: 50%;
          margin-bottom: 10px;
          height: 351px;
          .title{
            text-align: center;
            font-weight: bold;
            font-size: 16px;
          }
          .std-badge{
            margin: auto;
            border: 2px solid #8ca0be;
          }
          &.abs{
            position: relative;
            .select_badge{
              top: 50%;
              position: absolute;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }
        .select_badge{
          position: relative;
          padding: 0 20px;
          margin-top: 10px;
          text-align: center;
          input{
            float: left;
            width: 100%;
            height: 28px;
            border-radius: 2.6px;
            border: .6px solid #d1dbe2;
            background-color: rgba(245,248,249,.3);
            font-size: 14px;
            font-weight: 400;
            color: #1b2437!important;
            padding-left: 10px;
            cursor: pointer;
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
          }
          .loading-spinner{
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 100%;
            .lds-spinner{
              width: 30px;
              height: 30px;
              >div:after{
                top: 16px;
                left: 29px;
                width: 2px;
                height: 10px;
              }
            }
          }
        }
        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          background-color: #f5f5f5;
        }
  
        &::-webkit-scrollbar {
          width: 5px;
          background-color: #f5f5f5;
        }
  
        &::-webkit-scrollbar-thumb {
          background-color: #3eb6ad;
        }
      }
    }
  }
  &.dialogTerms{
    padding:20px;
    h3{
      text-align: center;
    }
  }
  &.dialogSuccess {
    width: 368px;
    height: 220px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    border: solid 1px #979797;
    border-left: 10px solid #00b3a9;
    background-color: #ffffff;
    position: relative;
    padding: 30px;
    .closeModal {
      position: absolute;
      top: -15px;
      right: -17px;
      border: 5px solid #fff;
      width: 40px;
      height: 40px;
      text-align: center;
      color: #fff;
      border-radius: 50%;
      font-size: 17px;
      line-height: 28px;
      background: #00b3a9;
      box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.3);
      img {
        max-width: 15px;
      }
    }
    h5 {
      opacity: 0.9;
      -webkit-text-stroke: 0 #f27046;
      font-family: Montserrat;
      font-size: 16.3px;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.32;
      letter-spacing: normal;
      color: #3db1a9;
      max-width: 250px;
    }
    .code_input{
      .textErrors{
        color: red;
      }
      .form-control{
        border: 0;
        padding-left: 0;
        &.submit{
          text-align: center;
          margin-top: 7px;
        }
        .activate_phone{
          min-width: 106px;
          height: 30px;
          object-fit: contain;
          border-radius: 49.5px;
          text-align: center;
          font-family: Montserrat;
          font-size: 12px;
          font-weight: 600;
          font-style: normal;
          font-stretch: normal;
          line-height: 30px;
          letter-spacing: normal;
          box-shadow: 0 6px 20px 0 rgba(90, 202, 195, 0.5);
          background-image: -webkit-linear-gradient(
            54deg,
            #72dcd6,
            #3eb6ad
          );
          background-image: linear-gradient(54deg, #72dcd6, #3eb6ad);
          color: #fff;
          border: none;
        }
      }
    }
  }
  &.dialogExistStudents{
    max-width: 1020px;
    width: 1020px;
    .friend-content-bloc{
      .exist-students .div_optiscroll{
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
      }
    }
  }
  .MuiInput-underline-72 {
    &:before {
      border-bottom: 1px solid #5cbfce;
    }
    &:after {
      border-bottom: 1px solid #5cbfce;
    }
  }
  .textInput {
    width: 100%;
    label {
      opacity: 0.9;
      font-family: "Montserrat", sans-serif;
      font-size: 11px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #d3dbde;
    }
  }
  h3 {
    opacity: 0.9;
    -webkit-text-stroke: 0 $orange;
    font-family: "Montserrat", sans-serif;
    font-size: 16.5px;
    letter-spacing: 0.3px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.6;
    color: #3db1a9;
    margin: 0;
  }
  .dialog-left,
  .dialog-right {
    width: 50%;
    float: left;
  }
  .RadioLegend {
    > span {
      padding-left: 0;
      padding-right: 5px;
      padding-bottom: 0;
      float: left;
    }
    legend {
      width: auto;
      float: left;
      display: inline-block;
      border: none;
      margin: 18px 25px 0 0;
      font-size: 12px;
    }
  }
  .dialog-left {
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    padding-right: 50px;
    .MuiFormControl-marginNormal-47 {
      margin: 0;
    }

    button {
      background: url("../../assets/img/cta.svg") no-repeat scroll transparent;
      font-family: "Montserrat", sans-serif;
      font-size: 10px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.7px;
      color: rgba(255, 255, 255, 0.9);
      width: 130px;
      padding: 8px 0 57px;
      background-size: cover;
      margin: 20px 0 0;
      border: none;
    }
    .password-forgot {
      margin-top: 20px;
      a {
        opacity: 0.9;
        font-family: "Montserrat", sans-serif;
        font-size: 10.5px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #9ca9ad;
        text-transform: uppercase;
      }
    }
    .dialog-form {
      .textErrors {
        color: #f13434;
        font-size: 10px;
        font-family: "Montserrat", sans-serif;
        width: 100%;
        float: left;
        &.captcha{
          padding-left: 52px;
          margin: 4px 0 0;
        }
      }
      .captcha {
        margin-top: 30px;
        p {
          display: inline-block;
          font-family: Montserrat;
          font-size: 11px;
          font-weight: 600;
          font-style: normal;
          font-stretch: normal;
          line-height: 0.8;
          letter-spacing: normal;
          color: #3e3e3e;
          margin-right: 6px;
        }
        .captchaInput {
          width: 106px;
          height: 30px;
          object-fit: contain;
          opacity: 0.9;
          border-radius: 49.5px;
          border: solid 1px #979797;
          background-color: #ffffff;
          text-align: center;
          font-family: "Montserrat", sans-serif;
          font-size: 12px;
          font-weight: 600;
          font-style: normal;
          font-stretch: normal;
          line-height: 1;
          letter-spacing: normal;
          text-align: center;
          color: #3eb6ad;

        }
      }

      .textInput {
        margin-top: 5px;
        min-height: 43px;
        input {
          line-height: 17px;
          font-size: 15px;
        }

        label {
          color: #949494;
        }
        &.textInputError {
          > div {
            &:before {
              border-bottom: 1px solid #f13434;
            }
            &:after {
              border-bottom: 1px solid #f13434;
            }
            &:hover,
            &:focus {
              &:before {
                border-bottom: 1px solid #f13434;
              }
              &:after {
                border-bottom: 1px solid #f13434;
              }
            }
          }
        }
        > div {
          &:before {
            border-bottom: 1px solid rgba(0, 179, 169, 0.3);
          }
          &:after {
            border-bottom: 1px solid rgba(0, 179, 169, 0.3);
          }
          &:hover,
          &:focus {
            &:before {
              border-bottom: 1px solid rgba(0, 179, 169, 0.3);
            }
            &:after {
              border-bottom: 1px solid rgba(0, 179, 169, 0.3);
            }
          }
        }
      }
    }
  }
  .dialog-right {
    margin-top: -76px;
    margin-bottom: -70px;
    margin-right: -36px;
    span {
      position: relative;
      display: block;
      margin-right: -60px;
      &:before {
        content: "";
        background: url("../../assets/img/rocket.png") no-repeat scroll;
        width: 70px;
        height: 90px;
        position: absolute;
        top: -49px;
        left: 48px;
      }
    }
  }
  &.dialogSignIn {
    min-width: 700px;
    &.dialogChoixCnx{
      max-width: 480px;
      min-width: auto;
      width: 100%;
      height: 250px;
      border-radius: 10px;
      border: solid 1px #979797;
      background-color: rgba(255, 255, 255, .85);
      padding: 40px;
      border-left: 10px solid #3db1a9;
      a{
        width: 250px;
        height: 34px;
        object-fit: contain;
        border-radius: 49.5px;
        border: solid 1px #979797;
        background-color: #ffffff;
        font-family: Montserrat;
        font-size: 13px;
        font-weight: 600;
        color: #3eb6ad;
        display: block;
        text-align: center;
        line-height: 32px;
        margin-bottom: 20px;
        &.active{
          background-color: #3db1a9;
          border: 1px solid #3db1a9;
          color: #ffffff;
        }
      }
    }
    &.dialogOubliePassword {
      min-width: 520px;
      max-width: 520px;
      padding: 45px 30px 25px 40px;
      .closeRegister {
        display: none;
      }
      .textErrors {
        color: #f13434;
        font-size: 10px;
        font-family: "Montserrat", sans-serif;
      }
      h3 {
        opacity: 0.9;
        -webkit-text-stroke: 0 #f27046;
        font-size: 16.3px;
        font-weight: 300;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.32;
        letter-spacing: normal;
        color: #3db1a9;
        margin-bottom: 17px;
      }
      p {
        opacity: 0.9;
        -webkit-text-stroke: 0 #f27046;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.8;
        letter-spacing: normal;
        color: #818181;
      }
      .textInput {
        max-width: 300px;
        margin-top: 0px;
        margin-bottom: 8px;
        > div {
          &:before {
            border-bottom: 1px solid #55b9b1;
          }
          &:after {
            border-bottom: 2px solid #55b9b1;
          }
          &:hover {
            &:before {
              border-bottom: 2px solid #55b9b1;
            }
          }
          input {
            padding: 11px 0 11px;
          }
        }
      }
      button {
        font-size: 11px;
        width: 170px;
        padding: 12px 0 80px;
        background-size: 170px 110px;
      }
    }
  }
  &.dialogRegister {
    padding: 25px 0 30px 50px;
    min-width: 700px;
    &.dialogAbs{
      min-width: 1200px;
      padding: 16px 18px;
      border-radius: 20px;
      max-height: 533px;
      max-width: 100%;
      width: 100%;
      .top{
        border-radius: 17px;
        float: left;
        width: 100%;
        margin-bottom: 40px;
        &.top-assurance{
          display: flex;
          justify-content: space-between;
          border: 1px solid #13bbb3;
          padding: 22px;
          border-radius: 17px;
          
        }
        img{
          width:28px;
          height:28px;
          border-radius: 28px;
          float: left;
          margin-right: 10px;
        }
        h4{
          font-family: Montserrat;
          font-size: 14px;
          font-weight: 500;
          color: rgba(27,36,55,.8);
          line-height: 28px;
          margin:0;
        }
      }
      .table{
        float:left;
        width:100%;
        float: left;
        width: 100%;
        overflow: auto;
        height: 415px;
        margin: 0;
        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          background-color: #f5f5f5;
        }
  
        &::-webkit-scrollbar {
          width: 5px;
          background-color: #f5f5f5;
        }
  
        &::-webkit-scrollbar-thumb {
          background-color: #3eb6ad;
        }
        table{
          width:100%;
          thead{
            tr{
              th{
                font-family: Montserrat;
                font-size: 14px;
                font-weight: 600;
                color: rgba(27, 36, 55, 0.8);
                border:none;
                padding: 0 15px 10px;
                &:nth-child(1) {
                  width: 110px;
                }
                &:nth-child(3){
                  width: 140px;
                }
                &:nth-child(4){
                  width: 152px;;
                }
                &:nth-child(5){
                  width: 205px;
                }
                
              }
            }
          }
          tbody{
            tr{
              width:100%;
              height: 60px;
              object-fit: contain;
              border-radius: 3px;
              box-shadow: 0 5px 45px 0 rgba(46, 104, 208, 0.1);
              background-color: #ffffff;
              margin-bottom: 10px;
              .button-custom{
                width: 100%;
                height: 60px;
                /* margin-left: 101px; */
                padding-left: 37%;
                object-fit: contain;
                border-radius: 3px;
                // box-shadow: 0 5px 45px 0 rgba(46, 104, 208, 0.1);
                // background-color: #ffffff;
                margin-bottom: 10px;
              }
              td{
                font-family: Montserrat;
                font-size: 14px;
                font-weight: 500;
                color: rgba(27, 36, 55, 0.8);
                vertical-align: middle;
                border:none;
                padding: 0 15px;
                margin-bottom: 10px;
                img{
                  width:24px;
                  height:24px;
                  border-radius: 24px;
                  margin-right: 10px;
                }
                span{
                  width: 90px;
                  min-height: 24px;
                  border-radius: 4px;    
                  display: inline-block;
                  text-align:center;
                  &.absent{
                    border: solid 1px #d33f29;
                  }
                  &.late{
                    border: solid 1px #edbd4f;
                  }
                  span{
                    font-size: 10px;
                    display: block;
                    min-height: unset;
                  }
                }
              }
              .school_comment{
                min-width: 200px;
                textarea{
                  border: 1px solid #e6e5e5;
                  width: 100%;
                  min-height: 100%;
                  border-radius: 5px;
                  overflow: hidden;
                }
              }
              .absence_monitoring{
                position: relative;
                z-index: 11;
                select{
                  border: none;
                  outline: none;
                  -webkit-appearance: none;
                  -moz-appearance: none;
                  appearance: none;
                  text-indent: 1px;
                  text-overflow: "";
                  background: transparent;
                  outline: 0;
                  position: relative;
                  z-index: 9;
                  border-radius: 4px !important;
                  border: none !important;
                  font-weight: 500;
                  font-family: Montserrat;
                  color: #1b2437;
                  padding: 0 5px;
                  padding-right: 15px;
                  cursor: pointer;
                  &.empty{
                    opacity: 0.5;
                  }
                }
                i{
                  color: #d2dbe2;
                  font-size: 14px;
                  position: absolute;
                  right: 15px;
                  top: 50%;
                  transform: translateY(-50%);
                  z-index: -1;
                }
              }
              .absence_reason{
                position: relative;
                z-index: 11;
                select{
                  border: none;
                  outline: none;
                  -webkit-appearance: none;
                  -moz-appearance: none;
                  appearance: none;
                  text-indent: 1px;
                  text-overflow: "";
                  background: transparent;
                  outline: 0;
                  position: relative;
                  z-index: 9;
                  border-radius: 4px !important;
                  border: none !important;
                  font-weight: 500;
                  font-family: Montserrat;
                  color: #1b2437;
                  padding: 0 5px;
                  padding-right: 15px;
                  cursor: pointer;
                  &.empty{
                    opacity: 0.5;
                  }
                }
                i{
                  color: #d2dbe2;
                  font-size: 14px;
                  position: absolute;
                  right: 15px;
                  top: 50%;
                  transform: translateY(-50%);
                  z-index: -1;
                }
              }
              .justified, .regul{
                .pretty{
                  margin: auto !important;
                }
              }
              &.space{
                height:10px;
                box-shadow: none;

              }
            }
          }
          &.absences_table{
            td, th{
              padding-left: 2px;
              padding-right: 2px;
            }
          }
        }
      }
    }
    &.std{
      .table{
        border: 1px solid #13bbb3;
        padding: 22px;
        border-radius: 17px;
        > h4{
          margin-top: -10px;
          font-size: 17px;
        }
      }
    }
    span.btn.btn-circle.add_item {
      /* margin-left: 162%; */
      display: inline-table;
      vertical-align: inherit;
      width: 40px;
      height: 40px;
      line-height: 40px;
      font-size: 43px;
      /* margin: 0px; */
      /* display: inline-block; */
      width: 50px;
      height: 50px;
      box-shadow: rgba(239, 194, 91, 0.5) 0px 18px 36px 0px;
      background-image: linear-gradient(225deg, rgb(253, 210, 113), rgb(213, 164, 51));
      font-size: 50px;
      text-align: center;
      line-height: 50px;
      color: rgb(255, 255, 255);
      /* margin-bottom: 15px; */
      /* border-radius: 150px; */
  }
    .dialog-left {
      .dialog-form {
        .textInput {
          margin-bottom: 2px;
          margin-top: 0;
          label {
            + div {
              margin-top: 10px;
              min-height: 33px;
            }
          }
          input {
            line-height: 20px;
            font-size: 12px;
          }
        }
      }
      .radioLegend {
        > span {
          padding-left: 0;
          padding-right: 5px;
          padding-bottom: 0;
          float: left;
        }
        legend {
          width: auto;
          float: left;
          display: inline-block;
          border: none;
          margin: 18px 39px 0 0;
        }
      }
    }
    .dialog-right {
      margin-top: -12px;
    }
  }
  &.dialogCandid {
    padding: 25px 0 30px 50px;
    min-width: 700px;
    &.dialogAbs{
      min-width: 1200px;
      padding: 16px 18px;
      border-radius: 20px;
      max-height: 533px;
      .top{
        border-radius: 17px;
        float: left;
        width: 100%;
        margin-bottom: 40px;
        &.top-assurance{
          display: flex;
          justify-content: space-between;
          border: 1px solid #13bbb3;
          padding: 22px;
          border-radius: 17px;
          
        }
        img{
          width:28px;
          height:28px;
          border-radius: 28px;
          float: left;
          margin-right: 10px;
        }
        h4{
          font-family: Montserrat;
          font-size: 14px;
          font-weight: 500;
          color: rgba(27,36,55,.8);
          line-height: 28px;
          margin:0;
        }
      }
      .table{
        float:left;
        width:100%;
        float: left;
        width: 100%;
        overflow: auto;
        height: 415px;
        margin: 0;
        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          background-color: #f5f5f5;
        }
  
        &::-webkit-scrollbar {
          width: 5px;
          background-color: #f5f5f5;
        }
  
        &::-webkit-scrollbar-thumb {
          background-color: #3eb6ad;
        }
        table{
          width:100%;
          thead{
            tr{
              th{
                font-family: Montserrat;
                font-size: 14px;
                font-weight: 600;
                color: rgba(27, 36, 55, 0.8);
                border:none;
                padding: 0 15px 10px;
                &:nth-child(1) {
                  width: 110px;
                }
                &:nth-child(3){
                  width: 140px;
                }
                &:nth-child(4){
                  width: 152px;;
                }
                &:nth-child(5){
                  width: 205px;
                }
                
              }
            }
          }
          tbody{
            tr{
              width:100%;
              height: 60px;
              object-fit: contain;
              border-radius: 3px;
              box-shadow: 0 5px 45px 0 rgba(46, 104, 208, 0.1);
              background-color: #ffffff;
              margin-bottom: 10px;
              .button-custom{
                width: 100%;
                height: 60px;
                /* margin-left: 101px; */
                padding-left: 37%;
                object-fit: contain;
                border-radius: 3px;
                // box-shadow: 0 5px 45px 0 rgba(46, 104, 208, 0.1);
                // background-color: #ffffff;
                margin-bottom: 10px;
              }
              td{
                font-family: Montserrat;
                font-size: 14px;
                font-weight: 500;
                color: rgba(27, 36, 55, 0.8);
                vertical-align: middle;
                border:none;
                padding: 0 15px;
                margin-bottom: 10px;
                img{
                  width:24px;
                  height:24px;
                  border-radius: 24px;
                  margin-right: 10px;
                }
                span{
                  width: 90px;
                  height: 24px;
                  border-radius: 4px;    
                  display: inline-block;
                  text-align:center;
                  &.absent{
                    border: solid 1px #d33f29;
                  }
                  &.late{
                    border: solid 1px #edbd4f;
                  }
                }
              }
              &.space{
                height:10px;
                box-shadow: none;

              }
            }
          }
        }
      }
    }
    span.btn.btn-circle.add_item {
      /* margin-left: 162%; */
      display: inline-table;
      vertical-align: inherit;
      width: 40px;
      height: 40px;
      line-height: 40px;
      font-size: 43px;
      /* margin: 0px; */
      /* display: inline-block; */
      width: 50px;
      height: 50px;
      box-shadow: rgba(239, 194, 91, 0.5) 0px 18px 36px 0px;
      background-image: linear-gradient(225deg, rgb(253, 210, 113), rgb(213, 164, 51));
      font-size: 50px;
      text-align: center;
      line-height: 50px;
      color: rgb(255, 255, 255);
      /* margin-bottom: 15px; */
      /* border-radius: 150px; */
  }
    .dialog-left {
      .dialog-form {
        .textInput {
          margin-bottom: 2px;
          margin-top: 0;
          label {
            + div {
              margin-top: 10px;
              min-height: 33px;
            }
          }
          input {
            line-height: 20px;
            font-size: 12px;
          }
        }
      }
      .radioLegend {
        > span {
          padding-left: 0;
          padding-right: 5px;
          padding-bottom: 0;
          float: left;
        }
        legend {
          width: auto;
          float: left;
          display: inline-block;
          border: none;
          margin: 18px 39px 0 0;
        }
      }
    }
    .dialog-right {
      margin-top: -12px;
    }
  }
}

.dialogOverlay {
  background: rgba(100, 115, 140, 0.9);
  // z-index: 99999;
  .dialogPredefinedAppreciationBlock{
    width: 900px;
  }
  @media(max-width: 767px) {
    &.dialogCandidate{
      padding: 0px;
      >div{
        border-radius: 0px;
        min-width:auto!important;
      }
    }
  }
  &.hight_z_index{
    z-index: 11111199999;
  }
  &.dialogStudentsDocumentsOverlay{
    z-index: 1040 !important;
    height: 100vh;
    .back_to_folders{
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      width: 40px;
      height: 40px;
      border-radius: 100px;
      box-shadow: 0 10px 20px 0 rgba(90, 202, 195, 0.5);
      background-image: -webkit-linear-gradient(225deg, #72dcd6, #3eb6ad);
      background-image: linear-gradient(225deg, #72dcd6, #3eb6ad);
      text-align: center;
      color: #fff;
      font-size: 40px;
      line-height: 40px;
    }
    .folder_name{
      position: absolute;
      left: 50px;
      top: 25px;
      font-size: 15px;
      font-weight: bold;
      @media(max-width: 767px) {
        top: 60px;
        left: 10px;
      }
    }
    .close_modal{
      position: absolute;
      cursor: pointer;
      top: 0;
      right: 0;
      z-index: 111;
      span{
        font-size: 30px;
        line-height: 30px;
        font-weight: 500;
      }
      @media(max-width: 1199px) {
        background: #d8dfe5;
        box-shadow: none;
        width: 35px;
        height: 35px;
        line-height: 35px;
        top: 5px;
        right: 10px;
        span{
          font-size: 23px;
        }
      }
    }
    @media(max-width: 1199px) {
      height: calc(var(--vh, 1vh) * 100);
    }
  }

  .account_activated{
    color: green;
    font-size: 12px;
    padding-bottom: 20px;
  }
  &.dialogCompetencies{
    .dialog {
      &.dialogAddSchool {
        width: 425px;
      }
    }
  }
  &.successDialog{
    z-index: 99999;
  }
}
.Group {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  z-index: 9990;
  cursor: pointer;
  img {
    -webkit-filter: drop-shadow(-5px 12px 20px rgba(0, 0, 0, 0.65));
    -moz-filter: drop-shadow(-5px 12px 20px rgba(0, 0, 0, 0.65));
    -ms-filter: drop-shadow(-5px 12px 20px rgba(0, 0, 0, 0.65));
    -o-filter: drop-shadow(-5px 12px 20px rgba(0, 0, 0, 0.65));
    filter: drop-shadow(-5px 12px 20px rgba(0, 0, 0, 0.65));
  }
}
//Dashboard Children
.dashboardChild {
  padding-top: 25px;

  section {
    padding-top: 25px;

    .blocs-textbook {
      background: url("../../assets/img/Circles_TextBook_BG.svg") no-repeat
        scroll transparent;
      border-radius: 10px;
      background-color: #f9fafb;
      box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.4);
      position: relative;
      min-height: 500px;
      padding: 25px 0 60px 100px;
      h3 {
        background: url("../../assets/img/TextBook_Heading_BG.svg") no-repeat
          scroll transparent;
        writing-mode: vertical-rl;
        text-orientation: sideways;
        position: absolute;
        left: -24px;
        top: 100px;
        width: 152px;
        height: 188px;
        font-size: 14px;
        font-weight: bold;
        color: #ffffff;
        padding-top: 22px;
        line-height: 246px;
        margin: 0;
        -webkit-filter: drop-shadow(0 -1px 4px rgba(0, 0, 0, 0.05));
        -moz-filter: drop-shadow(0 -1px 4px rgba(0, 0, 0, 0.05));
        -ms-filter: drop-shadow(0 -1px 4px rgba(0, 0, 0, 0.05));
        -o-filter: drop-shadow(0 -1px 4px rgba(0, 0, 0, 0.05));
        filter: drop-shadow(0 -1px 4px rgba(0, 0, 0, 0.05));
        &.zoom{
          background: url("../../assets/img/COURSES_LIVE_Heading_BG.svg") no-repeat scroll transparent;
        }
        &.courses{
          background: url("../../assets/img/COURSES_Heading_BG.svg") no-repeat scroll transparent;
        }
      }
      .container-textbook {
        .textbook-period {
          float: right;
          padding-right: 100px;
          p {
            color: #3eb6ac;
            font-weight: 500;
            font-size: 12px;
            padding-right: 8px;
            margin-bottom: 6px;
          }
          ul {
            &.list-period {
              > li {
                position: relative;
                padding-bottom: 15px;
                > a {
                  color: #3eb6ac;
                  border: 2px solid #3eb6ac;
                  padding: 4px 17px;
                  border-radius: 20px;
                  font-weight: 600;
                  font-size: 12px;
                  i {
                    font-size: 11px;
                  }
                }
                > ul {
                  z-index: 9;
                  opacity: 0.9;
                  border-radius: 9px;
                  border: solid 2px #3eb6ad;
                  background-color: #fdfdfd;
                  text-align: center;
                  padding: 3px 0;
                  overflow: hidden;
                  cursor: pointer;
                  position: absolute;
                  left: 50%;
                  top: 30px;
                  margin-left: -64px;
                  display: none;
                  li {
                    width: 123px;
                    opacity: 0.9;
                    font-family: "Montserrat", sans-serif;
                    padding: 5px 10px;
                    font-size: 11px;
                    font-weight: 500;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: normal;
                    letter-spacing: 0.8px;
                    text-align: center;
                    color: #3eb6ad;
                    display: block;
                    transition: 0.3s;
                    &:hover {
                      background: #ffffff;
                      box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.4);
                    }
                  }
                }
                &:hover {
                  > ul {
                    display: block;
                  }
                }
              }
            }
          }
        }
      }
      .textbooks {
        padding-right: 90px;
        padding-left: 100px;
        .bloc-textbook {
          border-radius: 7.2px;
          border: 10px solid $white;
          justify-content: center;
          align-items: center;
          align-self: center;
          position: relative;
          margin-bottom: 30px;
          float: left;
          width: 100%;
          box-shadow: 0 -1px 8px 0 rgba(0, 0, 0, 0.17);
          span {
            position: absolute;
            right: 0;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: 2.8em;
            letter-spacing: normal;
            text-align: center;
            color: #ffffff;
            border: 6px solid #ffffff;
            border-radius: 100%;
            top: -18%;
            width: 40px;
            height: 40px;
            padding-top: 0px;
            z-index: 999;
            font-size: 11px;
            left: 90%;
            -webkit-box-shadow: 0 -1px 8px 0 rgba(0, 0, 0, 0.17);
            -moz-box-shadow: 0 -1px 8px 0 rgba(0, 0, 0, 0.17);
            box-shadow: 0 -1px 8px 0 rgba(0, 0, 0, 0.17);
          }
          .content-textbook {
            border-radius: 7px;
            background-color: rgba(237, 189, 79, 0.9);
            float: left;
            width: 100%;
            height: 120px;
          }
        }
      }
    }
  }
}

.dialogMobile {
  padding:0px;
  .dialog {
    .closeRegister {
      display: none;
    }
  }
}

//Modal TextBook
.OverlayTextbook {
  background-color: rgba(100, 115, 140, 0.9);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99999;
  .courses_list{
    float:left;
    margin:20px 0px;
    width:100%;
    overflow: auto;
    @media  (orientation: landscape) and (max-width: 1224px) {
      overflow: visible ;
    }
    height: calc(100% - 50px);
    // min-height: 370px;
    @media screen and (max-width: 1200px) {
      // height: calc(100% - 205px);
      // min-height: 370px;
    }

    .course_item, .formation_item, .book_item{
      margin:10px 0px;
      max-height:170px;
      cursor:pointer;
      >div{
        img{
          width: 80px;
          height: 80px;
          border-radius: 50%;
        }
        margin:auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #fff;
        border-radius: 10px;
        box-shadow: 0 18px 36px 0 rgba(0, 0, 0, 0.2);
        // padding: 20px;
        padding: 10px;
        text-align: center;
        height: 100%;
        justify-content: space-between;
        p{
          &.notifred{
            position: absolute;
            right: 9px;
            top: -7px;
            background: #d33f29;
            width: 20px;
            height: 20px;
            border-radius: 12px;
            font-size: 10px;
            font-weight: 500;
            line-height: 20px;
            color: black;
          }
        }
      }
      .not_viewed_course{
        position: absolute;
        top: 0;
        right: 15px;
        background: red;
        border-radius: 50%;
        width: 16px;
        height: 16px;
      }
    }
  }
  > div {
    top: 70px !important;
    left: 30% !important;
    margin-left: -15%;
    max-width: 70%;
    bottom: auto !important;
    padding: 0 0 30px !important;
    border: none !important;
    border-radius: 10px !important;
    overflow: inherit !important;
    box-shadow: 0 18px 36px 0 rgba(0, 0, 0, 0.2);
    opacity: 0.9;
    height:80%;
    // height: 70%;
    >div{
      height:100%;
      display:flex;
      flex-direction: column;
      &.modal_textbook{
        z-index: 1;
        position: relative;
      }
    }

    .closeModal {
      position: absolute;
      top: -23px;
      right: -23px;
      border: 5px solid #fff;
      width: 40px;
      height: 40px;
      text-align: center;
      color: #fff;
      border-radius: 50%;
      font-size: 17px;
      line-height: 28px;
      img {
        max-width: 13px;
      }
    }
    .header {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      padding: 0;
      width: 100%;
      .slick-list {
        height: 50px;
      }
      float: left;
      .slick-dots {
        display: none !important;
      }
      // .slick-slide {
      //   /*width: 27% !important;*/
      //   margin: 0 10px;
      // }
      .slick-arrow {
        position: absolute;
        top: 50%;
        font-size: 0;
        background: transparent;
        border: none;
        margin-top: -4px;
        // margin-top: -13px;
        &:before {
          font: normal normal normal 23px/1 FontAwesome;
          line-height: normal;
          color: #fff;
        }

        &.slick-prev {
          left: -6px;
          &:before {
            content: "\f104";
          }
        }

        &.slick-next {
          right: -6px;
          &:before {
            content: "\f105";
          }
        }
      }
      a {
        opacity: 0.54;
        font-family: "Montserrat", sans-serif;
        font-size: 12px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: 1.5px;
        text-align: center;
        color: #ffffff;
        text-transform: uppercase;
        line-height: 50px;
        display: block;
        &.active {
          opacity: 1;
          font-weight: 700;
          position: relative;
          outline: none;
          &:before {
            content: "";
            position: absolute;
            left: 50%;
            margin-left: -10px;
            bottom: 0;
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 8px solid #fff;
          }
        }
      }
      ul {
        display: flex;
        justify-content: space-evenly;
        margin: 0;
        padding: 0;
        li {
          display: inline-block;
          a {
            opacity: 0.54;
            font-family: "Montserrat", sans-serif;
            font-size: 12px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            letter-spacing: 1.5px;
            text-align: center;
            color: #ffffff;
            text-transform: uppercase;
            line-height: 50px;
            display: block;
            &.active {
              opacity: 1;
              font-weight: 700;
              position: relative;
              &:before {
                content: "";
                position: absolute;
                left: 50%;
                margin-left: -10px;
                bottom: 0;
                width: 0;
                height: 0;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-bottom: 8px solid #fff;
              }
            }
          }
        }
        &.activities{
          justify-content: space-evenly;
        }
      }
      .web_slider_subjects{
        .slick-list{
          padding: 0 20px;
        }
        .slick-prev{
          left: 0px !important;
          z-index: 10;
        }
        .slick-next {
          right: 0px !important;
          z-index: 10;
        }
      }
    }
    .todoCompleted {
      float: left;
      width: 100%;
      padding: 30px 40px;
      text-align: center;
      span.not_done_tasks_count {
        position: absolute;
        right: 10px;
        top: 15px;
        background: red;
        border-radius: 50%;
        width: 16px;
        height: 16px;
        line-height: 16px;
        color: white;
        font-weight: bold;
        font-size: 10px;
        text-align: center;
        display: block;
    }
      div {
        display: inline-block;
        border-radius: 27px;
        border: solid 1px transparent;
        position: relative;
        overflow: hidden;
        height: 50px;

        a {
          font-family: "Montserrat", sans-serif;
          font-size: 11.4px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 1.2px;
          padding: 17px 35px;
          display: inline-block;
          position: relative;
          border-radius: 27px;
          overflow: hidden;

          &.active {
            color: #fff !important;
            &:before {
              content: "";
              position: absolute;
              left: 0;
              right: -2px;
              top: 0;
              bottom: 0;
              border-radius: 31px;
              border: 2px solid #fff;
            }
          }
        }
      }
    }
    .exercices-table {
      /*float: left;*/
      width: calc(100% - 20px);
      padding: 0 20px;
      margin: 0 10px;
      height: 100%;
      overflow: auto;
      @media screen and (min-width: 480px) {
        overflow: auto;
      }
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #f5f5f5;
      }

      &::-webkit-scrollbar {
        width: 5px;
        background-color: #f5f5f5;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #3eb6ad;
      }
      table {
        width: 100%;
        tr {
          td,
          th {
            &:nth-child(1) {
              width: 25%;
            }
            &:nth-child(3) {
              width: 10%;
            }
          }
          th {
            padding-bottom: 20px;
            text-align: left;
          }
        }
        thead {
          margin-bottom: 20px;
          tr {
            th {
              font-family: "Montserrat", sans-serif;
              font-size: 11.5px;
              font-weight: bold;
              font-style: normal;
              font-stretch: normal;
              line-height: normal;
              letter-spacing: 0.6px;
              text-align: left;
              color: #797979;
              &:nth-child(3) {
                text-align: right;
              }
              &.correction{
                text-align: center;
                padding-left: 15px;
                padding-right: 10px;
              }
            }
          }
        }

        tbody {
          tr {
            border-bottom: 0.5px solid rgba(62, 182, 173, 0.45);
            td {
              padding: 10px 0;

              &:nth-child(1) {
                font-family: "Montserrat", sans-serif;
                font-size: 10.5px;
                font-weight: 500;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: 0.5px;
                color: #3eb6ad;
                padding-left: 10px;
              }
              &:nth-child(2) {
                font-family: "Montserrat", sans-serif;
                font-size: 13.5px;
                font-weight: 500;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: 0.6px;
                color: #3eb6ad;
                // added for img arrow
                display: flex;
                justify-content: space-between;
                align-items: center;

              }
              &:nth-child(3) {
                font-family: "Montserrat", sans-serif;
                font-size: 10.5px;
                font-weight: 600;
                font-style: normal;
                font-stretch: normal;
                line-height: 2.3;
                letter-spacing: 0.6px;
                text-align: right;
                color: #ffffff;
                text-transform: uppercase;
                .expired,
                .visit {
                  color: #fff;
                  background: #bf3938;
                  border-radius: 13.5px;
                  padding: 3px 8px 1px;
                  line-height: 18px;
                  min-width: 69px;
                  display: inline-block;
                  text-align: center;
                }
                .done {
                  font-family: "Montserrat", sans-serif;
                  font-size: 11px;
                  font-weight: 600;
                  font-style: normal;
                  font-stretch: normal;
                  line-height: 2.3;
                  letter-spacing: 0.6px;
                  text-align: center;
                  i {
                    font-size: 17px;
                    float: right;
                    margin-top: 3px;
                    margin-left: 5px;
                    margin-right: 7px;
                  }
                }
                .expired {
                  background: #bf3938;
                }
              }
              .visit_corrections{
                color: #fff;
                background: #3eb6ad !important;
                border-radius: 13.5px;
                padding: 3px 12px 1px;
                line-height: 18px;
                min-width: 69px;
                display: inline-block;
                text-align: center;
                padding-top: 0; 
                margin-right: 20px;
                padding-bottom: 0;
                line-height: 22px;
                position: relative;
                .not_viewed_corrections{
                  position: absolute;
                  top: 6px;
                  right: 1px;
                  background: red;
                  border-radius: 50%;
                  width: 10px;
                  height: 10px;
                }
              }
              &.correction{
                text-align: center;
                padding-left: 15px;
                padding-right: 10px;
                .visit {
                  color: #fff;
                  background: #3eb6ad !important;
                  border-radius: 13.5px;
                  padding: 3px 8px 1px;
                  line-height: 18px;
                  min-width: 69px;
                  display: inline-block;
                  text-align: center;
                }
              }
            }
            &:last-child {
              border-bottom: none;
            }
          }
        }
      }
    }

    .maths {
      .closeModal {
        background-color: #56729f;
      }
      .header {
        background-color: #56729f;
      }
      .textbook-period ul.list-period > li{
        >a{
          color: #56729f;
          border: 2px solid #56729f;
        }
        >ul{
          border: 2px solid #56729f;
          li{
            color: #56729f;
          }
        }
      }
      .textbook-period .searched_name{
        border: 2px solid #56729f;
      }
      .textbook-period.bibliotheque{
        >ul{
          li.active{
            a{
              background: #56729f;
              color: white;
            }
          }
        }
      }
      
      .todoCompleted {
        div {
          border-color: #56729f;
          a {
            color: #56729f;
            &.active {
              background-color: #56729f;
            }
          }
        }
      }
      .exercices-table {
        table {
          border-collapse: unset;
          tbody {
            tr {
              &.border_txt {
                border: none;
              }
              td{
                &.datefor{
                  background: #56729f;
                  min-height: 35px;
    
                }
                &.next_txtb{
                  // background: #3eb5ad;
                  min-height: 36px;
                }
                &.next_txtb_extra {
                  border: 1px solid #56729f ;
                  background: #1c4381;
                  border-radius: 27px;
                  padding: 10px !important;
                  color: white !important;
                  font-size: 12px;
                  font-weight: bold;
                  align-items: center;
                  text-align: center;
              
              }
              }
              td:nth-child(3) {
                .visit {
                  background-color: #56729f;
                }
                .done {
                  color: #56729f;
                }
              }
            }
          }
        }
      }
    }

    .french {
      .closeModal {
        background-color: #e7c26a;
      }
      .header {
        background-color: #e7c26a;
      }
      .textbook-period ul.list-period > li{
        >a{
          color: #e7c26a;
          border: 2px solid #e7c26a;
        }
        >ul{
          border: 2px solid #e7c26a;
          li{
            color: #e7c26a;
          }
        }
      }
      .textbook-period .searched_name{
        border: 2px solid #e7c26a;
      }
      .textbook-period.bibliotheque{
        >ul{
          li.active{
            a{
              background: #e7c26a;
              color: white;
            }
          }
        }
      }
      .todoCompleted {
        div {
          border-color: #e7c26a;
          a {
            color: #e7c26a;
            &.active {
              background-color: #e7c26a;
            }
          }
        }
      }
      .exercices-table {
        table {
          border-collapse: unset;
          tbody {
            tr {
              &.border_txt {
                border: none;
              }
              td{
                &.datefor{
                  background: #e7c26a;
                  min-height: 35px;
    
                }
                &.next_txtb{
                  // background: #3eb5ad;
                  min-height: 36px;
                }
                &.next_txtb_extra {
                  border: 1px solid #e7c26a ;
                  background: #dd9e0c;
                  border-radius: 27px;
                  padding: 10px !important;
                  color: white !important;
                  font-size: 12px;
                  font-weight: bold;
                  align-items: center;
                  text-align: center;
              
              }
              }
              td:nth-child(3) {
                .visit {
                  background-color: #e7c26a;
                }
                .done {
                  color: #e7c26a;
                }
              }
            }
          }
        }
      }
    }
    .autre {
      .closeModal {
        background-color: #80c9ca;
      }
      .header {
        background-color: #80c9ca;
      }
      .textbook-period ul.list-period > li{
        >a{
          color: #80c9ca;
          border: 2px solid #80c9ca;
        }
        >ul{
          border: 2px solid #80c9ca;
          li{
            color: #80c9ca;
          }
        }
      }
      .textbook-period .searched_name{
        border: 2px solid #80c9ca;
      }
      .textbook-period.bibliotheque{
        >ul{
          li.active{
            a{
              background: #80c9ca;
              color: white;
            }
          }
        }
      }
      .todoCompleted {
        div {
          border-color: #80c9ca;
          a {
            color: #80c9ca;
            &.active {
              background-color: #80c9ca;
            }
          }
        }
      }
      .exercices-table {
        table {
          border-collapse: unset;
          tbody {
            tr {
              td{
                &.border_txt {
                  border: none;
                }
                &.datefor{
                  background: #80c9ca;
                  min-height: 35px;
    
                }
                &.next_txtb{
                  // background: #3eb5ad;
                  min-height: 36px;
                }
                &.next_txtb_extra {
                  border: 1px solid #80c9ca ;
                  background: #19abad;
                  border-radius: 27px;
                  padding: 10px !important;
                  color: white !important;
                  font-size: 12px;
                  font-weight: bold;
                  align-items: center;
                  text-align: center;
              
              }
              }
              td:nth-child(3) {
                .visit {
                  background-color: #80c9ca;
                }
                .done {
                  color: #80c9ca;
                }
              }
            }
          }
        }
      }
    }
    .arabic {
      .closeModal {
        background-color: #7cb9de;
      }
      .header {
        background-color: #7cb9de;
      }
      .textbook-period ul.list-period > li{
        >a{
          color: #7cb9de;
          border: 2px solid #7cb9de;
        }
        >ul{
          border: 2px solid #7cb9de;
          li{
            color: #7cb9de;
          }
        }
      }
      .textbook-period .searched_name{
        border: 2px solid #7cb9de;
      }
      .textbook-period.bibliotheque{
        >ul{
          li.active{
            a{
              background: #7cb9de;
              color: white;
            }
          }
        }
      }
      .todoCompleted {
        div {
          border-color: #7cb9de;
          a {
            color: #7cb9de;
            &.active {
              background-color: #7cb9de;
            }
          }
        }
      }
      .exercices-table {
        table {
          border-collapse: unset;
          tbody {
            tr {
              &.border_txt {
                border:none;
              }
              td{
                &.datefor{
                  background: #7cb9de;
                  min-height: 35px;
                }
                &.next_txtb{
                  // background: #3eb5ad;
                  min-height: 36px;
                }
                &.next_txtb_extra {
                  border: 1px solid #7cb9de ;
                  background: #1f7db8;
                  border-radius: 27px;
                  padding: 10px !important;
                  color: white !important;
                  font-size: 12px;
                  font-weight: bold;
                  align-items: center;
                  text-align: center;
              
              }
              }
              td:nth-child(3) {
                .visit {
                  background-color: #7cb9de;
                }
                .done {
                  color: #7cb9de;
                }
              }
            }
          }
        }
      }
    }

    .anglais {
      .closeModal {
        background-color: #e7c26a;
      }
      .header {
        background-color: #e7c26a;
      }
      .textbook-period ul.list-period > li{
        >a{
          color: #e7c26a;
          border: 2px solid #e7c26a;
        }
        >ul{
          border: 2px solid #e7c26a;
          li{
            color: #e7c26a;
          }
        }
      }
      .textbook-period .searched_name{
        border: 2px solid #e7c26a;
      }
      .textbook-period.bibliotheque{
        >ul{
          li.active{
            a{
              background: #e7c26a;
              color: white;
            }
          }
        }
      }
      .todoCompleted {
        div {
          border-color: #e7c26a;
          a {
            color: #e7c26a;
            &.active {
              background-color: #e7c26a;
            }
          }
        }
      }
      .exercices-table {
        table {
          border-collapse: unset;
          tbody {
            tr {
              &.border_txt {
                border:none;
              }
              td{
                &.datefor{
                  background: #e7c26a;
                  min-height: 35px;                        
                }
                &.next_txtb{
                  // background: #3eb5ad;
                  min-height: 36px;
                }
                &.next_txtb_extra {
                  border: 1px solid #e7c26a ;
                  background: #c5931f ;
                  border-radius: 27px;
                  padding: 10px !important;
                  color: white !important;
                  font-size: 12px;
                  font-weight: bold;
                  align-items: center;
                  text-align: center;
              

              }
              }
              td:nth-child(3) {
                .visit {
                  background-color: #e7c26a;
                }
                .done {
                  color: #e7c26a;
                }
              }
            }
          }
        }
      }
    }

    .science {
      .closeModal {
        background-color: #81b7ad;
      }
      .header {
        background-color: #81b7ad;
      }
      .textbook-period ul.list-period > li{
        >a{
          color: #81b7ad;
          border: 2px solid #81b7ad;
        }
        >ul{
          border: 2px solid #81b7ad;
          li{
            color: #81b7ad;
          }
        }
      }
      .textbook-period .searched_name{
        border: 2px solid #81b7ad;
      }
      .textbook-period.bibliotheque{
        >ul{
          li.active{
            a{
              background: #81b7ad;
              color: white;
            }
          }
        }
      }
      .todoCompleted {
        div {
          border-color: #81b7ad;
          a {
            color: #81b7ad;
            &.active {
              background-color: #81b7ad;
            }
          }
        }
      }
      .exercices-table {
        table {
          border-collapse: unset;
          tbody {
            tr {
              &.border_txt {
                border: none;
              }
              td{
                &.datefor{
                  background: #81b7ad;
                  min-height: 35px;                    
                }
                &.next_txtb{
                  // background: #3eb5ad;
                  min-height: 36px;
                }
                &.next_txtb_extra {
                  border: 1px solid #81b7ad ;
                  background: #3c9787 ;
                  border-radius: 27px;
                  padding: 10px !important;
                  color: white !important;
                  font-size: 12px;
                  font-weight: bold;
                  align-items: center;
                  text-align: center;
              
              }
              }
              td:nth-child(3) {
                .visit {
                  background-color: #81b7ad;
                }
                .done {
                  color: #81b7ad;
                }
              }
            }
          }
        }
      }
    }

    .géographie {
      .closeModal {
        background-color: #81b7ad;
      }
      .header {
        background-color: #81b7ad;
      }
      .textbook-period ul.list-period > li{
        >a{
          color: #81b7ad;
          border: 2px solid #81b7ad;
        }
        >ul{
          border: 2px solid #81b7ad;
          li{
            color: #81b7ad;
          }
        }
      }
      .textbook-period .searched_name{
        border: 2px solid #81b7ad;
      }
      .textbook-period.bibliotheque{
        >ul{
          li.active{
            a{
              background: #81b7ad;
              color: white;
            }
          }
        }
      }
      .todoCompleted {
        div {
          border-color: #81b7ad;
          a {
            color: #81b7ad;
            &.active {
              background-color: #81b7ad;
            }
          }
        }
      }
      .exercices-table {
        table {
          border-collapse: unset;
          tbody {
            tr {
              &.border_txt {
                border: none;
              }
              td{
                &.datefor{
                  background: #81b7ad;
                  min-height: 35px;                
    
                }
                &.next_txtb{
                  // background: #3eb5ad;
                  min-height: 36px;
                }
                &.next_txtb_extra {
                  border: 1px solid #81b7ad ;
                  background: #37a08d ;
                  border-radius: 27px;
                  padding: 10px !important;
                  color: white !important;
                  font-size: 12px;
                  font-weight: bold;
                  align-items: center;
                  text-align: center;
              
              }
              }
              td:nth-child(3) {
                .visit {
                  background-color: #81b7ad;
                }
                .done {
                  color: #81b7ad;
                }
              }
            }
          }
        }
      }
    }

    .histoire {
      .closeModal {
        background-color: #bf9592;
      }
      .header {
        background-color: #bf9592;
      }
      .textbook-period ul.list-period > li{
        >a{
          color: #bf9592;
          border: 2px solid #bf9592;
        }
        >ul{
          border: 2px solid #bf9592;
          li{
            color: #bf9592;
          }
        }
      }
      .textbook-period .searched_name{
        border: 2px solid #bf9592;
      }
      .textbook-period.bibliotheque{
        >ul{
          li.active{
            a{
              background: #bf9592;
              color: white;
            }
          }
        }
      }
      .todoCompleted {
        div {
          border-color: #bf9592;
          a {
            color: #bf9592;
            &.active {
              background-color: #bf9592;
            }
          }
        }
      }
      .exercices-table {
        table {
          border-collapse: unset;
          tbody {
            tr {
              &.border_txt {
                border: none;
              }
              td{
                &.datefor{
                  background: #bf3938;
                  min-height: 35px; 
    
                }
                &.next_txtb{
                  // background: #3eb5ad;
                  min-height: 36px;
                }
                &.next_txtb_extra {
                  border: 1px solid #bf3938 ;
                  background: #661212 ;
                  border-radius: 27px;
                  padding: 10px !important;
                  color: white !important;
                  font-size: 12px;
                  font-weight: bold;
                  align-items: center;
                  text-align: center;
              
              }
              }
              td:nth-child(3) {
                .visit {
                  background-color: #bf9592;
                }
                .done {
                  color: #bf9592;
                }
              }
            }
          }
        }
      }
    }

    .sciences {
      .closeModal {
        // background-color: #bf9592;
        background-color:#80b0a0;
      }
      .header {
        // background-color: #bf9592;
        background-color: #80b0a0;
      }
      .textbook-period ul.list-period > li{
        >a{
          color: #80b0a0;
          border: 2px solid #80b0a0;
        }
        >ul{
          border: 2px solid #80b0a0;
          li{
            color: #80b0a0;
          }
        }
      }
      .textbook-period .searched_name{
        border: 2px solid #80b0a0;
      }
      .textbook-period.bibliotheque{
        >ul{
          li.active{
            a{
              background: #80b0a0;
              color: white;
            }
          }
        }
      }
      .todoCompleted {
        div {
          border-color: #80b0a0;
          // border-color: #bf9592;
          a {
            color: #80b0a0;
            // color: #bf9592;
            &.active {
              background-color: #80b0a0;
              // background-color: #bf9592;
            }
          }
        }
      }
      .exercices-table {
        table {
          border-collapse: unset;
          tbody {
            tr {
              &.border_txt {
                border: none;
              }
              td{
                &.datefor{
                  background: #80b0a0;
                  min-height: 35px;                
                }
                &.next_txtb{
                  // background: #3eb5ad;
                  min-height: 36px;
                }
                &.next_txtb_extra {
                  border: 1px solid #80b0a0 ;
                  background: #2c8f6e ;
                  border-radius: 27px;
                  padding: 10px !important;
                  color: white !important;
                  font-size: 12px;
                  font-weight: bold;
                  align-items: center;
                  text-align: center;
              
              }
              }
              td:nth-child(3) {
                .visit {
                  background-color: #80b0a0;
                  // background-color: #bf9592;
                }
                .done {
                  color: #80b0a0;
                  // color: #bf9592;
                }
              }
            }
          }
        }
      }
    }

    .arabe {
      .closeModal {
        background-color: #7abade;
      }
      .header {
        background-color: #7abade;
      }
      .textbook-period ul.list-period > li{
        >a{
          color: #7abade;
          border: 2px solid #7abade;
        }
        >ul{
          border: 2px solid #7abade;
          li{
            color: #7abade;
          }
        }
      }
      .textbook-period .searched_name{
        border: 2px solid #7abade;
      }
      .textbook-period.bibliotheque{
        >ul{
          li.active{
            a{
              background: #7abade;
              color: white;
            }
          }
        }
      }
      .todoCompleted {
        div {
          border-color: #7abade;
          a {
            color: #7abade;
            &.active {
              background-color: #7abade;
            }
          }
        }
      }
      .exercices-table {
        table {
          border-collapse: unset;
          tbody {
            tr {
              &.border_txt {
                border:none;
              }
              td{
                &.datefor{
                  background: #7abade;
                  min-height: 35px;
                }
                &.next_txtb{
                  // background: #3eb5ad;
                  min-height: 36px;
                }
                &.next_txtb_extra {
                  border: 1px solid #7abade ;
                  background: #2f739b ;
                  border-radius: 27px;
                  padding: 10px !important;
                  color: white !important;
                  font-size: 12px;
                  font-weight: bold;
                  align-items: center;
                  text-align: center;
              
              }
              }
              td:nth-child(3) {
                .visit {
                  background-color: #7abade;
                }
                .done {
                  color: #7abade;
                }
              }
            }
          }
        }
      }
    }
  }
  &.OverlayActivities {
    > div{
      min-height: 500px;
      max-width: 90%;
      width: 90%;
      left: 5% !important;
      margin-left: 0;
      @media (max-width: 1199px){
        max-width: 100%;
        width: 100%;
        left: 0% !important;
      }
    }
    .closeModal {
      background-color: #8a78f8;
      @media (max-width: 1199px){
        top: 0;
        right: 0;
      }
    }
    .row.back{
      margin: 5px 20px;
      button{
        border: 2px solid #2ae2d780;
        min-width: 57px;
        box-shadow: none;
        background: white;
      }
    }
    .detailBack{
      width: 100%;
      float: left;
      padding: 5px 20px;
      .icon{
        float: left;
        width: 40px;
        height: 40px;
        border-radius: 100px;
        box-shadow: rgba(90, 202, 195, 0.5) 0px 10px 20px 0px;
        background-image: linear-gradient(225deg, rgb(114, 220, 214), rgb(62, 182, 173));
        text-align: center;
        i{
          color: rgb(255, 255, 255);
          font-size: 27px;
          line-height: 40px;
        }
      }
    }
    .activities_list{
      float:left;
      margin:0px 0px;
      padding-bottom: 25px;
      margin-bottom: 0;
      width:100%;
      overflow: auto;
      height: calc(100% - 50px);
      justify-content: start;
      // min-height: 370px;
      @media screen and (max-width: 1200px) {
        height: calc(100% - 205px);
        // min-height: 370px;
      }
      padding: 10px;
      .activity_item{
        margin:10px 0px;
        max-height:170px;
        max-width: 270px;
        cursor:pointer;
        >div{
          img{
            width: 80px;
            height: 80px;
            border-radius: 50%;
          }
          margin:auto;
          display: flex;
          flex-direction: column;
          align-items: center;
          background: #fff;
          border-radius: 10px;
          box-shadow: 0 18px 36px 0 rgba(0, 0, 0, 0.2);
          // padding: 20px;
          padding: 10px;
          text-align: center;
          height: 100%;
          justify-content: space-between;
          position: relative;
          i{
            position: absolute;
            color: gray;
            cursor: pointer;
            right: 7px;
            top: 7px;
            &:hover{
              color: #3eb6ad;
            }
          }
        }
        .activities_likes{
          margin: 0px;
          padding: 5px 0;
          width: 100%;
          display: flex;
          justify-content: space-between;
          font-size: 18px;
          button{
            position: relative;
            margin-right: 3px !important;
            width: max-content;
            min-width: inherit;
            padding-left: 25px;
            border: 0;
            &:focus{
              box-shadow: unset;
            }
            i{
              left: 10px;
              width: max-content;
              color: #ef475a;
              font-size: 18px;
            }
            &:not(.liked){
              i{
                color: #80808099;
                font-size: 18px;
              }
            }
          }
          .info-category{
            font-size: 12px;
            line-height: 30px;
            color: black;
            position: relative;
            a{
              font-weight: bold;
              color: black;
              margin: 0 !important;
            }
            span{
              color: black;
              margin-left: 5px;
            }
          }
        }
      }
      .activity_koolskools_item{
        margin: 10px 10px;
        max-height:200px;
        min-width: calc(20% - 20px);
        max-width: calc(20% - 20px);
        cursor:pointer;
        .exercise-img{
          width: 100px;
          height: 100px;
          border-radius: 50%;
          box-shadow: 0px 1px 12px 1px #b1acac;
          overflow: hidden;
          margin-bottom: 5px;
        }
        img{
          width: 90px;
          height: 90px;
          padding: 10px;
        }
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #fff;
        border-radius: 10px;
        box-shadow: 0 18px 36px 0 rgba(0, 0, 0, 0.2);
        // padding: 20px;
        padding: 10px;
        text-align: center;
        height: 100%;
        justify-content: space-between;
        position: relative;
        i{
          position: absolute;
          color: gray;
          cursor: pointer;
          right: 7px;
          top: 7px;
          &:hover{
            color: #3eb6ad;
          }
        }
        .activities_likes{
          margin: 0px;
          padding: 5px 0;
          width: 100%;
          display: flex;
          justify-content: space-between;
          font-size: 18px;
          button{
            position: relative;
            margin-right: 3px !important;
            width: max-content;
            min-width: inherit;
            padding-left: 25px;
            border: 0;
            &:focus{
              box-shadow: unset;
            }
            i{
              left: 10px;
              width: max-content;
              color: #ef475a;
              font-size: 18px;
            }
            &:not(.liked){
              i{
                color: #80808099;
                font-size: 18px;
              }
            }
          }
          .info-category{
            font-size: 12px;
            line-height: 30px;
            color: black;
            position: relative;
            a{
              font-weight: bold;
              color: black;
              margin: 0 !important;
            }
            span{
              color: black;
              margin-left: 5px;
            }
          }
        }
        .item_name{
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden !important;
          text-overflow: ellipsis !important;
          line-height: 15px;
          width: 100%;
        }
        @media (max-width: 1199px) and (min-width: 768px){
          min-width: calc(33% - 20px);
          max-width: calc(33% - 20px);
        }
        @media (max-width: 767px){
          min-width: calc(50% - 20px);
          max-width: calc(50% - 20px);
        }
      }
      .activity_theme_item{
        margin: 10px 10px;
        max-height:170px;
        min-width: calc(20% - 20px);
        max-width: calc(20% - 20px);
        cursor:pointer;
        .exercise-img{
          width: 100px;
          height: 100px;
          border-radius: 50%;
          box-shadow: 0px 1px 12px 1px #b1acac;
          overflow: hidden;
        }
        img{
          width: 90px;
          height: 90px;
          padding: 10px;
        }
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #fff;
        border-radius: 10px;
        box-shadow: 0 18px 36px 0 rgba(0, 0, 0, 0.2);
        // padding: 20px;
        padding: 10px;
        text-align: center;
        height: 100%;
        justify-content: space-around;
        position: relative;
        i{
          position: absolute;
          color: gray;
          cursor: pointer;
          right: 7px;
          top: 7px;
          &:hover{
            color: #3eb6ad;
          }
        }
        .notifs_info{
          position: absolute;
          right: 0;
          top: 0;
          color: white;
          background: red;
          border-radius: 50%;
          width: 20px;
        }
        .exercise-img{
          overflow: hidden;
        }
        @media (max-width: 1199px) and (min-width: 768px){
          min-width: calc(33% - 20px);
          max-width: calc(33% - 20px);
        }
        @media (max-width: 767px){
          min-width: calc(50% - 20px);
          max-width: calc(50% - 20px);
        }
      }
    }
    .categories_activities {
      .header {
        background-color: #8a78f8;
      }
      .todoCompleted {
        div {
          border-color: #8a78f8;
          a {
            color: #8a78f8;
            &.active {
              background-color: #8a78f8;
            }
          }
        }
      }
      .exercices-table {
        table {
          border-collapse: unset;
          tbody {
            tr {
              &.border_txt {
                border: none;
              }
              td{
                &.datefor{
                  background: #8a78f8;
                  min-height: 35px;

    
                }
                &.next_txtb{
                  // background: #3eb5ad;
                  min-height: 36px;
                }
                &.next_txtb_extra {
                  border: 1px solid #8a78f8 ;
                  background: #4433ad ;
                  border-radius: 27px;
                  padding: 10px !important;
                  color: white !important;
                  font-size: 12px;
                  font-weight: bold;
                  align-items: center;
                  text-align: center;              
              }
              }
              td:nth-child(3) {
                .visit {
                  background-color: #8a78f8;
                }
                .done {
                  color: #8a78f8;
                }
              }
            }
          }
        }
      }
    }
  }
  &.OverlayExams {
    z-index: 1111111111;
    > div{
      min-height: 500px;
      max-width: 60%;
      width: 60%;
      left: 20% !important;
      margin-left: 0;
      padding: 20px !important;
    }
    .closeModal {
      background-color: #8bc34a;
    }
    .exams{
      padding: 10px;
      .header {
        background-color: #8bc34a;
      }
    }
    .exams_list{
      float:left;
      margin:20px 0px;
      padding-bottom: 25px;
      margin-bottom: 0;
      width:100%;
      overflow: auto;
      height: calc(100% - 50px);
      // min-height: 370px;
      @media screen and (max-width: 1200px) {
        height: calc(100% - 205px);
        // min-height: 370px;
      }

      .exam_item{
        margin:10px 0px;
        min-height:220px;
        height: 220px;
        >div{
          cursor:pointer;
          img{
            width: 120px;
            height: 80px;
            border-radius: 50%;
            margin-top: 10px;
          }
          margin:auto;
          display: flex;
          flex-direction: column;
          align-items: center;
          background: #fff;
          border-radius: 10px;
          box-shadow: 0 18px 36px 0 rgba(0, 0, 0, 0.2);
          // padding: 20px;
          padding: 10px;
          text-align: center;
          height: 100%;
          justify-content: space-between;
          position: relative;
          i{
            position: absolute;
            color: gray;
            cursor: pointer;
            right: 7px;
            top: 7px;
            &:hover{
              color: #3eb6ad;
            }
          }
        }
        &:last-of-type{
          >div{
            margin-bottom: 15px;
          }
        }

        .exam_stats{
          font-weight: bold;
          margin-top: 5px;
          .lavel{
            margin-right: 5px;
          }
        }
        .corrected{
          color: #5acac3;
          margin-top: 20px;
          display: block;
          font-weight: bold;
        }
        .not_passed{
            color: #e92727;
            margin-top: 20px;
            display: block;
            font-weight: bold;
        }
        &.disabled{
          pointer-events: none;
          .timer_div{
            min-width: 70px;
            .timer{
              i{
                display: none;
              }
              span{
                width: 100%;
                display: inline-block;
                font-weight: bold;
              }
            }
          }
        }
      }
    }
    @media screen and (max-width: 1200px) {
      > div{
        max-height: 100%;
        max-width: 100%;
        width: 100%;
        left: 0% !important;
        .backModal{
          position: absolute;
          top: 0;
          left: 0;
          box-shadow: unset;
        }
        .header ul{
          justify-content: space-evenly;
        }
        .exams{
          height: 96%;
          .exams_list{
            .exam_item{
              min-width: 33%;
            }
            height: calc(100% - 50px);
          }
        }
      }
    }
    @media screen and (max-width: 460px) {
      > div{
        .exams{
          .exams_list{
            justify-content: center;
            .exam_item{
              min-width: 240px;
            }
          }
        }
      }
    }
    @media (orientation: landscape) and (max-width: 1199px){
      > div{
        .exams{
          .exams_list{
            // height: calc(100% - 217px);
            .exam_item:last-of-type{
              >div{
                margin-bottom: 140px;
              }
            }
          }
        }
      }
    }
  }
}

//Modal ScholarBooks
.OverlayScholarBooks {
  background-color: rgba(100, 115, 140, 0.9);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99999;
  > div {
    top: 70px !important;
    left: 30% !important;
    margin-left: -15%;
    max-width: 70%;
    bottom: auto !important;
    padding: 0 0 30px !important;
    border: none !important;
    border-radius: 10px !important;
    overflow: inherit !important;
    box-shadow: 0 18px 36px 0 rgba(0, 0, 0, 0.2);
    opacity: 0.9;
    height:80%;
    // height: 70%;
    >div{
      height:100%;
      display:flex;
      flex-direction: column;
      &.modal_scholar_books{
        z-index: 1;
        position: relative;
      }
    }

    .closeModal {
      position: absolute;
      top: -23px;
      right: -23px;
      border: 5px solid #fff;
      width: 40px;
      height: 40px;
      text-align: center;
      color: #fff;
      border-radius: 50%;
      font-size: 17px;
      line-height: 28px;
      img {
        max-width: 13px;
      }
    }
    .header {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      padding: 0;
      width: 100%;
      .slick-list {
        height: 50px;
      }
      float: left;
      .slick-dots {
        display: none !important;
      }
      .slick-arrow {
        position: absolute;
        top: 50%;
        font-size: 0;
        background: transparent;
        border: none;
        margin-top: -4px;
        &:before {
          font: normal normal normal 23px/1 FontAwesome;
          line-height: normal;
          color: #fff;
        }

        &.slick-prev {
          left: -6px;
          &:before {
            content: "\f104";
          }
        }

        &.slick-next {
          right: -6px;
          &:before {
            content: "\f105";
          }
        }
      }
      a {
        opacity: 0.54;
        font-family: "Montserrat", sans-serif;
        font-size: 12px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: 1.5px;
        text-align: center;
        color: #ffffff;
        text-transform: uppercase;
        line-height: 50px;
        display: block;
        &.active {
          opacity: 1;
          font-weight: 700;
          position: relative;
          outline: none;
          &:before {
            content: "";
            position: absolute;
            left: 50%;
            margin-left: -10px;
            bottom: 0;
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 8px solid #fff;
          }
        }
      }
      ul {
        display: flex;
        justify-content: space-evenly;
        margin: 0;
        padding: 0;
        li {
          display: inline-block;
          a {
            opacity: 0.54;
            font-family: "Montserrat", sans-serif;
            font-size: 12px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            letter-spacing: 1.5px;
            text-align: center;
            color: #ffffff;
            text-transform: uppercase;
            line-height: 50px;
            display: block;
            &.active {
              opacity: 1;
              font-weight: 700;
              position: relative;
              &:before {
                content: "";
                position: absolute;
                left: 50%;
                margin-left: -10px;
                bottom: 0;
                width: 0;
                height: 0;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-bottom: 8px solid #fff;
              }
            }
          }
        }
        &.activities{
          justify-content: space-evenly;
        }
      }
      .web_slider_subjects{
        .slick-list{
          padding: 0 20px;
        }
        .slick-prev{
          left: 0px !important;
          z-index: 10;
        }
        .slick-next {
          right: 0px !important;
          z-index: 10;
        }
      }
    }

    .books_list{
      height: calc(100% - 50px);
      >div{
        height: 100%;
        >div{
          height: 100%;
          .books{
            height: calc(100% - 40px);
            overflow: auto;
            padding: 10px 0px;
          }
        }
      }
    }

    .maths {
      .closeModal {
        background-color: #56729f;
      }
      .header {
        background-color: #56729f;
      }
      .textbook-period ul.list-period > li{
        >a{
          color: #56729f;
          border: 2px solid #56729f;
        }
        >ul{
          border: 2px solid #56729f;
          li{
            color: #56729f;
          }
        }
      }
      .textbook-period .searched_name{
        border: 2px solid #56729f;
      }
      .textbook-period.bibliotheque{
        >ul{
          li.active{
            a{
              background: #56729f;
              color: white;
            }
          }
        }
      }
      
      .todoCompleted {
        div {
          border-color: #56729f;
          a {
            color: #56729f;
            &.active {
              background-color: #56729f;
            }
          }
        }
      }
      .exercices-table {
        table {
          border-collapse: unset;
          tbody {
            tr {
              &.border_txt {
                border: none;
              }
              td{
                &.datefor{
                  background: #56729f;
                  min-height: 35px;
    
                }
                &.next_txtb{
                  // background: #3eb5ad;
                  min-height: 36px;
                }
                &.next_txtb_extra {
                  border: 1px solid #56729f ;
                  background: #1c4381;
                  border-radius: 27px;
                  padding: 10px !important;
                  color: white !important;
                  font-size: 12px;
                  font-weight: bold;
                  align-items: center;
                  text-align: center;
              
              }
              }
              td:nth-child(3) {
                .visit {
                  background-color: #56729f;
                }
                .done {
                  color: #56729f;
                }
              }
            }
          }
        }
      }
    }

    .french {
      .closeModal {
        background-color: #e7c26a;
      }
      .header {
        background-color: #e7c26a;
      }
      .textbook-period ul.list-period > li{
        >a{
          color: #e7c26a;
          border: 2px solid #e7c26a;
        }
        >ul{
          border: 2px solid #e7c26a;
          li{
            color: #e7c26a;
          }
        }
      }
      .textbook-period .searched_name{
        border: 2px solid #e7c26a;
      }
      .textbook-period.bibliotheque{
        >ul{
          li.active{
            a{
              background: #e7c26a;
              color: white;
            }
          }
        }
      }
      .todoCompleted {
        div {
          border-color: #e7c26a;
          a {
            color: #e7c26a;
            &.active {
              background-color: #e7c26a;
            }
          }
        }
      }
      .exercices-table {
        table {
          border-collapse: unset;
          tbody {
            tr {
              &.border_txt {
                border: none;
              }
              td{
                &.datefor{
                  background: #e7c26a;
                  min-height: 35px;
    
                }
                &.next_txtb{
                  // background: #3eb5ad;
                  min-height: 36px;
                }
                &.next_txtb_extra {
                  border: 1px solid #e7c26a ;
                  background: #dd9e0c;
                  border-radius: 27px;
                  padding: 10px !important;
                  color: white !important;
                  font-size: 12px;
                  font-weight: bold;
                  align-items: center;
                  text-align: center;
              
              }
              }
              td:nth-child(3) {
                .visit {
                  background-color: #e7c26a;
                }
                .done {
                  color: #e7c26a;
                }
              }
            }
          }
        }
      }
    }
    .autre {
      .closeModal {
        background-color: #80c9ca;
      }
      .header {
        background-color: #80c9ca;
      }
      .textbook-period ul.list-period > li{
        >a{
          color: #80c9ca;
          border: 2px solid #80c9ca;
        }
        >ul{
          border: 2px solid #80c9ca;
          li{
            color: #80c9ca;
          }
        }
      }
      .textbook-period .searched_name{
        border: 2px solid #80c9ca;
      }
      .textbook-period.bibliotheque{
        >ul{
          li.active{
            a{
              background: #80c9ca;
              color: white;
            }
          }
        }
      }
      .todoCompleted {
        div {
          border-color: #80c9ca;
          a {
            color: #80c9ca;
            &.active {
              background-color: #80c9ca;
            }
          }
        }
      }
      .exercices-table {
        table {
          border-collapse: unset;
          tbody {
            tr {
              td{
                &.border_txt {
                  border: none;
                }
                &.datefor{
                  background: #80c9ca;
                  min-height: 35px;
    
                }
                &.next_txtb{
                  // background: #3eb5ad;
                  min-height: 36px;
                }
                &.next_txtb_extra {
                  border: 1px solid #80c9ca ;
                  background: #19abad;
                  border-radius: 27px;
                  padding: 10px !important;
                  color: white !important;
                  font-size: 12px;
                  font-weight: bold;
                  align-items: center;
                  text-align: center;
              
              }
              }
              td:nth-child(3) {
                .visit {
                  background-color: #80c9ca;
                }
                .done {
                  color: #80c9ca;
                }
              }
            }
          }
        }
      }
    }
    .arabic {
      .closeModal {
        background-color: #7cb9de;
      }
      .header {
        background-color: #7cb9de;
      }
      .textbook-period ul.list-period > li{
        >a{
          color: #7cb9de;
          border: 2px solid #7cb9de;
        }
        >ul{
          border: 2px solid #7cb9de;
          li{
            color: #7cb9de;
          }
        }
      }
      .textbook-period .searched_name{
        border: 2px solid #7cb9de;
      }
      .textbook-period.bibliotheque{
        >ul{
          li.active{
            a{
              background: #7cb9de;
              color: white;
            }
          }
        }
      }
      .todoCompleted {
        div {
          border-color: #7cb9de;
          a {
            color: #7cb9de;
            &.active {
              background-color: #7cb9de;
            }
          }
        }
      }
      .exercices-table {
        table {
          border-collapse: unset;
          tbody {
            tr {
              &.border_txt {
                border:none;
              }
              td{
                &.datefor{
                  background: #7cb9de;
                  min-height: 35px;
                }
                &.next_txtb{
                  // background: #3eb5ad;
                  min-height: 36px;
                }
                &.next_txtb_extra {
                  border: 1px solid #7cb9de ;
                  background: #1f7db8;
                  border-radius: 27px;
                  padding: 10px !important;
                  color: white !important;
                  font-size: 12px;
                  font-weight: bold;
                  align-items: center;
                  text-align: center;
              
              }
              }
              td:nth-child(3) {
                .visit {
                  background-color: #7cb9de;
                }
                .done {
                  color: #7cb9de;
                }
              }
            }
          }
        }
      }
    }

    .anglais {
      .closeModal {
        background-color: #e7c26a;
      }
      .header {
        background-color: #e7c26a;
      }
      .textbook-period ul.list-period > li{
        >a{
          color: #e7c26a;
          border: 2px solid #e7c26a;
        }
        >ul{
          border: 2px solid #e7c26a;
          li{
            color: #e7c26a;
          }
        }
      }
      .textbook-period .searched_name{
        border: 2px solid #e7c26a;
      }
      .textbook-period.bibliotheque{
        >ul{
          li.active{
            a{
              background: #e7c26a;
              color: white;
            }
          }
        }
      }
      .todoCompleted {
        div {
          border-color: #e7c26a;
          a {
            color: #e7c26a;
            &.active {
              background-color: #e7c26a;
            }
          }
        }
      }
      .exercices-table {
        table {
          border-collapse: unset;
          tbody {
            tr {
              &.border_txt {
                border:none;
              }
              td{
                &.datefor{
                  background: #e7c26a;
                  min-height: 35px;                        
                }
                &.next_txtb{
                  // background: #3eb5ad;
                  min-height: 36px;
                }
                &.next_txtb_extra {
                  border: 1px solid #e7c26a ;
                  background: #c5931f ;
                  border-radius: 27px;
                  padding: 10px !important;
                  color: white !important;
                  font-size: 12px;
                  font-weight: bold;
                  align-items: center;
                  text-align: center;
              

              }
              }
              td:nth-child(3) {
                .visit {
                  background-color: #e7c26a;
                }
                .done {
                  color: #e7c26a;
                }
              }
            }
          }
        }
      }
    }

    .science {
      .closeModal {
        background-color: #81b7ad;
      }
      .header {
        background-color: #81b7ad;
      }
      .textbook-period ul.list-period > li{
        >a{
          color: #81b7ad;
          border: 2px solid #81b7ad;
        }
        >ul{
          border: 2px solid #81b7ad;
          li{
            color: #81b7ad;
          }
        }
      }
      .textbook-period .searched_name{
        border: 2px solid #81b7ad;
      }
      .textbook-period.bibliotheque{
        >ul{
          li.active{
            a{
              background: #81b7ad;
              color: white;
            }
          }
        }
      }
      .todoCompleted {
        div {
          border-color: #81b7ad;
          a {
            color: #81b7ad;
            &.active {
              background-color: #81b7ad;
            }
          }
        }
      }
      .exercices-table {
        table {
          border-collapse: unset;
          tbody {
            tr {
              &.border_txt {
                border: none;
              }
              td{
                &.datefor{
                  background: #81b7ad;
                  min-height: 35px;                    
                }
                &.next_txtb{
                  // background: #3eb5ad;
                  min-height: 36px;
                }
                &.next_txtb_extra {
                  border: 1px solid #81b7ad ;
                  background: #3c9787 ;
                  border-radius: 27px;
                  padding: 10px !important;
                  color: white !important;
                  font-size: 12px;
                  font-weight: bold;
                  align-items: center;
                  text-align: center;
              
              }
              }
              td:nth-child(3) {
                .visit {
                  background-color: #81b7ad;
                }
                .done {
                  color: #81b7ad;
                }
              }
            }
          }
        }
      }
    }

    .géographie {
      .closeModal {
        background-color: #81b7ad;
      }
      .header {
        background-color: #81b7ad;
      }
      .textbook-period ul.list-period > li{
        >a{
          color: #81b7ad;
          border: 2px solid #81b7ad;
        }
        >ul{
          border: 2px solid #81b7ad;
          li{
            color: #81b7ad;
          }
        }
      }
      .textbook-period .searched_name{
        border: 2px solid #81b7ad;
      }
      .textbook-period.bibliotheque{
        >ul{
          li.active{
            a{
              background: #81b7ad;
              color: white;
            }
          }
        }
      }
      .todoCompleted {
        div {
          border-color: #81b7ad;
          a {
            color: #81b7ad;
            &.active {
              background-color: #81b7ad;
            }
          }
        }
      }
      .exercices-table {
        table {
          border-collapse: unset;
          tbody {
            tr {
              &.border_txt {
                border: none;
              }
              td{
                &.datefor{
                  background: #81b7ad;
                  min-height: 35px;                
    
                }
                &.next_txtb{
                  // background: #3eb5ad;
                  min-height: 36px;
                }
                &.next_txtb_extra {
                  border: 1px solid #81b7ad ;
                  background: #37a08d ;
                  border-radius: 27px;
                  padding: 10px !important;
                  color: white !important;
                  font-size: 12px;
                  font-weight: bold;
                  align-items: center;
                  text-align: center;
              
              }
              }
              td:nth-child(3) {
                .visit {
                  background-color: #81b7ad;
                }
                .done {
                  color: #81b7ad;
                }
              }
            }
          }
        }
      }
    }

    .histoire {
      .closeModal {
        background-color: #bf9592;
      }
      .header {
        background-color: #bf9592;
      }
      .textbook-period ul.list-period > li{
        >a{
          color: #bf9592;
          border: 2px solid #bf9592;
        }
        >ul{
          border: 2px solid #bf9592;
          li{
            color: #bf9592;
          }
        }
      }
      .textbook-period .searched_name{
        border: 2px solid #bf9592;
      }
      .textbook-period.bibliotheque{
        >ul{
          li.active{
            a{
              background: #bf9592;
              color: white;
            }
          }
        }
      }
      .todoCompleted {
        div {
          border-color: #bf9592;
          a {
            color: #bf9592;
            &.active {
              background-color: #bf9592;
            }
          }
        }
      }
      .exercices-table {
        table {
          border-collapse: unset;
          tbody {
            tr {
              &.border_txt {
                border: none;
              }
              td{
                &.datefor{
                  background: #bf3938;
                  min-height: 35px; 
    
                }
                &.next_txtb{
                  // background: #3eb5ad;
                  min-height: 36px;
                }
                &.next_txtb_extra {
                  border: 1px solid #bf3938 ;
                  background: #661212 ;
                  border-radius: 27px;
                  padding: 10px !important;
                  color: white !important;
                  font-size: 12px;
                  font-weight: bold;
                  align-items: center;
                  text-align: center;
              
              }
              }
              td:nth-child(3) {
                .visit {
                  background-color: #bf9592;
                }
                .done {
                  color: #bf9592;
                }
              }
            }
          }
        }
      }
    }

    .sciences {
      .closeModal {
        // background-color: #bf9592;
        background-color:#80b0a0;
      }
      .header {
        // background-color: #bf9592;
        background-color: #80b0a0;
      }
      .textbook-period ul.list-period > li{
        >a{
          color: #80b0a0;
          border: 2px solid #80b0a0;
        }
        >ul{
          border: 2px solid #80b0a0;
          li{
            color: #80b0a0;
          }
        }
      }
      .textbook-period .searched_name{
        border: 2px solid #80b0a0;
      }
      .textbook-period.bibliotheque{
        >ul{
          li.active{
            a{
              background: #80b0a0;
              color: white;
            }
          }
        }
      }
      .todoCompleted {
        div {
          border-color: #80b0a0;
          // border-color: #bf9592;
          a {
            color: #80b0a0;
            // color: #bf9592;
            &.active {
              background-color: #80b0a0;
              // background-color: #bf9592;
            }
          }
        }
      }
      .exercices-table {
        table {
          border-collapse: unset;
          tbody {
            tr {
              &.border_txt {
                border: none;
              }
              td{
                &.datefor{
                  background: #80b0a0;
                  min-height: 35px;                
                }
                &.next_txtb{
                  // background: #3eb5ad;
                  min-height: 36px;
                }
                &.next_txtb_extra {
                  border: 1px solid #80b0a0 ;
                  background: #2c8f6e ;
                  border-radius: 27px;
                  padding: 10px !important;
                  color: white !important;
                  font-size: 12px;
                  font-weight: bold;
                  align-items: center;
                  text-align: center;
              
              }
              }
              td:nth-child(3) {
                .visit {
                  background-color: #80b0a0;
                  // background-color: #bf9592;
                }
                .done {
                  color: #80b0a0;
                  // color: #bf9592;
                }
              }
            }
          }
        }
      }
    }

    .arabe {
      .closeModal {
        background-color: #7abade;
      }
      .header {
        background-color: #7abade;
      }
      .textbook-period ul.list-period > li{
        >a{
          color: #7abade;
          border: 2px solid #7abade;
        }
        >ul{
          border: 2px solid #7abade;
          li{
            color: #7abade;
          }
        }
      }
      .textbook-period .searched_name{
        border: 2px solid #7abade;
      }
      .textbook-period.bibliotheque{
        >ul{
          li.active{
            a{
              background: #7abade;
              color: white;
            }
          }
        }
      }
      .todoCompleted {
        div {
          border-color: #7abade;
          a {
            color: #7abade;
            &.active {
              background-color: #7abade;
            }
          }
        }
      }
      .exercices-table {
        table {
          border-collapse: unset;
          tbody {
            tr {
              &.border_txt {
                border:none;
              }
              td{
                &.datefor{
                  background: #7abade;
                  min-height: 35px;
                }
                &.next_txtb{
                  // background: #3eb5ad;
                  min-height: 36px;
                }
                &.next_txtb_extra {
                  border: 1px solid #7abade ;
                  background: #2f739b ;
                  border-radius: 27px;
                  padding: 10px !important;
                  color: white !important;
                  font-size: 12px;
                  font-weight: bold;
                  align-items: center;
                  text-align: center;
              
              }
              }
              td:nth-child(3) {
                .visit {
                  background-color: #7abade;
                }
                .done {
                  color: #7abade;
                }
              }
            }
          }
        }
      }
    }
  }
}
.ReactModal__Body--open {
  overflow-y: hidden !important;
}

//Modal Daily challenge
.OverlayDaillyChallenge {
  background-color: rgba(100, 115, 140, 0.9);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: initial !important;
  z-index: 99999;
  &.overlayreclamation{
    z-index: 1000;
    .react-datepicker-wrapper{
      margin-left:0px!important
    }
  }
  &.modalSchedule{
    z-index: 100000;
  }
  &.modaljournacard{
    z-index: 1000;
    .journalbody {
      padding: 0% 1% 1%;
      // overflow-y: scroll;
      // /* min-height: 100px; */
      // height: 420px;
      >div{
      &.journal-details{
        height: 100%;
        display: flex;
        justify-content: space-around;
        font-size: 15px;
        font-weight: bolder;
        >div{
          &.journal-attach{
            width: 50%;
            @media (max-width: 767px) {
              width: 100%;
            }
            .exercise{
              display: flex;
              -webkit-box-pack: justify;
              justify-content: space-between;
              margin-bottom: 15px;
              box-shadow: 5px 5px 15px -2px;
              line-height: 27px;
              visibility: visible !important;
              opacity: 1 !important;
              border-width: 1px;
              border-style: solid;
              border-color: rgb(176, 190, 204);
              border-image: initial;
              padding: 5px;
              border-radius: 10px;
              margin-right: 10px;
            }
          }
          &.journal-note{
            width: 50%;
            float: right;
            height: 100%;
            // overflow-y: scroll;
            border-left: 1px solid #ccc;
            @media (max-width: 767px) {
              width: 100%;
              border: none;
            }
            .note{
              padding: .75em;
              margin: .75em;
              width: auto;
              background: #ffe677;
              color: #333;
              position: relative;
            }
            
          }
          &.journal-obj {
            float: left;
            position: relative;
            width: 50%;
            margin-left: 3%;
        }
        &.journal-mtr {
          float: right;
          position: relative;
          width: 50%;
          margin-left: 3%;
      }
      &.journal-mob {
        float: right;
        position: relative;
        width: 100%;
        margin-left: 5%;
    }
          &.note{
            background: #ffe677;
            color: #333;
            position: relative;
          }
        }
        >span{
          &.sous-details{
            font-weight: lighter;
          }
        }

      }
    }
    }
    .journal-to-scroll {
      // height: 400px;
      @media screen and (max-width: 460px) {
        height: 470px;
      }
      height: 365px;
      // overflow: scroll;
      overflow-y: scroll;
      overflow-x: hidden;
      @media screen and (max-width: 767px) {
        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
          background-color: #ffffff;
        }
  
        &::-webkit-scrollbar {
          width: 5px;
          background-color: #ffffff;
        }
  
        &::-webkit-scrollbar-thumb {
          background-color: #ffffff;
        
        }

      }
      @media screen and (min-width: 767px) {
        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          background-color: #f5f5f5;
        }

        &::-webkit-scrollbar {
          width: 5px;
          background-color: #f5f5f5;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #3eb6ad;
        
        }
      }
    }
    .journal-card {
      margin: 0px 30px;
      background: white;
  }
    .jounaltop{
      display:flex;
      background-color: #e4e8ef;
      min-height: 50px;
      font-weight: bold;
      // color:white;
      // border-radius: 5px;
      border-top-right-radius: 25px;
      border-top-left-radius: 17px;
      margin-bottom: 30px;
      >span{
        margin: 5px;
        img {
          width: 60px;
        }
        
        &.journal-date{
          margin-left: 25px;
          margin-top: 13px;
          h3{
            font-size: 20px;
            font-family: sans-serif;
            font-weight: bold;
          }
          h6{
            font-size: 12px;
          }
          
        }

      }
      @media (max-width: 767px) {
        // background-color: white;
        background-color: #e4e8ef;
        border-radius: 0px;
        min-width: 767px;

      >span{
        margin: 5px;
        img {
          width: 0px;
        }
        
        &.journal-date{
          margin-left: 5px;
          h3{
            font-size: 11px;
            font-family: sans-serif;
          }
          h6{
            font-size: 10px;
          }
        }

      }
    }
  }
  .notetop{
    display:flex;
      // background-color: #cbd4dd52;
      background: beige;
      min-height: 50px;
      font-weight: bold;
      // border-radius: 5px;
      border-top-right-radius: 25px;
      border-top-left-radius: 25px;
      // margin-bottom: 30px;
      >span{
        margin: 0px;
        img {
          width: 60px;
        }
        
        &.journal-date{
          margin-left: 25px;
          margin-top: 13px;
          h3{
            font-size: 20px;
            font-family: sans-serif;
            font-weight: bold;
          }
          h6{
            font-size: 12px;
          }
          
        }

      }
      @media (max-width: 767px) {
        background-color: white;
      >span{
        margin: 5px;
        padding: 0px !important;
        img {
          width: 0px;
        }
        
        &.journal-date{
          margin-left: 5px;
          h3{
            font-size: 11px;
            font-family: sans-serif;
          }
          h6{
            font-size: 10px;
          }
        }

      }
    }

  }
  .bloc-notee{
    // height: 35%;
    // overflow-y: scroll;
     margin-top: 5px;
    .delete-basket{
      position: absolute;
      top: -0.375em;
      right: .75em;
      cursor: pointer;
      box-shadow: -6px -4px 0 0 rgba(0,0,0,.1);
      background: red;
      color: #fff;
      display: inline-block;
      /* background-color: #666; */
      line-height: 28px;
      width: 28px;
      font-size: 16px;
      border-radius: 14px;
      -webkit-border-radius: 14px;
      text-align: center;
      margin-right: .375em
    }
    
      // width: 50%;
      // float: right;
      // border-left: 1px solid #ccc;
      .note{
        background: #ffe677;
        color: #333;
        position: relative;
        width: 80%;
        margin: 1.5em auto;
        padding: 20px;
        @media (max-width: 767px) {
          background: #ffe677;
          color: #333;
          position: relative;
          width: 100%;
          margin: auto;
          padding: 20px;
        }
        
      
      
    }
    >i{
      .pin {
        background: transparent url("../img/pin.png");
        position: absolute;
        width: 32px;
        height: 32px;
        left: 48%;
        top: -16px;
        z-index: 1;
    }
    }
  }
  .bloc-note{
    // height: 60%;
    height: 340px;
    overflow-y: scroll;
    .delete-basket{
      position: absolute;
      top: -0.375em;
      right: .75em;
      cursor: pointer;
      box-shadow: -6px -4px 0 0 rgba(0,0,0,.1);
      background: red;
      color: #fff;
      display: inline-block;
      /* background-color: #666; */
      line-height: 28px;
      width: 28px;
      font-size: 16px;
      border-radius: 14px;
      -webkit-border-radius: 14px;
      text-align: center;
      margin-right: .375em
    }
    
      // width: 50%;
      // float: right;
      // border-left: 1px solid #ccc;
      .note{
        background: #ffe677;
        color: #333;
        position: relative;
        width: 80%;
        margin: 1.5em auto;
        padding: 20px;
        @media (max-width: 1199px) {
          width: 100%;
      }
      
      
    }
    >i{
      .pin {
        background: transparent url("../img/pin.png");
        position: absolute;
        width: 32px;
        height: 32px;
        left: 48%;
        top: -16px;
        z-index: 1;
    }
    }
  }


span.button.primary.new-note {
  display: inline-block;
  min-width: 130px;
  height: 30px;

  // width: 132px;
  // height: 66px;
  cursor: pointer;
  /* float: right; */
  /* object-fit: contain; */
  border-radius: 49.5px;
  box-shadow: 0 6px 20px 0 rgba(90,202,195,.5);
  background-image: linear-gradient(54deg,#72dcd6,#3eb6ad);
  color: #fff;
  border: none;
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 25px;
  letter-spacing: normal;
  /* margin: 70px; */
  padding: 2px;
}
  }

  &.OverlayAvailableProfessors{
    > div{
      height: 80%;
      top: 10%;
      >div{
        height: calc(100% - 50px);
        overflow-y: auto;
        margin-top: 25px;
        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          background-color: #f5f5f5;
        }

        &::-webkit-scrollbar {
          width: 5px;
          background-color: #f5f5f5;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #3eb6ad;
        }
        .list_professors{
          padding: 0 25px;
          display: flex;
          flex-wrap: wrap;
        }
      }
      @media (max-width: 1199px) {
        height: 100%;
        width: 100%;
        top: 0% !important;
        max-width: 100% !important;
        left: 0 !important;
        transform: unset !important;
        margin-left: 0 !important;
        border-radius: 0 !important;
        .closeModal{
          top: -6px;
          right: -6px;
        }
      }
    }
  }


  &.OverlayOpenCourseMobile {
    background-color: rgba(100, 115, 140, 0.9);
    > div {
      box-shadow: 0 6px 36px 0 rgba(85, 86, 87, 0.23);
      max-width: 340px;
      margin-left: -170px;
      top: 50% !important;
      margin-top: -70px !important;
      right: 0 !important;
      overflow: initial !important;
      height: 140px;
      border-radius: 140px !important;
      .openCourse-left {
        width: 170px;
        height: 160px;
        float: left;
        margin-top: -27px;
        margin-left: -25px;
        margin-right: -8px;
        img {
          width: 170px;
        }
      }
      .openCourse-right {
        margin-top: 12px;
        span {
          width: 160px;
          height: 40px;
          opacity: 0.9;
          border-radius: 40px;
          border: solid 1.3px #f2985a;
          font-family: "Montserrat", sans-serif;
          font-size: 11px;
          font-weight: 600;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 1px;
          text-align: center;
          color: #f2985a;
          text-align: center;
          line-height: 38px;
          float: left;
          margin-top: 12px;
          &.course {
            background: #f2985a;
            color: #fff;
          }
        }
      }
    }
  }
  &.OverlayChangePassword {
    > div {
      max-width: 258px;
      margin-left: -129px;
      border-radius: 6px;
      opacity: 0.9;
      box-shadow: 0 18px 36px 0 rgba(0, 0, 0, 0.18);
      background-color: #ffffff;
      opacity: 1;
      .closeModal {
        line-height: 29px;
      }
      .header {
        background-color: #46bfb6;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        height: 39px;
        line-height: 39px;

        h3 {
          font-size: 11px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #ffffff;
          margin: 0;
          line-height: 40px;
        }
      }
      .content {
        padding: 25px 25px 17px;

        h3 {
          font-size: 10px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: #f27247;
          margin: 0;
        }
        input {
          width: 100%;
          height: 17px;
          margin-bottom: 25px;
          border: none;
          background: transparent;
          border-bottom: solid 1px #e6e6e6;
        }
        .textErrors {
          margin-top: -15px;
          color: #ef0707;
          font-size: 10px;
          margin-bottom: 15px;
        }
        .password-submit {
          text-align: center;
          button {
            width: 110px;
            height: 27px;
            opacity: 0.9;
            border-radius: 13.5px;
            background-color: #46bfb6;
            text-align: center;
            line-height: 27px;
            opacity: 0.9;
            font-family: Montserrat;
            font-size: 11px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #ffffff;
            border: none;
          }
        }
      }
    }
    &.OverlayChangeImage {
      > div {
        max-width: 782px;
        margin-left: -391px;
        opacity: 1;
        border-radius: 0 !important;
        border-bottom-left-radius: 14px !important;
        border-bottom-right-radius: 14px !important;
        box-shadow: 0 18px 36px 0 rgba(0, 0, 0, 0.2);
        background-color: #ffffff;
        .header {
          height: 55px;
          border-radius: 0 !important;
          .header-back {
            display: none;
          }
          h3 {
            font-size: 15px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: 1px;
            color: #ffffff;
            line-height: 55px;
          }
        }
        .content {
          text-align: center;
          padding-bottom: 30px;
          background: #fcfcfc;
          .choose-avatar {
            display: inline-block;
            position: relative;
            overflow: hidden;
            height: 40px;
            border-radius: 20.3px;
            border: 1px solid #fea625;
            margin-top: 11px;
            a {
              font-family: "Montserrat", sans-serif;
              font-size: 10px;
              font-weight: bold;
              font-style: normal;
              font-stretch: normal;
              line-height: normal;
              letter-spacing: 0.8px;
              padding: 12.5px 25px;
              display: inline-block;
              position: relative;
              border-radius: 27px;
              overflow: hidden;
              color: #fea625;
              &.active {
                color: #ffffff;
                background: #fea625;
                &:before {
                  content: "";
                  position: absolute;
                  left: 0;
                  right: 0;
                  top: 0;
                  bottom: 0;
                  border-radius: 31px;
                  border: 2px solid #fff;
                }
              }
            }
          }
          .choose-image {
            width: 100%;
            float: left;
            position: relative;
            height: 300px;
          }
          .choose-apply {
            text-align: center;
            margin-top: 40px;
            a {
              border-radius: 16.7px;
              box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.12);
              background-color: #3eb6ad;
              font-family: "Montserrat", sans-serif;
              font-size: 12px;
              font-weight: bold;
              font-style: normal;
              font-stretch: normal;
              line-height: normal;
              letter-spacing: 0.6px;
              text-align: center;
              color: #ffffff;
              padding: 9px 55px;
            }
          }
          .upload {
            margin-top: 40px;
            p {
              font-family: "Montserrat", sans-serif;
              font-size: 9px;
              font-weight: bold;
              font-style: normal;
              font-stretch: normal;
              line-height: normal;
              letter-spacing: 0.5px;
              color: #888888;
              b {
                font-size: 11px;
                margin-top: 9px;
                display: block;
                margin-bottom: 6px;
              }
              i {
                color: #ccc;
                font-size: 80px;
              }
              a {
                font-family: "Montserrat", sans-serif;
                font-size: 15px;
                font-weight: bold;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: 0.8px;
                color: #3eb6ad;
                border-bottom: 2px solid #3eb6ad;
              }
            }
            .choose-apply {
              margin-top: 80px;
            }
          }
        }
      }
    }  
  }

  &.OverlayTextbookExercice {
    > div {
      @media (min-width: 768px){
        height:80%;
      }
      max-width: 880px;
      padding: 0 !important;
      margin-left: -440px;
      top: 55px!important;
      // height: 80%;
      display:flex;
      flex-direction:column;
      .header-exercice {
        height: 40px;
        background-color: #3eb6ad;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        position:relative;
        display: flex;
        align-items: center;
        h3 {
          font-family: "Montserrat", sans-serif;
          font-size: 12px;
          font-weight: 600;
          font-style: normal;
          font-stretch: normal;
          letter-spacing: normal;
          color: #ffffff;
          margin: 0;
          text-align: center;
          flex:1;
          display: flex;
          align-items: center;
          // line-height: 40px;
        }
        button:not(.btn-back){
          position: absolute;
          top: 5px;
          left: 5px;
          height: 30px;
          width: 30px;
        }
      }
      .content-exercice {
        padding: 20px 10px;
        background: #fafbfe;
        float: left;
        width: 100%;
        height: 100%;
        overflow: auto;
        .content-exercice-scroll {
          //height: calc(var(--vh, 1vh) * 100 - 80px)!important;
          overflow-y: auto;
          &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: #f5f5f5;
          }

          &::-webkit-scrollbar {
            width: 5px;
            background-color: #f5f5f5;
          }

          &::-webkit-scrollbar-thumb {
            background-color: #3eb6ad;
          }
        }

        .content-exercice-width {
          padding: 0 10px;
          float: left;
          width: 25%;
          margin-bottom: 20px;
          .bloc-content-exercice {
            object-fit: contain;
            border-radius: 4px;
            box-shadow: 0 0 13px -1px rgba(46, 104, 208, 0.1);
            background-color: #ffffff;
            text-align: center;
            min-height: 198px;
            padding: 20px 10px 5px;
            position: relative;
            &.bloc-content-exercice-complete {
              border: solid 1px #3eb6ad;
              .exercice-active {
                display: block;
              }
            }
            &.bloc-content-exercice-lock {
              object-fit: contain;
              mix-blend-mode: luminosity;
            }
            .exercice-active {
              position: absolute;
              right: 5px;
              top: 5px;
              width: 27px;
              height: 27px;
              border-radius: 27px;
              background-color: #3eb6ad;
              text-align: center;
              display: none;
              i {
                color: #fff;
                font-size: 17px;
                line-height: 27px;
              }
            }
            .exercice-img {
              img {
                width: 90px;
                height: 90px;
                margin: auto;
              }
            }
            h4 {
              font-size: 14px;
              font-weight: normal;
              font-style: normal;
              font-stretch: normal;
              line-height: 1.29;
              letter-spacing: normal;
              text-align: center;
              color: #1b2437;
              height: 36px;
              margin: 8px 0 10px;
            }
            p {
              margin: 0;
              span {
                font-family: "Montserrat", sans-serif;
                font-size: 13px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                letter-spacing: 0.5px;
                text-align: center;
                line-height: 27px;
                &.complete {
                  color: #1b2437;
                }
                &.play {
                  color: #ffffff;
                  width: 92px;
                  height: 27px;
                  text-align: center;
                  background: #3eb6ad;
                  display: inline-block;
                  border-radius: 40.7px;
                  line-height: 27px;
                  cursor: pointer;
                  &.percent_1{
                    background: #dc3545;  
                  }
                  &.percent_2{
                    background: #ffc107;  
                    color: #212529;
                  }
                }
                &.lock {
                  color: #ffffff;
                  width: 92px;
                  height: 27px;
                  text-align: center;
                  display: inline-block;
                  border-radius: 40.7px;
                  background: #3eb6ad;
                }
              }
            }
          }
        }
      }
    }
    &.OverlyStatsTaskByExercises{
      >div{
        max-width: 100%;
        left: 40px !important;
        margin-left: 0;
        top: 5% !important;
        height: 90%;
        .header-exercice{
          h3{
            display: flex;
            justify-content: space-around;
            .actions{
              padding: 0 15px;
              span{
                margin: 0 5px;
                background: #808080b5;
                padding: 5px;
                border-radius: 15px;
                color: white;
                cursor: pointer;
                min-width: 100px;
                &.active{
                  color: #3eb6ad;
                  background: white;
                }

              }
            }
          }
        }
        .students_exercises{
          height: calc(100% - 40px);
          overflow-y: auto;
          padding: 15px;
          background: #f8f9fc;
          border-radius: 8px;
          .students_exercisesRight{
            height: 100%;
            border-width: 1px;
            border-style: solid;
            border-color: #b1bdcb;
            border-image: initial;
            background-color: rgba(255, 255, 255, 0.3);
            border-radius: 8px;
            overflow: auto;
            &::-webkit-scrollbar-track{
                -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
                background-color: #F5F5F5;
            }
            &::-webkit-scrollbar{
                width: 5px;
                height: 5px;
            }
            &::-webkit-scrollbar-thumb {
              background-color: #3eb6ad;
              border-radius: 8px;
            }
            .student_name{
              width: 250px;
              display: flex;
              justify-content: center;
              flex-direction: column;
              min-width: 200px;
            }
            .students_exercisesHeader{
              display: flex;
              justify-content: space-evenly;
              border-bottom: 1px solid #b1bdcb;
              background: #e7eaef;
              width: max-content;
              min-width: 100%;
              .items_header{
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                flex: 1;
                text-align: center;
                .type{
                  border-bottom: 1px solid #b1bdcb;
                }
                .type{
                  padding: 5px;
                }
                .items{
                  >div{
                    padding: 5px;
                    position: relative;
                    min-width: 100px;
                    .post_type_icon{
                      background-image: url('../img/iconProfessor.svg');
                      background-size: contain;
                      position: absolute;
                      right: 25px;
                      height: 20px;
                      width: 24px;
                      background-repeat: no-repeat;
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      color: yellow;
                      cursor: pointer;
                    }
                    .down_up{
                      position: absolute;
                      right: 5px;
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      i{
                        cursor: pointer;
                        font-weight: bold;
                        color: black;
                        &.deactivated{
                          color: gray;
                          cursor: not-allowed;
                        }
                      }
                    }
                  }
                }
                .items_moy{
                  display: flex;
                  .moy{
                    width: 100px;
                    padding-right: 16px;
                  }
                  .items{
                    flex: 1;
                  }
                }
              }
              .student_name{
                text-align: center;
                border-right: 1px solid #b1bdcb;
                min-width: 200px;
              }
              >div{
                float: left;
                font-family: Montserrat;
                font-size: 14px;
                font-weight: 600;
                color: rgba(27, 36, 55, 0.8);
                text-align: center;
                line-height: 18px;
              }
            }
            .items{
              display: flex;
              justify-content: flex-start;
              >div{
                min-width: 100px;
                border-right: 1px solid #b1bdcb;
                &:last-of-type{
                  border-right: 0;
                }
                display: flex;
                flex-direction: column;
                justify-content: center;
                flex: 1;
                text-align: center;
                position: relative;
              }
              .post_type_icon{
                background-image: url('../img/iconProfessor.svg');
                background-size: contain;
                position: absolute;
                right: 5px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                height: 20px;
                width: 24px;
                background-repeat: no-repeat;
                color: yellow;
                cursor: pointer;
              }
            }
            .students_exercisesScrolling{
              height: calc(100% - 58px);
              min-width: 100%;
              width: max-content;
              display: flex;
              flex-direction: column;
              .students_exercisesRow{
                display: flex;
                float: left;
                min-width: 100%;
                width: max-content;
                height: 77px;
                background-color: rgb(255, 255, 255);
                position: relative;
                border-radius: 0px;
                border: 0px;
                border-bottom: 1px solid #b1bdcb;
                
                .student_name{
                  height: 75px;
                  display: flex;
                  border-right: 1px solid #b1bdcb;
                  padding: 13px 13px;
                  align-items: center;
                  flex-direction: row;
                  width: 250px;
                  justify-content: start;
                  background: #e7eaef;
                  min-width: 200px;
                  img{
                    width: 48px;
                    height: 48px;
                    float: left;
                    margin-right: 10px;
                    border-radius: 50%;
                  }
                  h3{
                    font-size: 14px;
                    font-weight: 500;
                    color: rgba(27, 36, 55, 0.8);
                    text-align: left;
                    line-height: 18px;
                    text-overflow: ellipsis;
                    margin-top: 5px;
                    overflow: hidden;
                    max-height: 100%;
                  }
                }
                .items{
                  flex: 1;
                  span{
                    max-width: 92px;
                    width: max-content;
                    min-width: 57px;
                    margin: auto;
                    color: #ffffff;
                    height: 27px;
                    display: inline-block;
                    padding: 0.25em 0.6em;
                    font-size: 75%;
                    font-weight: 700;
                    text-align: center;
                    white-space: nowrap;
                    vertical-align: baseline;
                    border-radius: 40.7px;
                    line-height: 23px;
                    &.date_completion{
                      position: absolute;
                      background: transparent !important;
                      color: black;
                      left: 50%;
                      bottom: 0;
                      transform: translateX(-50%);
                      font-size: 9px;
                      height: 20px;
                      line-height: 14px;
                    }
                  }
                  .gray{
                    span{
                      background-color: #ccc;
                    }
                  }
                  .bad{
                    span{
                      background-color: #dc3545;
                    }
                  }
                  .average{
                    span{
                      background-color: #ffc107;
                    }
                  }
                  .good{
                    span{
                      background-color: #3eb6ad;
                    }
                  }
                }
                .moy{
                  width: 89px;
                }
              }
              .students_competencesRow{
                display: flex;
                float: left;
                width: 100%;
                height: 77px;
                background-color: rgb(255, 255, 255);
                position: relative;
                border-radius: 0px;
                border: 0px;
                border-bottom: 1px solid #b1bdcb;
                
                .student_name{
                  height: 75px;
                  display: flex;
                  border-right: 1px solid #b1bdcb;
                  padding: 13px 13px;
                  align-items: center;
                  flex-direction: row;
                  width: 250px;
                  justify-content: start;
                  background: #e7eaef;
                  min-width: 200px;
                  img{
                    width: 48px;
                    height: 48px;
                    float: left;
                    margin-right: 10px;
                    border-radius: 50%;
                  }
                  h3{
                    font-size: 14px;
                    font-weight: 500;
                    color: rgba(27, 36, 55, 0.8);
                    text-align: left;
                    line-height: 18px;
                    text-overflow: ellipsis;
                    margin-top: 5px;
                    overflow: hidden;
                    max-height: 100%;
                  }
                }
                .items{
                  flex: 1;
                  span{
                    width: 92px;
                    margin: auto;
                    color: #ffffff;
                    height: 27px;
                    display: inline-block;
                    padding: 0.25em 0.4em;
                    font-size: 75%;
                    font-weight: 700;
                    text-align: center;
                    white-space: nowrap;
                    vertical-align: baseline;
                    border-radius: 40.7px;
                    line-height: 23px;
                  }
                  .gray{
                    span{
                      background-color: #ccc;
                    }
                  }
                  .bad{
                    span{
                      background-color: #dc3545;
                    }
                  }
                  .average{
                    span{
                      background-color: #ffc107;
                    }
                  }
                  .good{
                    span{
                      background-color: #3eb6ad;
                    }
                  }
                }
                .moy{
                  width: 89px;
                }
              }
            }
            .moy{
              text-align: center;
              display: flex;
              flex-direction: column;
              justify-content: center;
            }
          }
        }
        @media screen and(max-width: 768px) {
          left: 0!important;
          top: 0!important;
          height: 100%;
        }
      }
    }
    &.OverlyStatsCourse{
      >div{
        max-width: 100%;
        left: 40px !important;
        margin-left: 0;
        top: 5% !important;
        height: 90%;
        .header-exercice{
          h3{
            display: flex;
            justify-content: space-around;
            .actions{
              padding: 0 15px;
              span{
                margin: 0 5px;
                background: #808080b5;
                padding: 5px;
                border-radius: 15px;
                color: white;
                cursor: pointer;
                min-width: 100px;
                &.active{
                  color: #3eb6ad;
                  background: white;
                }

              }
            }
          }
        }
        .students_course_view{
          height: calc(100% - 40px);
          overflow-y: auto;
          padding: 15px;
          background: #f8f9fc;
          border-radius: 8px;
          .students_course_viewRight{
            height: 100%;
            border-width: 1px;
            border-style: solid;
            border-color: #b1bdcb;
            border-image: initial;
            background-color: rgba(255, 255, 255, 0.3);
            border-radius: 8px;
            overflow-y: auto;
            &::-webkit-scrollbar {
              width: 5px;
            }
            &::-webkit-scrollbar-thumb {
              background-color: #3eb6ad;
              border-radius: 8px;
            }
            .student_name{
              width: 15%;
              display: flex;
              justify-content: center;
              flex-direction: column;
            }
            .students_exercisesHeader{
              display: flex;
              justify-content: space-evenly;
              border-bottom: 1px solid #b1bdcb;
              background: #e7eaef;
              width: max-content;
              min-width: 100%;
              .items_header{
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                flex: 1;
                text-align: center;
                .type{
                  border-bottom: 1px solid #b1bdcb;
                }
                .type{
                  padding: 5px;
                }
                .items{
                  >div{
                    padding: 5px;
                    position: relative;
                    .down_up{
                      position: absolute;
                      right: 5px;
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      opacity: 1;
                      i{
                        cursor: pointer;
                        font-weight: bold;
                        color: black;
                        &.deactivated{
                          color: gray;
                          cursor: not-allowed;
                        }
                      }
                    }
                  }
                }
                .items_moy{
                  display: flex;
                  .moy{
                    width: 100px;
                    padding-right: 16px;
                  }
                  .items{
                    flex: 1;
                  }
                }
              }
              .student_name{
                text-align: center;
                border-right: 1px solid #b1bdcb;
              }
              >div{
                float: left;
                font-family: Montserrat;
                font-size: 14px;
                font-weight: 600;
                color: rgba(27, 36, 55, 0.8);
                text-align: center;
                line-height: 18px;
              }
            }
            .items{
              display: flex;
              justify-content: flex-start;
              >div{
                border-right: 1px solid #b1bdcb;
                &:last-of-type{
                  border-right: 0;
                }
                display: flex;
                flex-direction: column;
                justify-content: center;
                flex: 1;
                text-align: center;
                position: relative;
                min-width: 100px;
              }
            }
            .students_exercisesScrolling{
              height: calc(100% - 58px);
              min-width: 100%;
              width: max-content;
              display: flex;
              flex-direction: column;
              .students_exercisesRow{
                display: flex;
                float: left;
                min-width: 100%;
                width: max-content;
                height: 77px;
                background-color: rgb(255, 255, 255);
                position: relative;
                border-radius: 0px;
                border: 0px;
                border-bottom: 1px solid #b1bdcb;
                
                .student_name{
                  height: 75px;
                  display: flex;
                  border-right: 1px solid #b1bdcb;
                  padding: 13px 13px;
                  align-items: center;
                  flex-direction: row;
                  width: calc(15%);
                  justify-content: start;
                  background: #e7eaef;
                  img{
                    width: 48px;
                    height: 48px;
                    float: left;
                    margin-right: 10px;
                    border-radius: 50%;
                  }
                  h3{
                    font-size: 14px;
                    font-weight: 500;
                    color: rgba(27, 36, 55, 0.8);
                    text-align: left;
                    line-height: 18px;
                    text-overflow: ellipsis;
                    margin-top: 5px;
                    overflow: hidden;
                    max-height: 100%;
                  }
                }
                .items{
                  flex: 1;
                  span{
                    max-width: 92px;
                    width: max-content;
                    min-width: 57px;
                    margin: auto;
                    color: #000000;
                    height: 27px;
                    display: inline-block;
                    padding: 0.25em 0.6em;
                    font-size: 75%;
                    font-weight: 700;
                    text-align: center;
                    white-space: nowrap;
                    vertical-align: baseline;
                    border-radius: 40.7px;
                    line-height: 23px;
                    &.date_completion{
                      position: absolute;
                      background: transparent !important;
                      color: black;
                      left: 50%;
                      bottom: 0;
                      transform: translateX(-50%);
                      font-size: 9px;
                      height: 20px;
                      line-height: 14px;
                    }
                  }
                  .gray{
                    span{
                      background-color: #ccc;
                    }
                  }
                  .bad{
                    span{
                      background-color: #dc3545;
                    }
                  }
                  .average{
                    span{
                      background-color: #ffc107;
                    }
                  }
                  .good{
                    span{
                      background-color: #3eb6ad;
                    }
                  }
                }
                .moy{
                  width: 89px;
                }
              }
              .students_competencesRow{
                display: flex;
                float: left;
                width: 100%;
                height: 77px;
                background-color: rgb(255, 255, 255);
                position: relative;
                border-radius: 0px;
                border: 0px;
                border-bottom: 1px solid #b1bdcb;
                
                .student_name{
                  height: 75px;
                  display: flex;
                  border-right: 1px solid #b1bdcb;
                  padding: 13px 13px;
                  align-items: center;
                  flex-direction: row;
                  width: calc(15%);
                  justify-content: start;
                  background: #e7eaef;
                  img{
                    width: 48px;
                    height: 48px;
                    float: left;
                    margin-right: 10px;
                    border-radius: 50%;
                  }
                  h3{
                    font-size: 14px;
                    font-weight: 500;
                    color: rgba(27, 36, 55, 0.8);
                    text-align: left;
                    line-height: 18px;
                    text-overflow: ellipsis;
                    margin-top: 5px;
                    overflow: hidden;
                    max-height: 100%;
                  }
                }
                .items{
                  flex: 1;
                  span{
                    width: 92px;
                    margin: auto;
                    color: #ffffff;
                    height: 27px;
                    display: inline-block;
                    padding: 0.25em 0.4em;
                    font-size: 75%;
                    font-weight: 700;
                    text-align: center;
                    white-space: nowrap;
                    vertical-align: baseline;
                    border-radius: 40.7px;
                    line-height: 23px;
                  }
                  .gray{
                    span{
                      background-color: #ccc;
                    }
                  }
                  .bad{
                    span{
                      background-color: #dc3545;
                    }
                  }
                  .average{
                    span{
                      background-color: #ffc107;
                    }
                  }
                  .good{
                    span{
                      background-color: #3eb6ad;
                    }
                  }
                }
                .moy{
                  width: 89px;
                }
              }
            }
            .moy{
              text-align: center;
              display: flex;
              flex-direction: column;
              justify-content: center;
            }
          }
        }
      }
      &.studentSlide{
        > div{
          width: 50%;
          left: 25% !important;
          .student_name{
            width: 30% !important;
          }
          .students_exercisesRow{
            height: 40px !important;
            .student_name{
              height: 40px !important;
              border-bottom: 1px solid #b1bdcb;
            }
          }
          .students_exercisesHeader{
            height: 60px !important;
            width: max-content;
            min-width: 100%;
            .items_header{
              justify-content: center !important;
              .type{
                border-bottom: 0 !important;
                height: unset;
              }
            }
          }
        }
        .header-exercice .student_name{
          height: 40px;
          display: flex;
          padding: 13px 13px;
          align-items: center;
          flex-direction: row;
          width: max-content !important;
          justify-content: start;
          img{
            width: 34px;
            height: 34px;
            float: left;
            margin-right: 10px;
            border-radius: 50%;
          }
          h3{
            margin-right: 5px;
          }
        }
        .students_exercisesHeader{
          width: max-content;
          min-width: 100%;
          .student_name{
            position: relative;
            .down_up{
              position: absolute;
              right: 5px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              opacity: 1;
              i{
                cursor: pointer;
                font-weight: bold;
                color: black;
                &.deactivated{
                  color: gray;
                  cursor: not-allowed;
                }
              }
            }
          }
          .items_header{
            .type{
              height: 100%;
            }
          }
        }
        .students_exercisesRow{
          .student_name{
            text-align: center;
            justify-content: center !important;
          }
        }
        &.all{
          >div{
            width: calc(100% - 80px);
            left: 40px !important;
            .student_name{
              width: 50% !important;
            }
            .students_exercisesScrolling{
              border: 1px solid #b1bdcb;
            }
            .students_course_viewRight{
              >div{
                display: flex;
                justify-content: space-between;
                .students_exercisesScrolling{
                  flex: 1;
                }
              }
            }
            .header-exercice .student_name{
              width: max-content !important;
            }
          }
        }
      }
    }
    &.OverlayExerciceSubject {
      > div {
        max-width: 336px;
        margin-left: -168px !important;
        .header-exercice {
          background: #fda801;
          height: 40px;
        }
        .closeModal {
          background: #fda801;
        }
        .content {
          padding: 20px 30px 0;
          p {
            font-family: "Montserrat";
            font-size: 11px;
            color: #cf112d;
          }
          form {
            width: 100%;
            float: left;
          }
          .addFriend {
            float: left;
            position: relative;
            width: 277px;
            margin-top: 10px;
            margin-bottom: 30px;

            input {
              width: 100%;
              height: 44.6px;
              border-radius: 22.3px;
              border: solid 2px #dbdbdb;
              background-color: #f4f5f6;
              font-family: "Montserrat", sans-serif;
              font-size: 13px;
              font-weight: normal;
              font-style: normal;
              font-stretch: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #000;
              padding-left: 23px;
              &::placeholder {
                color: #000;
                opacity: 1;
              }

              &:-ms-input-placeholder {
                color: #000;
              }

              &::-ms-input-placeholder {
                color: #000;
              }
            }
            button {
              position: absolute;
              right: 5px;
              top: 5px;
              bottom: 5px;
              border: none;
              background: transparent;
              font-size: 25px;
              color: #dbdbdb;
              font-weight: bold;
              background: #fda701;
              border-radius: 44px;
              max-width: 34px !important;
              max-height: 34px !important;
              img {
                width: 22px;
              }
              i {
                color: #fff;
              }
            }
          }
          .bloc-level {
            float: left;
            width: 100%;
            margin-bottom: 25px;
            position: relative;
            &.bloc-level-lock {
              object-fit: contain;
              mix-blend-mode: luminosity;
              .bloc-level-left {
                color: #999;
              }
              .lock {
                position: absolute;
                width: 22px;
                height: 22px;
                right: -2px;
                top: -9px;
                border-radius: 22px;
                border: solid 1px #ffffff;
                background-color: #ececec;
                text-align: center;
                i {
                  color: #999;
                  font-size: 15px;
                  line-height: 20px;
                }
              }
              .progress-level {
                span {
                  background: #ffe7b9 !important;
                }
              }
            }
            .bloc-level-left {
              float: left;
              width: 49px;
              height: 49px;
              border-radius: 49px;
              border: solid 5px #ffd88c;
              position: relative;
              z-index: 9;
              span {
                float: left;
                height: 39px;
                width: 39px;
                border: solid 1px #ffffff;
                background: #fda801;
                border-radius: 43px;
                font-family: "Montserrat", sans-serif;
                font-size: 9px;
                font-weight: 600;
                font-style: normal;
                font-stretch: normal;
                line-height: 1;
                letter-spacing: normal;
                color: #ffcb65;
                text-align: center;
                line-height: 36px;
                b {
                  font-size: 13px;
                  color: #fff;
                  font-weight: 600;
                }
              }
            }
            .bloc-level-right {
              height: 49px;
              border-radius: 9px;
              background-color: #ffffff;
              width: calc(100% - 39px);
              margin-left: -10px;
              float: left;
              padding: 10px 15px 10px 30px;
              h5 {
                font-family: "Montserrat", sans-serif;
                font-size: 11px;
                font-weight: bold;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #737272;
                margin: 0 0 5px;
              }
              .progress-level {
                border-radius: 26px;
                span {
                  width: 24px;
                  height: 9px;
                  float: left;
                  border-right: 1px solid #fff;
                  &:nth-child(1) {
                    background: #fff1d5;
                    border-top-left-radius: 26px;
                    border-bottom-left-radius: 26px;
                  }
                  &:nth-child(2) {
                    background: #ffe7b9;
                  }
                  &:nth-child(3) {
                    background: #ffdd99;
                  }
                  &:nth-child(4) {
                    background: #ffd582;
                  }
                  &:nth-child(5) {
                    background: #ffcc68;
                  }
                  &:nth-child(6) {
                    background: #ffbe3f;
                  }
                  &:nth-child(7) {
                    background: #ffb625;
                  }
                  &:nth-child(8) {
                    background: #fda800;
                    border-right: none;
                    border-top-right-radius: 26px;
                    border-bottom-right-radius: 26px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &.OverlayFriends {
    &.OverlayStudents {
      > div {
        border-radius: 20px !important;
        padding:50px 15px 15px 15px !important;
        &.cardViewjouranl{
          padding: 0px !important;
        }
        /*overflow-y: auto !important;
        height: calc(100vh - 80px) ;
        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
          background-color: #f5f5f5;
        }
  
        &::-webkit-scrollbar {
          width: 5px;
          background-color: #f5f5f5;
        }
  
        &::-webkit-scrollbar-thumb {
          background-color: #3eb6ad;
        }*/
       
      
      
      
       
       
        .friends-bloc {
          text-align: center;
        }
        .closeModal {
          position: absolute;
          cursor: pointer;
          top: 0;
          right: 0;
          width: 40px;
          height: 40px;
          border-radius: 100px;
          box-shadow: 0 10px 20px 0 rgba(90, 202, 195, 0.5);
          background-image: linear-gradient(225deg, #72dcd6, #3eb6ad);
          color: #fff;
          font-size: 40px;
          line-height: 40px;
          text-align: center;
          border: none;
        }
      }
      &.OverlaySchool {
        &.OverlaySchoolNew {
          > div {
            max-width: 550px;
            margin-left: -275px;
            @media screen and (max-width: 767px) {
              margin-left: 0px;
            }
          }
          &.OverlayNewCatActivity{
            > div {
              top: 50% !important;
              transform: translateY(-50%) !important;
            }
            label{
              margin-bottom: 10px;
            }
            .select_image{
              text-align: left;
              width: 100%;
              padding-top: 5px;
              padding-right: 5px;
              position: relative;
              span:not(.file_name){
                color: white;
                background: #3EB6AD;
                font-weight: bold;
                border-radius: 15px;
                padding: 5px 10px;
                cursor: pointer;
                margin-right: 5px;
              }
              span.file_name{
                position: absolute;
                display: block;
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 12px;
                margin-top: 5px;
                color: gray;
              }
              input{
                visibility: hidden;
                width: 0px;
                padding: 0;
              }
              img{
                position: absolute;
                max-width: 70px;
                max-height: 70px;
                right: 0;
                top: -20px;
              }
            }
            .activities_types{
              .RadioLegend{
                width: 25%;
                @media (max-width: 767px) {
                  width: 50%;
                }
              }
            }
          }
        }
        &.OverlayHomeWork {
          &.OverlayAddExercice {
            > div {
              max-width: 90%;
              height: calc(100% - 80px);;
              display:flex;
              flex-direction: column;
              margin: auto;
              left:0px!important;
              /*padding: 20px 40px 20px !important;*/
              background: #fff;
              .exerciceHeader {
                /*float: left;*/
                width: 100%;
                .onBack {
                  position: absolute;
                  cursor: pointer;
                  top: 0;
                  left: 0;
                  width: 35px;
                  height: 35px;
                  border-radius: 100px;
                  box-shadow: 0 10px 20px 0 rgba(90, 202, 195, 0.5);
                  background-image: linear-gradient(225deg, #72dcd6, #3eb6ad);
                  text-align: center;
                  color: #fff;
                  font-size: 30px;
                  line-height: 35px;
                }
                h2 {
                  opacity: 0.8;
                  font-family: "Montserrat";
                  font-size: 14px;
                  font-weight: 500;
                  font-style: normal;
                  font-stretch: normal;
                  line-height: normal;
                  letter-spacing: normal;
                  text-align: center;
                  color: #1b2437;
                  margin: 0;
                }
                .exerciceFilter {
                  .search {
                    width: 228px;
                    float: left;
                    height: 28px;
                    border-radius: 19px;
                    border: solid 1px #b1bfcd;
                    background-color: #f8f8f8;
                    position: relative;
                    i {
                      position: absolute;
                      right: 9px;
                      font-size: 15px;
                      top: 6px;
                    }
                    input {
                      background: transparent;
                      border: none;
                      height: 26px;
                      width: 100%;
                      padding: 0 0 0 13px;
                      font-size: 12px;
                    }
                  }
                  >.select{
                    width: 170px !important;
                  }
                  .exerciceFilterRight {
                    float: right;
                    width: calc(100% - 524px);
                    select{
                      width:100px;
                    }
                    div {
                      float: left;
                      margin-left: 20px;
                      opacity: 0.8;
                      font-family: "Montserrat", sans-serif;
                      font-size: 14px;
                      font-weight: 500;
                      font-style: normal;
                      font-stretch: normal;
                      line-height: normal;
                      letter-spacing: normal;
                      color: #1b2437;
                      img {
                        max-height: 19px;
                        margin-right: 3px;
                        float: left;
                        margin-top: 6px;
                        max-width: 19px;
                      }
                      i {
                        vertical-align: top;
                      }
                    }
                    >div{
                      width: calc(33.33% - 48.33px);
                      margin-left: 5px;
                    }
                  }
                  .select{
                    float: left;
                    width: max-content;
                    height: 29px;
                    border-radius: 2.6px;
                    border: solid 0.6px #d1dbe2;
                    background-color: rgba(245, 248, 249, 0.3);
                    position: relative;
                    padding-left: 3px;
                    margin-left: 0 !important;
                    width: calc(100% - 22px);
                    select{
                      float: left;
                      width: 100%;
                      font-size: 12px;
                      height: 28px;
                      border: none;
                      outline: none;
                      -webkit-appearance: none;
                      -moz-appearance: none;
                      appearance: none;
                      text-indent: 1px;
                      text-overflow: "";
                      background: transparent;
                      outline: 0;
                      position: relative;
                      z-index: 9;
                      border-radius: 4px !important;
                      border: none !important;
                      font-weight: 500;
                      font-family: Montserrat;
                      color: #1b2437;
                      padding: 0 5px;
                      padding-right: 23px;
                    }
                    i{
                      color: #d2dbe2;
                      font-size: 14px;
                      position: absolute;
                      right: 5px;
                      top: 6px;
                    }
                  }
                  &.students{
                    position: relative;
                    height: 20px;
                    margin-top: 10px;
                    .search{
                      left: 50%;
                      position: absolute;
                      transform: translateX(-50%);
                    }
                  }
                }
              }
              .exerciceContent {
                float: left;
                width: calc(100% + 20px);
                padding: 20px 0 0;
                height: calc(100% - 70px);
                @media screen and (max-width: 1200px) {
                  height: calc(100% - 185px);
                }

                .student_view{
                  height: 100%;
                  display:flex;
                  flex-direction:column;
                  .exercise_content_view{
                    height: 100%;
                    .open_question_exercise{
                      .response_question {
                        .question{
                          max-height: 50%;
                          &.h_100{
                            max-height: 100%;
                          }
                          >p{
                            height: 95%;
                            overflow-y: scroll;
                            padding: 15px;
                          }
                        }
                      }
                    }
                    .open_question_v2_exercise{
                      .response_question {
                        .question{
                          height: calc(100% - 44px);
                          >p{
                            height: 95%;
                            overflow-y: scroll;
                            padding: 15px;
                          }
                        }
                      }
                    }
                  }
                }
                .view-xblock{
                  height: 100%;
                }
                .loading-spinner{
                  height:100%!important;
                  top:0px!important;
                }
                .exercise_tree_item{
                  >div:not(.RadioLegend){
                    min-height: 100%;
                    position:relative;
                  }
                  /*display: flex;
                  flex-direction: column;*/
                  height: 100%;
                  overflow-y: auto;
                  // align-items: center;
                  &::-webkit-scrollbar-track{
                    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
                    background-color: #F5F5F5;
                  }
                  &::-webkit-scrollbar{
                      width: 5px;
                      height: 3px;
                      background-color: #F5F5F5;
                  }
                  &::-webkit-scrollbar-thumb{
                      background-color: #3eb6ad;
                  }
                  .list_students_for_task{
                    padding: 15px;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: start;
                    .std{
                      width: 128px !important;
                      height: 160px !important;
                      padding: 8px 9px !important;
                      .img{
                        img{
                          width: 110px !important;
                        }
                      }
                      &.selected{
                        opacity: 1 !important;
                      }
                    }
                    @media screen and (max-width: 1199px) and (min-width: 768px) {
                    }
                  }
                  legend{
                    display: flex;
                    align-items: center;
                    margin: 0px;
                    img{
                      height: 30px;
                      margin-right: 5px;
                    }
                  }
                  @media screen and (max-width: 360px) {
                    padding-right: 0;
                    .list_students_for_task{
                      .std{
                        width: 47% !important;
                      }
                    }
                  }
                  @media screen and (max-width: 500px) and (min-width: 361px) {
                    padding-right: 0;
                    .list_students_for_task{
                      .std{
                        width: 47% !important;
                      }
                    }
                  }
                  @media screen and (max-width: 640px) and (min-width: 501px) {
                    padding-right: 0;
                    .list_students_for_task{
                      .std{
                        width: 31% !important;
                      }
                    }
                  }
                  @media screen and (max-width: 767px) and (min-width: 641px) {
                    padding-right: 0;
                    .list_students_for_task{
                      .std{
                        width: 31% !important;
                      }
                    }
                  }
                }
                .RadioLegend {
                  /*width: calc(33.33% - 20px);
                  float: left;*/
                  display: flex;
                  align-items: center;
                  margin: 0px 10px 15px 0px;
                  background: #fff;
                  height: 60px;
                  object-fit: contain;
                  border-radius: 8px;
                  box-shadow: 0 5px 45px 0 rgba(46, 104, 208, 0.1);
                  /*padding: 9px;*/
                  &.selected{
                    background:#cbefef;
                  }
                  > div {
                    padding: 0;
                    float: left;
                    -webkit-flex: none;
                    flex: none;
                    display: initial;
                    position: relative;
                    &.badge{
                      height: 20px;
                      width: 20px;
                      line-height: 20px;
                      border-radius: 50%;
                      margin: 0 10px;
                    }
                  }
                  > span {
                    float: left;
                    /*margin: 10px 4px 0 0;*/
                  }
                  > span svg {
                    color: #b7c3d0;
                  }
                  > span[class*="checked"] svg {
                    color: #00b3a9;
                  }
                  > legend {
                    /*float: left;*/
                    margin-left: 0;
                    opacity: 0.8;
                    font-family: "Montserrat";
                    font-size: 14px;
                    font-weight: 500;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: #1b2437;
                    border: none;
                    /*width: calc(100% - 52px);
                    padding-top: 3px;*/
                    img {
                      float: left;
                      margin-right: 6px;
                      /*margin-top: 5px;*/
                    }
                  }
                }
                &.students{
                  .exercise_tree_item{
                    >div:not(.RadioLegend){
                      min-height: inherit !important;
                    }
                  }
                }
              }
              .exerciceBottom {
                >div{
                  margin-top:10px;
                }
                
                /*position: absolute;
                left: 0;
                right: 0;
                bottom: 20px;
                text-align: center;*/
                button {
                  display: inline-block;
                  min-width: 106px;
                  height: 30px;
                  object-fit: contain;
                  border-radius: 49.5px;
                  text-align: center;
                  font-family: Montserrat;
                  font-size: 12px;
                  font-weight: 600;
                  font-style: normal;
                  font-stretch: normal;
                  letter-spacing: normal;
                  box-shadow: 0 6px 20px 0 rgba(90, 202, 195, 0.5);
                  background-image: -webkit-linear-gradient(
                    54deg,
                    #72dcd6,
                    #3eb6ad
                  );
                  background-image: linear-gradient(54deg, #72dcd6, #3eb6ad);
                  color: #fff;
                  border: none;
                  margin: 0 7px;
                }
              }
            }
            &.OverlayAddCourse {
              > div {
                .exerciceHeader {
                  .onBack {
                  }
                  h2 {
                  }
                  .exerciceFilter {
                    .search {
                      i {
                      }
                      input {
                      }
                    }
                    .exerciceFilterRight {
                      select{
                      }
                      div {
                        img {
                        }
                        i {
                        }
                      }
                    }
                    &.students{
                      .search{
                      }
                    }
                  }
                }
                .exerciceContent {
  
                  .student_view{
                    .exercise_content_view{
                      .open_question_exercise{
                        .response_question {
                          .question{
                            >p{
                            }
                          }
                        }
                      }
                    }
                    .left-tree-menu{
                      .view-tree-container{
                        width: 0%;
                        &.open{
                          width: 90px;
                        }
                      }
                    }
                  }
                  .view-xblock{
                  }
                  .loading-spinner{
                  }
                  .exercise_tree_item{
                    >div:not(.RadioLegend){
                    }
                    &::-webkit-scrollbar-track{
                    }
                    &::-webkit-scrollbar{
                    }
                    &::-webkit-scrollbar-thumb{
                    }
                    legend{
                      img{
                      }
                    }
                    @media screen and (max-width: 360px) {
                      padding-right: 0;
                      .list_students_for_task{
                        .std{
                        }
                      }
                    }
                    @media screen and (max-width: 500px) and (min-width: 361px) {
                      .list_students_for_task{
                        .std{
                        }
                      }
                    }
                    @media screen and (max-width: 640px) and (min-width: 501px) {
                      .list_students_for_task{
                        .std{
                        }
                      }
                    }
                    @media screen and (max-width: 767px) and (min-width: 641px) {
                      .list_students_for_task{
                        .std{
                        }
                      }
                    }
                  }
                  .RadioLegend {
                    &.selected{
                    }
                    > div {
                      &.badge{
                      }
                    }
                    > span {
                      float: left;
                      /*margin: 10px 4px 0 0;*/
                    }
                    > span svg {
                    }
                    > span[class*="checked"] svg {
                    }
                    > legend {
                      img {
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                      }
                    }
                  }
                  &.students{
                    .exercise_tree_item{
                      >div:not(.RadioLegend){
                      }
                    }
                  }
                }
                .exerciceBottom {
                  >div{
                  }
                  button {
                  }
                }
              }
            }
          }
          > div {
            max-width: 600px;
            margin-left: -300px;
            padding: 25px 25px 0 !important;
            @media(max-width: 1199px){
              max-width: 100%;
              margin: auto;
              top:0px!important;
              right:0px!important;
              left:0px!important;
              bottom:0px!important;
              padding: 30px 45px 0 !important;
              min-height: 100vh;
              border-radius: 0!important;
              .closeModal {
                background: #d8dfe5;
                box-shadow: none;  
                top: 15px;
                right: 15px;
              }
            }
            h2 {
              text-align: left;
              font-size: 18px;
            }
            .add-school-form{
              display: flex;
              flex-direction: column;
              height:calc(100vh - 150px);
              overflow-y: auto;
              overflow-x: hidden;
              padding: 0px 10px;
              float:none;
              // @media screen and (max-width: 767px) {
              //   overflow: hidden;
              // }
              &::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                background-color: #f5f5f5;
              }
      
              &::-webkit-scrollbar {
                width: 5px;
                background-color: #f5f5f5;
              }
      
              &::-webkit-scrollbar-thumb {
                background-color: #3eb6ad;
              }
            }
            .school-ligne {
              .fileUpload {
                display: inline-block;
                min-width: 105px;
                height: 30px;
                object-fit: contain;
                border-radius: 49.5px;
                text-align: center;
                font-family: Montserrat;
                line-height: 30px;
                font-size: 14px;
                font-weight: 500;
                font-style: normal;
                font-stretch: normal;
                letter-spacing: normal;
                box-shadow: 0 6px 20px 0 rgba(90, 202, 195, 0.5);
                background-image: -webkit-linear-gradient(
                  54deg,
                  #3eb6ad,
                  #72dcd6
                );
                background-image: -webkit-linear-gradient(
                  36deg,
                  #3eb6ad,
                  #72dcd6
                );
                background-image: linear-gradient(54deg, #3eb6ad, #72dcd6);
                color: #fff;
                border: none;
                float: left;
                margin-bottom: 0px;
                input {
                  max-width: 95px;
                  visibility: hidden;
                  position: absolute;
                  width: auto;
                  z-index: 999;
                }
                i {
                  margin-right: 3px;
                  font-size: 15px;
                }
              }
              .or {
                font-size: 13px;
                color: rgba(27, 36, 55, 1);
                font-weight: 500;
                margin: 7px 9px 0;
                // float: left;
              }
              .inputLink {
                max-width: 170px;
                position: relative;
                float: left;
                i {
                  position: absolute;
                  left: 8px;
                  top: 8px;
                  color: #b2c0cf;
                }
                input {
                  font-weight: 600;
                  padding-left: 30px;
                  height: 30px;
                  color: #626875;
                }
              }
              label {
                .add {
                  // width: 80px;
                  height: 30px;
                  border: 1px solid #e7ecf0;
                  background: #fcfcfd;
                  border-radius: 2px;
                  display: inline-block;
                  text-align: center;
                  font-size: 13px;
                  line-height: 24px;
                  font-weight: 500;
                  color: rgba(27, 36, 55, 1);
                }
              }
            }
          }
          .field_required{
            color: red;
          }
          .school-ligne-center {
            text-align: center;
          }
        }
        &.OverlayAnnualReviews{
          table{
            border-color: rgb(204, 204, 204);
            width: 100%;
            tr{
              th{
                padding: 5px 10px;
                text-align: center;
              }
              td{
                padding: 5px 10px;
                text-align: center;
                height: 20px;
                .pretty{
                  margin-right: 0px;
                  padding-top: 10px;
                }
              }
            }
          }
          >div{
            .add-school-form{
              height: max-content !important;
              max-height: calc(100vh - 140px) !important;
            }
          }
        }
      }
      > div {
        .add-school-form {
          margin-top: 25px;
          float: left;
          height: calc(100vh - 200px);
          overflow-y: auto;
          overflow-x: hidden;
          padding: 0px 15px;
          &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
            background-color: #f5f5f5;
          }
    
          &::-webkit-scrollbar {
            width: 5px;
            background-color: #f5f5f5;
          }
    
          &::-webkit-scrollbar-thumb {
            background-color: #3eb6ad;
          }
          .school-ligne {
            margin-bottom: 15px;
            width: 100%;
            float: left;
            .intl-tel-input{
              width:100%
            }
            &.school-ligne-center {
              margin: 20px 0;
            }
            .newClassesTop {
              float: left;
              width: 100%;
              margin-bottom: 25px;
              img {
                max-width: 55px;
              }
              span {
                width: 115px;
                height: 30px;
                text-align: center;
                object-fit: contain;
                border-radius: 49.5px;
                background-color: #3eb6ad;
                font-family: Montserrat;
                font-size: 12px;
                font-weight: 500;
                font-style: normal;
                font-stretch: normal;
                line-height: 30px;
                letter-spacing: normal;
                color: rgba(255, 255, 255, 0.8);
                display: inline-block;
                margin-left: 10px;
              }
            }
            button {
              &.add {
                display: inline-block;
                min-width: 106px;
                height: 30px;
                object-fit: contain;
                border-radius: 49.5px;
                text-align: center;
                font-family: Montserrat;
                font-size: 12px;
                font-weight: 600;
                font-style: normal;
                font-stretch: normal;
                letter-spacing: normal;
                box-shadow: 0 6px 20px 0 rgba(90, 202, 195, 0.5);
                background-image: -webkit-linear-gradient(
                  54deg,
                  #72dcd6,
                  #3eb6ad
                );
                background-image: linear-gradient(54deg, #72dcd6, #3eb6ad);
                color: #fff;
                border: none;
                margin: 0 7px;
                &.cancel {
                  background: #fff;
                  color: #3eb6ad;
                  box-shadow: none;
                  border: solid 0.6px #d1dbe2;
                }
                &.newStudents {
                  width: 155px;
                }
              }
            }
            .label_select_students{
              width: 100%;
              display: inline-block;
            }
            .RadioLegend {
              float: left;
              margin-right: 25px;
              &:last-child {
                margin-right: 0;
              }
              > span {
                padding: 0;
                float: left;
                flex: none;
                display: initial;
              }
              > legend {
                float: left;
                margin: 0;
                font-family: Montserrat;
                font-size: 13px;
                font-weight: 500;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #1b2437;
                margin-left: 5px;
                margin-top: 4px;
                display: inline-block;
                border: none;
                width: auto;
              }
            }
            textarea {
              float: left;
              width: 100%;
              height: 50px;
              border-radius: 2.6px;
              border: solid 0.6px #d1dbe2;
              background-color: rgba(245, 248, 249, 0.3);
              resize: none;
            }
            .select {
              float: left;
              width: 100%;
              height: 26.5px;
              border-radius: 2.6px;
              border: solid 0.6px #d1dbe2;
              background-color: rgba(245, 248, 249, 0.3);
              position: relative;
              select {
                float: left;
                width: 100%;
                font-size: 12px;
                height: 25.5px;
                border: none;
                outline: none;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                text-indent: 1px;
                text-overflow: "";
                background: transparent;
                outline: 0;
                position: relative;
                z-index: 9;
                border-radius: 4px!important;
                border: none!important;
                font-weight: 500;
                font-family: Montserrat;
                color: #1b2437;
                padding: 0 10px;
                &::-ms-expand {
                  display: none;
                }
              }
              i {
                color: #d2dbe2;
                font-size: 16px;
                position: absolute;
                right: 5px;
                top: 5px;
              }
            }
            label {
              display: block;
              font-family: Montserrat;
              font-size: 14px;
              font-weight: 500;
              font-style: normal;
              font-stretch: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #1b2437;
              margin-bottom: 3px;
              text-align: left;
            }
            input {
              float: left;
              width: 100%;
              height: 26.5px;
              border-radius: 2.6px;
              border: solid 0.6px #d1dbe2;
              background-color: rgba(245, 248, 249, 0.3);
              &:not([type='tel']){
                padding-left: 10px;
              }
              font-size: 14px;
              font-weight: 500;
            }
            &.school-ligne-diploma {
              margin-bottom: 25px;
            }
            &.school-ligne-margin {
              width: calc(100% + 20px);
              margin-left: -10px;
              margin-right: -10px;
              margin-bottom: 15px;
              .school-ligne-w50 {
                width: 50%;
                float: left;
                padding: 0 10px;
              }
            }
            .error{
              color: red;
            }
            .RadioLegend {
              // width: 25%;
              width: 31%;
              margin: 0 5px 15px;
            }
            .radioLevels {
              .RadioLegend {
                > legend {
                  text-transform: uppercase;
                }
              }
            }
            &.school-ligne-classes {
              border: 2px dotted #d8dfe6;
              margin: 0 -20px 15px;
              width: calc(100% + 40px);
              padding: 10px 20px 0;
            }
          }
        }
      }
    }
    &.OverlayJournals {
      > div {
        border-radius: 20px !important;
        padding:0px !important;
        // height: 90%;
        height: 500px;
        // overflow-y: scroll !important;
        &::-webkit-scrollbar-track{
          -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
          background-color: #F5F5F5;
        }
        &::-webkit-scrollbar{
            width: 5px;
            height: 3px;
            background-color: #F5F5F5;
        }
        &::-webkit-scrollbar-thumb{
            background-color: #3eb6ad;
        }
        /*overflow-y: auto !important;
        height: calc(100vh - 80px) ;
        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
          background-color: #f5f5f5;
        }
  
        &::-webkit-scrollbar {
          width: 5px;
          background-color: #f5f5f5;
        }
  
        &::-webkit-scrollbar-thumb {
          background-color: #3eb6ad;
        }*/
       
      
      
      
       
       
        .friends-bloc {
          text-align: center;
        }
        .closeModal {
          position: absolute;
          cursor: pointer;
          top: 0;
          right: 0;
          width: 40px;
          height: 40px;
          border-radius: 100px;
          box-shadow: 0 10px 20px 0 rgba(90, 202, 195, 0.5);
          background-image: linear-gradient(225deg, #72dcd6, #3eb6ad);
          color: #fff;
          font-size: 40px;
          line-height: 40px;
          text-align: center;
          border: none;
        }
      }
      &.OverlaySchool {
        &.OverlaySchoolNew {
          > div {
            max-width: 550px;
            margin-left: -275px;
            @media screen and (max-width: 767px) {
              margin-left: 0px;
            }
          }
          &.OverlayNewCatActivity{
            > div {
              top: 50% !important;
              transform: translateY(-50%) !important;
            }
            label{
              margin-bottom: 10px;
            }
            .select_image{
              text-align: left;
              width: 100%;
              padding-top: 5px;
              padding-right: 5px;
              position: relative;
              span:not(.file_name){
                color: white;
                background: #3EB6AD;
                font-weight: bold;
                border-radius: 15px;
                padding: 5px 10px;
                cursor: pointer;
                margin-right: 5px;
              }
              span.file_name{
                position: absolute;
                display: block;
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 12px;
                margin-top: 5px;
                color: gray;
              }
              input{
                visibility: hidden;
                width: 0px;
                padding: 0;
              }
              img{
                position: absolute;
                max-width: 70px;
                max-height: 70px;
                right: 0;
                top: -20px;
              }
            }
            .activities_types{
              .RadioLegend{
                width: 25%;
                @media (max-width: 767px) {
                  width: 50%;
                }
              }
            }
          }
        }
        &.OverlayHomeWork {
          &.OverlayAddExercice {
            > div {
              max-width: 90%;
              height: calc(100% - 80px);;
              display:flex;
              flex-direction: column;
              margin: auto;
              left:0px!important;
              /*padding: 20px 40px 20px !important;*/
              background: #fff;
              .exerciceHeader {
                /*float: left;*/
                width: 100%;
                .onBack {
                  position: absolute;
                  cursor: pointer;
                  top: 0;
                  left: 0;
                  width: 35px;
                  height: 35px;
                  border-radius: 100px;
                  box-shadow: 0 10px 20px 0 rgba(90, 202, 195, 0.5);
                  background-image: linear-gradient(225deg, #72dcd6, #3eb6ad);
                  text-align: center;
                  color: #fff;
                  font-size: 30px;
                  line-height: 35px;
                }
                h2 {
                  opacity: 0.8;
                  font-family: "Montserrat";
                  font-size: 14px;
                  font-weight: 500;
                  font-style: normal;
                  font-stretch: normal;
                  line-height: normal;
                  letter-spacing: normal;
                  text-align: center;
                  color: #1b2437;
                  margin: 0;
                }
                .exerciceFilter {
                  .search {
                    width: 228px;
                    float: left;
                    height: 28px;
                    border-radius: 19px;
                    border: solid 1px #b1bfcd;
                    background-color: #f8f8f8;
                    position: relative;
                    i {
                      position: absolute;
                      right: 9px;
                      font-size: 15px;
                      top: 6px;
                    }
                    input {
                      background: transparent;
                      border: none;
                      height: 26px;
                      width: 100%;
                      padding: 0 0 0 13px;
                      font-size: 12px;
                    }
                  }
                  .exerciceFilterRight {
                    float: right;
                    select{
                      width:100px;
                    }
                    div {
                      float: left;
                      margin-left: 40px;
                      opacity: 0.8;
                      font-family: "Montserrat", sans-serif;
                      font-size: 14px;
                      font-weight: 500;
                      font-style: normal;
                      font-stretch: normal;
                      line-height: normal;
                      letter-spacing: normal;
                      color: #1b2437;
                      img {
                        max-height: 19px;
                        margin-right: 3px;
                      }
                      i {
                        vertical-align: top;
                      }
                    }
                  }
                  &.students{
                    position: relative;
                    height: 20px;
                    margin-top: 10px;
                    .search{
                      left: 50%;
                      position: absolute;
                      transform: translateX(-50%);
                    }
                  }
                }
              }
              .exerciceContent {
                float: left;
                width: calc(100% + 20px);
                padding: 20px 0 0;
                height: calc(100% - 70px);
                @media screen and (max-width: 1200px) {
                  height: calc(100% - 185px);
                }

                .student_view{
                  height: 100%;
                  display:flex;
                  flex-direction:column;
                  .exercise_content_view{
                    height: 100%;
                    .open_question_exercise{
                      .response_question {
                        .question{
                          max-height: 50%;
                          &.h_100{
                            max-height: 100%;
                          }
                          >p{
                            height: 95%;
                            overflow-y: scroll;
                            padding: 15px;
                          }
                        }
                      }
                    }
                    .open_question_v2_exercise{
                      .response_question {
                        .question{
                          max-height: 50%;
                          &.h_100{
                            max-height: 100%;
                          }
                          >p{
                            height: 95%;
                            overflow-y: scroll;
                            padding: 15px;
                          }
                        }
                      }
                    }
                  }
                }
                .view-xblock{
                  height: 100%;
                }
                .loading-spinner{
                  height:100%!important;
                  top:0px!important;
                }
                .exercise_tree_item{
                  >div:not(.RadioLegend){
                    min-height: 100%;
                    position:relative;
                  }
                  /*display: flex;
                  flex-direction: column;*/
                  height: 100%;
                  overflow-y: auto;
                  // align-items: center;
                  &::-webkit-scrollbar-track{
                    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
                    background-color: #F5F5F5;
                  }
                  &::-webkit-scrollbar{
                      width: 5px;
                      height: 3px;
                      background-color: #F5F5F5;
                  }
                  &::-webkit-scrollbar-thumb{
                      background-color: #3eb6ad;
                  }
                  .list_students_for_task{
                    padding: 15px;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: start;
                    .std{
                      width: 128px !important;
                      height: 160px !important;
                      padding: 8px 9px !important;
                      .img{
                        img{
                          width: 110px !important;
                        }
                      }
                      &.selected{
                        opacity: 1 !important;
                      }
                    }
                    @media screen and (max-width: 1199px) and (min-width: 768px) {
                    }
                  }
                  legend{
                    display: flex;
                    align-items: center;
                    margin: 0px;
                    img{
                      height: 30px;
                      margin-right: 5px;
                    }
                  }
                  @media screen and (max-width: 360px) {
                    padding-right: 0;
                    .list_students_for_task{
                      .std{
                        width: 47% !important;
                      }
                    }
                  }
                  @media screen and (max-width: 500px) and (min-width: 361px) {
                    padding-right: 0;
                    .list_students_for_task{
                      .std{
                        width: 47% !important;
                      }
                    }
                  }
                  @media screen and (max-width: 640px) and (min-width: 501px) {
                    padding-right: 0;
                    .list_students_for_task{
                      .std{
                        width: 31% !important;
                      }
                    }
                  }
                  @media screen and (max-width: 767px) and (min-width: 641px) {
                    padding-right: 0;
                    .list_students_for_task{
                      .std{
                        width: 31% !important;
                      }
                    }
                  }
                }
                .RadioLegend {
                  /*width: calc(33.33% - 20px);
                  float: left;*/
                  display: flex;
                  align-items: center;
                  margin: 0px 10px 15px 0px;
                  background: #fff;
                  height: 60px;
                  object-fit: contain;
                  border-radius: 8px;
                  box-shadow: 0 5px 45px 0 rgba(46, 104, 208, 0.1);
                  /*padding: 9px;*/
                  &.selected{
                    background:#cbefef;
                  }
                  > div {
                    padding: 0;
                    float: left;
                    -webkit-flex: none;
                    flex: none;
                    display: initial;
                    position: relative;
                    &.badge{
                      height: 20px;
                      width: 20px;
                      line-height: 20px;
                      border-radius: 50%;
                      margin: 0 10px;
                    }
                  }
                  > span {
                    float: left;
                    /*margin: 10px 4px 0 0;*/
                  }
                  > span svg {
                    color: #b7c3d0;
                  }
                  > span[class*="checked"] svg {
                    color: #00b3a9;
                  }
                  > legend {
                    /*float: left;*/
                    margin-left: 0;
                    opacity: 0.8;
                    font-family: "Montserrat";
                    font-size: 14px;
                    font-weight: 500;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: #1b2437;
                    border: none;
                    /*width: calc(100% - 52px);
                    padding-top: 3px;*/
                    img {
                      float: left;
                      margin-right: 6px;
                      /*margin-top: 5px;*/
                    }
                  }
                }
                &.students{
                  .exercise_tree_item{
                    >div:not(.RadioLegend){
                      min-height: inherit !important;
                    }
                  }
                }
              }
              .exerciceBottom {
                >div{
                  margin-top:10px;
                }
                
                /*position: absolute;
                left: 0;
                right: 0;
                bottom: 20px;
                text-align: center;*/
                button {
                  display: inline-block;
                  min-width: 106px;
                  height: 30px;
                  object-fit: contain;
                  border-radius: 49.5px;
                  text-align: center;
                  font-family: Montserrat;
                  font-size: 12px;
                  font-weight: 600;
                  font-style: normal;
                  font-stretch: normal;
                  letter-spacing: normal;
                  box-shadow: 0 6px 20px 0 rgba(90, 202, 195, 0.5);
                  background-image: -webkit-linear-gradient(
                    54deg,
                    #72dcd6,
                    #3eb6ad
                  );
                  background-image: linear-gradient(54deg, #72dcd6, #3eb6ad);
                  color: #fff;
                  border: none;
                  margin: 0 7px;
                }
              }
            }
          }
          > div {
            max-width: 600px;
            margin-left: -300px;
            padding: 25px 25px 0 !important;
            @media(max-width: 1199px){
              max-width: 100%;
              margin: auto;
              top:0px!important;
              right:0px!important;
              left:0px!important;
              bottom:0px!important;
              padding: 30px 45px 0 !important;
              min-height: 100vh;
              border-radius: 0!important;
              .closeModal {
                background: #d8dfe5;
                box-shadow: none;  
                top: 15px;
                right: 15px;
              }
            }
            h2 {
              text-align: left;
              font-size: 18px;
            }
            .add-school-form{
              display: flex;
              flex-direction: column;
              height:calc(100vh - 150px);
              overflow-y: auto;
              overflow-x: hidden;
              padding: 0px 10px;
              float:none;
              &::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                background-color: #f5f5f5;
              }
      
              &::-webkit-scrollbar {
                width: 5px;
                background-color: #f5f5f5;
              }
      
              &::-webkit-scrollbar-thumb {
                background-color: #3eb6ad;
              }
            }
            .school-ligne {
              .fileUpload {
                display: inline-block;
                min-width: 105px;
                height: 30px;
                object-fit: contain;
                border-radius: 49.5px;
                text-align: center;
                font-family: Montserrat;
                line-height: 30px;
                font-size: 14px;
                font-weight: 500;
                font-style: normal;
                font-stretch: normal;
                letter-spacing: normal;
                box-shadow: 0 6px 20px 0 rgba(90, 202, 195, 0.5);
                background-image: -webkit-linear-gradient(
                  54deg,
                  #3eb6ad,
                  #72dcd6
                );
                background-image: -webkit-linear-gradient(
                  36deg,
                  #3eb6ad,
                  #72dcd6
                );
                background-image: linear-gradient(54deg, #3eb6ad, #72dcd6);
                color: #fff;
                border: none;
                float: left;
                margin-bottom: 0px;
                input {
                  max-width: 95px;
                  visibility: hidden;
                  position: absolute;
                  width: auto;
                  z-index: 999;
                }
                i {
                  margin-right: 3px;
                  font-size: 15px;
                }
              }
              .or {
                font-size: 13px;
                color: rgba(27, 36, 55, 1);
                font-weight: 500;
                margin: 7px 9px 0;
                // float: left;
              }
              .inputLink {
                max-width: 170px;
                position: relative;
                float: left;
                i {
                  position: absolute;
                  left: 8px;
                  top: 8px;
                  color: #b2c0cf;
                }
                input {
                  font-weight: 600;
                  padding-left: 30px;
                  height: 30px;
                  color: #626875;
                }
              }
              label {
                .add {
                  // width: 80px;
                  height: 30px;
                  border: 1px solid #e7ecf0;
                  background: #fcfcfd;
                  border-radius: 2px;
                  display: inline-block;
                  text-align: center;
                  font-size: 13px;
                  line-height: 24px;
                  font-weight: 500;
                  color: rgba(27, 36, 55, 1);
                }
              }
            }
          }
          .field_required{
            color: red;
          }
          .school-ligne-center {
            text-align: center;
          }
        }
      }
      > div {
        .add-school-form {
          margin-top: 25px;
          float: left;
          height: calc(100vh - 200px);
          overflow-y: auto;
          overflow-x: hidden;
          padding: 0px 15px;
          &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
            background-color: #f5f5f5;
          }
    
          &::-webkit-scrollbar {
            width: 5px;
            background-color: #f5f5f5;
          }
    
          &::-webkit-scrollbar-thumb {
            background-color: #3eb6ad;
          }
          .school-ligne {
            margin-bottom: 15px;
            width: 100%;
            float: left;
            .intl-tel-input{
              width:100%
            }
            &.school-ligne-center {
              margin: 20px 0;
            }
            .newClassesTop {
              float: left;
              width: 100%;
              margin-bottom: 25px;
              img {
                max-width: 55px;
              }
              span {
                width: 115px;
                height: 30px;
                text-align: center;
                object-fit: contain;
                border-radius: 49.5px;
                background-color: #3eb6ad;
                font-family: Montserrat;
                font-size: 12px;
                font-weight: 500;
                font-style: normal;
                font-stretch: normal;
                line-height: 30px;
                letter-spacing: normal;
                color: rgba(255, 255, 255, 0.8);
                display: inline-block;
                margin-left: 10px;
              }
            }
            button {
              &.add {
                display: inline-block;
                min-width: 106px;
                height: 30px;
                object-fit: contain;
                border-radius: 49.5px;
                text-align: center;
                font-family: Montserrat;
                font-size: 12px;
                font-weight: 600;
                font-style: normal;
                font-stretch: normal;
                letter-spacing: normal;
                box-shadow: 0 6px 20px 0 rgba(90, 202, 195, 0.5);
                background-image: -webkit-linear-gradient(
                  54deg,
                  #72dcd6,
                  #3eb6ad
                );
                background-image: linear-gradient(54deg, #72dcd6, #3eb6ad);
                color: #fff;
                border: none;
                margin: 0 7px;
                &.cancel {
                  background: #fff;
                  color: #3eb6ad;
                  box-shadow: none;
                  border: solid 0.6px #d1dbe2;
                }
                &.newStudents {
                  width: 155px;
                }
              }
            }
            .label_select_students{
              width: 100%;
              display: inline-block;
            }
            .RadioLegend {
              float: left;
              margin-right: 25px;
              &:last-child {
                margin-right: 0;
              }
              > span {
                padding: 0;
                float: left;
                flex: none;
                display: initial;
              }
              > legend {
                float: left;
                margin: 0;
                font-family: Montserrat;
                font-size: 13px;
                font-weight: 500;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #1b2437;
                margin-left: 5px;
                margin-top: 4px;
                display: inline-block;
                border: none;
                width: auto;
              }
            }
            textarea {
              float: left;
              width: 100%;
              height: 50px;
              border-radius: 2.6px;
              border: solid 0.6px #d1dbe2;
              background-color: rgba(245, 248, 249, 0.3);
              resize: none;
            }
            .select {
              float: left;
              width: 100%;
              height: 26.5px;
              border-radius: 2.6px;
              border: solid 0.6px #d1dbe2;
              background-color: rgba(245, 248, 249, 0.3);
              position: relative;
              margin-left: 5px;
              select {
                float: left;
                width: 100%;
                font-size: 12px;
                height: 25.5px;
                border: none;
                outline: none;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                text-indent: 1px;
                text-overflow: "";
                background: transparent;
                outline: 0;
                position: relative;
                z-index: 9;
                border-radius: 4px!important;
                border: none!important;
                font-weight: 500;
                font-family: Montserrat;
                color: #1b2437;
                padding: 0 10px;
                &::-ms-expand {
                  display: none;
                }
              }
              i {
                color: #d2dbe2;
                font-size: 16px;
                position: absolute;
                right: 5px;
                top: 5px;
              }
            }
            label {
              display: block;
              font-family: Montserrat;
              font-size: 14px;
              font-weight: 500;
              font-style: normal;
              font-stretch: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #1b2437;
              margin-bottom: 3px;
              text-align: left;
            }
            input {
              float: left;
              width: 100%;
              height: 26.5px;
              border-radius: 2.6px;
              border: solid 0.6px #d1dbe2;
              background-color: rgba(245, 248, 249, 0.3);
              &:not([type='tel']){
                padding-left: 10px;
              }
              font-size: 14px;
              font-weight: 500;
            }
            &.school-ligne-diploma {
              margin-bottom: 25px;
            }
            &.school-ligne-margin {
              width: calc(100% + 20px);
              margin-left: -10px;
              margin-right: -10px;
              margin-bottom: 15px;
              .school-ligne-w50 {
                width: 50%;
                float: left;
                padding: 0 10px;
              }
            }
            .error{
              color: red;
            }
            .RadioLegend {
              // width: 25%;
              width: 30%;
              margin: 0 0 15px;
            }
            .radioLevels {
              .RadioLegend {
                > legend {
                  text-transform: uppercase;
                }
              }
            }
            &.school-ligne-classes {
              border: 2px dotted #d8dfe6;
              margin: 0 -20px 15px;
              width: calc(100% + 40px);
              padding: 10px 20px 0;
            }
          }
        }
      }
    }
    &.OverlaySticky {
      > div {
        border-radius: 20px !important;
        padding:0px !important;
        height: 80%;
        
        /*overflow-y: auto !important;
        height: calc(100vh - 80px) ;
        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
          background-color: #f5f5f5;
        }
  
        &::-webkit-scrollbar {
          width: 5px;
          background-color: #f5f5f5;
        }
  
        &::-webkit-scrollbar-thumb {
          background-color: #3eb6ad;
        }*/
       
      
      
      
       
       
        .friends-bloc {
          text-align: center;
        }
        .closeModal {
          position: absolute;
          cursor: pointer;
          top: 0;
          right: 0;
          width: 40px;
          height: 40px;
          border-radius: 100px;
          box-shadow: 0 10px 20px 0 rgba(90, 202, 195, 0.5);
          background-image: linear-gradient(225deg, #72dcd6, #3eb6ad);
          color: #fff;
          font-size: 40px;
          line-height: 40px;
          text-align: center;
          border: none;
        }
      }
      &.OverlaySchool {
        &.OverlaySchoolNew {
          > div {
            max-width: 550px;
            margin-left: -275px;
            @media screen and (max-width: 767px) {
              margin-left: 0px;
            }
          }
          &.OverlayNewCatActivity{
            > div {
              top: 50% !important;
              transform: translateY(-50%) !important;
            }
            label{
              margin-bottom: 10px;
            }
            .select_image{
              text-align: left;
              width: 100%;
              padding-top: 5px;
              padding-right: 5px;
              position: relative;
              span:not(.file_name){
                color: white;
                background: #3EB6AD;
                font-weight: bold;
                border-radius: 15px;
                padding: 5px 10px;
                cursor: pointer;
                margin-right: 5px;
              }
              span.file_name{
                position: absolute;
                display: block;
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 12px;
                margin-top: 5px;
                color: gray;
              }
              input{
                visibility: hidden;
                width: 0px;
                padding: 0;
              }
              img{
                position: absolute;
                max-width: 70px;
                max-height: 70px;
                right: 0;
                top: -20px;
              }
            }
            .activities_types{
              .RadioLegend{
                width: 25%;
                @media (max-width: 767px) {
                  width: 50%;
                }
              }
            }
          }
        }
        &.OverlayHomeWork {
          &.OverlayAddExercice {
            > div {
              max-width: 90%;
              height: calc(100% - 80px);;
              display:flex;
              flex-direction: column;
              margin: auto;
              left:0px!important;
              /*padding: 20px 40px 20px !important;*/
              background: #fff;
              .exerciceHeader {
                /*float: left;*/
                width: 100%;
                .onBack {
                  position: absolute;
                  cursor: pointer;
                  top: 0;
                  left: 0;
                  width: 35px;
                  height: 35px;
                  border-radius: 100px;
                  box-shadow: 0 10px 20px 0 rgba(90, 202, 195, 0.5);
                  background-image: linear-gradient(225deg, #72dcd6, #3eb6ad);
                  text-align: center;
                  color: #fff;
                  font-size: 30px;
                  line-height: 35px;
                }
                h2 {
                  opacity: 0.8;
                  font-family: "Montserrat";
                  font-size: 14px;
                  font-weight: 500;
                  font-style: normal;
                  font-stretch: normal;
                  line-height: normal;
                  letter-spacing: normal;
                  text-align: center;
                  color: #1b2437;
                  margin: 0;
                }
                .exerciceFilter {
                  .search {
                    width: 228px;
                    float: left;
                    height: 28px;
                    border-radius: 19px;
                    border: solid 1px #b1bfcd;
                    background-color: #f8f8f8;
                    position: relative;
                    i {
                      position: absolute;
                      right: 9px;
                      font-size: 15px;
                      top: 6px;
                    }
                    input {
                      background: transparent;
                      border: none;
                      height: 26px;
                      width: 100%;
                      padding: 0 0 0 13px;
                      font-size: 12px;
                    }
                  }
                  .exerciceFilterRight {
                    float: right;
                    select{
                      width:100px;
                    }
                    div {
                      float: left;
                      margin-left: 40px;
                      opacity: 0.8;
                      font-family: "Montserrat", sans-serif;
                      font-size: 14px;
                      font-weight: 500;
                      font-style: normal;
                      font-stretch: normal;
                      line-height: normal;
                      letter-spacing: normal;
                      color: #1b2437;
                      img {
                        max-height: 19px;
                        margin-right: 3px;
                      }
                      i {
                        vertical-align: top;
                      }
                    }
                  }
                  &.students{
                    position: relative;
                    height: 20px;
                    margin-top: 10px;
                    .search{
                      left: 50%;
                      position: absolute;
                      transform: translateX(-50%);
                    }
                  }
                }
              }
              .exerciceContent {
                float: left;
                width: calc(100% + 20px);
                padding: 20px 0 0;
                height: calc(100% - 70px);
                @media screen and (max-width: 1200px) {
                  height: calc(100% - 185px);
                }

                .student_view{
                  height: 100%;
                  display:flex;
                  flex-direction:column;
                  .exercise_content_view{
                    height: 100%;
                    .open_question_exercise{
                      .response_question {
                        .question{
                          max-height: 50%;
                          &.h_100{
                            max-height: 100%;
                          }
                          >p{
                            height: 95%;
                            overflow-y: scroll;
                            padding: 15px;
                          }
                        }
                      }
                    }
                    .open_question_v2_exercise{
                      .response_question {
                        .question{
                          max-height: 50%;
                          &.h_100{
                            max-height: 100%;
                          }
                          >p{
                            height: 95%;
                            overflow-y: scroll;
                            padding: 15px;
                          }
                        }
                      }
                    }
                  }
                }
                .view-xblock{
                  height: 100%;
                }
                .loading-spinner{
                  height:100%!important;
                  top:0px!important;
                }
                .exercise_tree_item{
                  >div:not(.RadioLegend){
                    min-height: 100%;
                    position:relative;
                  }
                  /*display: flex;
                  flex-direction: column;*/
                  height: 100%;
                  overflow-y: auto;
                  // align-items: center;
                  &::-webkit-scrollbar-track{
                    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
                    background-color: #F5F5F5;
                  }
                  &::-webkit-scrollbar{
                      width: 5px;
                      height: 3px;
                      background-color: #F5F5F5;
                  }
                  &::-webkit-scrollbar-thumb{
                      background-color: #3eb6ad;
                  }
                  .list_students_for_task{
                    padding: 15px;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: start;
                    .std{
                      width: 128px !important;
                      height: 160px !important;
                      padding: 8px 9px !important;
                      .img{
                        img{
                          width: 110px !important;
                        }
                      }
                      &.selected{
                        opacity: 1 !important;
                      }
                    }
                    @media screen and (max-width: 1199px) and (min-width: 768px) {
                    }
                  }
                  legend{
                    display: flex;
                    align-items: center;
                    margin: 0px;
                    img{
                      height: 30px;
                      margin-right: 5px;
                    }
                  }
                  @media screen and (max-width: 360px) {
                    padding-right: 0;
                    .list_students_for_task{
                      .std{
                        width: 47% !important;
                      }
                    }
                  }
                  @media screen and (max-width: 500px) and (min-width: 361px) {
                    padding-right: 0;
                    .list_students_for_task{
                      .std{
                        width: 47% !important;
                      }
                    }
                  }
                  @media screen and (max-width: 640px) and (min-width: 501px) {
                    padding-right: 0;
                    .list_students_for_task{
                      .std{
                        width: 31% !important;
                      }
                    }
                  }
                  @media screen and (max-width: 767px) and (min-width: 641px) {
                    padding-right: 0;
                    .list_students_for_task{
                      .std{
                        width: 31% !important;
                      }
                    }
                  }
                }
                .RadioLegend {
                  /*width: calc(33.33% - 20px);
                  float: left;*/
                  display: flex;
                  align-items: center;
                  margin: 0px 10px 15px 0px;
                  background: #fff;
                  height: 60px;
                  object-fit: contain;
                  border-radius: 8px;
                  box-shadow: 0 5px 45px 0 rgba(46, 104, 208, 0.1);
                  /*padding: 9px;*/
                  &.selected{
                    background:#cbefef;
                  }
                  > div {
                    padding: 0;
                    float: left;
                    -webkit-flex: none;
                    flex: none;
                    display: initial;
                    position: relative;
                    &.badge{
                      height: 20px;
                      width: 20px;
                      line-height: 20px;
                      border-radius: 50%;
                      margin: 0 10px;
                    }
                  }
                  > span {
                    float: left;
                    /*margin: 10px 4px 0 0;*/
                  }
                  > span svg {
                    color: #b7c3d0;
                  }
                  > span[class*="checked"] svg {
                    color: #00b3a9;
                  }
                  > legend {
                    /*float: left;*/
                    margin-left: 0;
                    opacity: 0.8;
                    font-family: "Montserrat";
                    font-size: 14px;
                    font-weight: 500;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: #1b2437;
                    border: none;
                    /*width: calc(100% - 52px);
                    padding-top: 3px;*/
                    img {
                      float: left;
                      margin-right: 6px;
                      /*margin-top: 5px;*/
                    }
                  }
                }
                &.students{
                  .exercise_tree_item{
                    >div:not(.RadioLegend){
                      min-height: inherit !important;
                    }
                  }
                }
              }
              .exerciceBottom {
                >div{
                  margin-top:10px;
                }
                
                /*position: absolute;
                left: 0;
                right: 0;
                bottom: 20px;
                text-align: center;*/
                button {
                  display: inline-block;
                  min-width: 106px;
                  height: 30px;
                  object-fit: contain;
                  border-radius: 49.5px;
                  text-align: center;
                  font-family: Montserrat;
                  font-size: 12px;
                  font-weight: 600;
                  font-style: normal;
                  font-stretch: normal;
                  letter-spacing: normal;
                  box-shadow: 0 6px 20px 0 rgba(90, 202, 195, 0.5);
                  background-image: -webkit-linear-gradient(
                    54deg,
                    #72dcd6,
                    #3eb6ad
                  );
                  background-image: linear-gradient(54deg, #72dcd6, #3eb6ad);
                  color: #fff;
                  border: none;
                  margin: 0 7px;
                }
              }
            }
          }
          > div {
            max-width: 600px;
            margin-left: -300px;
            padding: 25px 25px 0 !important;
            @media(max-width: 1199px){
              max-width: 100%;
              margin: auto;
              top:0px!important;
              right:0px!important;
              left:0px!important;
              bottom:0px!important;
              padding: 30px 45px 0 !important;
              min-height: 100vh;
              border-radius: 0!important;
              .closeModal {
                background: #d8dfe5;
                box-shadow: none;  
                top: 15px;
                right: 15px;
              }
            }
            h2 {
              text-align: left;
              font-size: 18px;
            }
            .add-school-form{
              display: flex;
              flex-direction: column;
              height:calc(100vh - 150px);
              overflow-y: auto;
              overflow-x: hidden;
              padding: 0px 10px;
              float:none;
              &::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                background-color: #f5f5f5;
              }
      
              &::-webkit-scrollbar {
                width: 5px;
                background-color: #f5f5f5;
              }
      
              &::-webkit-scrollbar-thumb {
                background-color: #3eb6ad;
              }
            }
            .school-ligne {
              .fileUpload {
                display: inline-block;
                min-width: 105px;
                height: 30px;
                object-fit: contain;
                border-radius: 49.5px;
                text-align: center;
                font-family: Montserrat;
                line-height: 30px;
                font-size: 14px;
                font-weight: 500;
                font-style: normal;
                font-stretch: normal;
                letter-spacing: normal;
                box-shadow: 0 6px 20px 0 rgba(90, 202, 195, 0.5);
                background-image: -webkit-linear-gradient(
                  54deg,
                  #3eb6ad,
                  #72dcd6
                );
                background-image: -webkit-linear-gradient(
                  36deg,
                  #3eb6ad,
                  #72dcd6
                );
                background-image: linear-gradient(54deg, #3eb6ad, #72dcd6);
                color: #fff;
                border: none;
                float: left;
                margin-bottom: 0px;
                input {
                  max-width: 95px;
                  visibility: hidden;
                  position: absolute;
                  width: auto;
                  z-index: 999;
                }
                i {
                  margin-right: 3px;
                  font-size: 15px;
                }
              }
              .or {
                font-size: 13px;
                color: rgba(27, 36, 55, 1);
                font-weight: 500;
                margin: 7px 9px 0;
                // float: left;
              }
              .inputLink {
                max-width: 170px;
                position: relative;
                float: left;
                i {
                  position: absolute;
                  left: 8px;
                  top: 8px;
                  color: #b2c0cf;
                }
                input {
                  font-weight: 600;
                  padding-left: 30px;
                  height: 30px;
                  color: #626875;
                }
              }
              label {
                .add {
                  // width: 80px;
                  height: 30px;
                  border: 1px solid #e7ecf0;
                  background: #fcfcfd;
                  border-radius: 2px;
                  display: inline-block;
                  text-align: center;
                  font-size: 13px;
                  line-height: 24px;
                  font-weight: 500;
                  color: rgba(27, 36, 55, 1);
                }
              }
            }
          }
          .field_required{
            color: red;
          }
          .school-ligne-center {
            text-align: center;
          }
        }
      }
      > div {
        .add-school-form {
          margin-top: 25px;
          float: left;
          height: calc(100vh - 200px);
          overflow-y: auto;
          overflow-x: hidden;
          padding: 0px 15px;
          &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
            background-color: #f5f5f5;
          }
    
          &::-webkit-scrollbar {
            width: 5px;
            background-color: #f5f5f5;
          }
    
          &::-webkit-scrollbar-thumb {
            background-color: #3eb6ad;
          }
          .school-ligne {
            margin-bottom: 15px;
            width: 100%;
            float: left;
            .intl-tel-input{
              width:100%
            }
            &.school-ligne-center {
              margin: 20px 0;
            }
            .newClassesTop {
              float: left;
              width: 100%;
              margin-bottom: 25px;
              img {
                max-width: 55px;
              }
              span {
                width: 115px;
                height: 30px;
                text-align: center;
                object-fit: contain;
                border-radius: 49.5px;
                background-color: #3eb6ad;
                font-family: Montserrat;
                font-size: 12px;
                font-weight: 500;
                font-style: normal;
                font-stretch: normal;
                line-height: 30px;
                letter-spacing: normal;
                color: rgba(255, 255, 255, 0.8);
                display: inline-block;
                margin-left: 10px;
              }
            }
            button {
              &.add {
                display: inline-block;
                min-width: 106px;
                height: 30px;
                object-fit: contain;
                border-radius: 49.5px;
                text-align: center;
                font-family: Montserrat;
                font-size: 12px;
                font-weight: 600;
                font-style: normal;
                font-stretch: normal;
                letter-spacing: normal;
                box-shadow: 0 6px 20px 0 rgba(90, 202, 195, 0.5);
                background-image: -webkit-linear-gradient(
                  54deg,
                  #72dcd6,
                  #3eb6ad
                );
                background-image: linear-gradient(54deg, #72dcd6, #3eb6ad);
                color: #fff;
                border: none;
                margin: 0 7px;
                &.cancel {
                  background: #fff;
                  color: #3eb6ad;
                  box-shadow: none;
                  border: solid 0.6px #d1dbe2;
                }
                &.newStudents {
                  width: 155px;
                }
              }
            }
            .label_select_students{
              width: 100%;
              display: inline-block;
            }
            .RadioLegend {
              float: left;
              margin-right: 25px;
              &:last-child {
                margin-right: 0;
              }
              > span {
                padding: 0;
                float: left;
                flex: none;
                display: initial;
              }
              > legend {
                float: left;
                margin: 0;
                font-family: Montserrat;
                font-size: 13px;
                font-weight: 500;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #1b2437;
                margin-left: 5px;
                margin-top: 4px;
                display: inline-block;
                border: none;
                width: auto;
              }
            }
            textarea {
              float: left;
              width: 100%;
              height: 50px;
              border-radius: 2.6px;
              border: solid 0.6px #d1dbe2;
              background-color: rgba(245, 248, 249, 0.3);
              resize: none;
            }
            .select {
              float: left;
              width: 100%;
              height: 26.5px;
              border-radius: 2.6px;
              border: solid 0.6px #d1dbe2;
              background-color: rgba(245, 248, 249, 0.3);
              position: relative;
              margin-left: 5px;
              select {
                float: left;
                width: 100%;
                font-size: 12px;
                height: 25.5px;
                border: none;
                outline: none;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                text-indent: 1px;
                text-overflow: "";
                background: transparent;
                outline: 0;
                position: relative;
                z-index: 9;
                border-radius: 4px!important;
                border: none!important;
                font-weight: 500;
                font-family: Montserrat;
                color: #1b2437;
                padding: 0 10px;
                &::-ms-expand {
                  display: none;
                }
              }
              i {
                color: #d2dbe2;
                font-size: 16px;
                position: absolute;
                right: 5px;
                top: 5px;
              }
            }
            label {
              display: block;
              font-family: Montserrat;
              font-size: 14px;
              font-weight: 500;
              font-style: normal;
              font-stretch: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #1b2437;
              margin-bottom: 3px;
              text-align: left;
            }
            input {
              float: left;
              width: 100%;
              height: 26.5px;
              border-radius: 2.6px;
              border: solid 0.6px #d1dbe2;
              background-color: rgba(245, 248, 249, 0.3);
              &:not([type='tel']){
                padding-left: 10px;
              }
              font-size: 14px;
              font-weight: 500;
            }
            &.school-ligne-diploma {
              margin-bottom: 25px;
            }
            &.school-ligne-margin {
              width: calc(100% + 20px);
              margin-left: -10px;
              margin-right: -10px;
              margin-bottom: 15px;
              .school-ligne-w50 {
                width: 50%;
                float: left;
                padding: 0 10px;
              }
            }
            .error{
              color: red;
            }
            .RadioLegend {
              // width: 25%;
              width: 30%;
              margin: 0 0 15px;
            }
            .radioLevels {
              .RadioLegend {
                > legend {
                  text-transform: uppercase;
                }
              }
            }
            &.school-ligne-classes {
              border: 2px dotted #d8dfe6;
              margin: 0 -20px 15px;
              width: calc(100% + 40px);
              padding: 10px 20px 0;
            }
          }
        }
      }
    }
  }
  > div {
    background: #fff !important;
    padding: 20px 60px 35px !important;
    max-width: 845px;
    margin-left: -422.5px;
    h2 {
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #1b2437;
      margin: 0;
    }
    .friends-switch {
      border-radius: 49.5px;
      border: solid 0.6px #d1dbe2;
      background-color: rgba(245, 248, 249, 0.3);
      height: 32px;
      display: inline-block;
      margin: auto;
      overflow: hidden;
      a {
        font-family: "Montserrat", sans-serif;
        font-size: 12px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #3eb6ad;
        padding: 8px 20px;
        float: left;
        border-radius: 49.5px;
        &.active {
          box-shadow: 0 -10px 20px 0 rgba(90, 202, 195, 0.5);
          background-image: linear-gradient(259deg, #72dcd6, #3eb6ad);
          color: #fff;
          padding: 8px 30px;
        }
      }
    }
    .friends-top {
      margin-top: 30px;
      float: left;
      width: 100%;
      .friend-search {
        float: left;
        width: 228px;
        position: relative;
        input {
          height: 28px;
          border-radius: 19px;
          border: solid 1px #b1bfcd;
          background-color: #f8f8f8;
          width: 100%;
          padding-left: 16px;
        }
        span {
          position: absolute;
          right: 11px;
          top: 3px;
        }
      }
      .friend-right {
        float: right;
        .bloc-level {
          display: inline-block;
          margin-left: 50px;
          font-family: "Montserrat", sans-serif;
          font-size: 14px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #1b2437;
          img {
            max-width: 19px;
            margin-right: 4px;
          }
          .fa-sort-amount-desc,
          .fa-university {
            color: #d3dae2;
          }
          .fa-sort-desc {
            vertical-align: text-top;
          }
        }
      }
    }

    .friend-content {
      float: left;
      width: 100%;
      margin-top: 20px;
      overflow-y: auto;
      .content-scroll {
        height: 280px;
        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          background-color: #f5f5f5;
        }

        &::-webkit-scrollbar {
          width: 5px;
          background-color: #f5f5f5;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #3eb6ad;
        }
      }

      h5 {
        font-size: 15px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #525867;
        margin: 0;
      }
      .friend-content-bloc {
        float: left;
        width: 100%;
        margin: 10px 0 0;
        .friend-content-bloc-scroll {
          height: calc(100vh - 300px);
          overflow-y: auto;
          padding-right: 10px;
          padding-top: 10px;
          padding-left: 5px;
          &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: #f5f5f5;
          }

          &::-webkit-scrollbar {
            width: 5px;
            background-color: #f5f5f5;
          }

          &::-webkit-scrollbar-thumb {
            background-color: #3eb6ad;
          }
        }
        .friend-bloc {
          width: 12.5%;
          height: 110px;
          float: left;
          padding: 0 10px;
          text-align: center;
          margin-bottom: 25px;
          position: relative;
          cursor:pointer;
          &.selected {
            &:before {
              content: "";
              width: 80px;
              height: 80px;
              border-radius: 76px;
              /* background: #FFF; */
              position: absolute;
              left: 7px;
              top: -2px;
              background: url("../img/item_selected.svg") no-repeat scroll
                transparent;
              background-position: 0 0;
              background-size: 80px 80px;
              box-shadow: 0 7px 24px -4px #a9adb1;
            }
            img {
              visibility: hidden;
            }
          }
          img {
            width: 72px;
            height: 72px;
            box-shadow: 0 7px 24px -4px #a9adb1;
            border-radius: 72px;
            margin: auto;
          }
          h5 {
            font-size: 11.5px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.27;
            letter-spacing: normal;
            text-align: center;
            color: #1b2437;
            margin-top: 10px;
          }
        }
      }
    }
    .bloc-next {
      margin-top: 16px;
      text-align: center;
      width: 100%;
      float: left;
      a {
        box-shadow: 0 1px 20px -1px rgba(90, 202, 195, 0.5);
        background-image: -webkit-linear-gradient(54deg, #72dcd6, #3eb6ad);
        background-image: -webkit-linear-gradient(36deg, #72dcd6, #3eb6ad);
        background-image: linear-gradient(54deg, #72dcd6, #3eb6ad);
        color: #fff;
        padding: 5px 40px;
        display: inline-block;
        border-radius: 20px;
        margin-bottom: 15px;
        font-weight: 600;
      }
    }
  }

  &.OverlayPeerChallenge {
    .header-center {
      float: left;
      width: calc(100% - 404px);
      text-align: center;
      .challenge-request {
        display: inline-block;
        //border: solid 1px transparent;
        position: relative;
        overflow: hidden;
        height: 40px;
        border-radius: 20.3px;
        border: 1px solid #3eb6ad;
        margin-top: 11px;
        a {
          font-family: "Montserrat", sans-serif;
          font-size: 10px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 0.8px;
          padding: 12.5px 25px;
          display: inline-block;
          position: relative;
          border-radius: 27px;
          overflow: hidden;
          color: #3eb6ad;
          &.active {
            color: #ffffff;
            background: #3eb6ad;
            &:before {
              content: "";
              position: absolute;
              left: 0;
              right: 0;
              top: 0;
              bottom: 0;
              border-radius: 31px;
              border: 2px solid #fff;
            }
          }
        }
      }
    }
    .myRequest {
      padding: 60px 70px 0;
      .bloc-myRequest-width {
        width: 33.33%;
        float: left;
        .bloc-myRequest {
          margin: 0 20px 50px;
          border-radius: 14px;
          box-shadow: -3px 10px 36px 0 rgba(0, 0, 0, 0.12);
          background-color: #f8f8f8;
          float: left;
          width: calc(100% - 40px);
          padding: 22px 12px;
          h3 {
            font-size: 18px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 0.6;
            letter-spacing: -0.1px;
            text-align: center;
            color: #3eb6ad;
            margin: 0 0 25px;
          }
          .request-top {
            float: left;
            width: 100%;
            margin-bottom: 10px;
            .request-bloc {
              width: 50%;
              float: left;
              padding: 0 18px;
              text-align: center;
              img {
                width: 66px;
                height: 66px;
                border-radius: 3px;
                box-shadow: 0px 0px 15px 3px #d4d3d4;
                margin: 0 auto;
              }
              h4 {
                font-size: 14px;
                font-weight: 600;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #1f2022;
                margin-top: 12px;
              }
            }
          }
          h5 {
            font-size: 15px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.29;
            letter-spacing: normal;
            text-align: center;
            color: #1b2437;
            margin: 0;
          }
          .request-bottom {
            float: left;
            width: 100%;
            text-align: center;
            margin-top: 17px;
            a {
              border-radius: 16px;
              box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.17);
              border: solid 4px #ffffff;
              margin: 0 7px;
              text-align: center;
              padding: 5px 22px 3px;
              i {
                font-size: 20px;
                color: #fff;
              }
              &.request-valid {
                background: #02da3f;
              }
              &.request-remove {
                background: #fc0e09;
              }
            }
          }
        }
      }
    }
    .mychallenges {
      padding: 35px 95px 20px;
      .bloc-mychallenge-width {
        float: left;
        width: 33.33%;
        .bloc-mychallenge {
          border-radius: 8px;
          box-shadow: 2px 3px 10px 0 rgba(0, 0, 0, 0.1);
          background-color: #ffffff;
          margin: 0 25px 40px;
          padding-top: 25px;
          border-bottom: 6px solid #ec735b;
          float: left;
          width: calc(100% - 50px);
          &.bloc-mychallenge-add {
            min-height: 305px;
            border-bottom: none;
            text-align: center;
            padding-top: 133px;
            .mychallenge-add {
              font-family: "Montserrat", sans-serif;
              font-size: 14px;
              font-weight: bold;
              font-style: normal;
              font-stretch: normal;
              line-height: normal;
              letter-spacing: 0.6px;
              text-align: center;
              color: #ffffff;
              text-transform: uppercase;
              border-radius: 50.4px;
              background-color: #fda800;
              padding: 12px 20px;
              i {
                font-size: 19px;
                color: #ffffff;
                margin-right: 5px;
                vertical-align: middle;
              }
            }
          }
          h3 {
            font-size: 18px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 0.6;
            letter-spacing: -0.1px;
            text-align: center;
            color: #3eb6ad;
            margin: 0 0 28px;
          }
          .mychallenge-one {
            float: left;
            width: 100%;
            margin-bottom: 17px;
            padding: 0 20px;
            .mychallenge-img {
              width: 66px;
              height: 66px;
              float: left;
              border-radius: 3px;
              box-shadow: 0px 0px 15px 3px #d4d3d4;
              margin-right: 15px;
              img {
                width: 66px;
                height: 66px;
                float: left;
                border-radius: 3px;
              }
            }
            h4 {
              font-size: 14px;
              font-weight: 600;
              font-style: normal;
              font-stretch: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #1f2022;
            }
            h5 {
              font-size: 12px;
              font-weight: 500;
              font-style: normal;
              font-stretch: normal;
              line-height: 0.9;
              letter-spacing: -0.1px;
              color: #3eb6ad;
            }
          }
          .mychallenge-bottom {
            margin-top: 3px;
            border-top: 2px solid #f3f3f3;
            float: left;
            width: 100%;
            text-align: center;
            h4 {
              font-size: 14px;
              font-weight: 500;
              font-style: normal;
              font-stretch: normal;
              line-height: 1.29;
              letter-spacing: normal;
              text-align: center;
              color: #1b2437;
              margin-top: 12px;
            }
            p {
              font-size: 12px;
              font-weight: normal;
              font-style: normal;
              font-stretch: normal;
              line-height: normal;
              letter-spacing: normal;
              text-align: center;
              color: #1b2437;
            }
          }
        }
      }
      &.group{
        display: flex;
        justify-content: flex-start;
        .challenge_item{
          height: auto;
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          background-color: #fff;
          padding: 0.5rem 0.7rem;
          border-radius: 5px;
          box-shadow: 0 5px 48px 0 rgba(46, 104, 208, 0.1);
          flex: 0 0 24%;
          margin-right: 1%;
          max-width: 24%;
          .loading_image{
            position: absolute;
            width: 100%;
            height: 80%;
            left: 0%;
            top: 10%;
            background: gray;
            opacity: 0.5;
            border-radius: 12px;
            @media (max-width: 767px) {
              left: 25%;
              width: 50%;
            }
          }
          &:last-of-type {
            margin-right: 0%;
          }
          img.medal {
              width: 40%;
              height: 80px;
          }

          .c_title {
              display: block;
              display: -webkit-box;
              height: $font-size*$line-height*$lines-to-show;
              font-size: 15px;
              font-weight: 600;
              max-width: 80%;
              margin-bottom: 0.2rem;
              margin: 0 auto;
              font-size: $font-size;
              line-height: $line-height;
              -webkit-line-clamp: $lines-to-show;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
          }

          .c_level {
              font-size: 0.8rem;
              display: block;
              width: 100%;
              text-align: center;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;

              .level {
                  color: $primary_gray;
              }
          }

          .grades {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 100%;
              text-align: center;
              display: flex;
              flex-direction: column;
              font-size: 0.75rem;
              text-align: center;
              .span{
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 100%;
              }
          }

          .all_btn {
              margin: 0.8rem 0;

              .btn {
                  text-transform: uppercase;
              }
          }

          .c_footer {
              width: 100%;
              text-align: right;
              /*display: flex;
              justify-content: space-between;
              align-items: flex-end;*/

              // .c_icons {
              //     img {
              //         width: 1rem;
              //         margin-right: 0.6rem;
              //     }
              // }

              .c_btn {
                  button {
                      font-size: 0.75rem;
                  }
              }
          }

          &.all,
          &.group {
              .all_btn {
                  margin: 0.8rem 0;

                  .btn {
                      text-transform: uppercase;
                      font-size: 0.75rem;
                  }
              }
          }
        }
      }
    }
  }
  &.OverlayGroupClasseChallenge{
    &>div{
      max-width: 880px;
      width: 100%;
      margin-left: 0;
      transform: translateX(-50%);
      .content.mychallenges{
        padding: 20px 15px;
        .challenge_item{
          margin-right: 1.6%;
          &:nth-child(4n){
            margin-right: -1%;
          }
        }
        .content-scroll{
          .bloc-content{
            position: relative;
            height: 210px;
            .exercice-active{
              position: absolute;
              right: 5px;
              top: 5px;
              width: 27px;
              height: 27px;
              border-radius: 27px;
              background-color: #3eb6ad;
              text-align: center;
              display: block;
              i{
                color: #fff;
                font-size: 17px;
                line-height: 27px;
              }
            }
            >p{
              font-family: Montserrat,sans-serif;
              font-size: 13px;
              font-weight: 400;
              font-style: normal;
              font-stretch: normal;
              letter-spacing: .5px;
              text-align: center;
              line-height: 27px;
              color: black;
              text-transform: uppercase;
            }
          }
        }
      }
      @media screen and (min-width: 1200px) {
        .content.mychallenges{
          flex-wrap: wrap;
          height: 480px;
          overflow-y: scroll;
          margin-bottom: 10px;
          .challenge_item{
            margin-bottom: 10px;
            height: 255px;
            &:last-of-type{
              margin-bottom: 20px;
            }
            .c_footer{
              position: absolute;
              bottom: 15px;
              padding: 0 10px;
              .c_btn{
                display: flex;
                justify-content: space-around;
                .btn-challenge{
                  max-width: 100px;
                  padding: 0.2rem 0.8rem;
                  width: max-content;
                  min-width: 65px;
                }
              }
            }
          }
          &::-webkit-scrollbar-track{
              -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3) !important;
              background-color: #F5F5F5 !important;
          }
          &::-webkit-scrollbar{
              width: 5px !important;
              height: 3px !important;
              background-color: #F5F5F5 !important;
          }
          &::-webkit-scrollbar-thumb{
              background-color: #3eb6ad !important;
          }
        }
      }
      @media screen and (max-width: 1199px) and (min-width: 768px) {
        width: 98%;
        left: 0% !important;
        margin: 1%;
        top: 0 !important;
        height: 97%;
        border-radius: 4px !important;
        .closeModal{
          top: -8px;
          right: -9px;
        }
        .content.mychallenges{
          padding: 25px 20px 20px;
          flex-wrap: wrap;
          height: 87%;
          overflow: scroll;
          .challenge_item{
            margin-left: 0;
            margin-right: 2%;
            margin-bottom: 10px;
            height: 255px;
            flex: 0 0 32%;
            &:nth-child(3n+0){
              margin-right: 0;
            }
            .c_footer{
              position: absolute;
              bottom: 15px;
              padding: 0 10px;
              .c_btn{
                display: flex;
                justify-content: space-around;
                .btn-challenge{
                  max-width: 100px;
                  padding: 0.2rem 0.8rem;
                  width: 50%;
                  min-width: 70px;
                }
              }
            }
          }
          .content-scroll{
            overflow-y: scroll;
            height: 100%;
            flex-wrap: wrap;
            display: flex;
            &>div{
              height: max-content !important;
            }
          }
        }
      }
      @media screen and (max-width: 767px) {
        width: 100%;
        left: 0% !important;
        margin-left: 0;
        top: 0 !important;
        height: 100%;
        border-radius: 0 !important;
        .closeModal{
          top: -6px;
          right: -4px;
        }
        .content.mychallenges{
          padding: 25px 20px 20px;
          flex-wrap: wrap;
          height: 87%;
          overflow: scroll;
          .challenge_item{
            margin-left: 0;
            margin-right: 2%;
            margin-bottom: 10px;
            height: 255px;
            flex: 0 0 49%;
            padding: 0.5rem 0.3rem;
            &:nth-child(2n+0){
              margin-right: 0;
            }
            .c_footer{
              position: absolute;
              bottom: 15px;
              padding: 0 0.3rem;
              .c_btn{
                display: flex;
                justify-content: space-around;
                .btn-challenge{
                  max-width: 100px;
                  padding: 0.2rem 0.8rem;
                  width: 50%;
                  min-width: 70px;
                }
              }
            }
          }
          .content-scroll{
            .bloc-content{
              height: 180px;
              &>a{
                width: 60px;
                height: 60px;
                line-height: 60px;
                margin-top: 11px;
              }
              &>h4{
                margin: 7px 0 10px;
                height: 30px;
                font-size: 11px;
              }
            }
          }
        }
      }
    }
    &.OverlayGroupClasseChallenge2{
      &>div{
        @media (max-width: 1199px) {
          left: 50% !important;
        }
        @media (max-width: 1000px){
          width: 100%;
          left: 0% !important;
          transform: unset !important;
          top: 0 !important;
          height: 100%;
          margin: 0;
          max-width: 100%;
        }
        .content.mychallenges{
          @media (max-width: 767px) and (min-width: 551px) {
            .challenge_item{
              margin-right: 2% !important;
              width: 32%;
              max-width: 32%;
            }
          }
          @media (max-width: 550px) and (min-width: 400px) {
            .challenge_item{
              margin-right: 2% !important;
              width: 48%;
              max-width: 48%;
            }
          }
          @media (max-width: 399px){
            .challenge_item{
              width: 70%;
              max-width: 70%;
              flex: 1 !important;
              margin-left: 15% !important;
            }
          }
        }
      }
    }
  }
  &.OverlayChallengeScore {
    &.OverlayMatchAnswers{
        > div{
          max-width: initial;
          top:20px!important;
          bottom:20px!important;
          left:20px!important;
          right:20px!important;
          margin: 0px;
          display: flex;
          flex-direction: column;
          border-radius: 14px!important;
          height:auto;
          .header{
            float: none;
            height: 55px;
            box-shadow: -3px 10px 36px 0 rgba(0, 0, 0, 0.04);
            background-color: #5975f7;
            padding:0 20px!important;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .headerLeft{
              font-family: Montserrat;
              font-size: 18px;
              font-weight: 500;
              color: #ffffff;
              img{
                width:50px;
                height:50px;
                margin-right:15px;
              }
            }
            .headerRight{
              height: 30px;
              border-radius: 15px;
              border: solid 1px #ffffff;
              span{
                display: inline-block;
                width:28px;
                height:28px;
                border-radius: 28px;
                text-align: center;
                line-height: 28px;
                font-size: 12px;
                color: rgba(255,255,255,.8);
                font-weight: 400;
                &.active{
                  background: #FFF;
                  color: #5975f7;
                  font-weight: 600;
                }
              }
            }
            .feedback-exercise{
              .close_{
                float: right;
              }
            }
          }
          .content{
            padding:0;
            float:none;
            flex:1;
            .challengeexercice{
              height: 100%;
              display: flex;
              flex-direction: column;
              .exercise_xblock_content{
                flex:1;
                @media (min-width: 1200px) {
                  height: calc(100vh - 500px);
                }
                .xblock_viewer{
                  height: 100%;
                  .xblock-student_view.open_question_exercise{
                    .container_exercice{
                      flex: unset;
                      .response_question {
                        .question{
                          max-height: 50%;
                          &.h_100{
                            max-height: 100%;
                          }
                          >p{
                            height: 95%;
                            overflow-y: auto;
                            padding: 15px;
                            width: 100%;
                          }
                        }
                        .editor{
                          max-height: 100%;
                          min-height: 50%;
                        }
                      }
                    }
                    .description_action{
                      display: none;
                    }
                  }
                  .xblock-student_view.open_question_v2_exercise{
                    .container_exercice{
                      flex: unset;
                      .response_question {
                        .question{
                          max-height: 50%;
                          &.h_100{
                            max-height: 100%;
                          }
                          >p{
                            height: 95%;
                            overflow-y: auto;
                            padding: 15px;
                            width: 100%;
                          }
                        }
                        .editor{
                          max-height: 100%;
                          min-height: 50%;
                        }
                      }
                    }
                    .description_action{
                      display: none;
                    }
                  }
                }
              }
              .contentBottom{
                height: 50px;
                background-color: #fbfbfb;
                width:100%;
                /*padding: 23px 0;*/
                text-align: center;
                border-bottom-left-radius: 14px;
                border-bottom-right-radius: 14px;
                display: flex;
                justify-content: center;
                align-items: center;
                button{
                  width: 111px;
                  height: 30px;
                  object-fit: contain;
                  border-radius: 49.5px;
                  background-color: #3eb6ad;
                  margin:0 5px;
                  display: inline-block;
                  font-size: 12px;
                  font-weight: 600;
                  color: #FFF;
                  text-align: center;
                  line-height: 28px;
                  &.submit{
                    width: 152px;
                    border: solid 1px #d1dbe2;
                    background-color: #ffffff;
                    color: #3eb6ad;
                    line-height: 28px;
                  }
                }
                img{
                  &.replay_ke{
                    // position: absolute;
                    //top: 507px;
                    //right: 28px;
                    cursor: pointer;
                    margin: auto !important;
                    padding-right: 184px !important;
                 
                  }
                  &.replay{
                    position: absolute;
                    top: 507px;
                    right: 28px;
                    cursor: pointer;
                 
                  }
                }
              }
              .contentBottomParent{
                display: flex;
                justify-content: space-between;
              }
            }
          }
      }
    }
    > div {
      max-width: 1100px;
      .header {
        padding: 0 24px;
      }
      .content {
        padding: 35px 24px;
        .daillyChallenge{
          .score-button{
            float: left;
            width:100%;
            text-align: right;
            margin: 0 30px 15px 0;
            display:flex;
            justify-content: flex-end;
            >a, >div{
              position:relative;
              height: 34px;
              >div{
                width: 110px;
                height: 30px;
                border-radius: 18px;
                border: solid 2px #3eb6ad;
                background-color: rgba(255, 255, 255, 0.8);
                font-family: Montserrat;
                font-size: 13px;
                font-weight: bold;
                letter-spacing: 0.86px;
                text-align: center;
                color: #3eb6ad;
                position: relative;
                margin-left: 22px;
                line-height: 26px;
                &.for_challenge{
                  width: 210px;
                }
              }
              i{
                margin: 6px 7px 0 0;
                font-size: 14px;
                float: right;
                color: #3eb6ad;
              }
              .scoreList{
                margin:0;
                padding:0;
                border: solid 2px #3eb6ad;
                border-radius: 7px;
                position: absolute;
                right: 0;
                top:34px;
                width:110px;
                background: #FFF;
                overflow: hidden;
                display: none;
                &.for_challenge{
                  width: 210px;
                }
                li{
                  margin:0;
                  padding:0;
                  a{
                    float: left;
                    width: 100%;
                    padding: 4px;
                    font-family: Montserrat;
                    font-size: 13px;
                    font-weight: bold;
                    letter-spacing: 0.86px;
                    text-align: center;
                    color: #3eb6ad;
                    &:hover{
                      background: #fafafa;
                    }
                  }
                }
              }
              &:hover{
                .scoreList{
                  display: block;
                }
              }
            }
            
          }
        }
        
        .challenge-score {
          border-radius: 14px;
          box-shadow: -3px 10px 36px 0 rgba(0, 0, 0, 0.12);
          border: solid 3px #ffcd3d;
          background-color: #ffffff;
          background-image: url("../img/leader-board.png");
          background-repeat: no-repeat;
          background-position: 60px 260px;
          float: left;
          width: 100%;
          padding: 26px 60px 120px;
          text-align: center;
          .avatar-challenge {
            span {
              width: 97px;
              height: 97px;
              border-radius: 50%;
              box-shadow: 0px 0px 10px -1px rgba(0, 0, 0, 0.25);
              display: inline-block;
              img {
                width: 87px;
                height: 87px;
                border-radius: 50%;
              }
            }
          }
          .challenge-score-two,
          .challenge-score-three {
            padding-top: 59px;
          }
          .challenge-score-two,
          .challenge-score-one,
          .challenge-score-three {
            float: left;
            width: 33.33%;
            p {
              margin: 0;
            }
            h5 {
              font-family: "Montserrat", sans-serif;
              font-size: 14px;
              font-weight: bold;
              font-style: normal;
              font-stretch: normal;
              line-height: normal;
              letter-spacing: normal;
              text-align: center;
              color: #737272;
            }
            h6 {
              font-family: "Montserrat", sans-serif;
              font-size: 13px;
              font-weight: 600;
              font-style: normal;
              font-stretch: normal;
              line-height: normal;
              letter-spacing: 0.8px;
              color: #ffffff;
              border-radius: 11.5px;
              box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.18);
              border: solid 1px #ffffff;
              background-color: #ffcd3d;
              display: inline-block;
              padding: 4px 15px;
              margin-top: 12px;
            }
          }
          .challenge-score-two {
            .avatar-challenge {
              span {
                border: 5px solid #e0e0e1;
                img {
                  border: 5px solid #c5c6c7;
                }
              }
            }
          }

          .challenge-score-one {
            .avatar-challenge {
              span {
                border: 5px solid #f6da7c;
                img {
                  border: 5px solid #f6c525;
                }
              }
            }
          }

          .challenge-score-three {
            .avatar-challenge {
              span {
                border: 5px solid #fac191;
                img {
                  border: 5px solid #faa864;
                }
              }
            }
          }
        }
        .select-challenge {
          text-align: right;
          margin-bottom: 15px;
          p {
            font-family: "Montserrat", sans-serif;
            font-size: 12.1px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: 0.9px;
            display: inline-block;
            color: #3eb6ad;
            border-radius: 17.1px;
            border: solid 2.2px #3eb6ad;
            background-color: #ffffff;
            padding: 6px 15px;
            margin-left: 15px;
          }
        }
        .score-friends {
          .content-scroll {
            padding: 10px;
            .scrolling{
              height: auto;
              overflow: hidden;
            }
          }
          .bloc-score-friends {
            margin-bottom: 15px;
            float: left;
            width: 100%;
            .score-number {
              width: 31px;
              height: 31px;
              float: left;
              box-shadow: -4px 5px 13px 0 rgba(0, 0, 0, 0.06);
              border: solid 2.3px #fece50;
              background-color: #ffffff;
              border-radius: 50%;
              text-align: center;
              line-height: 26px;
              font-family: "Montserrat", sans-serif;
              font-size: 14px;
              font-weight: bold;
              font-style: normal;
              font-stretch: normal;
              letter-spacing: normal;
              color: #555555;
              margin-right: 20px;
              margin-top: 17px;
            }
            .score-right {
              float: left;
              padding: 5px 5px 4px;
              border-radius: 40.7px;
              box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.12);
              background-color: #ffffff;
              width: calc(100% - 51px);
              .score-img {
                width: 52px;
                height: 52px;
                float: left;
                img {
                  width: 52px;
                  height: 52px;
                  border-radius: 52px;
                }
              }
              h5 {
                font-family: "Montserrat", sans-serif;
                font-size: 12px;
                font-weight: 600;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: 0.4px;
                color: #555555;
                float: left;
                margin-left: 10px;
                margin-top: 18px;
              }
              h6 {
                font-family: "Montserrat", sans-serif;
                font-size: 13px;
                font-weight: bold;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: 0.5px;
                color: #6c6c6c;
                border: 1px solid #fef8e8;
                float: right;
                padding: 5px;
                border-radius: 40px;
                display: inline-block;
                margin: 0;
                .one {
                  border: 1px solid #fdefce;
                  border-radius: 40px;
                  padding: 4px;
                  display: inline-block;
                  .two {
                    border: 2px solid #ffdf8e;
                    border-radius: 40px;
                    padding: 5px 10px;
                    display: inline-block;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &.OverlayCategoriesActivities{
    &>div{
      max-width: 880px;
      width: 100%;
      margin-left: 0;
      transform: translateX(-50%);
      .content.categories_activities{
        padding: 20px 15px;
        .category_item, .activity_item{
          margin-right: 1.6%;
          &:nth-child(4n){
            margin-right: -1%;
          }
        }
        .content-scroll{
          .bloc-content{
            position: relative;
            height: 210px;
            a{
              width: 100%;
              min-width: 160px;
              height: 60%;
              min-height: 125px;
              img{
                width: 80%;
                height: 100%;
              }
            }
            h4{
              width: 90%;
              margin-left: 5%;
              font-weight: bold;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            >p{
              font-family: Montserrat,sans-serif;
              font-size: 13px;
              font-weight: 400;
              font-style: normal;
              font-stretch: normal;
              letter-spacing: .5px;
              text-align: center;
              line-height: 27px;
              color: black;
              text-transform: uppercase;
            }
          }
        }
      }
      @media screen and (min-width: 1200px) {
        .content.categories_activities{
          flex-wrap: wrap;
          height: 480px;
          overflow-y: scroll;
          margin-bottom: 10px;
          .category_item, .activity_item{
            margin-bottom: 10px;
            height: 255px;
            &:last-of-type{
              margin-bottom: 20px;
            }
            .c_footer{
              position: absolute;
              bottom: 15px;
              padding: 0 10px;
              .c_btn{
                display: flex;
                justify-content: space-around;
                .btn-challenge{
                  max-width: 100px;
                  padding: 0.2rem 0.8rem;
                  width: max-content;
                  min-width: 65px;
                }
              }
            }
          }
          &::-webkit-scrollbar-track{
              -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3) !important;
              background-color: #F5F5F5 !important;
          }
          &::-webkit-scrollbar{
              width: 5px !important;
              height: 3px !important;
              background-color: #F5F5F5 !important;
          }
          &::-webkit-scrollbar-thumb{
              background-color: #3eb6ad !important;
          }
        }
      }
      @media screen and (max-width: 1199px) and (min-width: 768px) {
        width: 98%;
        left: 0% !important;
        margin: 1%;
        top: 0 !important;
        height: 97%;
        border-radius: 4px !important;
        .closeModal{
          top: -8px;
          right: -9px;
        }
        .content.categories_activities{
          padding: 25px 20px 20px;
          flex-wrap: wrap;
          height: 87%;
          overflow: scroll;
          .category_item, .activity_item{
            margin-left: 0;
            margin-right: 2%;
            margin-bottom: 10px;
            height: 255px;
            flex: 0 0 32%;
            &:nth-child(3n+0){
              margin-right: 0;
            }
            .c_footer{
              position: absolute;
              bottom: 15px;
              padding: 0 10px;
              .c_btn{
                display: flex;
                justify-content: space-around;
                .btn-challenge{
                  max-width: 100px;
                  padding: 0.2rem 0.8rem;
                  width: 50%;
                  min-width: 70px;
                }
              }
            }
          }
          .content-scroll{
            overflow-y: scroll;
            height: 100%;
            flex-wrap: wrap;
            display: flex;
            &>div{
              height: max-content !important;
            }
          }
        }
      }
      @media screen and (max-width: 767px) {
        width: 100%;
        left: 0% !important;
        margin-left: 0;
        top: 0 !important;
        height: 100%;
        border-radius: 0 !important;
        .closeModal{
          top: -6px;
          right: -4px;
        }
        .content.categories_activities{
          padding: 25px 20px 20px;
          flex-wrap: wrap;
          height: 87%;
          overflow: scroll;
          .category_item, .activity_item{
            margin-left: 0;
            margin-right: 2%;
            margin-bottom: 10px;
            height: 255px;
            flex: 0 0 49%;
            padding: 0.5rem 0.3rem;
            &:nth-child(2n+0){
              margin-right: 0;
            }
            .c_footer{
              position: absolute;
              bottom: 15px;
              padding: 0 0.3rem;
              .c_btn{
                display: flex;
                justify-content: space-around;
                .btn-challenge{
                  max-width: 100px;
                  padding: 0.2rem 0.8rem;
                  width: 50%;
                  min-width: 70px;
                }
              }
            }
          }
          .content-scroll{
            .bloc-content{
              height: 180px;
              &>a{
                width: 60px;
                height: 60px;
                line-height: 60px;
                margin-top: 11px;
              }
              &>h4{
                margin: 7px 0 10px;
                height: 30px;
                font-size: 11px;
              }
            }
          }
        }
      }
    }
  }
  &.OverlayExams{
    &>div{
      max-width: 880px;
      width: 100%;
      margin-left: 0;
      transform: translateX(-50%);
      .content.exams{
        padding: 20px 15px;
        .category_item, .exam_item{
          margin-right: 1.6%;
          &:nth-child(4n){
            margin-right: -1%;
          }
        }
        .content-scroll{
          .bloc-content{
            position: relative;
            height: 210px;
            a{
              width: 100%;
              min-width: 160px;
              height: 60%;
              min-height: 125px;
              img{
                width: 80%;
                height: 100%;
              }
            }
            h4{
              width: 90%;
              margin-left: 5%;
              font-weight: bold;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            >p{
              font-family: Montserrat,sans-serif;
              font-size: 13px;
              font-weight: 400;
              font-style: normal;
              font-stretch: normal;
              letter-spacing: .5px;
              text-align: center;
              line-height: 27px;
              color: black;
              text-transform: uppercase;
            }
          }
        }
      }
      @media screen and (min-width: 1200px) {
        .content.exams{
          flex-wrap: wrap;
          height: 480px;
          overflow-y: scroll;
          margin-bottom: 10px;
          .category_item, .exam_item{
            margin-bottom: 10px;
            height: 255px;
            &:last-of-type{
              margin-bottom: 20px;
            }
            .c_footer{
              position: absolute;
              bottom: 15px;
              padding: 0 10px;
              .c_btn{
                display: flex;
                justify-content: space-around;
                .btn-challenge{
                  max-width: 100px;
                  padding: 0.2rem 0.8rem;
                  width: max-content;
                  min-width: 65px;
                }
              }
            }
          }
          &::-webkit-scrollbar-track{
              -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3) !important;
              background-color: #F5F5F5 !important;
          }
          &::-webkit-scrollbar{
              width: 5px !important;
              height: 3px !important;
              background-color: #F5F5F5 !important;
          }
          &::-webkit-scrollbar-thumb{
              background-color: #3eb6ad !important;
          }
        }
      }
      @media screen and (max-width: 1199px) and (min-width: 768px) {
        width: 98%;
        left: 0% !important;
        margin: 1%;
        top: 0 !important;
        height: 97%;
        border-radius: 4px !important;
        .closeModal{
          top: -8px;
          right: -9px;
        }
        .content.exams{
          padding: 25px 20px 20px;
          flex-wrap: wrap;
          height: 87%;
          overflow: scroll;
          .category_item, .exam_item{
            margin-left: 0;
            margin-right: 2%;
            margin-bottom: 10px;
            height: 255px;
            flex: 0 0 32%;
            &:nth-child(3n+0){
              margin-right: 0;
            }
            .c_footer{
              position: absolute;
              bottom: 15px;
              padding: 0 10px;
              .c_btn{
                display: flex;
                justify-content: space-around;
                .btn-challenge{
                  max-width: 100px;
                  padding: 0.2rem 0.8rem;
                  width: 50%;
                  min-width: 70px;
                }
              }
            }
          }
          .content-scroll{
            overflow-y: scroll;
            height: 100%;
            flex-wrap: wrap;
            display: flex;
            &>div{
              height: max-content !important;
            }
          }
        }
      }
      @media screen and (max-width: 767px) {
        width: 100%;
        left: 0% !important;
        margin-left: 0;
        top: 0 !important;
        height: 100%;
        border-radius: 0 !important;
        .closeModal{
          top: -6px;
          right: -4px;
        }
        .content.exams{
          padding: 25px 20px 20px;
          flex-wrap: wrap;
          height: 87%;
          overflow: scroll;
          .category_item, .exam_item{
            margin-left: 0;
            margin-right: 2%;
            margin-bottom: 10px;
            height: 255px;
            flex: 0 0 49%;
            padding: 0.5rem 0.3rem;
            &:nth-child(2n+0){
              margin-right: 0;
            }
            .c_footer{
              position: absolute;
              bottom: 15px;
              padding: 0 0.3rem;
              .c_btn{
                display: flex;
                justify-content: space-around;
                .btn-challenge{
                  max-width: 100px;
                  padding: 0.2rem 0.8rem;
                  width: 50%;
                  min-width: 70px;
                }
              }
            }
          }
          .content-scroll{
            .bloc-content{
              height: 180px;
              &>a{
                width: 60px;
                height: 60px;
                line-height: 60px;
                margin-top: 11px;
              }
              &>h4{
                margin: 7px 0 10px;
                height: 30px;
                font-size: 11px;
              }
            }
          }
        }
      }
    }
  }
  > div {
    max-width: 1020px;
    left: 50% !important;
    margin-left: -510px;
    border-radius: 10px !important;
    padding: 0 !important;
    border: none !important;
    overflow: inherit !important;
    bottom: auto !important;
    .closeModal {
      position: absolute;
      top: -15px;
      right: -17px;
      border: 5px solid #fff;
      width: 40px;
      height: 40px;
      text-align: center;
      color: #fff;
      border-radius: 50%;
      font-size: 17px;
      line-height: 28px;
      background-color: #3eb6ad;
      box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.25);
      z-index:200;
      &.group{
        background-color: #00b2c7;
      }
      &.classe{
        background-color: #ffb2c7;
      }
      &.group_classe{
        background-color: #ffb2c7;
      }
      &.activities{
        background-color: #AAB2C7;
      }
      img {
        max-width: 13px;
      }
    }
    .header {
      background: #fff;
      height: 62px;
      float: left;
      width: 100%;
      padding: 0 14px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      .bloc-title {
        float: left;
        width: 178px;
        height: 76px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        background-color: #3eb6ad;
        text-transform: uppercase;
        position: relative;
        z-index: 9;
        &.group{
          background-color: #00b2c7;
        }
        &.classe{
          background-color: #ffb2c7;
        }
        &.activities{
          background-color: #8a78f8;
        }
        h3 {
          margin: 0;
          font-family: "Montserrat", sans-serif;
          font-size: 12px;
          font-weight: 600;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 0.8px;
          color: #ffffff;
          text-align: center;
          padding: 25px 0;
          &.title-superheror {
            padding: 23px 4px;
          }
        }
      }
      .backHeader {
        font-family: "Montserrat", sans-serif;
        font-size: 11px;
        font-weight: 800;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 1.1px;
        text-align: center;
        color: #9a9898;
        text-transform: uppercase;
        border-radius: 19px;
        border: solid 2px #3eb6ad;
        padding: 10px 14px 10px 11px;
        float: left;
        margin: 12px 23px 0 0;
        img {
          margin-right: 5px;
        }
      }
      .header-exercices {
        float: left;
        margin-left: 150px;
        margin-top: 22px;
        p {
          font-family: "Montserrat", sans-serif;
          font-size: 14px;
          font-weight: 600;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 0.5px;
          text-align: center;
          color: #555555;
        }
      }
      .header-right {
        float: right;
        margin-top: 20px;
        margin-right: 17px;
        .challenge-scores {
          font-family: "Montserrat", sans-serif;
          font-size: 12px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 1px;
          text-align: right;
          color: #3eb6ad;
          border-radius: 17px;
          border: 2px solid #3eb6ad;
          background-color: #ffffff;
          padding: 5px 17px;
        }
        .challenge-global {
          float: right;
          margin-left: 45px;
          margin-top: 3px;
          p {
            font-family: "Montserrat", sans-serif;
            font-size: 12px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: 0.5px;
            text-align: right;
            color: #6c6c6c;
            span {
              padding: 5px 33px;
              border-radius: 40.7px;
              border: solid 1px #ffdf8e;
              background-color: #ffffff;
              margin-left: 20px;
            }
          }
        }
      }
      .header-left {
        float: left;
        .back{
          margin-top: 12px;
          margin-left: 17px;
          box-shadow: 0px 0px 7px -3px #c5c7c9;
          padding: 10px;
          width: 40px;
          height: 40px;
          cursor: pointer;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .content {
      //box-shadow: -3px 10px 36px 0 rgba(0, 0, 0, 0.12);
      background-color: #f4f5f6;
      float: left;
      width: 100%;
      padding: 30px 60px 15px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;

      .challengeexercice {
        height: 400px;
        text-align: center;
        .view-student-xblock {
          height: 400px;
          position: relative;
        }
      }
      .content-scroll {
        height: 468px;
        overflow-y: auto;
        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          background-color: #f5f5f5;
        }

        &::-webkit-scrollbar {
          width: 5px;
          background-color: #f5f5f5;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #3eb6ad;
        }
      }
      .bloc-width {
        float: left;
        width: 20%;
        .bloc-content {
          object-fit: contain;
          border-radius: 4px;
          box-shadow: 0px 0px 13px -9px #8e8e8e;
          background-color: #ffffff;
          margin: 0 10px 20px;
          text-align: center;
          padding-bottom: 7px;
          a {
            width: 70px;
            height: 70px;
            display: inline-block;
            line-height: 70px;
            margin-top: 17px;
            img {
              max-width: 70px;
            }
          }
          h4 {
            margin: 11px 0 15px;
            font-family: "Montserrat", sans-serif;
            font-size: 14px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;

            text-transform: uppercase;
          }
          h5 {
            font-family: "Montserrat", sans-serif;
            font-size: 12px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #1b2437;
            margin: 0 0 6px;
          }
          p {
            font-family: "Montserrat", sans-serif;
            font-size: 11.5px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #b1bfcd;
            margin: 0;
          }
          &.bloc-maths {
            border: solid 1px #7cc2c3;
            border-bottom: 6px solid #7dc3c3;
            h4 {
              color: black;
            }
          }

          &.bloc-physique {
            border: solid 1px #7792c6;
            border-bottom: 6px solid #7792c6;
            h4 {
              color: #7792c6;
            }
          }

          &.bloc-chimie {
            border: solid 1px #ef745b;
            border-bottom: 6px solid #ef745b;
            h4 {
              color: #ef745b;
            }
          }

          &.bloc-arabe {
            border: solid 1px #46cdf1;
            border-bottom: 6px solid #46cdf1;
            h4 {
              color: #46cdf1;
            }
          }

          &.bloc-french {
            border: solid 1px #2858f3;
            border-bottom: 6px solid #2858f3;
            h4 {
              color: #2858f3;
            }
          }
        }
      }
      .bloc-next {
        margin-top: 16px;
        text-align: center;
        width: 100%;
        float: left;
        a {
          padding: 9px 58px;
          font-family: "Montserrat", sans-serif;
          font-size: 12px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 0.6px;
          text-align: center;
          color: #ffffff;
          text-transform: uppercase;
          background: #3eb6ad;
          border-radius: 16.7px;
          display: inline-block;
        }
      }
    }
  }
}
@media screen and (max-width: 1199px) {
  .OverlayDaillyChallenge{
    &.OverlayChallengeScore{
      &.OverlayMatchAnswers{
        &:before{
          background: none;
        }
        > div{
          border-radius: 0!important;
          height: 100%;
          top:0px!important;
          left:0px!important;
          right:0px!important;
          bottom:0px!important;
          display: flex;
          flex-direction: column;
          // overflow:auto!important;
          .header{
            float:none;
            margin: 0;
            border-radius: 0;
            width: 100%;
            height: 66px;
            padding: 8px 20px!important;
            .headerLeft{
              padding: 0;
              font-size: 16px;
              img{
                margin: 0;
              }
              .back{
                width: 31px;
                height: 31px;
                border-radius: 31px;
                border: 2px solid #3eb6ad;
                background: #FFF;
                text-align: center;
                line-height: 24px;
                float: left;
                opacity: 1;
                margin-right: 5px;
                margin-top: 9px;
                img{
                  width: 15px;
                  height: auto;
                  margin: 0;
                }
              }
            }
          }
          .content{
            float:none;
            padding: 5px;
            text-align: center;
            display: flex;
            flex-direction: column;
            height: calc(100% - 66px);
            .headerRight{
              height: 30px;
              border-radius: 15px;
              border: solid 1px rgba(62, 182, 173, 1);
              margin: 15px auto 30px auto;    
              display: inline-block;
              width: max-content;
              span{
                display: inline-block;
                width:28px;
                height:28px;
                border-radius: 28px;
                text-align: center;
                line-height: 28px;
                font-size: 12px;
                color: rgba( 161, 169, 179, 1);
                font-weight: 400;
                &.active{
                  background: rgba(62, 182, 173, 1);
                  color: #fff;
                  font-weight: 600;
                }
              }
            }
            .challengeexercice{
              flex: 1;
              height: 100%;
              display: flex;
              flex-direction: column;
              .view-student-xblock{
                height: 100%;
              }
              // .container_exercise .content-exercise-parent{
              //   height: calc(100% - 35px);
              // }
              .object.focus-mobile{
                position: fixed;
                top: 0px;
                width: 95%;
              }
              >div{
                width: 100%;
                &:first-child{
                  flex: 1;
                  height: calc(100% - 40px);
                }
                &.contentBottom{
                  background: transparent;
                  height: auto;
                  padding: 5px 0;
                  @media (orientation: portrait) {
                    visibility:hidden;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .selectInput {
    height: 35px;
  }
  
  .dialogOverlay {
    &.dialogFormOverlay {
      background: #fff;
      display: block;
      padding: 0;
      .dialog {
        &.dialogAddSchool{
          .add-school-form{
            .school-ligne{
              
              &.schoolServer{
                margin:0!important;
              }
              &.school-ligne-margin{
                .school-ligne-w50{
                  &.school100{
                    width:100%!important;
                    margin: 0 0 25px!important;
                  }
                }
              }
            }
          }
        }
        &.dialogPrinView {
          border-left: none;
          position: relative;
          min-height: calc(100vh + 10px);
          box-shadow: none;
          border: none;
          width: 100%;
          padding: 25px 40px;
          opacity: 1;
        }
        &.dialogBuyPack{
          max-width: 100%;
            width: 100%;
            min-height: 100vh;
            height: auto;
            border: none;
            box-shadow: none;
            h3{
              font-size: 20px;
              margin: 60px 0;
            }
            .buyPack{
              max-width: 640px;
              margin: auto;
            }
          .buyBack{
            left:20px;
            top: 20px;
          }
          .buyClose{
            right:20px;
            top: 20px;
          }
          .buyRight{
            width: 269px;
            height: 291px;
          }
          .buyLeft{
            width: 279px;
            height: 262px;
          }
          &.dialogBuyPackage{
            max-width: 100%;
            width: 100%;
            min-height: 100vh;
            border: none;
            box-shadow: none;
            .packageTop{
              max-width: 640px;
              margin: 40px auto;
              float: none;
              overflow: hidden;
              padding: 20px;
              h2{
                font-size: 25px;
                line-height: normal;
                font-weight: 400;
                color: #4c5361;
                &::before{
                  display: none;
                }
                img{
                  display: initial;
                  margin-right: 5px;
                }
              }
              a{
                width: 200px;
                height: 47px;
                line-height: 41px;
                margin-top: -5px;
              }
            }
            .packages{
              max-width: 640px;
              margin: auto;
            }
          }
        }
        &.dialogAddSchool {
          &.dialogAbs{
            width: 100%;
            padding: 25px 20px;
            min-width: 100%;
          }
          &.dialogParents{
            max-width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            &.dialogParentsInformations{
              .add-school-form{
                .upload{
                  padding-bottom: 20px;
                }
                .school-ligne{
                  .school-ligne-w100{
                    margin-bottom: 15px;
                  }
                  &.school-ligne-center{
                    position: relative;
                    left: auto;
                    right: auto;
                  }
                }
              }
            }
            &::before{
              right: 20px;
            }
            .schoolClose{
              box-shadow: 0 10px 20px 0 rgba(90, 202, 195, 0.5);
            background-image: -webkit-linear-gradient(225deg, #72dcd6, #3eb6ad);
            background-image: linear-gradient(225deg, #72dcd6, #3eb6ad);
            }
            .add-school{
              max-width: 600px;
              margin: auto;
            }
          }
          &.dialogAddProfessor {
            &.dialogNewClasse {
              padding: 25px 45px;
              .newClassesTop {
                margin-top: 25px;
                margin-bottom: 0;
                img {
                  max-width: 70px;
                }
                span {
                  width: 140px;
                  margin-left: 15px;
                }
              }
              .add-school-form {
                .school-ligne {
                  &.school-ligne-center {
                    &.schoolBottom {
                      position: absolute;
                      bottom: 10px;
                      left: 0;
                      right: 0;
                      button {
                        &.add {
                          &.cancel {
                            background: #fff;
                            color: #3eb6ad;
                            box-shadow: none;
                            border: solid 0.6px #d1dbe2;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            &:before {
              display: none;
            }
            .add-school-form {
              .school-ligne {
                margin-bottom: 25px;
                .intl-tel-input{
                  width:100%
                }
                &.school-ligne-margin {
                  margin: 0 0 25px;
                }
              }
            }
            .newClassesTop {
              float: left;
              width: 100%;
              margin-bottom: 25px;
              img {
                max-width: 55px;
              }
              span {
                width: 115px;
                height: 30px;
                text-align: center;
                object-fit: contain;
                border-radius: 49.5px;
                background-color: #3eb6ad;
                font-family: Montserrat;
                font-size: 12px;
                font-weight: 500;
                font-style: normal;
                font-stretch: normal;
                line-height: 30px;
                letter-spacing: normal;
                color: rgba(255, 255, 255, 0.8);
                display: inline-block;
                margin-left: 10px;
              }
            }
          }
          border-left: none;
          position: relative;
          min-height: calc(100vh + 10px);
          box-shadow: none;
          border: none;
          width: 100%;
          padding: 25px 40px;
          opacity: 1;
          .schoolClose {
            background: #d8dfe5;
            box-shadow: none;
            width: 35px;
            height: 35px;
            line-height: 35px;
            font-size: 34px;
            top: 15px;
            right: 15px;
          }
          h2 {
            font-size: 16px;
            margin-bottom: 35px;
          }
          .add-school-form {
            .school-ligne {
              margin-bottom: 25px;
              &.school-ligne-margin {
                margin: 0 0 25px;
                width: 100%;
                .w100 {
                  width: 100% !important;
                  margin: 0 0 25px !important;
                }
                .school-ligne-w50 {
                  padding: 0;
                  width: calc(50% - 10px) !important;
                  margin: 0 10px 0!important;
                  &:nth-child(1) {
                    margin-left:0!important;
                  }
                  &:nth-child(2) {
                    margin-right:0!important;
                  }
                  &:last-child {
                    margin-bottom: 0;
                  }

                  .flagSelect {
                    height: 35px;
                    .select {
                      height: 34px;
                      border: none;
                      border-radius: 4px!important;
                      border: none!important;
                      font-weight: 500;
                      font-family: Montserrat;
                      font-size: 14px;
                      color: #1b2437;
                      padding: 0 5px;
                      select {
                        height: 33.5px;
                      }
                      i {
                        top: 8px;
                      }
                    }
                  }
                }
              }
              &.school-ligne-diploma {
                margin-bottom: 25px;
              }
              &.school-ligne-center {
                position: absolute;
                bottom: 5px;
                left: 0;
                right: 0;
              }
              .error{
                color: red;
              }
              button:not(.btn) {
                height: 40px;
                &.add, &.cancel, &.save {
                  height: 40px;
                  &.new {
                    width: 100%;
                    margin: 0 0 20px;
                  }
                }
              }
              input {
                height: 35px;
              }
              .select{
                height: 35px;
                select{
                  height: 34px;
                  border-radius: 4px!important;
                  border: none!important;
                  font-weight: 500;
                  font-family: Montserrat;
                  font-size: 14px;
                  color: #1b2437;
                  padding: 0 10px;
                }
                i{
                  top: 10px;
                }
              }
              .RadioLegend {
                margin: 0 0 13px;
                width: 100%;
              }
            }
          }
          &.dialogAddProfessor {
            .add-school-form {
              .school-ligne {
                &.school-ligne.school-ligne-center {
                  position: relative;
                  left: auto;
                  bottom: auto;
                  right: auto;
                  margin-bottom: 20px;
                }
                &.school-ligne-classes {
                  margin-top: 15px;
                }
                .RadioLegend {
                  width: 25%;
                  > legend {
                    font-size: 11px;
                    margin-left: 3px;
                    margin-top: 4px;
                  }
                }
              }
            }
          }
        }
      }
      &.dialogFonctionalityDontExist{
        border-radius: 5%;
        background: rgba(100, 115, 140, 0.9);
        width: 100%;
        max-height: 100%;
        border-radius: 0;
        .dialogAddSchool{
          border-left: 10px solid #00b3a9;
        }
        @media screen and (max-width: 479px) {
          .dialogAddSchool{
            width: 90%;
            max-height: 300px;
            min-height: 300px;
            top: calc(50% - 150px);
            &::before{
              content: none;
            }
          }
        }
        @media screen and (max-width: 1199px) and (min-width: 480px){
          box-shadow: 1px 2px 19px 1px #bdbdbd;
          .dialogAddSchool{
            width: 90%;
            max-height: 300px;
            min-height: 300px;
            top: calc(50% - 150px);
            &::before{
              content: none;
            }
          }
        }
        
      }
    }
    &.dialogOverlayAddGroup {
      background: transparent;
    }
  }
  .OverlayDaillyChallenge {
    &.OverlayChangePassword {
      > div {
        max-width: 300px;
        margin-left: -150px;
        top: 80px !important;
        .content {
          padding: 25px 15px 17px;
        }
        .closeModal {
          top: -45px;
          right: 0;
        }
      }
      &.OverlayChangeImage {
        > div {
          max-width: 740px;
          margin-left: -370px;
          right: 0 !important;
        }
      }
    }
    &.OverlayTextbookExercice {
      > div {
        margin-left: -440px;
        .content-exercice {
          .content-exercice-width {
            width: 25%;
          }
        }
      }
    }
  }

  .Group {
    bottom: 8px;
    right: 8px;
    width: 50px;
  }
  .navigation-mobile {
    background: #67cbcb;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9999999;
    overflow: hidden;
    height: 100vh;
    .logo {
      text-align: center;
    }
    a.close-mobile {
      padding: 0 10px 1px;
      margin-left: 10px;
      border: 1px solid #ffffff;
      border-radius: 50%;
      display: inline-block;
      background: #3eb6ad;
      color: #ffffff!important;
      font-size: 22px;
      margin-top: 20px;
    }
    .content-mobile {
      height: calc(100vh - 150px);
      vertical-align: middle;
      display: table;
      width: 100%;
      .center-mobile {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        .translate {
          .translate-bloc {
            background: #42b8b0;
            display: inline-block;
            margin: 0 2px;
            padding: 7px 17px;
            border-radius: 40px;
            color: #fff;
            font-size: 10px;
            font-weight: bold;
            img {
              width: 10px;
              margin-left: 9px;
            }
          }
        }
        nav {
          max-width: 150px;
          ul {
            li {
              margin-left: 0;
              margin-bottom: 10px;
              width: 100%;
              a {
                width: 100%;
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }

  .logo {
    img {
      width: 100px;
    }
  }
  .header-right {
    text-align: right;
    margin-top: 13px;
    ul {
      margin: 0;
      padding: 0;
      li {
        display: inline-block;
        a {
          margin-left: 10px;
          border: 1px solid #ffffff;
          border-radius: 50%;
          display: inline-block;
          background: #3eb6ad;
          width: 40px;
          height: 40px;
          padding: 10px;
          img {
            width: 18px;
            display: block;
          }
        }
      }
    }
  }
  .signin {
    text-align: center;
    padding: 0;
    width: 150px;
    margin: 30px auto 0;
    li {
      background: #3eb6ad;
      width: 100%;
      padding: 4px 9px 7px;
      border-radius: 20px;
      margin-bottom: 10px;
      border: 2px solid #ffffff;
      &:last-child {
        margin-bottom: 0;
      }
      a {
        font-size: 10px;
        width: 100%;
        letter-spacing: 1px;
        text-transform: uppercase;

        img {
          width: 15px;
          margin-right: 5px;
        }
      }
    }
  }
  section{
    overflow-x: hidden!important;
  }
  header {
    padding-top: 5px;
  }
  .dialogMobile {
    background: rgba(100, 115, 140, 0.9);
    .dialog {
      border: none;
      padding: 5px 4px 7px 4px;
      border-radius: 15px;
      background: #f3f3f3;
      float: left;
      margin-top: 40px;
      max-width: 100%;
      &.dialogSignIn {
        &.dialogOubliePassword {
          min-width: 100%;
          max-width: 100%;
          padding: 20px 12px 20px 20px;
          .closeRegister {
            display: block;
          }
        }
      }
      .closeRegister {
        display: block;
        position: absolute;
        top: -37px;
        right: 0;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        color: #ffff;
        font-size: 20px;
        line-height: 23px;
        text-align: center;
        border: 5px solid #fff;
        background: #13bbb3;
        cursor: pointer;
      }
      .dialog-top {
        background: #13bbb3;
        border-radius: 25px;
        padding: 1px;
        float: left;
        width: 100%;
        a {
          width: 50%;
          float: left;
          text-align: center;
          font-size: 13px;
          letter-spacing: 1px;
          font-weight: normal;
          text-transform: uppercase;
          padding: 8px;
          border-radius: 28px;
          border: 2px solid transparent;

          color: rgba(255, 255, 255, 0.4);

          &.active {
            font-weight: bold;
            background: $primary;
            border: 2px solid $white;
            color: $white;
          }
        }
      }
      .dialog-content {
        float: left;
        width: 100%;
        padding: 25px 8px 25px 35px;
        .dialog-left {
          h3 {
            font-size: 14px;
            line-height: 1.6;
          }
          .textInput {
            label {
              font-size: 10px;
              color: #949494;
            }
          }
          button {
            width: 100px;
            padding: 4px 0 41px;
          }
          .password-forgot {
            a {
              font-size: 9px;
            }
          }
        }
        .radioLegend {
          span {
            padding-left: 0;
            padding-right: 5px;
            padding-bottom: 0;
            float: left;
          }
          legend {
            width: auto;
            float: left;
            display: inline-block;
            border: none;
            margin: 18px 39px 0 0;
          }
          &.radioLegendLast {
            legend {
              margin-right: 0;
            }
          }
        }
      }
      .dialog-right {
        margin-top: 40px;
        margin-bottom: -49px;
        margin-right: 0;
        span {
          margin-right: 0;
          &:before {
            left: 43px;
          }
        }
      }
    }
  }
  section {
    overflow: initial;
    &.one {
      box-shadow: 0px -10px 5px -12px rgba(0, 0, 0, 0.6);
      padding-top: 20px;
      position: relative;
      min-height: auto;
      &:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 0px;
        box-shadow: 0px -2px 6px 1px rgba(0, 0, 0, 0.1);
        z-index: 999;
      }
      .slider {
        margin: 5px 10px;
        .carousel {
          h3 {
            font-size: 25px;
          }
          .banner-anim {
            min-height: 435px;
          }
          .banner-anim-elem {
            min-height: 430px;
            .bg {
              min-height: 430px;
            }
          }
        }
      }
      .news-feed {
        h3 {
          color: #46bfb6;
          margin: 50px 0 10px;
          text-align: center;
        }
      }
      .before-one {
        top: -15px;
        left: -15px;
        right: -15px;
        bottom: auto;
      }
      .slider {
        box-shadow: -4px 22px 61px 0 rgba(0, 0, 0, 0.15);
      }
    }
    &.two {
      .subjects {
        h2 {
          font-size: 19px;
        }
        .subjects-bg {
          max-width: 730px;
          background-size: 730px auto;
          .subjects-bbg {
            padding: 40px 0 50px 10px;
            max-width: 564px;
          }
        }
        .arrow-next,
        .arrow-prev {
          top: 47%;
        }
      }
    }
    &.section-news-feeds {
      text-align: center;
      padding: 40px 0 85px;
      h3 {
        position: relative;
        margin: 0 0 53px;
        top: 0;
        border: 1.5px solid #fff;
        background: $primary;
        display: inline-block;
        padding: 12px 30px;
        border-radius: 50px;
        font-size: 17px;
      }
      .section-feeds {
        padding: 0 60px;
        .section-feed {
          max-width: 47%;
          .news-article {
            &:before {
              left: 5%;
              width: 90%;
              bottom: -85px;
            }
            p {
              font-size: 11px;
              line-height: 12px;
              &.news-date {
                display: none;
              }
              &.link {
                a {
                  font-size: 9px;
                  min-width: 110px;
                }
              }
            }
          }
          &.feed-left {
            margin-right: 30px;
          }
          &.feed-right {
            margin-left: 30px;
          }
        }
      }
    }
    &.koolskools-notes {
      padding: 35px 0 140px;
      .container-koolskools {
        max-width: 780px;
        h3 {
          margin-bottom: 43px;
        }
      }
      main {
        &:before {
          top: auto;
          bottom: 30px;
          left: -15px;
        }
      }
    }
  }
  footer {
    .bloc-footer {
      width: 33.33%;
    }
  }

  //Modal TextBook
  .OverlayTextbook {
    background-color: rgba(255, 255, 255, 1);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 99999;
    // &:before {
    //   content: "";
    //   width: 190px;
    //   height: 227px;
    //   position: absolute;
    //   bottom: 55px;
    //   left: 50%;
    //   margin-left: -95px;
    //   background: url("../img/textbook_bg01.png") no-repeat scroll transparent;
    //   z-index: 1;
    // }
    &:after {
      content: "";
      width: 467px;
      height: 318px;
      position: absolute;
      bottom: 70px;
      left: 50%;
      margin-left: -150px;
      background: url("../img/textbook_bg02.png") no-repeat scroll transparent;
    }

    > div {
      top: 0 !important;
      left: 0 !important;
      bottom: 0 !important;
      margin-left: 0;
      height: 100vh;
      max-width: 100%;
      bottom: auto !important;
      padding: 0 !important;
      border: none !important;
      border-radius: 0 !important;
      overflow: inherit !important;
      box-shadow: none;
      opacity: 1;
      float: left;
      width: 100%;
      .modal-fuseesLeft {
        width: 158px;
        height: 102px;
        position: absolute;
        bottom: 150px;
        left: 0;
        background: url("../img/textbook_bg03.png") no-repeat scroll transparent;
      }
      .modal-fuseesRight {
        width: 94px;
        height: 122px;
        position: absolute;
        bottom: 160px;
        right: 25px;
        background: url("../img/textbook_bg04.png") no-repeat scroll transparent;
      }
      .backModal {
        float: left;
        width: 100%;
        box-shadow: 0px 0px 7px -3px #c5c7c9;
        padding: 10px;
        margin-bottom: 30px;
        a {
          width: 30px;
          height: 30px;
          display: block;
          border-radius: 30px;
          border: 2px solid #41b4ac;
          text-align: center;
          line-height: 25px;
          i {
            color: #41b4ac;
            font-size: 15px;
          }
        }
      }
      .header {
        margin: 0 10px;
        border-radius: 35px;
        width: calc(100% - 20px);
        padding: 0 15px;
        ul {
          justify-content: space-between;
          li {
            a {
              font-size: 11px;
            }
          }
        }
      }
      .todoCompleted {
        div {
          height: 43px;
          a {
            font-size: 10.4px;
            padding: 14px 25px;
          }
        }
      }
      .exercices-table {
        table {
          thead {
            tr {
              
              th {
                padding-bottom: 15px;
                font-size: 10.5px;
              }
            }
          }
          tbody {
            tr {
              td {
                font-size: 9.5px;
                &:nth-child(3) {
                  width: 16%;
                }
              }
            }
          }
        }
      }
    }
  }

  //Modal ScholarBooks
  .OverlayScholarBooks {
    background-color: rgba(255, 255, 255, 1);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 99999;
    &:after {
      content: "";
      width: 467px;
      height: 318px;
      position: absolute;
      bottom: 70px;
      left: 50%;
      margin-left: -150px;
      background: url("../img/textbook_bg02.png") no-repeat scroll transparent;
    }

    > div {
      top: 0 !important;
      left: 0 !important;
      bottom: 0 !important;
      margin-left: 0;
      height: 100vh;
      max-width: 100%;
      bottom: auto !important;
      padding: 0 !important;
      border: none !important;
      border-radius: 0 !important;
      overflow: inherit !important;
      box-shadow: none;
      opacity: 1;
      float: left;
      width: 100%;
      .modal-fuseesLeft {
        width: 158px;
        height: 102px;
        position: absolute;
        bottom: 150px;
        left: 0;
        background: url("../img/textbook_bg03.png") no-repeat scroll transparent;
      }
      .modal-fuseesRight {
        width: 94px;
        height: 122px;
        position: absolute;
        bottom: 160px;
        right: 25px;
        background: url("../img/textbook_bg04.png") no-repeat scroll transparent;
      }
      .backModal {
        float: left;
        width: 100%;
        box-shadow: 0px 0px 7px -3px #c5c7c9;
        padding: 10px;
        margin-bottom: 30px;
        a {
          width: 30px;
          height: 30px;
          display: block;
          border-radius: 30px;
          border: 2px solid #41b4ac;
          text-align: center;
          line-height: 25px;
          i {
            color: #41b4ac;
            font-size: 15px;
          }
        }
      }
      .header {
        margin: 0 10px;
        border-radius: 35px;
        width: calc(100% - 20px);
        padding: 0 15px;
        ul {
          justify-content: space-between;
          li {
            a {
              font-size: 11px;
            }
          }
        }
      }
    }
  }
}

@media screen and(max-width: 991px) {
  .OverlayDaillyChallenge {
    &.OverlayTextbookExercice {
      > div {
        max-width: 700px;
        margin-left: -350px;
        .content-exercice {
          .content-exercice-width {
            width: 33.33%;
          }
        }
      }
    }
    &.OverlayFriends:not(.OverlaySchool) {
      > div {
        padding-left: 20px;
        padding-right: 20px;
        left: 30px !important;
        right: 30px !important;
        margin-left: 0 !important;
        .friend-content {
          .friend-content-bloc {
            .friend-bloc {
              width: 14.285%;
            }
          }
        }
      }
    }
  }
  section {
    &.section-news-feeds {
      &:before {
        width: 80%;
      }
      .section-feeds {
        padding: 0;
        .section-feed {
          .news-article {
            p {
              &.link {
                a {
                  min-width: 92px;
                  padding: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice {
    > div {
      padding: 20px!important;
      margin: 0px;
      max-width: 100%;
      .text-right{
        text-align: center!important;
      }
      .exerciceFilterRight{
        select{
          max-width: 60px;
        }
      }
      .exerciceHeader {
        h2 {
          text-align: left;
          font-size: 21px;
        }
        .onClose {
          position: absolute;
          cursor: pointer;
          top: 10px;
          right: 10px;
          width: 40px;
          height: 40px;
          border-radius: 100px;
          background: #d8dfe5;
          text-align: center;
          color: #fff;
          font-size: 40px;
          line-height: 40px;
          z-index: 20;
        }
        .exerciceFilter {
          float: left;
          width: 100%;
          margin: 25px 0 5px;
          .search {
            height: 32px;
            float: none;
            margin: auto;
            width: 100%;
            input {
              height: 30px;
            }
            i {
              top: 8px;
            }
          }
          .exerciceFilterRight {
            text-align: center;
            width: 100%;
            > div {
              margin: 20px 0 0;
              float: none;
              display: inline-block;
              width: 33.33%;
            }
          }
        }
      }

      .exerciceContent {
        
        .exercise_tree_item{
          >div:not(.RadioLegend){
            min-height: 100%;
            position:relative;
          }
          .RadioLegend{
            width: 100%;
            margin: 0 0 10px;
            /*overflow: hidden;*/
            padding: 4px 0;
            legend{
              p{
                margin: 0;
                max-height: 50px;
              }
            }
          }
        }
      }
      .exerciceBottom {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        background: #fff;
        padding: 10px;
      }
    }
  }

  .OverlayTextbook {
    tr.empty_tr{
      height: 120px;
    }
  }
  .OverlayScholarBooks {
    tr.empty_tr{
      height: 120px;
    }
  }
}
@media screen and (max-width: 767px) {
  
  .dialogOverlay {
    .dialog{
      &.dialogRegister{
        &.dialogContinueCompte{
          min-width: 100%;
          padding-left: 17px;
          .dialog-left{
            width: 100%;
          }
          .dialog-right{
            width: 150px;
            position: absolute;
            bottom: 33px;
            right: 10px;
          }
        }
      }
      &.dialogCandid{
        &.dialogContinueCompte{
          min-width: 100%;
          padding-left: 17px;
          .dialog-left{
            width: 100%;
          }
          .dialog-right{
            width: 150px;
            position: absolute;
            bottom: 33px;
            right: 10px;
          }
        }
      }
      
    }
    &.dialogOverlayAddGroup {
      padding: 5px 10px;
      background: #fff;
      opacity: 1;
      overflow: hidden;
      .dialog {
        
        &.dialogMessagerie {
          &.dialogAddGroup {
            max-width: 100%;
            height: calc(100% - 15px);
            overflow-y: scroll;
            opacity: 1;
            .closeModal {
              display: none;
            }
            form {
              .button {
                padding-top: 15px;
                margin-top: 0;
                position: absolute;
                bottom: 10px;
                left: 0;
                right: 0;
                border-top: 1px solid #fbfbfb;
                button {
                  padding: 0;
                  height: 32px;
                  font-size: 13px;
                }
              }
            }
          }
        }
      }
    }
    &.dialogFormOverlay{
      .dialog{
        &.dialogAddSchool{
          .school-ligne{
            &.schoolMargin{
              margin-bottom:0!important;
            }
            &.school-ligne-center{
              margin: 0!important;
              .add,.cancel{
                padding:0;
                margin:0 10px;
                width: calc(50% - 20px);
                &.addNew{
                  width: calc(100% - 20px);
                  margin-bottom: 20px;
                }
              }
            }
          }
          &.dialogParents{
            &::before{
              bottom: 140px;
            }
            .add-school{
              .add-school-form{
                .schoolRightForm{
                  @media (min-width: 1200px){
                      margin-right: 100px;
                      width: calc(100% - 100px);
                  }
                }
                .school-ligne{
                  margin-bottom: 0;
                  .school-ligne-w50{
                    margin-bottom: 15px!important;
                    width: 100%;
                  }
                }
                .bloc-next{
                  margin-top: 20px;
                }
              }
            }
          }
        }
        &.dialogBuyPack{
          h3{
            margin: 0 0 50px;
          }
          .buyPack{
            max-width: 240px;
          }
          &.dialogBuyPackage{
            padding-top: 0;
            .packageTop{
              margin: 4px auto 25px;
              text-align: center;
              h2{
                font-size: 22px;
                display: block;
                float: none;
                img{
                  margin-right: 3px;
                  width: 25px;
                }
              }
              a{
                display: none;
              }
            }
            .packages{
              max-width: 250px;
            }
            .addPackage{
              position: fixed;
      right: 15px;
      bottom: 15px;
      cursor: pointer;
      width: 50px;
      height: 50px;
      border-radius: 100px;
      box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.3);
      background-image: linear-gradient(225deg, #e2b346, #f6c965);
      color: #fff;
      font-size: 47px;
      line-height: 50px;
      text-align: center;
      z-index: 99;
            }
          }
        }
      }
    }
  }
  .react-datepicker-wrapper {
    .react-datepicker__input-container {
      &:before {
        top: 5px;
      }
    }
  }
  .OverlayDaillyChallenge {
    min-height: 100%;
    overflow-y: auto;
    &.OverlayChangePassword {
      > div {
        max-width: 100%;
        left: 10px !important;
        right: 10px !important;
        margin-left: 0 !important;
        overflow: initial !important;
        .closeModal {
          display: block;
        }
      }
      &.OverlayChangeImage {
        left: -5px;
        right: -5px;
        top: -5px;
        bottom: -5px;
        background: #fff;
        > div {
          position: relative;
          box-shadow: none;
          top: 5px !important;
          left: 0 !important;
          .closeModal {
            display: none;
          }
          .header {
            background: #fff;
            .header-back {
              display: block;
              position: absolute;
              left: 15px;
              top: 13px;
              height: 30px;
              width: 30px;
              border-radius: 35px;
              border: 2px solid #3db7ae;
              line-height: 25px;
              text-align: center;
            }
            h3 {
              color: #46bfb6;
              font-size: 13px;
            }
          }
          .content {
            background: #fff;
            padding: 0 15px 17px;
            .choose-image {
              height: 250px;
            }
            .choose-apply {
              margin-top: 60px;
              float: left;
              width: 100%;
              a {
                padding: 9px 80px;
              }
            }
          }
        }
      }
    }
    &.OverlayFriends {
      &.OverlayStudents {
        &.OverlaySchool {
          &.OverlayHomeWork {
            &.OverlayAddExercice {
              > div {
                padding: 20px!important;
                margin: 0px;
                max-width: 100%;
                .text-right{
                  text-align: center!important;
                }
                .exerciceFilterRight{
                  select{
                    max-width: 60px;
                  }
                }
                .exerciceHeader {
                  h2 {
                    text-align: left;
                    font-size: 21px;
                  }
                  .onClose {
                    position: absolute;
                    cursor: pointer;
                    top: 10px;
                    right: 10px;
                    width: 40px;
                    height: 40px;
                    border-radius: 100px;
                    background: #d8dfe5;
                    text-align: center;
                    color: #fff;
                    font-size: 40px;
                    line-height: 40px;
                    z-index: 20;
                  }
                  .exerciceFilter {
                    float: left;
                    width: 100%;
                    margin: 25px 0 5px;
                    .search {
                      height: 32px;
                      float: none;
                      margin: auto;
                      width: 100%;
                      input {
                        height: 30px;
                      }
                      i {
                        top: 8px;
                      }
                    }
                    .exerciceFilterRight {
                      text-align: center;
                      width: 100%;
                      > div {
                        margin: 20px 0 0;
                        float: none;
                        display: inline-block;
                        width: 33.33%;
                      }
                    }
                  }
                }

                .exerciceContent {
                  
                  .exercise_tree_item{
                    >div:not(.RadioLegend){
                      min-height: 100%;
                      position:relative;
                    }
                    .RadioLegend{
                      width: 100%;
                      margin: 0 0 10px;
                      /*overflow: hidden;*/
                      padding: 4px 0;
                      legend{
                        p{
                          margin: 0;
                          max-height: 50px;
                        }
                      }
                    }
                  }
                }
                .exerciceBottom {
                  position: fixed;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  background: #fff;
                  padding: 10px;
                }
              }
            }
            > div {
              // padding: 30px 45px 0 !important;
              padding: 30px 10px 0 !important;
              min-height: 100vh;
              border-radius: 0!important;
              .closeModal {
                background: #d8dfe5;
                box-shadow: none;  
              }
              .add-school-form {
                margin-top: 30px;
                .school-ligne {
                  input {
                    font-size: 12px;
                    font-weight: 500;
                    color: #3e4655;
                  }
                  .exerciceHomeWork {
                    .RadioLegend {
                      width: 50%;
                    }
                  }
                }
              }
            }
          }
          &.OverlaySchoolNew {
            > div {
              padding: 55px 30px 0 !important;
            }
          }
          > div {
            @media screen and (max-width: 767px) {
              margin-left:0px;
            }
            // padding: 55px 35px 0 !important;
            border-radius: 0 !important;
            left: 0 !important;
            right: 23 !important;
            box-shadow: none;
            top: 0 !important;
            min-height: calc(100vh + 10px);
            .closeModal {
              background: #d8dfe5;
              box-shadow: none;
              right: 10px;
              top: 10px;
              height: 35px;
              width: 35px;
              line-height: 35px;
            }
            .friends-top {
              .friend-search {
                width: 230px;
                float: none;
                margin: 0 auto 15px;
              }
              .friend-right {
                .bloc-level {
                  width: 50%;
                  margin-left: 0;
                }
              }
            }
            .add-school-form {
              .school-ligne {
                &.school-ligne-margin {
                  margin-bottom: 25px;
                  .school-ligne-w50 {
                    width: 100%;
                    margin-bottom: 25px;
                    &:last-child {
                      margin-bottom: 0!important;
                    }
                    &.w50 {
                      width: 50%;
                      margin-bottom: 0;
                    }
                  }
                }
                &.school-ligne-classes {
                  background: #fff;
                }
                .RadioLegend {
                  width: 33.33%;
                  > legend {
                    font-size: 12px;
                    margin-left: 3px;
                  }
                }
                .flagSelect {
                  float: left;
                  width: 100%;
                  height: 35px;
                  border-radius: 2.6px;
                  border: solid 0.6px #d1dbe2;
                  background-color: rgba(245, 248, 249, 0.3);
                  .select {
                    float: left;
                    width: 70px;
                    height: 34px;
                    position: relative;
                    border: none;
                    &:before {
                      content: "";
                      position: absolute;
                      right: 0;
                      top: 4px;
                      bottom: 4px;
                      width: 1px;
                      background: #b1bfcd;
                    }
                    select {
                      float: left;
                      width: 50px;
                      font-size: 12px;
                      height: 34px;
                      border: none;
                      outline: none;
                      -webkit-appearance: none;
                      -moz-appearance: none;
                      appearance: none;
                      text-indent: 1px;
                      text-overflow: "";
                      background: transparent;
                      outline: 0;
                      position: relative;
                      z-index: 9;
                      border-radius: 4px!important;
                      border: none!important;
                      font-weight: 500;
                      font-family: Montserrat;
                      color: #1b2437;
                      padding: 0 5px;
                      &::-ms-expand {
                        display: none;
                      }
                    }
                    i {
                      color: #d2dbe2;
                      font-size: 16px;
                      position: absolute;
                      right: 5px;
                      top: 8px;
                    }
                  }

                  input {
                    border: none;
                    width: calc(100% - 50px);
                    padding-left: 6px;
                  }
                }
                button {
                  &.add {
                    height: 35px;
                    width: 120px;
                    &.newStudents {
                      margin-top: 20px;
                      width: 100%;
                    }
                  }
                }
                input,
                .select {
                  height: 35px;

                  select {
                    height: 34px;
                  }
                }
                .select {
                  i {
                    top: 8px;
                  }
                }
              }
            }
          }
        }
      }
      &.OverlayJournals {
        &.OverlaySchool {
          &.OverlayHomeWork {
            &.OverlayAddExercice {
              > div {
                padding: 20px!important;
                margin: 0px;
                max-width: 100%;
                .text-right{
                  text-align: center!important;
                }
                .exerciceFilterRight{
                  select{
                    max-width: 60px;
                  }
                }
                .exerciceHeader {
                  h2 {
                    text-align: left;
                    font-size: 21px;
                  }
                  .onClose {
                    position: absolute;
                    cursor: pointer;
                    top: 10px;
                    right: 10px;
                    width: 40px;
                    height: 40px;
                    border-radius: 100px;
                    background: #d8dfe5;
                    text-align: center;
                    color: #fff;
                    font-size: 40px;
                    line-height: 40px;
                    z-index: 20;
                  }
                  .exerciceFilter {
                    float: left;
                    width: 100%;
                    margin: 25px 0 5px;
                    .search {
                      height: 32px;
                      float: none;
                      margin: auto;
                      width: 100%;
                      input {
                        height: 30px;
                      }
                      i {
                        top: 8px;
                      }
                    }
                    .exerciceFilterRight {
                      text-align: center;
                      width: 100%;
                      > div {
                        margin: 20px 0 0;
                        float: none;
                        display: inline-block;
                        width: 33.33%;
                      }
                    }
                  }
                }

                .exerciceContent {
                  
                  .exercise_tree_item{
                    >div:not(.RadioLegend){
                      min-height: 100%;
                      position:relative;
                    }
                    .RadioLegend{
                      width: 100%;
                      margin: 0 0 10px;
                      /*overflow: hidden;*/
                      padding: 4px 0;
                      legend{
                        p{
                          margin: 0;
                          max-height: 50px;
                        }
                      }
                    }
                  }
                }
                .exerciceBottom {
                  position: fixed;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  background: #fff;
                  padding: 10px;
                }
              }
            }
            > div {
              padding: 30px 45px 0 !important;
              min-height: 100vh;
              border-radius: 0!important;
              .closeModal {
                background: #d8dfe5;
                box-shadow: none;  
              }
              .add-school-form {
                margin-top: 30px;
                .school-ligne {
                  input {
                    font-size: 12px;
                    font-weight: 500;
                    color: #3e4655;
                  }
                  .exerciceHomeWork {
                    .RadioLegend {
                      width: 50%;
                    }
                  }
                }
              }
            }
          }
          &.OverlaySchoolNew {
            > div {
              padding: 55px 30px 0 !important;
            }
          }
          > div {
            @media screen and (max-width: 767px) {
              margin-left:0px;
            }
            // padding: 55px 35px 0 !important;
            border-radius: 0 !important;
            left: 0 !important;
            right: 23 !important;
            box-shadow: none;
            top: 0 !important;
            min-height: calc(100vh + 10px);
            .closeModal {
              background: #d8dfe5;
              box-shadow: none;
              right: 10px;
              top: 10px;
              height: 35px;
              width: 35px;
              line-height: 35px;
            }
            .friends-top {
              .friend-search {
                width: 230px;
                float: none;
                margin: 0 auto 15px;
              }
              .friend-right {
                .bloc-level {
                  width: 50%;
                  margin-left: 0;
                }
              }
            }
            .add-school-form {
              .school-ligne {
                &.school-ligne-margin {
                  margin-bottom: 25px;
                  .school-ligne-w50 {
                    width: 100%;
                    margin-bottom: 25px;
                    &:last-child {
                      margin-bottom: 0!important;
                    }
                    &.w50 {
                      width: 50%;
                      margin-bottom: 0;
                    }
                  }
                }
                &.school-ligne-classes {
                  background: #fff;
                }
                .RadioLegend {
                  width: 33.33%;
                  > legend {
                    font-size: 12px;
                    margin-left: 3px;
                  }
                }
                .flagSelect {
                  float: left;
                  width: 100%;
                  height: 35px;
                  border-radius: 2.6px;
                  border: solid 0.6px #d1dbe2;
                  background-color: rgba(245, 248, 249, 0.3);
                  .select {
                    float: left;
                    width: 70px;
                    height: 34px;
                    position: relative;
                    border: none;
                    &:before {
                      content: "";
                      position: absolute;
                      right: 0;
                      top: 4px;
                      bottom: 4px;
                      width: 1px;
                      background: #b1bfcd;
                    }
                    select {
                      float: left;
                      width: 50px;
                      font-size: 12px;
                      height: 34px;
                      border: none;
                      outline: none;
                      -webkit-appearance: none;
                      -moz-appearance: none;
                      appearance: none;
                      text-indent: 1px;
                      text-overflow: "";
                      background: transparent;
                      outline: 0;
                      position: relative;
                      z-index: 9;
                      border-radius: 4px!important;
                      border: none!important;
                      font-weight: 500;
                      font-family: Montserrat;
                      color: #1b2437;
                      padding: 0 5px;
                      &::-ms-expand {
                        display: none;
                      }
                    }
                    i {
                      color: #d2dbe2;
                      font-size: 16px;
                      position: absolute;
                      right: 5px;
                      top: 8px;
                    }
                  }

                  input {
                    border: none;
                    width: calc(100% - 50px);
                    padding-left: 6px;
                  }
                }
                button {
                  &.add {
                    height: 35px;
                    width: 120px;
                    &.newStudents {
                      margin-top: 20px;
                      width: 100%;
                    }
                  }
                }
                input,
                .select {
                  height: 35px;

                  select {
                    height: 34px;
                  }
                }
                .select {
                  i {
                    top: 8px;
                  }
                }
              }
            }
          }
        }
      }
      &.OverlaySticky {
        &.OverlaySchool {
          &.OverlayHomeWork {
            &.OverlayAddExercice {
              > div {
                padding: 20px!important;
                margin: 0px;
                max-width: 100%;
                .text-right{
                  text-align: center!important;
                }
                .exerciceFilterRight{
                  select{
                    max-width: 60px;
                  }
                }
                .exerciceHeader {
                  h2 {
                    text-align: left;
                    font-size: 21px;
                  }
                  .onClose {
                    position: absolute;
                    cursor: pointer;
                    top: 10px;
                    right: 10px;
                    width: 40px;
                    height: 40px;
                    border-radius: 100px;
                    background: #d8dfe5;
                    text-align: center;
                    color: #fff;
                    font-size: 40px;
                    line-height: 40px;
                    z-index: 20;
                  }
                  .exerciceFilter {
                    float: left;
                    width: 100%;
                    margin: 25px 0 5px;
                    .search {
                      height: 32px;
                      float: none;
                      margin: auto;
                      width: 100%;
                      input {
                        height: 30px;
                      }
                      i {
                        top: 8px;
                      }
                    }
                    .exerciceFilterRight {
                      text-align: center;
                      width: 100%;
                      > div {
                        margin: 20px 0 0;
                        float: none;
                        display: inline-block;
                        width: 33.33%;
                      }
                    }
                  }
                }

                .exerciceContent {
                  
                  .exercise_tree_item{
                    >div:not(.RadioLegend){
                      min-height: 100%;
                      position:relative;
                    }
                    .RadioLegend{
                      width: 100%;
                      margin: 0 0 10px;
                      /*overflow: hidden;*/
                      padding: 4px 0;
                      legend{
                        p{
                          margin: 0;
                          max-height: 50px;
                        }
                      }
                    }
                  }
                }
                .exerciceBottom {
                  position: fixed;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  background: #fff;
                  padding: 10px;
                }
              }
            }
            > div {
              padding: 30px 45px 0 !important;
              min-height: 100vh;
              border-radius: 0!important;
              .closeModal {
                background: #d8dfe5;
                box-shadow: none;  
              }
              .add-school-form {
                margin-top: 30px;
                .school-ligne {
                  input {
                    font-size: 12px;
                    font-weight: 500;
                    color: #3e4655;
                  }
                  .exerciceHomeWork {
                    .RadioLegend {
                      width: 50%;
                    }
                  }
                }
              }
            }
          }
          &.OverlaySchoolNew {
            > div {
              padding: 55px 30px 0 !important;
            }
          }
          > div {
            @media screen and (max-width: 767px) {
              margin-left:0px;
              padding: 20px 10px 0 !important;
              width: 100%;
              overflow: scroll !important;
            }
            padding: 55px 35px 0 !important;
            border-radius: 0 !important;
            left: 0 !important;
            right: 23 !important;
            box-shadow: none;
            top: 0 !important;
            min-height: calc(100vh + 10px);
            .closeModal {
              background: #d8dfe5;
              box-shadow: none;
              right: 10px;
              top: 10px;
              height: 35px;
              width: 35px;
              line-height: 35px;
            }
            .friends-top {
              .friend-search {
                width: 230px;
                float: none;
                margin: 0 auto 15px;
              }
              .friend-right {
                .bloc-level {
                  width: 50%;
                  margin-left: 0;
                }
              }
            }
            .add-school-form {
              .school-ligne {
                &.school-ligne-margin {
                  margin-bottom: 25px;
                  .school-ligne-w50 {
                    width: 100%;
                    margin-bottom: 25px;
                    &:last-child {
                      margin-bottom: 0!important;
                    }
                    &.w50 {
                      width: 50%;
                      margin-bottom: 0;
                    }
                  }
                }
                &.school-ligne-classes {
                  background: #fff;
                }
                .RadioLegend {
                  width: 33.33%;
                  > legend {
                    font-size: 12px;
                    margin-left: 3px;
                  }
                }
                .flagSelect {
                  float: left;
                  width: 100%;
                  height: 35px;
                  border-radius: 2.6px;
                  border: solid 0.6px #d1dbe2;
                  background-color: rgba(245, 248, 249, 0.3);
                  .select {
                    float: left;
                    width: 70px;
                    height: 34px;
                    position: relative;
                    border: none;
                    &:before {
                      content: "";
                      position: absolute;
                      right: 0;
                      top: 4px;
                      bottom: 4px;
                      width: 1px;
                      background: #b1bfcd;
                    }
                    select {
                      float: left;
                      width: 50px;
                      font-size: 12px;
                      height: 34px;
                      border: none;
                      outline: none;
                      -webkit-appearance: none;
                      -moz-appearance: none;
                      appearance: none;
                      text-indent: 1px;
                      text-overflow: "";
                      background: transparent;
                      outline: 0;
                      position: relative;
                      z-index: 9;
                      border-radius: 4px!important;
                      border: none!important;
                      font-weight: 500;
                      font-family: Montserrat;
                      color: #1b2437;
                      padding: 0 5px;
                      &::-ms-expand {
                        display: none;
                      }
                    }
                    i {
                      color: #d2dbe2;
                      font-size: 16px;
                      position: absolute;
                      right: 5px;
                      top: 8px;
                    }
                  }

                  input {
                    border: none;
                    width: calc(100% - 50px);
                    padding-left: 6px;
                  }
                }
                button {
                  &.add {
                    height: 35px;
                    width: 120px;
                    &.newStudents {
                      margin-top: 20px;
                      width: 100%;
                    }
                  }
                }
                input,
                .select {
                  height: 35px;

                  select {
                    height: 34px;
                  }
                }
                .select {
                  i {
                    top: 8px;
                  }
                }
              }
            }
          }
        }
      }
    }
    &.OverlayFriends,
    &.OverlayTextbookExercice {
      background: #ffffff;
      .closeModal {
        top: -37px;
        right: -10px;
        width: 33px;
        height: 33px;
        line-height: 23px;
        &.modaldownload_files{
          display: block !important;
          top: 6px !important;
          right: 6px !important;
          background: #d8dfe5;
          box-shadow: none;
          width: 35px;
          height: 35px;
          line-height: 26px;
          font-size: 34px;
          top: 15px;
          right: 15px;
          border-radius: 100px;
          text-align: center;
          color: #fff;
        }
        img {
          max-width: 13px;
        }
      }
      > div {
        left: 15px !important;
        right: 15px !important;
        box-shadow: 0 0 8px -1px #dedfe2;
        top: 45px !important;
        padding: 20px 40px 35px !important;
        overflow: initial !important;
        .friend-content {
          .friend-content-bloc {
            .friend-content-bloc-scroll {
              height: auto;
              overflow-y: inherit;
            }
            .friend-bloc {
              width: 33.33%;
              &.selected {
                &:before {
                  left: 50%;
                  margin-left: -38px;
                }
              }
            }
          }
        }
      }
      &.OverlayExerciceSubject {
        background-color: rgba(100, 115, 140, 0.9);
        > div {
          left: 50% !important;
          top: 50% !important;
          margin-top: -141px;
          .closeModal {
            display: block;
            top: -40px;
            right: 0px;
          }
        }
      }
    }
    &.OverlayTextbookExercice {
      > div {
        padding: 0 !important;
        top: 0 !important;
        left: 0 !important;
        right: 0 !important;
        margin-left: 0;
        .content-exercice {
          .content-exercice-width {
            width: 50%;
            .bloc-content-exercice {
              h4 {
                line-height: 1;
              }
            }
          }
          .content-exercice-scroll {
            height: calc(var(--vh, 1vh) * 100 - 56px)!important;
          }
        }
      }
      .closeModal {
        display: none;
      }
    }
  }
  .header-right {
    ul {
      li {
        a {
          width: 40px;
          height: 40px;
          margin-left: 5px;
          img {
            width: 17px;
            display: block;
          }
        }
      }
    }
  }
  .signin {
    margin: 40px auto 0;
    width: 180px;
    li {
      padding: 6px 9px 8px;
      border-radius: 52px;
      margin-bottom: 15px;
      a {
        font-size: 12px;
        letter-spacing: 1.8px;
        img {
          width: 20px;
        }
      }
    }
  }
  .navigation-mobile {
    .logo {
      margin-top: -30px;
      img {
        width: 150px;
      }
    }
    header {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 9999;
      right: 0;
      box-shadow: 0px 3px 16px -1px rgba(0, 0, 0, 0.4);

      .content-mobile {
        .center-mobile {
          nav {
            max-width: 180px;
            ul {
              li {
                a {
                  width: 100%;
                  font-size: 17px;
                  padding: 7px;
                  font-weight: 500;
                }
              }
            }
          }
          .translate {
            .translate-bloc {
              padding: 9px 22px;
              font-size: 12px;
              img {
                width: 13px;
              }
            }
          }
        }
      }
    }
  }
  section {

    &.one {
      position: relative;
      padding: 24px 0 0;
      .slider {
        margin: 0 0 190px;
        .carousel {
          border-radius: 5px;
          h3 {
            font-size: 17px;
            line-height: 23px;
            bottom: 10px;
          }
          .banner-anim {
            min-height: 200px;

            .banner-anim-elem {
              min-height: 200px;
              .bg {
                min-height: 200px;
              }
            }
          }
        }
        .slider-bottom {
          bottom: -170px;
          left: 25px;
          right: 25px;
          a {
            margin: 0 0 22px;
            width: 100%;
            display: block;
            font-size: 12.5px;
            color: rgba(255, 255, 255, 0.9);
            padding: 14px 13px;
            border: 4px solid #fff;
            border-radius: 82px;
          }
        }
      }
      .news-feed {
        h3 {
          margin-bottom: 15px;
        }
        .bloc-feeds {
          margin: 10px 0 0;
          .feed-img {
            width: 56%;
            margin-right: 12px;
          }
          .bloc-feed {
            margin-bottom: 15px;
          }
          .feeds {
            margin-top: 25px;
          }
        }
        p {
          &.link {
            margin-top: 25px;
          }
        }
      }
    }
    &.two {
      .subjects {
        box-shadow: 0 -4px 16px -10px rgba(0, 0, 0, 0.45);
        padding-top: 15px;
        h2 {
          font-size: 17px;
          margin-bottom: 20px;
          width: 210px;
          padding-left: 0;
          padding-right: 0;
        }
        .subjects-mobile {
          max-width: 210px;
          margin: auto;
          img {
            margin: 42px 0;
            -webkit-filter: drop-shadow(5px 22px 23px rgba(0, 0, 0, 0.15));
            -moz-filter: drop-shadow(5px 22px 23px rgba(0, 0, 0, 0.15));
            -ms-filter: drop-shadow(5px -22px 23px rgba(0, 0, 0, 0.15));
            -o-filter: drop-shadow(5px 22px 23px rgba(0, 0, 0, 0.15));
            filter: drop-shadow(5px 22px 23px rgba(0, 0, 0, 0.15));
          }
        }
      }
    }
    &.section-news-feeds {
      &:before {
        width: 450px;
        right: -150px;
      }
      h3 {
        margin: 0 0 30px;
      }
      .section-feeds {
        padding-bottom: 15px;
        .slider-frame {
          overflow: inherit !important;
        }
        .section-feed {
          max-width: 290px;
          margin: auto;
          border-radius: 6px;
          padding-bottom: 10px;
          background: #fff;
          .news-img {
            width: 100%;
            height: 220px;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.4);
          }
          .news-article {
            width: 100%;
            height: auto;
            padding-bottom: 40px;
            &:before {
              left: 0;
              width: 112px;
              bottom: -83px;
              min-height: 94px;
            }
            &:after {
              bottom: -6px;
            }
            p {
              font-size: 12px;
              line-height: 16px;
              text-align: left;
              &.link {
                text-align: right;
                a {
                  padding: 5px 10px;
                  font-size: 10px;
                }
                i {
                  margin: 0;
                  vertical-align: middle;
                }
              }
            }
          }
        }
      }
      .slider-control-bottomcenter {
        bottom: -50px !important;
        right: 31px !important;
        transform: translateX(0%) !important;
        ul {
          li {
            button {
              font-size: 53px !important;
              line-height: 38px !important;
              color: #297a74 !important;
              padding: 2px !important;
            }
          }
        }
      }
      .slider-control-centerright,
      .slider-control-centerleft {
        button {
          background: #3eb6ad !important;
          padding:O 0 !important;
          font-size: 0;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          border: 5px solid #ffffff !important;
          position: relative;
          &:before {
            font: normal normal normal 14px/1 FontAwesome;
            color: #fff;
            line-height: 14px;
            font-size: 24px;
          }
        }
      }
      .slider-control-centerright {
        button {
          &:before {
            content: "\f105";
          }
        }
      }
      .slider-control-centerleft {
        button {
          &:before {
            content: "\f104";
          }
        }
      }
    }
    &.koolskools-notes {
      padding: 35px 0 110px;
      main {
        background: #f8f6f6;
        &:before {
          top: auto;
          bottom: -10px;
          left: 50px;
          width: 100px;
          height: 130px;
        }
        > .pager-controls {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 25px;
        }
        .view {
          transform: scale(2);
          -webkit-transform: scale(2);
          &.current {
            -webkit-transform: scale(2.5);
            transform: scale(2.5);
          }
        }

        .pager-controls {
          .pager-control--prev,
          .pager-control--next {
            display: block;
            position: absolute;
            top: 50%;
            background: #3eb6ad;
            padding: 0;
            font-size: 0;
            width: 40px;
            height: 40px;
            margin-top: -25px;
            border-radius: 50%;
            border: 5px solid #ffffff;
            line-height: 45px;
            &:before {
              font: normal normal normal 14px/1 FontAwesome;
              color: #fff;
              line-height: 14px;
              font-size: 24px;
            }
          }
          .pager-control--prev {
            left: 17px;
            &:before {
              content: "\f104";
            }
          }

          .pager-control--next {
            right: 17px;
            &:before {
              content: "\f105";
            }
          }
        }
      }
      .container-koolskools {
        max-width: 100%;
        padding: 0;
        h3 {
          margin-bottom: 67px;
          position: relative;
          z-index: 99;
          font-size: 12px;
          line-height: 16px;
          span {
            &:before {
              right: -63px;
              top: 25px;
              width: 117px;
              height: 102px;
              z-index: 9;
              -webkit-transform: rotate(50deg);
              transform: rotate(56deg);
            }
          }
        }
      }
      .frame {
        margin-left: 0;
        margin-right: 0;
        overflow: inherit !important;
        .track {
          padding: 130px 0 150px;
          img {
            border: 7px solid #ffffff;
          }
        }
      }
    }
  }
  footer {
    .bloc-footer {
      width: 100%;
    }
    .footer-logo {
      margin-bottom: 10px;
      a {
        background-size: 246px auto;
        width: 246px;
        height: 127px;
        margin: -100px auto 0;
        img {
          width: 140px;
        }
      }
    }
  }
  .dialogMobile {
    padding-right: 10px;
    padding-left: 10px;
    z-index: 99999;
    display: block;
    .dialog {
      padding-bottom: 60px;
      max-width: 100%;
      display: block;
      float: left;
      margin-top: 40px;
      &.dialogConnexion {
        .account_activated{
          color: green;
          font-size: 12px;
          padding: 10px;
        }
        &.dialogRegister {
          width: 100%;
          min-width: 100%;
          padding: 5px;
          background: #fff;
          
          .closeRegister {
            background: #b0bfcd;
            font-size: 35px;
            line-height: 35px;
            border: none;
          }
          .dialog-top {
            border: 1px solid #e1e8ec;
            background: #f6f8f9;
            padding: 0;
            overflow: hidden;
            a {
              background: #f6f8f9;
              color: #a4acb5;
              border: none;
              letter-spacing: 0.6px;
              padding: 11px 6px;
              border-radius: 0;
              &.active {
                background: #64d1cb;
                color: #fdfefe;
                font-weight: 500;
                border-radius: 28px;
              }
            }
          }
          .dialog-content {
            h2 {
              color: #222b3d;
            }
            .modalConnexionForm {
              .connexionLigne {
                .intl-tel-input{
                  width:100%
                }
                label {
                  color: #6c727f;
                  font-weight: 500;
                }
                .RadioLegend {
                  margin-top: 4px;
                  margin-bottom: 4px;
                }
                input,
                select,
                .flagSelect {
                  height: 35px;
                  .select {
                    i {
                      top: 8px;
                    }
                  }
                }
                .select {
                  height: 33px;
                  border-radius: 4px!important;
                  border: none!important;
                  font-weight: 500;
                  font-family: Montserrat;
                  font-size: 14px;
                  color: #1b2437;
                  padding: 0 10px;
                }
                .forgot {
                  font-family: Montserrat;
                  font-size: 12px;
                  font-weight: normal;
                  color: #4e4e4e;
                }
                a {
                  &.signin {
                    float: right;
                    color: #fff;
                    height: 30px;
                    line-height: 30px;
                    margin: 0;
                  }
                }
              }
            }
          }
        }
        &.dialogCandid {
          width: 100%;
          min-width: 100%;
          padding: 5px;
          background: #fff;
          
          .closeRegister {
            background: #b0bfcd;
            font-size: 35px;
            line-height: 35px;
            border: none;
          }
          .dialog-top {
            border: 1px solid #e1e8ec;
            background: #f6f8f9;
            padding: 0;
            overflow: hidden;
            a {
              background: #f6f8f9;
              color: #a4acb5;
              border: none;
              letter-spacing: 0.6px;
              padding: 11px 6px;
              border-radius: 0;
              &.active {
                background: #64d1cb;
                color: #fdfefe;
                font-weight: 500;
                border-radius: 28px;
              }
            }
          }
          .dialog-content {
            h2 {
              color: #222b3d;
            }
            .modalConnexionForm {
              .connexionLigne {
                .intl-tel-input{
                  width:100%
                }
                label {
                  color: #6c727f;
                  font-weight: 500;
                }
                .RadioLegend {
                  margin-top: 4px;
                  margin-bottom: 4px;
                }
                input,
                select,
                .flagSelect {
                  height: 35px;
                  .select {
                    i {
                      top: 8px;
                    }
                  }
                }
                .select {
                  height: 33px;
                  border-radius: 4px!important;
                  border: none!important;
                  font-weight: 500;
                  font-family: Montserrat;
                  font-size: 14px;
                  color: #1b2437;
                  padding: 0 10px;
                }
                .forgot {
                  font-family: Montserrat;
                  font-size: 12px;
                  font-weight: normal;
                  color: #4e4e4e;
                }
                a {
                  &.signin {
                    float: right;
                    color: #fff;
                    height: 30px;
                    line-height: 30px;
                    margin: 0;
                  }
                }
              }
            }
          }
        }
      }
      .closeRegister {
        display: block;
        position: absolute;
        top: -37px;
        right: 0;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        color: #ffff;
        font-size: 20px;
        line-height: 23px;
        text-align: center;
        border: 5px solid #fff;
        background: #13bbb3;
        cursor: pointer;
      }
      .dialog-content {
        padding: 30px 8px 0 15px;
        .dialog-left {
          width: 98%;
          .textInput {
            label {
              color: #868686;
            }
          }
          h3 {
            font-size: 15px;
          }
          .password-forgot {
            a {
              font-size: 10px;
            }
          }
        }
        .dialog-right {
          width: 150px;
          position: absolute;
          bottom: 33px;
          right: 10px;
          span {
            &:before {
              display: none;
            }
          }
        }
      }
      .dialog-top {
        a {
          font-size: 13px;
          padding: 6px;
          font-weight: 500;
        }
      }
    }
  }
  .OverlayTextbook {
    overflow: auto;
    margin-bottom: 55px;

    tr.empty_tr{
      height: 30px;
    }
    &:before {
      display: none;
    }

    &:after {
      display: none;
    }
    > div {
      right: 0 !important;
      .header {
        padding: 0 15px;
        height: 50px;
        .react-multi-carousel-list {
          padding: 0 0 0 33px;
          button {
            background: transparent;
            &.react-multiple-carousel__arrow--left {
              left: 0;
            }
            &.react-multiple-carousel__arrow--right {
              right: 0;
            }
            &:before {
              font-size: 15px;
            }
          }
        }
        ul {
          justify-content: inherit;
          li {
            padding: 0;
            width: 147px !important;
            a {
              font-size: 11px;
              font-weight: 500;
              line-height: 40px;
            }
          }
        }
      }
      .modal-fuseesLeft {
        display: none;
      }
      .modal-fuseesRight {
        display: none;
      }
      .todoCompleted {
        padding: 15px 0;
        div {
          height: 37px;
          a {
            font-size: 9px;
            font-weight: 600;
            padding: 12px 21px;
          }
        }
      }
      .exercices-table {
        table {
          thead {
            tr {
              th {
                padding-bottom: 0;
                font-size: 10px;
                font-weight: 600;
                &:nth-child(0) {
                  width: 20%;
                }
              }
            }
          }
          tbody {
            tr {
              td {
                &:nth-child(1),
                &:nth-child(2) {
                  font-size: 13px;
                  font-weight: 400;
                  .separator {
                    display: none;
                  }
                  .month {
                    display: block;
                  }
                }
                &:nth-child(3) {
                  .visit,
                  .expired {
                    font-size: 10px;
                    min-width: 60px;
                    padding: 3px 4px 1px;
                    font-weight: 500;
                  }
                  .done {
                    font-size: 10px;
                    font-weight: 600;
                    i {
                      font-size: 16px;
                      float: right;
                      margin-top: 4px;
                      margin-left: 3px;
                      margin-right: 3px;
                      font-weight: bold;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .dialogOverlay {
    &.dialogCalendarOverlay {
      width: 100%;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: -10px;
      min-height: 100vh;
      height: 100%;
      background: #fff;
      margin: 0;
      padding: 0;
      z-index: 999;
      display: block;
      &.dialogStudentsDocumentsOverlay{
        z-index: 1040 !important;
      }
      .dialog {
        &.dialogCalendar {
          max-width: 100%;
          border: none;
          padding: 10px 0 70px;
          float: left;
          box-shadow: none;
          .calendarHeader {
            padding: 5px 25px 15px;
            .react-datepicker-wrapper {
              margin-left: 40px;
              .react-datepicker__input-container {
                &:before {
                  content: "\F0D7";
                  font: normal normal normal 13px/1 FontAwesome;
                  color: #d8dfe5;
                  left: auto;
                  right: 2px;
                  font-weight: 500;
                  top: 9px;
                }
                input {
                  font-size: 13px;
                  font-weight: bold;
                  width: 80px;
                  padding-left: 0;
                  border: none;
                }
              }
            }
          }
          .calendar {
            box-shadow: none;
            border-radius: 0;
            float: left;
            width: 100%;
            border: none;
            .week {
              .day {
                padding: 24px 1px 4px;
                b {
                  display: none;
                }
                span {
                  top: 0px;
                  right: 0px;
                  width: 21px;
                  height: 21px;
                  border-radius: 36px;
                  line-height: 21px;
                  font-size: 9px;
                }
                .event {
                  padding: 1px;
                  font-size: 8px;
                  line-height: 10px;
                }
              }
            }
            .dayDetails {
              float: left;
              width: 100%;
              padding: 20px 10px;
            }
          }
          .onBack {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 10px;
            width: 40px;
            height: 40px;
            border-radius: 100px;
            box-shadow: 0 10px 20px 0 rgba(90, 202, 195, 0.5);
            background-image: -webkit-linear-gradient(225deg, #72dcd6, #3eb6ad);
            background-image: linear-gradient(225deg, #72dcd6, #3eb6ad);
            text-align: center;
            color: #fff;
            font-size: 27px;
            line-height: 40px;
          }
          .calendar-switch {
            position: fixed;
            bottom: 0;
            background: #fff;
            left: 0;
            right: 0;
            margin: 0;
            padding: 12px 15px;
            height: auto;
            border-radius: 0;
            border: none;
            box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.3);
            width: 100%;
            z-index: 999;
            > div {
              border: solid 1px #d1dbe2;
              background-color: #f5f8f9;
              float: left;
              width: 100%;
              border-radius: 15px;
              a {
                font-size: 13px;
              }
            }
          }
          header {
            &.header {
              height: 30px;
              .day-names {
                .day {
                  font-size: 12px;
                  line-height: 30px;
                  text-transform: inherit;
                }
              }
            }
            &.header-week {
              height: 52px;
              .weekLeft,
              .weekRight {
                float: left;
                width: 20px;
                height: 50px;
                line-height: 50px;
                font-size: 22px;
                text-align: center;
                color: #b0bfcd;
                cursor: pointer;
              }
              .listDays {
                float: left;
                width: calc(100% - 40px);
                span {
                  float: left;
                  width: 16.66%;
                  line-height: 50px;
                  text-align: center;
                  font-size: 12px;
                  font-weight: 500;
                  color: #444c5c;
                  b {
                    background: #5bcac3;
                    color: #fff;
                    border-radius: 50%;
                    padding: 7px;
                  }
                }
              }
            }
          }
        }
      }
    }
    
  }
}

.dialogInscriptionsDocuments{
  .dialogAddDocument{
    width: 50%;
    height: 80%;
    max-width: 500px;
    .inscriptions_documents{
      width: 100%;
      height: 100%;
      .sectionSession{
        width: 100%;
        height: calc(100% - 80px);
        overflow-y: auto;

        .docs{
          display: flex;
          flex-wrap: wrap;
          margin-top: 20px;
          .document{
            &>h2{
              margin: 0 0 5px;
              padding-top: 5px;
            }
          }
        }

        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          background-color: #f5f5f5;
        }

        &::-webkit-scrollbar {
          width: 5px;
          background-color: #f5f5f5;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #3eb6ad;
        }
      }
      .action{
        margin-top: 10px;
      }
    }
  }
}
@media screen and (max-width: 450px) {
  .OverlayDaillyChallenge {
    &.OverlayFriends {
      > div {
        .friends-top {
          margin-top: 15px;
          .friend-search {
            width: 100%;
            margin-bottom: 15px;
          }
          .friend-right {
            float: left;
            width: 100%;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 410px) {
  .OverlayTextbook {
    > div {
      .todoCompleted {
        div {
          a {
            padding: 12px 10px;
          }
        }
      }
      .exercices-table {
        padding: 0 10px;
      }
    }
  }
  .dialogOverlay {
    &.dialogFormOverlay {
      .dialog {
        &.dialogAddSchool {
          padding: 25px 20px;
          &.dialogAddProfessor {
            .add-school {
              .add-school-form {
                .school-ligne {
                  &.school-ligne-classes {
                    margin: 0 -10px 15px;
                    width: calc(100% + 20px);
                    padding: 10px 10px 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 370px) {
  .OverlayDaillyChallenge {
    &.OverlayTextbookExercice {
      > div {
        .content-exercice {
          .content-exercice-width {
            width: 100%;
          }
        }
      }
    }
    &.OverlayFriends {
      &.OverlayStudents {
        &.OverlaySchool {
          &.OverlayHomeWork {
            > div {
              .school-ligne {
                .inputLink {
                  max-width: 115px;
                }
              }
            }
          }
        }
      }
      &.OverlayJournals {
        &.OverlaySchool {
          &.OverlayHomeWork {
            > div {
              .school-ligne {
                .inputLink {
                  max-width: 115px;
                }
              }
            }
          }
        }
      }
      &.OverlaySticky {
        &.OverlaySchool {
          &.OverlayHomeWork {
            > div {
              .school-ligne {
                .inputLink {
                  max-width: 115px;
                }
              }
            }
          }
        }
      }
    }
  }
  .header-right {
    ul {
      li {
        a {
          width: 32px;
          height: 32px;
        }
      }
    }
  }
}

@media screen and (max-height: 700px) {
  .OverlayTextbook {
    &:before {
      display: none;
    }
    &:after {
      display: none;
    }
    .modal-fuseesLeft,
    .modal-fuseesRight {
      display: none;
    }
  }
  .OverlayScholarBooks {
    &:before {
      display: none;
    }
    &:after {
      display: none;
    }
    .modal-fuseesLeft,
    .modal-fuseesRight {
      display: none;
    }
  }
}

.sticky {
  top: 10px !important;
}

.stageModal{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 100000;
  background: #fff;
}
.stageModal {
  >div{
    top: 0px!important;
    left: 0px!important;
    right: 0px!important;
    bottom: 0px!important;
    border: 0px!important;
    padding: 0px!important;

  }
}
.stage_modal{
  height: calc(var(--vh, 1vh) * 100);
  position: relative;
  min-height: 250px;
}


.iti-mobile .intl-tel-input.iti-container{
  z-index: 10000000;
}
@media (max-width: 767px) {
  #viewerBackdrop{
    > button{
      display: block;
      span{
        padding: 2px 2px 4px 2px;
        border: 2px solid;
        border-radius: 4px;}
    }
  }
}

.xblock_koolskool{
  height: 100%!important;
}


.score_animate{
    position:absolute;
    z-index: 900000;
    visibility:hidden;
    display: flex;
    height: 35px;
    line-height: 35px;
    width: 100px;
    transform-origin: top left;
    display:none;
    &.animate{
      animation: dollar_animate 1s ease-in forwards;
      visibility:visible;
      display:block;
    }
    >img{
      width:35px;
    }
    >span{
      color: #84cacd;
      font-size: 20px;
      font-weight: bold;
    }
  }
  

  /* Standard syntax */
  @keyframes dollar_animate{
    0%{
      transfrom:translate(-50%,-50%);
      left:50%;
      top:50%;
    }
    25%{
      transfrom:translate(-50%,-50%);
      left:50%;
      top:50%;
    }
    100%  {
      left:95%; 
      top:0%;
      transfrom:translate(0%,0%);
    }
  }

  .btn-challenge{
    border-radius: 14px;
    color: #fff;
    border-radius: 40px;
    padding: 0.2rem 1.5rem;
    font-size: 0.8rem;
    border: none!important;
    &:hover{
      border: none!important;
    }
  }
  .footer_btns {
    margin-top: 2rem;
    text-align: center;

    &:not(.mobile){
      margin-bottom: 2rem;
    }

    .btn-yellow {
        margin-right: 2rem;
        padding: 0.7rem 2rem;
        font-size: 0.7rem;
        background-image: linear-gradient(258deg, #fdd271, #d5a433);
        box-shadow: 0 5px 24px 0 rgba(239, 194, 91, 0.5);
        height: auto;
        min-width: auto;
        &:hover{
          background-image: linear-gradient(258deg, #fdd271, #d5a433);
          box-shadow: 0 5px 24px 0 rgba(239, 194, 91, 0.5);
          border: none;
        }
        span {
            margin-left: 0.5rem;
        }
    }
  }

  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    opacity:0.2!important
  }
  ::-moz-placeholder { 
    opacity:0.2!important
  }
  :-ms-input-placeholder { 
    opacity:0.2!important
  }
  :-moz-placeholder { 
    opacity:0.2!important
  }


  .pretty{
    &.checkbox-form{
      .state label{
        &:before,&:after{
          top: 0px!important;
        }
      }
    }
  }
  .display_flex{
    display: flex;
    flex-wrap: wrap;
  }

  button:disabled{
    opacity: 0.2;
  }

  .icon{
   cursor:pointer;
  }
  
  .text-add{
      /* display: inline-block; */
      text-align: center;
      min-width: 106px;
      height: 30px;
      object-fit: contain;
      border-radius: 49.5px;
      /* text-align: center; */
      font-family: Montserrat;
      font-size: 12px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      box-shadow: 0 6px 20px 0 rgba(90, 202, 195, 0.5);
      background-image: linear-gradient(54deg, #72dcd6, #3eb6ad);
      color: #fff;
      border: none;
      margin: 0 7px;
      float: right;
  }

  .badge-dark, .btn-dark{
    background-color: #a1a3a5;
  }


  .badge-info{
    color: #fff;
    background-color: #c13727;
    border-radius: 50%;
    padding: 8px;
    width: 20px;
    height: 20px;
    position: absolute;
    bottom: 90%;
    left: 90%;
    display: flex;
    font-size: 11px;
    align-items: center;
    justify-content:center;
  }

  .select2-container--open {
    z-index: 99999;
  }
  .add-school-form .select2{
    width: max-content !important;
    min-width: 100px;
    border-radius: 2.6px;
    border: solid 0.6px #d1dbe2;
    padding-right: 2px;
  }

  .dialogOverlayViewImage{
    .dialogViewImage{
      width: 500px;
      height: 300px;
      @media (max-width: 767px){
        width: 100%;
        height: 300px;
      }
      img{
        position: absolute;
        width: 100%;
        height: 100%;
      }
    }
  }
  .langue_toggle{
    height: 35px !important;
    border-radius: 15px;
    .btn{
      color: #212529;
      background-color: #80c9ca;
      border-color: #80c9ca;
      &.toggle-handle{
        background-color: #d1dbe2;
        border-color: #d1dbe2;
        border-radius: 50%;
        padding: 0.375rem 1rem;
      }
    }
  }
  .month_day{
    .react-datepicker__current-month{
      display: none;
    }
  }
  .select.multiple_select{
    z-index: 1;
    padding-left: 0px !important;
    .rw-sr{
      display: none !important;
    }
    .rw-widget-input{
      ul{
        display: flex;
        margin: 0;
        padding: 2px;
        flex-wrap: wrap;
        li{
          display: flex;
          margin-right: 5px;
          button{
            border-radius: 50%;
            width: 24px;
            height: 24px;
          }
        }
      }
      .rw-select{
        display: none !important;
      }
    }
    .rw-list-empty{
      display: none !important;
    }
    .rw-popup-container{
      background: white;
      border-right: 1px solid rgb(214, 214, 214);
      border-bottom: 1px solid rgb(214, 214, 214);
      border-left: 1px solid rgb(214, 214, 214);
      .rw-list{
        padding-left: 5px;
        margin-bottom: 0;
        li{
          border-bottom: 1px solid #e4e4e4;
        }
      }
    }
    &.hide_input{
      .rw-popup-container{
        display: none;
      }
      .rw-widget-input{
        input{
          display: none;
        }
      }
    }
  }
  .row.school-ligne.select_multiple{
    margin-bottom: 6px;
    .select_multiple{
      // height: 84px;
      z-index: 10;
    }
  }
  input.arabic_name{
    text-align: right;
  }
  .appreciation{
    input:disabled{
      background-color: transparent;
    }
  }

  .connexion-custom-select{
    input{
      height: auto!important;
    }
  }
  .add_appreciation_input{
    input{
      border: 0 !important;
    }
  }
  .dialogAddGradThreshold{
    input{
      border: 0 !important;
    }
  }

  #choose_image.modal {
    .modal-body{
      max-height: calc(100% - 135px);
    }
    .list-images{
      flex-wrap: wrap;
      overflow-y: auto;
      img{
        width:100px;
        height:100px;
        padding:5px;
        margin:10px;
        border-radius : 4px;
        border: 1px solid #ccc;
        cursor:pointer;
      }
    } 
    .btn-choose-img{
      position: relative;
      overflow: hidden;
      input{
        opacity: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
      }
    }
  }

  #crop_image{
    img{
      flex:1;
    }
  }

  .content-exercise, .content-helpers{
    .viewer_video{
      position: absolute;
      width: 100%;
      height: 100%;
      top:0;
      left:0;
      z-index: 91;
      background: white;
      .btn{
        position: absolute;
        right:0px;
      }
      iframe{
        width: 100%;
        height: 100%;
        border: none;
        padding: 15px;
      }
    }
    .asset-img{
      width: 100px;
      height: 100px;
      position: absolute;
      top:20px;
      left:0px;
      cursor: grab;
      border:1px solid #ccc;
      &.viewer{
        border:none;
      }
      .remove-asset,.crop-asset{
        position: absolute;
        right: -25px;
        top: 0px;
        border-radius: 50%;
        border: 1px solid #ccc;
        background: #ccc;
        color: white;
        /* padding: 4px; */
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor:pointer;
      }
      .crop-asset{
        top: 25px;
      }
      .doc-asset{
        width: 100%;
        height: 100%;
        display: flex;
        border-radius: 50%;
        background: #4dc1b9;
        align-items: center;
        justify-content: center;
        color: #fff;
        cursor:pointer;
      }
      .video-asset{
        cursor:pointer;
        width: 100%;
        height: 100%;
        img{
          width: 100%;
          height: 100%;
        }
        i.fa{
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: red;
          background: #fff;
          font-size: 25px;
        }
      }
      img{
        width:100%;
        height:100%;
      }
    }
    .ui-resizable-handle{
      position: absolute;
      display: block;
      background: #7c7c7c;
      width: 8px;
      height: 8px;
      &.ui-resizable-e {
        cursor: e-resize;
        right: -4px;
        top: 50%;
        transform: translateY(-50%);
      }
      &.ui-resizable-w {
        cursor: w-resize;
        left: -4px;
        top: 50%;
        transform: translateY(-50%);
      }
      &.ui-resizable-s {
        cursor: s-resize;
        bottom: -4px;
        left: 50%;
        transform: translateX(-50%);
      }
      &.ui-resizable-sw{
        cursor: sw-resize;
        bottom: -4px;
        left: -4px;
      }
      &.ui-resizable-se{
        cursor: se-resize;
        bottom: -4px;
        right: -4px;
      }

      &.ui-resizable-n {
        cursor: s-resize;
        top: -4px;
        left: 50%;
        transform: translateX(-50%);
      }
      &.ui-resizable-ne{
        cursor: ne-resize;
        top: -4px;
        right: -4px;
      }
      &.ui-resizable-nw{
        cursor: nw-resize;
        top: -4px;
        left: -4px;
      }
    }
  }

.view_image{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: gray;

  >img{
    position: absolute;
    left: 50%;
    max-width: 100%;
    max-height: 100%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  &.cms{
    width: 80%;
    height: 80%;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 100;
    .closeModal{
      position: absolute;
      top: -15px;
      right: -17px;
      border: 6px solid #fff;
      width: 40px;
      height: 40px;
      text-align: center;
      color: #fff;
      border-radius: 50%;
      font-size: 37px;
      line-height: 23px;
      background-color: #47bfb6;
      z-index: 99;
      box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.3);
    }
    >img{
      position: absolute;
      left: 50%;
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  &.act{
    z-index: 990;
    @media (max-width: 1200px){
      width: 100%;
      height: 100%;
      .closeModal{
        top: 0px;
        right: 0px;
      }
    }
  }
}
.view_video{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: gray;
  &.cms{
    width: 80%;
    height: 80%;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 100;
    .closeModal{
      position: absolute;
      top: -15px;
      right: -17px;
      border: 6px solid #fff;
      width: 40px;
      height: 40px;
      text-align: center;
      color: #fff;
      border-radius: 50%;
      font-size: 37px;
      line-height: 23px;
      background-color: #47bfb6;
      z-index: 99;
      box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.3);
    }
  }
  &.act{
    z-index: 990;
    @media (max-width: 1200px){
      width: 100%;
      height: 100%;
      .closeModal{
        top: 0px;
        right: 0px;
      }
    }
  }
  .react-player2{
    position: absolute;
    left: 0;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
  }
}

.input-group{
  flex-wrap: nowrap;
}

.fa-emoji{
  color: #ffda51;
  font-size: 20px;
}

.replacement_seance{
  border-bottom: 2px solid red;
}

.mq-math-mode{
  cursor: default;
  &.mq_selected{
    cursor: not-allowed;
    outline: 3px solid #b4d7ff;
  }
  *{
    font-family: Symbola, "Times New Roman", serif!important;
  }
  /*.mq-supsub{
    .mq-sup{
      margin-bottom: 10px!important;
    }
    .mq-sub{
      margin-top: 20px!important;
    }
  }*/
}



/*.mq-math-mode var, .mq-math-mode i, .mq-math-mode i.mq-font, .mq-math-mode .mq-sqrt-stem{
  display: inline-flex;
}*/

// .mq-supsub{
//   position: relative;
//   margin-right: 5px;
//   vertical-align:0px!important;
//   &:after{
//     content: '';
//   }
//   .mq-sup{
//     position: absolute;
//     top: -10px;
//   }
//   .mq-sub{
//     position: absolute;
//     bottom: -5px;
//   }
// }
body{
  .tox-silver-sink{
    z-index: 111111111111;
  }
}
.pos_rel{
  position: relative;
}
.p_l_14{
  padding-left: 14px;
}
.w_600{
  width: 600px !important;
}
.w_500{
  width: 500px !important;
}
.post_type_icon{
  background-image: url('../img/iconProfessor.svg');
  color: yellow;
  width: 24px;
  background-repeat: no-repeat;
  padding-left: 3px; 
  cursor: pointer; 
  background-size: contain; 
  height: 20px; 
  margin: 3px;
}
.h_100{
  height: 100%;
  p.p_render{
      height: calc(100% - 15px);
      overflow-y: auto;
      margin-top: 15px;
  }
  .tox-toolbar__primary{
      width: 100%;
      padding-right: 20px;
  }
}
.d_flex{
  display: flex;
}
.jc_sb{
  justify-content: space-between;
}
.h_0{
  height: 0 !important;
}

.school_validation_params{
  .modal-content{
    height: 100%;
    &> div{
      height: 100%;
      .modal-body{
        height: calc(100% - 40px);
        .list_classes_controls{
          height: calc(100% - 56px);
          overflow: auto;
        }
      }
    }
  }
}

.dialogAddGradThreshold{
  .choosed_color{
    width: 50px;
    height: 30px;
    border-radius: 15px;
    cursor: pointer;
    border: 2px solid;
  }
}


.dialogGenerateControls{
  max-width: 90%;
  width: 90%;
  height: 95%;
  .generate_controls{
    height: 100%;
    .header{
      text-align: center;
    }
    .form{
      height: calc(100% - 47px);
      .add-school-form{
        height: calc(100% - 45px);
        margin-bottom: 15px;
      }
      .actions{
        width: 100%;
      }
      .controls_table{
        height: calc(100% - 87px);
      }
    }
    .table_controls{
      height: 100%;
      overflow-y: auto;
      background-color: rgba(255,255,255,0.3);
      border-radius: 8px;
      padding: 10px 5px;
      border: 1px solid rgb(209,219,226);
      table{
        max-width: 100%;
        &.table_content{
          border-collapse: separate;
          border-spacing: 0px;
          tr{
            &:not(.spacer){
              th{
                padding: 10px;
                text-align: center;
                background-color: rgb(245,246,250);
                border: 1px solid rgb(232,232,232);
                border-width: 1px 0px 1px 1px;
                &:first-child{
                  border-top-left-radius: 4px;
                  border-bottom-left-radius: 4px;
                }
                &:last-child{
                  border-right-width: 1px;
                  border-top-right-radius: 4px;
                  border-bottom-right-radius: 4px;
                }
                &.subject{
                  width: 55%;
                }
              }
              td{
                background-color: rgb(245,246,250);
                border: 1px solid rgb(232,232,232);
                border-width: 1px 0px 1px 1px;
                height: 50px;
                padding: 10px;
                &:first-child{
                  border-top-left-radius: 4px;
                  border-bottom-left-radius: 4px;
                }
                &:last-child{
                  border-right-width: 1px;
                  border-top-right-radius: 4px;
                  border-bottom-right-radius: 4px;
                }
                &.subject{
                }
                &.control{
                  position: relative;
                  .RadioLegend{
                    margin-right: 10px;
                  }
                  div{
                    display: flex;
                    &.field_error{
                      font-size: 10px;
                      position: absolute;
                      bottom: -3px;
                    }
                  }
                }
              }
            }
            &.spacer{
              height: 10px;
            }
          }
        }
      }


      &::-webkit-scrollbar-track{
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        background-color: #F5F5F5;
      }
      &::-webkit-scrollbar{
          width: 5px;
          height: 3px;
          background-color: #F5F5F5;
      }
      &::-webkit-scrollbar-thumb{
          background-color: #3eb6ad;
      }
    }
    .field_error{
      color: red;

    }
  }
}

.dialogSortChapters{
  overflow: unset !important;
}

.upload_file_button{
  button{
    position: relative;
    .file_uploading{
      left: 50%;
      top: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
    }
    input{
      cursor: pointer;
      opacity: 0 !important;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }
  .actions{
    display: flex;
    justify-content: center;
    width: 60px;
    button{
      margin: 0 5px;
      padding: 0 5px;
      width: 20px!important;
      height: 20px!important;
      font-size: 12px;
      line-height: 20px;
      i{
        font-size: 13px;
      }
    }
    .remove_document{
      color: red;
      margin: 0 5px;
      cursor: pointer;
      border-radius: 50%;
      border: 1px solid red;
      display: block;
      width: 20px;
      height: 20px;
      line-height: 19px;
      i{
        margin-left: 4px;
      }
    }
  }
  &.absences_modal{
    .document{
      display: flex;
      justify-content: center;
    }
    button{
    }
    .remove_document{
      margin-top: 7px;
      i{
        margin-left: 3px;
      }
    }
    &.card{
      img{
        margin: auto;
      }
      .remove_document{
        margin: auto;
        i{
          margin-left: 2px;
        }
      }
      button{
        min-width: max-content;
        border: 0;
        margin-top: 5px;
        margin-left: 5px;
        overflow: hidden;
        input{
          z-index: 1111;
        }
      }
    }
    &.professor_abs{
      .remove_document{
        border: 0;
        i{
          width: 100%;
          height: 100%;
          padding-top: 0;
        }
      }
    }
  }
}